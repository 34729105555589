import { toArray, toNumber } from '../../../../utils';
import { LauncherTaskStep, TriggerEntity } from './launcher-config.fragment';

export const mapTrigger = (value: TriggerEntity): TriggerEntity | null => {
  if (value?.triggerChoice === 'activation' && value.activationTrigger) {
    return {
      triggerChoice: value.triggerChoice,
      activationTrigger: {
        value: toNumber(value.activationTrigger?.value, 0),
        unit: value.activationTrigger?.unit,
        order: value.activationTrigger?.order,
        dependentOn: value.activationTrigger?.dependentOn,
        itemId: value.activationTrigger?.itemId || null,
        deadlineType: value.activationTrigger?.deadlineType || undefined,
        deadlineValue: toNumber(value.activationTrigger?.deadlineValue),
        deadlineUnit: value.activationTrigger?.deadlineUnit || undefined,
      },
      deadlineTrigger: undefined,
    };
  }

  if (value?.triggerChoice === 'deadline' && value.deadlineTrigger) {
    return {
      triggerChoice: value.triggerChoice,
      deadlineTrigger: {
        value: toNumber(value.deadlineTrigger?.value, 0),
        unit: value.deadlineTrigger?.unit,
        order: value.deadlineTrigger?.order,
        dependentOn: value.deadlineTrigger?.dependentOn,
        itemId: value.deadlineTrigger?.itemId || null,
      },
      activationTrigger: undefined,
    };
  }

  if (value?.triggerChoice === 'default') {
    return {
      triggerChoice: value.triggerChoice,
      deadlineTrigger: undefined,
      activationTrigger: undefined,
    };
  }
  return null;
};

const mapTaskSteps = (steps?: LauncherTaskStep[]) => {
  return toArray(steps).map((value) => {
    return {
      stepId: value.stepId,
      title: value.title,
      type: value.type,
      formId: value.formId || undefined,
      sopId: value.sopId || undefined,
      trainingId: value.trainingId || undefined,
    };
  });
};

export const toLauncherConfig = (values: any = {}) => {
  return {
    title: values.title,
    published: values.published,
    contents: toArray(values.contents).map((phase) => ({
      eid: phase.eid,
      category: phase.category,
      meta: phase.meta || undefined,
      assignedType: phase.assignedType || undefined,
      assignedUser: phase.assignedUser || [],
      trigger: mapTrigger(phase.trigger) || undefined,
      triggerAssignment: phase.triggerAssignment || undefined,
      tasks: toArray(phase.tasks).map((task) => ({
        eid: task.eid,
        title: task.title,
        description: task.description || '',
        category: task.category,
        isRequired: task.isRequired,
        docRequired: task.docRequired,
        complianceDocRequired: task.docRequired
          ? task.complianceDocRequired
          : false,

        meta: task.meta || undefined,
        assignedType: task.assignedType || undefined,
        assignedUser: task.assignedUser || [],
        trigger: mapTrigger(task.trigger) || undefined,
        steps: mapTaskSteps(task.steps),
      })),
    })),
    updateLocations: undefined as unknown as boolean,
  };
};
