import { ChevronRightIcon } from '@chakra-ui/icons';
import { Badge, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateToCustom } from 'sub-components/Launcher/dashboard/helper';
import stars from '../../../../../../assets/images/lolav2dashboard/starsmakelive.png';
import { AmplitudeEvent, deployEvent } from '../../../../../../shared';
import { ActionButton } from 'ui-components';
import { faWarning } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IoWarningOutline } from 'react-icons/io5';
import { usePendingApprovals } from 'sub-components/nexus/Compliance/Listing/components/common';
import { Image } from 'ui-components';

interface CardProps {
  card: {
    id: number;
    title: string;
    description?: string;
    status:
      | 'Ready to Go Live'
      | 'Lagging'
      | 'Last Phase'
      | 'Pending Compliance approvals';
    date: string;
    badge: string;
    phaseName: string;
    days: string;
    liveDate: string;
    pendingTasksCount?: string;
    launchId: string;
    approvalStatus: string;
    location: {
      name: string;
      eid: string;
      launchId: string;
    };
    board: string;
    locationId: string;
  };
  locMakeLiveHandler: () => void;
}

const Card: FC<CardProps> = ({ card, locMakeLiveHandler }) => {
  const history = useHistory();
  const pendingApprovals = usePendingApprovals();

  const sendEvent = () => {
    deployEvent(AmplitudeEvent.LAUNCHER_REQUIRE_ATTENTION_CLICK, {
      location_name: card.title,
      location_id: card?.locationId,
      late_by_days: card.days,
      status: card.status,
    });
  };

  //card type rendering
  const renderContent = () => {
    switch (card?.status) {
      case 'Ready to Go Live':
        return (
          <>
            {/* @ts-ignore  */}
            <Flex
              maxWidth='339px'
              minWidth='339px'
              height='120px'
              bg='#B5E4CA'
              borderRadius='12px'
              p='20px'
              position='relative'
              direction={'column'}
              cursor={'default'}
            >
              <Image
                src={stars}
                alt='Sparkle'
                height={200}
                width={200}
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  height: '100% !important',
                  width: '100% !important',
                }}
              />
              <Flex direction='column' gap={'4px'}>
                <Tooltip
                  label={card?.title}
                  isDisabled={card?.title?.length < 25}
                >
                  <Text
                    as={'span'}
                    fontSize='15px'
                    fontWeight='700'
                    isTruncated
                    maxWidth='150px'
                    color={'#000000'}
                    zIndex={1}
                  >
                    {card?.title}
                  </Text>
                </Tooltip>
                <Tooltip
                  label={card?.board}
                  isDisabled={card?.board?.length < 25}
                >
                  <Text
                    as={'span'}
                    fontSize='12px'
                    fontWeight='400'
                    isTruncated
                    maxWidth='150px'
                    color={'#6F767E'}
                    zIndex={1}
                  >
                    {card?.board}
                  </Text>
                </Tooltip>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems='center'>
                <Text
                  fontSize='14px'
                  fontWeight={500}
                  color='#00000'
                  lineHeight={'24px'}
                  letterSpacing={'-0.1px'}
                  zIndex={10000}
                >
                  Completed on {card.date ? formatDateToCustom(card.date) : '-'}
                </Text>
                <Button
                  aria-label='Make live'
                  bg='#83BF6E'
                  fontSize='15px'
                  fontWeight={600}
                  textColor='#FCFCFC'
                  borderRadius='12px'
                  width='104px'
                  height='40px'
                  _hover={{ bg: '#83BF6E' }}
                  _active={{ bg: '#83BF6E' }}
                  size='md'
                  onClick={() => {
                    sendEvent();
                    return locMakeLiveHandler();
                  }}
                >
                  Make live!
                </Button>
              </Flex>
            </Flex>
          </>
        );

      case 'Lagging':
        return (
          <>
            <Flex
              width='314px'
              height='146px'
              bg='#F4F4F4'
              borderRadius='12px'
              p='20px'
              onClick={() => {
                sendEvent();
                history.push(`/launcher/location/${card.launchId}`);
              }}
              cursor={'pointer'}
            >
              <Flex direction='column' gap={'8px'}>
                <Flex direction='column' gap={'4px'}>
                  <Tooltip
                    label={card?.title}
                    isDisabled={card?.title?.length < 25}
                  >
                    <Text
                      as={'span'}
                      fontSize='15px'
                      fontWeight='700'
                      isTruncated
                      maxWidth='200px'
                      color={'#000000'}
                    >
                      {card?.title}
                    </Text>
                  </Tooltip>
                  <Tooltip
                    label={card?.board}
                    isDisabled={card?.board?.length < 20}
                  >
                    <Text
                      as={'span'}
                      fontSize='12px'
                      fontWeight='400'
                      isTruncated
                      maxWidth='150px'
                      color={'#6F767E'}
                    >
                      {card?.board}
                    </Text>
                  </Tooltip>
                </Flex>
                <Tooltip
                  label={card?.phaseName}
                  isDisabled={card?.phaseName?.length < 20}
                >
                  <Text
                    fontSize='14px'
                    fontWeight='600'
                    color={'#000000'}
                    isTruncated
                    maxWidth='180px'
                  >
                    {card?.phaseName}
                  </Text>
                </Tooltip>
                <Flex
                  fontSize='12px'
                  fontWeight='500'
                  gap={2}
                  align='center'
                  wrap='nowrap'
                  mr={5}
                >
                  <Badge
                    size='lg'
                    variant='solid'
                    borderRadius='6px'
                    bgColor={HeaderColors.Red.Mid}
                    textTransform='none'
                    py={1}
                    px={2}
                    fontWeight='500'
                    flexShrink={0}
                  >
                    {card?.badge}
                  </Badge>
                  <Text
                    color={HeaderColors.Red.Mid}
                    fontSize='12px'
                    fontWeight={600}
                    flex='1'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                  >
                    Late by {card?.days} days
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </>
        );

      case 'Last Phase':
        return (
          <>
            <Flex
              justifyContent={'space-between'}
              width='314px'
              height='146px'
              bg='#F4F4F4'
              borderRadius='12px'
              p='20px'
              onClick={() => {
                sendEvent();
                history.push(`/launcher/location/${card.launchId}`);
              }}
              cursor={'pointer'}
            >
              <Flex direction='column' gap={'8px'}>
                <Flex direction='column' gap={'4px'}>
                  <Tooltip
                    label={card?.title}
                    isDisabled={card?.title?.length < 25}
                  >
                    <Text
                      as={'span'}
                      fontSize='15px'
                      fontWeight='700'
                      isTruncated
                      maxWidth='200px'
                      color={'#000000'}
                    >
                      {card?.title}
                    </Text>
                  </Tooltip>
                  <Tooltip
                    label={card?.board}
                    isDisabled={card?.board?.length < 20}
                  >
                    <Text
                      as={'span'}
                      fontSize='12px'
                      fontWeight='400'
                      isTruncated
                      maxWidth='150px'
                      color={'#6F767E'}
                    >
                      {card?.board}
                    </Text>
                  </Tooltip>
                </Flex>

                <Tooltip
                  label={card?.phaseName}
                  isDisabled={card?.phaseName?.length < 20}
                >
                  <Text
                    fontSize='14px'
                    fontWeight='600'
                    color={'#000000'}
                    isTruncated
                    maxWidth='180px'
                    as={'span'}
                  >
                    {card?.phaseName}
                  </Text>
                </Tooltip>
                <Text
                  as={'span'}
                  fontSize='13px'
                  fontWeight='500'
                  color={'#2A85FF'}
                >
                  {`Expected Go-Live: ${formatDateToCustom(card?.liveDate)}` ||
                    ''}
                </Text>
              </Flex>
              <Button
                aria-label='Action button'
                bg={HeaderColors.White}
                size='xs'
                shadow='sm'
                borderRadius='4px'
                onClick={() => {
                  sendEvent();
                  history.push(`/launcher/location/${card?.launchId}`);
                }}
              >
                <ChevronRightIcon />
              </Button>
            </Flex>
          </>
        );
      case 'Pending Compliance approvals':
        return (
          <>
            <Flex
              justifyContent='space-between'
              background='#F4F4F4'
              padding='20px'
              borderRadius='12px'
            >
              <Flex direction='column' gap={3} flex='1'>
                <Flex gap={2} align='center' wrap='nowrap'>
                  <Text color={'#000000'} fontWeight={700} fontSize={'15px'}>
                    {card?.location?.name}
                  </Text>
                </Flex>
                <ActionButton
                  colorScheme={'red'}
                  color={'white'}
                  leftIcon={
                    <IoWarningOutline color={'white'} fontSize={'18px'} />
                  }
                  actionFn={() => {
                    pendingApprovals({
                      locationId: card?.location?.eid,
                    });
                  }}
                >
                  View pending approvals
                </ActionButton>
              </Flex>
              <Button
                aria-label='Action button'
                bg={HeaderColors.White}
                size='xs'
                shadow='sm'
                borderRadius='4px'
                onClick={() => {
                  sendEvent();
                  history.push(
                    `/launcher/location/${card?.locationId}/${card?.location?.launchId}/documents`
                  );
                }}
              >
                <ChevronRightIcon />
              </Button>
            </Flex>
          </>
        );
      default:
        return <Text>Unknown status</Text>;
    }
  };

  return renderContent();
};

export default Card;
