import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../../../../atoms';
import TitleHeader from '../../../../../CardEditor/TitleHeader';
import { IDocumentInput } from './compliance.input';

interface IProps {
  disableDocumentName?: boolean;
}

const DocumentTitleInput: FC<IProps> = ({ disableDocumentName }) => {
  const { t } = useTranslation(['nexus']);

  return (
    <Controller<IDocumentInput, 'title'>
      name='title'
      defaultValue=''
      rules={{ required: t('nexus:documents.validations.titleRequired') }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <TitleHeader
            title='Add document name'
            titleStyle={{ marginBottom: '8px' }}
            isRequired={true}
          />
          <Input
            disabled={disableDocumentName}
            variant='auditOutline'
            size='lg'
            placeholder='Document title'
            {...field}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

DocumentTitleInput.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/DocumentTitleInput';

export default DocumentTitleInput;
