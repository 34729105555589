import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import DocTitleInput from './DocTitleInput';

interface IProps {}

const AddForm: FC<IProps> = () => {
  return (
    <Flex>
      <DocTitleInput />
    </Flex>
  );
};

AddForm.displayName =
  'packages/sop-web/src/pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/uploadOtherDoc/AddForm';

export default AddForm;
