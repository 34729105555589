import React, { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import ExpiryDateSelectField from './ExpiryDateSelectField';
import CategorySelectField from './CategorySelectField';
import LocationSelectField from './LocationSelectField';
import { DocumentReminder } from './document-reminder';
import SignedDateSelectField from './SignedDateSelectField';
import { Flex } from '@chakra-ui/react';
import DocumentTitleInput from './DocumentTitleInput';

interface IProps {
  complianceId?: string;
  type?: 'update' | 'reminder';
  preDefinedLocationId?: string;
  disableCategorySelect?: boolean;
  disableDocumentName?: boolean;
  disableSigningDate?: boolean;
  disableExpiryDate?: boolean;
  disableReminder?: boolean;
}

const UpdateDocumentForm: FC<IProps> = ({
  complianceId,
  type,
  preDefinedLocationId,
  disableCategorySelect,
  disableDocumentName,
  disableSigningDate,
  disableExpiryDate,
  disableReminder,
}) => {
  return (
    <>
      <DocumentTitleInput disableDocumentName={disableDocumentName} />
      {type !== 'reminder' && (
        <CategorySelectField disableCategorySelect={disableCategorySelect} />
      )}

      {type !== 'reminder' && (
        <LocationSelectField preDefinedLocationId={preDefinedLocationId} />
      )}
      <Flex gap={8}>
        <SignedDateSelectField
          isEditing
          disableSigningDate={disableSigningDate}
        />
        <ExpiryDateSelectField
          isEditing
          disableExpiryDate={disableExpiryDate}
        />
      </Flex>

      <DocumentReminder disableReminder={disableReminder} />
    </>
  );
};

UpdateDocumentForm.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/UpdateDocumentForm';

export default UpdateDocumentForm;
