import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownShortWide, faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

export type SortType =
  | 'EXPIRYDATE_ASC'
  | 'EXPIRYDATE_DESC'
  | 'CREATEDAT_ASC'
  | 'CREATEDAT_DESC';

type sortKey = 'EXPIRYDATE' | 'CREATEDAT';

type SortPopoverProps = {
  sorting: SortType | undefined;
  setSorting: (value: SortType | undefined) => void;
  sortKey: sortKey;
};

const SortPopover: React.FC<SortPopoverProps> = ({
  sorting,
  setSorting,
  sortKey,
}) => {
  const sortingItems = useMemo(() => {
    switch (sortKey) {
      case 'CREATEDAT':
        return [
          { label: 'Oldest first', value: 'CREATEDAT_ASC' },
          { label: 'Newest first', value: 'CREATEDAT_DESC' },
        ];
      case 'EXPIRYDATE':
        return [
          { label: 'Oldest expiry date first', value: 'EXPIRYDATE_DESC' },
          { label: 'Newest expiry date first', value: 'EXPIRYDATE_ASC' },
        ];
      default:
        return [];
    }
  }, [sortKey]);

  const isSortingEnabled = useMemo(() => {
    if (sortKey === 'CREATEDAT') {
      return sorting === 'CREATEDAT_ASC' || sorting === 'CREATEDAT_DESC';
    }

    if (sortKey === 'EXPIRYDATE') {
      return sorting === 'EXPIRYDATE_ASC' || sorting === 'EXPIRYDATE_DESC';
    }
  }, [sortKey, sorting]);

  return (
    <Popover placement='bottom-end' isLazy>
      <PopoverTrigger>
        <Box as='span' display='inline-block'>
          <FontAwesomeIcon
            color={isSortingEnabled ? '#2A85FF' : '#6F767E'}
            cursor='pointer'
            icon={faArrowDownShortWide as IconProp}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w='220px' zIndex={1000} borderRadius='16px'>
          <PopoverBody>
            <Flex flexDirection='column' gap={1} align='flex-start'>
              {isSortingEnabled && (
                <Button
                  color='#2A85FF'
                  variant='unstyled'
                  gap={2}
                  alignItems='center'
                  onClick={() => setSorting(undefined)}
                  fontSize='12px'
                  display='flex'
                  padding='0px 12px'
                >
                  <FontAwesomeIcon
                    color={'#2A85FF'}
                    cursor='pointer'
                    icon={faX as IconProp}
                  />
                  Clear selection
                </Button>
              )}

              {sortingItems.map((item, index) => (
                <Button
                  key={index}
                  variant='unstyled'
                  onClick={() => setSorting(item.value as SortType)}
                  _hover={{
                    backgroundColor: '#2A85FF1A',
                  }}
                  color='#1A1D1F'
                  fontWeight={500}
                  fontSize='14px'
                  borderRadius='8px'
                  backgroundColor={
                    sorting === item.value ? '#2A85FF1A' : '#FFFFFF'
                  }
                  display='flex'
                  alignItems='center'
                  padding='12px'
                  width='100%'
                  justifyContent='flex-start'
                >
                  {item.label}
                </Button>
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default SortPopover;
