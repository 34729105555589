import { Center, Box, useToast } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { LuDownload } from 'react-icons/lu';
import { NexusDocumentType } from '../DocumentFilters/FiltersAndListing';
import axios from 'axios';
import { ActionButton } from 'ui-components';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';

interface IProps {
  type?: NexusDocumentType;
  locationId?: string;
  categoryIds?: string[];
  includeTask?: boolean;
  entityId?: string;
  locationIds?: string[];
  approvalStatuses?: string[];
}

const reqUrl = `${process.env.REACT_APP_OPS_ENDPOINT}/dump/compliance`;

const ExportDocuments: FC<IProps> = ({
  type,
  locationId,
  categoryIds,
  includeTask,
  entityId,
  locationIds,
  approvalStatuses,
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });
  const { userAuthRole } = useUserDataSelector((state) => {
    return {
      userAuthRole: state.authRole,
    };
  });

  const exportDocuments = () => {
    const data: {
      locationIds?: string[];
      categoryIds?: string[];
      includeTaskInfo?: boolean;
      type?: NexusDocumentType;
      entityId?: string;
      approvalStatuses?: string[];
    } = {};

    if (categoryIds?.length && userAuthRole !== AuthRole.LOCATION_OWNER) {
      data.categoryIds = categoryIds;
    }

    if (includeTask) {
      data.includeTaskInfo = true;
    }

    if (locationId) {
      data.locationIds = [locationId];
    }

    if (type) {
      data.type = type;
    }

    if (entityId) {
      data.entityId = entityId;
    }

    if (locationIds) {
      data.locationIds = locationIds;
    }

    if (approvalStatuses) {
      data.approvalStatuses = approvalStatuses;
    }

    setLoading(true);

    axios.post(reqUrl, data, { responseType: 'blob' }).then((response) => {
      const _url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = _url;
      link.setAttribute('download', 'documentExport.xlsx');
      document.body.appendChild(link);
      link.click();
      // Cleanup and toast

      toast({
        title: `Summary exported successfully.`,
        status: 'success',
      });

      window.URL.revokeObjectURL(_url);
      document.body.removeChild(link);
      setLoading(false);
    });
  };

  return (
    <ActionButton
      actionFn={exportDocuments}
      isLoading={loading}
      disabled={loading}
      width='46px'
      height='46px'
      border='2px solid #EFEFEF'
      borderRadius='12px'
      cursor='pointer'
      variant='unstyled'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <LuDownload size='20px' />
    </ActionButton>
  );
};

ExportDocuments.displayName =
  'displayName:LocationDetails/components/LocationAssetsCard/components/Documents/components/ExportDocuments.tsx';

export default ExportDocuments;
