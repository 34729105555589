import { FC, useEffect, useState } from 'react';
import { useDisclosure, Text, Flex } from '@chakra-ui/react';
import { VersionHistoryType } from '../../../types/compliance.types';
import { Heading } from '../Filter';
import VersionHistoryList from './VersionHistoryList';
import { useLazyQuery } from '@apollo/client';
import { GET_DOCUMENT_VERSION } from 'sub-components/nexus/Compliance/query/compliance.graphql';
import { Loader } from 'sub-components';

interface IProps {
  complianceId: string;
  closeModal?: () => void;
}

const VersionHistoryContent: FC<IProps> = ({ closeModal, complianceId }) => {
  const [complianceData, setComplianceData] = useState<VersionHistoryType>({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [getVersionHistory, { loading: versionLoader }] = useLazyQuery(
    GET_DOCUMENT_VERSION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setComplianceData(data?.EntityComplianceById);
      },
    }
  );

  useEffect(() => {
    getVersionHistory({
      variables: {
        eid: complianceId,
      },
    });
  }, []);

  return (
    <>
      {versionLoader || !complianceData?.eid ? (
        <Flex position={'relative'} h={'500px'}>
          <Flex
            height={'100%'}
            width={'100%'}
            left={'50%'}
            top={'50%'}
            position={'fixed'}
            h={'500px'}
          >
            <Loader />
          </Flex>
        </Flex>
      ) : (
        <Flex flexDir={'column'}>
          <Text fontWeight={600} fontSize={'18px'}>
            {`${
              complianceData?.location?.name
                ? complianceData?.location?.name
                : 'HQ'
            }, ${complianceData?.category?.category}`}
          </Text>
          <Flex py={'14px'} overflowY={'auto'} h={'500px'}>
            <VersionHistoryList data={complianceData} />
          </Flex>
        </Flex>
      )}
    </>
  );
};

VersionHistoryContent.displayName =
  'packages/sop-web/src/sub-components/nexus/Compliance/Listing/components/VersionHistory/VersionHistoryContent';
export default VersionHistoryContent;
