import React, { FC, useMemo } from 'react';
import { IComplianceFile } from 'sub-components/Launcher/tasks/components/task-details/types';
import { useControl } from './layers';
import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { ReactComponent as CloseicondarkIcon } from 'assets/images/closeIconDark.svg';
import complianceDoc from 'assets/images/compliance/complianceDoc.svg';
import othersDoc from 'assets/images/compliance/othersDoc.svg';

const blueFilter =
  'invert(38%) sepia(89%) saturate(1356%) hue-rotate(199deg) brightness(99%) contrast(104%)';
const purpleFilter = `invert(39%) sepia(86%) saturate(3583%) hue-rotate(240deg) brightness(103%) contrast(101%)`;

const DocumentChip = ({
  document,
  refetchData,
  taskStatus,
}: {
  document: IComplianceFile;
  refetchData: () => void;
  taskStatus: string;
}) => {
  const control = useControl(refetchData);

  const getDocumentColor = () => {
    if (document.status === 'inactive' && document.type === 'compliance')
      return '#ceecfd';

    if (document.status === 'inactive' && document.type === 'other')
      return '#CABDFF4D';

    if (
      document.type === 'compliance' &&
      document.approvalStatus === 'approved' &&
      document.status === 'active'
    )
      return '#ceecfd';

    if (document.type === 'compliance') return '#B1E5FC';

    if (document.type === 'other') return '#CABDFF';
  };

  const getTextColor = () => {
    if (document.status === 'inactive') return '#7d888f';

    if (
      document.type === 'compliance' &&
      document.approvalStatus === 'approved' &&
      document.status === 'active'
    )
      return '#7d888f';

    return '#111315';
  };

  const showRemoveIcon = useMemo(() => {
    if (taskStatus === 'completed') return false;
    if (document.status === 'inactive') return false;

    if (
      document.approvalStatus === 'approved' &&
      document.type === 'compliance' &&
      document.status === 'active'
    )
      return false;

    return true;
  }, [document]);

  const lastFile = document?.files?.[document?.files?.length - 1];
  if (!lastFile) return null;

  return (
    <Flex
      padding='5px 10px'
      background={getDocumentColor()}
      borderRadius='7px'
      alignItems='center'
      gap={2}
    >
      {['image/png', 'image/jpeg'].includes(lastFile.type) && lastFile.url ? (
        <Image
          src={lastFile.url}
          height={25}
          width={25}
          style={{ borderRadius: '50%' }}
        />
      ) : (
        <Image
          src={document.type === 'compliance' ? complianceDoc : othersDoc}
          alt='other doc'
          width='24px'
          filter={document.type === 'compliance' ? blueFilter : ''}
        />
      )}

      <Tooltip
        label={
          document.approvalStatus === 'approved' &&
          document.type === 'compliance'
            ? `${lastFile.name} has been approved`
            : lastFile.name
        }
      >
        <Text
          color={getTextColor()}
          fontWeight={500}
          fontSize='14px'
          isTruncated
          maxWidth='90px'
          cursor='pointer'
          onClick={() => {
            window.open(lastFile.url, '_blank');
          }}
        >
          {lastFile.name}
        </Text>
      </Tooltip>
      {showRemoveIcon && (
        <CloseicondarkIcon
          cursor='pointer'
          width='18px'
          onClick={() => {
            control?.removeFile(
              lastFile,
              document?.files,
              document.metadata?.launchId as string,
              document.metadata?.taskId as string,
              document?.eid
            );
          }}
        />
      )}
    </Flex>
  );
};

export default DocumentChip;
