import React, { FC, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import { DashboardContainer } from '../../../sub-components';
import { ListContainer } from 'sub-components/Launcher/document-listing/components';
import LocationDocumentHeading from './LocationDocumentHeading';
import { useParams } from 'react-router-dom';

interface RouteParams {
  locationId: string;
}

const DocumentListingPage: FC = () => {
  const { locationId } = useParams<RouteParams>();
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  return (
    <DashboardContainer>
      <LocationDocumentHeading
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
      />
      <Flex direction='column'>
        <Flex mt='20px' columnGap='1rem'>
          <div
            style={{
              width: '100%',
            }}
          >
            <Card
              style={{
                position: 'relative',
                borderRadius: '12px',
              }}
            >
              <ListContainer
                locId={locationId}
                triggerRefresh={triggerRefresh}
              />
            </Card>
          </div>
        </Flex>
      </Flex>
    </DashboardContainer>
  );
};

export default DocumentListingPage;
