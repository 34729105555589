import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import BaseContent from './uploadOtherDoc/BaseContent';
import { MetaComplianceData } from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';

interface IProps {
  documentId?: string;
  documentType?: string;
  locationId?: string;
  closeDocumentTypeSelectionModal?: () => void;

  /**`localSave` is used to prevent api hit*/
  localSave?: boolean;

  /** Used to show read-only input field for license and will show instead of License dropdown */
  preDefinedLicense?: string;

  /** Used to show read-only input field for location name and will show instead of location dropdown */
  preDefinedLocationName?: string;

  /** Used to show pre selected location in the dropdown which will be read-only */
  preDefinedLocationId?: string;

  /** Used to return the data as callback that had to be used otherwise for api hit */
  localSaveCb?: (data: any) => void;

  /** If file is selected from elsewhere and only the form has to be shown  */
  initialFile?: File;

  /** Successfully returned data callback */
  successCb?: (data: any | undefined) => void;

  /** Meta Compliance Data: `MetaComplianceData` */
  metaComplianceData?: MetaComplianceData;
}

type OtherDocsProp = (props?: IProps) => void;

export const useUploadOtherDoc = (): OtherDocsProp => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      documentId,
      documentType,
      locationId,
      closeDocumentTypeSelectionModal,

      localSave = false,
      preDefinedLicense = '',
      preDefinedLocationName = '',
      preDefinedLocationId = '',
      initialFile,
      metaComplianceData,
      localSaveCb,
      successCb,
    } = {}) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex justify='space-between' gap={3}>
            <BoxHeader
              title={documentId ? 'Rename document' : 'Add document'}
              color={'#CABDFF'}
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <BaseContent
            locationId={locationId}
            documentType={documentType}
            documentId={documentId}
            closeModal={() => confirmRef.current?.destroy()}
            closeDocumentTypeSelectionModal={closeDocumentTypeSelectionModal}
            localSave={localSave}
            preDefinedLicense={preDefinedLicense}
            preDefinedLocationName={preDefinedLocationName}
            preDefinedLocationId={preDefinedLocationId}
            localSaveCb={localSaveCb}
            successCb={successCb}
            initialFile={initialFile}
            metaComplianceData={metaComplianceData}
          />
        ),
        isCentered: true,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '12px',
          minW: '550px',
        },
        footer: null,
      });
    },
    []
  );
};
