import {
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Image,
  Text,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-duotone-svg-icons';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { getFileType } from 'utils/utils';
import { cloneDeep } from '@apollo/client/utilities';
import {
  FileWithEid,
  useUploadMultipleFilesWithCancel,
} from 'hooks/useUploadMultipleFilesWithCancel';
import { v4 as uuidv4 } from 'uuid';
import { IModeType } from './Body/BodyComponent';
import { getFileIcon } from 'utils';
import {
  AddLauncherTaskFilesResponse,
  AddLauncherTaskFilesVariables,
  FileInputEntity,
  IFilesProgressData,
  IRefetchType,
} from './task-detail-drawer.types';
import { useMutation } from '@apollo/client';
import { ADD_LAUNCHER_TASK_FILES } from './task-detail-drawer.graphql';
import { useUserDataSelector } from 'hooks';
import Loader from 'ui-components/Loader';
import useCombinedStore from 'zustandStore/store';
import { AuthRole } from 'sop-commons/src/client';

interface IProps {
  mode: IModeType;
  selectedTaskData: CustomLauncherTask;
  filesProgressData: IFilesProgressData[];
  refetchHandler: (type: IRefetchType) => void;
  setFilesProgressData: React.Dispatch<
    React.SetStateAction<IFilesProgressData[]>
  >;
}

const TaskDrawerUpload: FC<IProps> = ({
  mode,
  selectedTaskData,
  filesProgressData,
  refetchHandler,
  setFilesProgressData,
}) => {
  const {
    taskDetails: {
      isUploading,
      selectedFiles,
      updateIsUploading,
      updateSelectedFiles,
    },
  } = useCombinedStore();
  const taskStatus = selectedTaskData?.taskStatus;
  const loggedInUserDataEid = useUserDataSelector((state) => state.eid);
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const toast = useToast();
  const { uploadFiles, cancelUpload } = useUploadMultipleFilesWithCancel();

  const [isFileDeleting, setIsFileDeleting] = useState(false);

  const [addLauncherTaskFiles] = useMutation<
    AddLauncherTaskFilesResponse,
    AddLauncherTaskFilesVariables
  >(ADD_LAUNCHER_TASK_FILES);

  const onFileDrop = async (files: File[]) => {
    let filesWithEid = files?.map((file) => {
      return {
        file,
        eid: uuidv4(),
        url: '',
      };
    });
    updateSelectedFiles([...selectedFiles, ...filesWithEid]);
    let filesArr: IFilesProgressData[] = [];
    filesWithEid?.forEach((file) => {
      filesArr.push({
        progressNum: 0,
        uploadStatus: 'initial',
        fileEid: file?.eid,
      });
    });
    setFilesProgressData([...filesProgressData, ...filesArr]);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        [],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/zip': ['.zip'],
    },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });

  const baseCloseIcon = (
    selectedFile: IFilesProgressData,
    fileEid: string,
    fileUrl: string
  ) => {
    // if (selectedFile?.uploadStatus === 'uploading') return;
    if (isUploading) return;
    const iconStyle = {
      '--fa-primary-color': 'white',
      '--fa-secondary-color': 'black',
      '--fa-secondary-opacity': '1',
    };
    return (
      <Box
        className='close-icon'
        position='absolute'
        top='-8px'
        right='-6px'
        cursor='pointer'
        opacity='0'
        visibility='hidden'
        transition='opacity 0.2s ease, visibility 0.2s'
        zIndex={1}
      >
        <FontAwesomeIcon
          icon={faCircleXmark as IconProp}
          fontSize='24px'
          style={iconStyle as never}
          onClick={() => {
            removeFilesHandler(fileEid, fileUrl);
          }}
        />
      </Box>
    );
  };

  const removeFilesHandler = async (fileEid: string, fileUrl: string) => {
    if (!fileUrl) {
      let _filesData = cloneDeep(selectedFiles);
      let filteredFilesData = _filesData?.filter(
        (fileData) => fileData?.eid !== fileEid
      );
      updateSelectedFiles(filteredFilesData);
    } else {
      let _filesData = cloneDeep(selectedTaskData);
      let filteredFilesData = _filesData?.files
        ?.filter((fileData) => fileData?.url !== fileUrl)
        ?.map((fileData) => {
          return {
            createdBy: loggedInUserDataEid,
            fileSize: fileData?.fileSize,
            mimetype: fileData?.mimetype,
            name: fileData?.name,
            type: fileData?.type,
            url: fileData?.url,
          };
        });
      console.log('Filtered Files Data : ', filteredFilesData);
      setIsFileDeleting(true);
      try {
        let _input = {
          launchId: selectedTaskData?.launchId,
          taskId: selectedTaskData?.eid,
          files: filteredFilesData,
        };
        console.log('INPUT : : : : : ', _input);
        let res = await addLauncherTaskFiles({
          variables: {
            addLauncherTaskFilesInput2: _input,
          },
        });
        if (res?.data?.AddLauncherTaskFiles?.succeed) {
          let _tempFilesData = cloneDeep(selectedFiles);
          let filteredFilesData = _tempFilesData?.filter(
            (fileData) => fileData?.eid !== fileEid
          );
          updateSelectedFiles(filteredFilesData);
          toast({
            status: 'success',
            title: 'File removed successfully',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
          refetchHandler('file');
        }
      } catch (err) {
        toast({
          status: 'error',
          title: 'File could not be removed',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setTimeout(() => {
          setIsFileDeleting(false);
        }, 2000);
      }
    }
  };

  const statusBanner = (selectedFile: IFilesProgressData, fileEid: string) => {
    if (selectedFile?.uploadStatus === 'initial') return;
    const uploadedIconStyle = {
      '--fa-primary-color': 'white',
      '--fa-secondary-color': '#83BF6E',
      '--fa-secondary-opacity': '1',
    };
    const errorIconStyle = {
      '--fa-primary-color': 'white',
      '--fa-secondary-color': '#FF6A55',
      '--fa-secondary-opacity': '1',
    };
    return (
      <Center
        boxSize='90px'
        position='absolute'
        borderRadius='8px'
        bg='#000000b3'
        top='0px'
        right='0px'
        cursor={
          selectedFile?.uploadStatus === 'uploaded' ? 'pointer' : 'default'
        }
      >
        {selectedFile?.uploadStatus === 'uploading' && (
          <CircularProgress value={selectedFile?.progressNum}>
            <CircularProgressLabel>
              {/* <FontAwesomeIcon
                icon={faXmark as IconProp}
                color='white'
                fontSize='20px'
                cursor='pointer'
                onClick={() => {
                  let _filesData = cloneDeep(selectedFiles);
                  let filteredFilesData = _filesData?.filter(
                    (fileData) => fileData?.eid !== fileEid
                  );
                  let _filesProgressData = cloneDeep(filesProgressData);
                  let filteredFilesProgressData = _filesProgressData?.filter(
                    (fileData) => fileData?.fileEid !== fileEid
                  );
                  cancelUpload(fileEid);
                  setSelectedFiles(filteredFilesData);
                  setFilesProgressData(filteredFilesProgressData);
                }}
              /> */}
            </CircularProgressLabel>
          </CircularProgress>
        )}
        {selectedFile?.uploadStatus === 'uploaded' && (
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            style={uploadedIconStyle as never}
            size='2x'
          />
        )}
        {selectedFile?.uploadStatus === 'error' && (
          <FontAwesomeIcon
            icon={faCircleXmark as IconProp}
            style={errorIconStyle as never}
            size='2x'
          />
        )}
      </Center>
    );
  };

  const fileNameRender = (fileName: string) => {
    return (
      <Text isTruncated maxW='90px'>
        {fileName}
      </Text>
    );
  };

  const filePreviewRender = (
    selectedFile: File,
    fileEid: string,
    fileUrl: string
  ) => {
    let fileType = getFileType(selectedFile.type);
    return (
      <Flex
        flexDir='column'
        onClick={() => fileUrl && window.open(fileUrl, '_blank')}
      >
        <Center
          position='relative'
          _hover={{
            '.close-icon': {
              opacity: 1,
              visibility: 'visible',
            },
          }}
        >
          <Box>
            {baseCloseIcon(
              filesProgressData?.find((data) => data?.fileEid === fileEid)!,
              fileEid,
              fileUrl
            )}
            <Image src={getFileIcon(fileType, false)} boxSize='90px' />
            {statusBanner(
              filesProgressData?.find((data) => data?.fileEid === fileEid)!,
              fileEid
            )}
          </Box>
        </Center>
        {fileNameRender(selectedFile?.name)}
      </Flex>
    );
  };

  const fileCreatedByCheck = (createdBy: unknown) => {
    if (typeof createdBy === 'string') {
      return createdBy || undefined;
      // Handle string case
    } else if (typeof createdBy === 'object' && createdBy !== null) {
      // Check if the object has an 'eid' property
      if ('eid' in createdBy) {
        const createdByObj = createdBy as any;
        console.log('Input is an object and has an "eid" property');
        return createdByObj.eid || undefined;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const uploadFilesToServer = async (fileUrls: FileWithEid[]) => {
    console.log('UPLOAD FILES TO SERVER : ', fileUrls);
    let fileInput: FileInputEntity[] = [];
    let filesWithUrls = fileUrls?.filter((file) => file?.url) || [];
    if (filesWithUrls?.length > 0) {
      filesWithUrls?.forEach((selected) => {
        fileInput?.push({
          createdBy: loggedInUserDataEid,
          fileSize: selected?.file?.size,
          mimetype: selected?.file?.type,
          name: selected?.file?.name,
          type: getFileType(selected?.file?.type),
          url: selected?.url,
        });
      });
    }
    let selectedTaskDataFiles: FileInputEntity[] =
      selectedTaskData?.files?.map((file) => {
        return {
          createdBy: fileCreatedByCheck(file?.createdBy?.eid),
          fileSize: file?.fileSize,
          mimetype: file?.mimetype,
          name: file?.name,
          type: file?.type,
          url: file?.url,
        };
      }) || [];
    let combinedFiles: FileInputEntity[] = [
      ...selectedTaskDataFiles,
      ...fileInput,
    ];
    try {
      let _input = {
        launchId: selectedTaskData?.launchId,
        taskId: selectedTaskData?.eid,
        files: combinedFiles,
      };
      console.log('INPUT : : : : : ', _input);
      let res = await addLauncherTaskFiles({
        variables: {
          addLauncherTaskFilesInput2: _input,
        },
      });
      if (res?.data?.AddLauncherTaskFiles?.succeed) {
        let _selectedFilesData = cloneDeep(selectedFiles);
        _selectedFilesData?.map((selected) => {
          fileUrls?.map((fileUrl) => {
            if (selected?.eid === fileUrl?.eid) {
              selected.url = fileUrl.url;
              return;
            }
          });
        });
        updateSelectedFiles(_selectedFilesData);
      }
      refetchHandler('file');
      updateIsUploading(false);
      toast({
        status: 'success',
        title: 'Files uploaded successfully',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (err) {
      updateIsUploading(false);
      toast({
        status: 'error',
        title: 'Something went wrong',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const uploadFilesHandler = async () => {
    let _filesData = cloneDeep(filesProgressData);
    updateIsUploading(true);
    let filteredSelectedFiles = selectedFiles?.filter(
      (selectedFile) => !selectedFile?.url
    );
    filteredSelectedFiles?.map((selectedFile) => {
      _filesData?.map((_fileData) => {
        if (selectedFile?.eid === _fileData?.fileEid && !selectedFile?.url) {
          _fileData.uploadStatus = 'uploading';
        }
      });
    });
    setFilesProgressData(_filesData);
    try {
      const fileUrls = await uploadFiles(
        filteredSelectedFiles,
        (progress, fileEid) => {
          setFilesProgressData((prevFiles) => {
            return prevFiles.map((file) => {
              if (file?.fileEid === fileEid) {
                return {
                  ...file,
                  progressNum: progress,
                  uploadStatus: progress === 100 ? 'uploaded' : 'uploading',
                };
              }
              return file;
            });
          });
        }
      );
      console.log('File URLS : : : : : ', fileUrls);
      if (fileUrls?.length > 0) {
        uploadFilesToServer(fileUrls);
      }
    } catch (err) {
      updateIsUploading(false);
      toast({
        status: 'error',
        title: 'Something went wrong',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const removeFileFromCompletedTask = async (selectedFile: FileInputEntity) => {
    let filteredFiles =
      selectedTaskData?.files
        ?.filter((file) => file?.url !== selectedFile?.url)
        ?.map((file) => {
          return {
            createdBy: fileCreatedByCheck(file?.createdBy?.eid),
            fileSize: file?.fileSize,
            mimetype: file?.mimetype,
            name: file?.name,
            type: file?.type,
            url: file?.url,
          };
        }) || [];
    setIsFileDeleting(true);
    try {
      let _input = {
        launchId: selectedTaskData?.launchId,
        taskId: selectedTaskData?.eid,
        files: filteredFiles,
      };
      let res = await addLauncherTaskFiles({
        variables: {
          addLauncherTaskFilesInput2: _input,
        },
      });
      if (res?.data?.AddLauncherTaskFiles?.succeed) {
        toast({
          status: 'success',
          title: 'File removed successfully',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        refetchHandler('file');
      }
    } catch (err) {
      toast({
        status: 'error',
        title: 'File could not be removed',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setTimeout(() => {
        setIsFileDeleting(false);
      }, 2000);
    }
  };

  if (taskStatus === 'complete' || mode === 'view') {
    const iconStyle = {
      '--fa-primary-color': 'white',
      '--fa-secondary-color': 'black',
      '--fa-secondary-opacity': '1',
    };
    if (isFileDeleting) {
      return (
        <Center h='20vh'>
          <Loader />
        </Center>
      );
    }
    return (
      <Flex
        gap={
          loggedInUserAuthRole === AuthRole.LOCATION_OWNER
            ? '1.4rem'
            : '2.758rem'
        }
        flexWrap='wrap'
        mt={4}
        w='full'
      >
        {selectedTaskData?.files?.map((selectedFile, index) => {
          return (
            <Flex
              key={index}
              flexDir='column'
              cursor='pointer'
              onClick={() => window.open(selectedFile?.url, '_blank')}
            >
              <Center
                borderRadius='8px'
                cursor='pointer'
                position='relative'
                _hover={{
                  '.close-icon': {
                    opacity: 1,
                    visibility: 'visible',
                  },
                }}
              >
                <Box>
                  <Box
                    className='close-icon'
                    position='absolute'
                    top='-8px'
                    right='-6px'
                    cursor='pointer'
                    opacity='0'
                    visibility='hidden'
                    transition='opacity 0.2s ease, visibility 0.2s'
                    zIndex={1}
                  >
                    {taskStatus !== 'complete' && (
                      <FontAwesomeIcon
                        icon={faCircleXmark as IconProp}
                        fontSize='24px'
                        style={iconStyle as never}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          removeFileFromCompletedTask(selectedFile);
                        }}
                      />
                    )}
                  </Box>
                  <Image
                    src={getFileIcon(selectedFile?.type, false)}
                    boxSize='90px'
                  />
                </Box>
              </Center>
              {fileNameRender(selectedFile?.name)}
            </Flex>
          );
        })}
      </Flex>
    );
  }

  return (
    // @ts-ignore
    <Flex flexDir='column' gap={2} w='full'>
      {!isUploading && (
        <>
          <TitleHeader
            title='Upload your file'
            isRequired={selectedTaskData?.docRequired}
          />
          <Center
            flexDir='column'
            p={8}
            gap={4}
            backgroundImage={`url(
            "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23CCCCCCFF' stroke-width='3' stroke-dasharray='14%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e"
            )`}
            borderRadius='20px'
            w='full'
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <FontAwesomeIcon
              icon={faCloudArrowUp as IconProp}
              color='#BFC6CE'
              size='2x'
            />
            <Flex fontSize='16px' align='center' gap='5px'>
              <Text>Drag and drop document or </Text>
              <Text color='#2A85FF' as='u' cursor='pointer' onClick={open}>
                browse
              </Text>
              <Text>here</Text>
            </Flex>
            <Box color='#212121B2'>
              PDF, XLSX, DOCX, JPG, PNG, PPT, ZIP, AUDIO, VIDEO
            </Box>
          </Center>
        </>
      )}
      {isFileDeleting && (
        <Center h='20vh'>
          <Loader />
        </Center>
      )}
      {!isFileDeleting && (
        <Flex gap='1.4rem' flexWrap='wrap' mt={4}>
          {selectedFiles?.map((selectedFile) =>
            filePreviewRender(
              selectedFile?.file,
              selectedFile?.eid,
              selectedFile?.url
            )
          )}
        </Flex>
      )}
      {selectedFiles?.length > 0 && selectedFiles?.some((file) => !file?.url) && (
        <Flex align='center' w='full' justify='center'>
          <Text
            as='u'
            color='#2A85FF'
            cursor='pointer'
            fontSize='16px'
            onClick={() => {
              if (!isUploading) {
                uploadFilesHandler();
              }
            }}
          >
            {!isUploading ? 'Upload' : 'Uploading'}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default TaskDrawerUpload;
