import { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { GET_PENDING_APPROVAL_COUNT } from 'sub-components/nexus/Compliance/query/compliance.graphql';

export const usePendingApprovalCount = (locationId?: string) => {
  const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);

  const getFilterVariables = () => {
    let filterPayload: {
      approvalStatus?: string;
      status?: string[];
      locationIds?: string[];
    } = {
      approvalStatus: 'pending',
      status: ['active'],
    };

    if (locationId) {
      filterPayload.locationIds = [locationId];
    }

    return filterPayload;
  };

  const { data } = useQuery(GET_PENDING_APPROVAL_COUNT, {
    fetchPolicy: 'network-only',
    variables: {
      filter: getFilterVariables(),
    },
    onCompleted: (data) => {
      setPendingApprovalCount(data?.EntityCompliancePagination?.count);
    },
  });

  return { pendingApprovalCount: data?.EntityCompliancePagination?.count };
};
