import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HeaderColors } from '../../../../../../../configs';
import { BoxHeader } from '../../../../../../../ui-components';

const AllDocuments: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'nexus']);
  return (
    <Flex flexDir={'column'} gap={5}>
      <Flex alignItems={'flex-start'}>
        <BoxHeader title='Compliance Documents' color={HeaderColors.Purple} />
      </Flex>
      <Text color='#6F767E' fontWeight={600} fontSize='14px'>
        Keep track of all your franchise compliance documents and their expiry
        in one place!
      </Text>
      <Button
        variant={'solid'}
        fontWeight={700}
        isFullWidth
        onClick={() => history.push('/nexus/documents')}
        color={'#1A1D1F'}
        rightIcon={<ArrowForwardIcon fontSize='20px' />}
      >
        {`${t('common:viewAll')}`}
      </Button>
    </Flex>
  );
};

export default AllDocuments;
