import { ModalCloseButton } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useCallback, useRef } from 'react';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import DocumentTypeSelectionContent from './DocumentTypeSelectionContent';
import { ApolloProvider, useApolloClient } from '@apollo/client';

interface IProps {
  locationId?: string;
}

const useDocumentTypeSelect = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ locationId }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }
    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      size: '3xl',
      title: (
        <>
          <BoxHeader color={HeaderColors.Green} title='Add document' />
          <ModalCloseButton top='1.5rem' right='1.5rem' />
        </>
      ),
      content: (
        <DocumentTypeSelectionContent
          locationId={locationId}
          closeDocumentTypeSelectionModal={() => confirmRef.current?.destroy()}
        />
      ),
      contentProps: {
        borderRadius: '12px',
        p: '2rem 2.5rem',
      },
      headerProps: {
        p: '0px',
      },
      bodyProps: {
        p: '0px',
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

useDocumentTypeSelect.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/useDocumentSelect.tsx';

export default useDocumentTypeSelect;
