import React, { FC } from 'react';
import { MetaComplianceData } from './add-document.graphql';
import { Input } from './compliance.helper';
import NewDocumentContent from './NewDocumentContent';
import UpdateCompliance from './UpdateCompliance';

interface IProps {
  complianceId?: string[];
  type?: 'update' | 'reminder';
  documentType?: 'compliance' | 'other';
  moveToCompliance?: boolean;
  localSave?: boolean;
  preDefinedLicense?: string;
  preDefinedLocationName?: string;
  preDefinedLocationId?: string;
  localSaveCb?: (data: Input) => void;
  successCb?: (data: Input | undefined) => void;
  initialFile?: File;
  metaComplianceData?: MetaComplianceData;
  replaceDocument?: boolean;
  disableCategorySelect?: boolean;
  disableDocumentName?: boolean;
  onActionComplete?: () => void;
  uploadedFrom?: 'compliance' | 'location_profile' | 'task_attachment';
  sendForApproval?: boolean;
  disableSigningDate?: boolean;
  disableExpiryDate?: boolean;
  disableReminder?: boolean;
}

const BaseContent: FC<IProps> = ({
  complianceId,
  type,
  localSave,
  preDefinedLicense,
  preDefinedLocationName,
  preDefinedLocationId,
  initialFile,
  metaComplianceData,
  localSaveCb,
  successCb,
  documentType,
  moveToCompliance,
  replaceDocument,
  disableCategorySelect,
  disableDocumentName,
  onActionComplete,
  uploadedFrom,
  sendForApproval,
  disableExpiryDate,
  disableSigningDate,
  disableReminder,
}) => {
  if (type === 'reminder') {
    return <UpdateCompliance type={type} complianceIds={complianceId} />;
  }

  return complianceId ? (
    <UpdateCompliance
      preDefinedLocationId={preDefinedLocationId}
      complianceIds={complianceId}
      moveToCompliance={moveToCompliance}
      successCb={successCb}
      replaceDocument={replaceDocument}
      disableCategorySelect={disableCategorySelect}
      disableDocumentName={disableDocumentName}
      onActionComplete={onActionComplete}
      uploadedFrom={uploadedFrom}
      sendForApproval={sendForApproval}
      disableSigningDate={disableSigningDate}
      disableExpiryDate={disableExpiryDate}
      disableReminder={disableReminder}
    />
  ) : (
    <NewDocumentContent
      localSave={localSave}
      predefinedLicense={preDefinedLicense}
      preDefinedLocationName={preDefinedLocationName}
      preDefinedLocationId={preDefinedLocationId}
      localSaveCb={localSaveCb}
      successCb={successCb}
      initialFile={initialFile}
      metaComplianceData={metaComplianceData}
      documentType={documentType}
      uploadedFrom={uploadedFrom}
    />
  );
};

BaseContent.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/BaseContent';

export default BaseContent;
