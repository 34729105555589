import { useReactiveVar } from '@apollo/client';
import { Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import { ColumnsType } from 'atoms';
import moment from 'moment';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import { DocumentAction } from '../components';
import SortPopover, { SortType } from '../components/SortPopover';
import {
  IDocument,
  NexusDocumentType,
} from '../DocumentFilters/FiltersAndListing';

interface IProps {}

const useDocumentColumns = ({
  documentType,
  onActionComplete,
  setSorting,
  sorting,
  selectedTab,
}: {
  documentType: NexusDocumentType;
  onActionComplete: () => void;
  setSorting: (value: SortType | undefined) => void;
  sorting: SortType | undefined;
  selectedTab?: string;
}) => {
  const users = useReactiveVar(usersEntityObj);

  const getUser = (eid: string) => {
    return users.find((user) => user?.eid === eid);
  };

  const columns: ColumnsType = [
    {
      title: (
        <Box as='span' fontWeight={500} color={'#6F767E'} fontSize={'14px'}>
          Document Name
        </Box>
      ),
      width: 220,
      dataIndex: 'title',
      key: 'title',
      render: (_, record: IDocument) => {
        return (
          <Flex
            gap={3}
            flex={1}
            cursor='pointer'
            color='#111315'
            fontWeight={600}
          >
            {record.title}
          </Flex>
        );
      },
    },
    {
      title: (
        <Box as='span' fontWeight={500} color={'#6F767E'} fontSize={'14px'}>
          Uploaded by
        </Box>
      ),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 200,
      render: (_, record: IDocument) => {
        const user = getUser(record?.createdBy);

        return (
          <Flex gap='5px' fontSize={'14px'}>
            <Image
              src={getImage(user?.profilePic, user?.name)}
              height='24px'
              width='24px'
              borderRadius='5px'
            />
            <Flex>
              <Tooltip label={user?.name}>
                <Box
                  as='span'
                  isTruncated
                  maxWidth={'60px'}
                  fontWeight='600'
                  color='#33383F'
                >
                  {user?.name},
                </Box>
              </Tooltip>

              <Box as='span' color='#6F767E' fontWeight={400}>
                {user?.role}
              </Box>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex
          gap={2}
          fontWeight={500}
          color={'#6F767E'}
          fontSize={'14px'}
          alignItems='center'
        >
          Uploaded on
          <SortPopover
            setSorting={setSorting}
            sorting={sorting}
            sortKey='CREATEDAT'
          />
        </Flex>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 145,
      render: (value) => (
        <Flex
          flex={1}
          cursor='pointer'
          flexDirection='column'
          alignItems='flex-end'
        >
          <Box as='span' color='#33383F' fontWeight={600}>
            {value && moment(value)?.isValid()
              ? moment(value).format('DD MMM YYYY')
              : '--'}
          </Box>
          <Box as='span' color='#6F767E' fontWeight={400} fontSize='12px'>
            {value && moment(value)?.isValid()
              ? moment(value).format('hh:mm A')
              : '--'}
          </Box>
        </Flex>
      ),
    },
    ...(documentType === 'compliance'
      ? [
          {
            title: (
              <Flex
                gap={2}
                fontWeight={500}
                color={'#6F767E'}
                fontSize={'14px'}
                alignItems='center'
              >
                Signed Date
              </Flex>
            ),
            dataIndex: 'signingDate',
            key: 'signingDate',
            width: 120,
            render: (value: Date) => (
              <Flex
                flex={1}
                cursor='pointer'
                flexDirection='column'
                alignItems='flex-end'
              >
                <Box as='span' color='#33383F' fontWeight={600}>
                  {value && moment(value)?.isValid()
                    ? moment(value).format('DD MMM YYYY')
                    : '--'}
                </Box>
              </Flex>
            ),
          },
          {
            title: (
              <Flex
                gap={2}
                fontWeight={500}
                color={'#6F767E'}
                fontSize={'14px'}
                alignItems='center'
              >
                Expiry Date
                <SortPopover
                  setSorting={setSorting}
                  sorting={sorting}
                  sortKey='EXPIRYDATE'
                />
              </Flex>
            ),
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            width: 145,
            render: (value: Date) => {
              const isExpired =
                moment(value)?.isValid() &&
                moment(value)?.isBefore(moment()?.utc());
              return (
                <Flex
                  flex={1}
                  cursor='pointer'
                  flexDirection='column'
                  alignItems='flex-end'
                >
                  <Box
                    as='span'
                    color={isExpired ? '#FF6A55' : '#33383F'}
                    fontWeight={600}
                  >
                    {value && moment(value)?.isValid()
                      ? moment(value).format('DD MMM YYYY')
                      : '--'}
                  </Box>
                </Flex>
              );
            },
          },
        ]
      : []),
    {
      title: (
        <Box as='span' fontWeight={500} color={'#6F767E'} fontSize={'14px'}>
          Actions
        </Box>
      ),
      key: 'actions',
      width: 80,
      fixed: 'right',
      render: (_, record: IDocument) => {
        return (
          <DocumentAction
            document={record}
            onActionComplete={onActionComplete}
            pageType='LocationProfile'
            selectedTab={selectedTab}
          />
        );
      },
    },
  ];

  return { columns };
};

export default useDocumentColumns;
