import { Box, Button, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownToSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, Key } from 'react';
import { ReactComponent as DeleteAuditIcon } from 'assets/images/delete-audit.svg';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { useParams } from 'react-router-dom';
import { useDeleteCompliance } from 'sub-components/nexus/Compliance/Listing/components/useDeleteCompliance';

interface IProps {
  complianceId: string[] | Key[];
  onActionComplete: () => void;
}

const MoveButtons: FC<IProps> = ({ complianceId, onActionComplete }) => {
  const params = useParams<{ locationId: string }>();
  const complianceUpload = useComplianceUpload();
  const deleteDocument = useDeleteCompliance();

  return (
    <Flex alignItems={'center'}>
      <Button
        textColor={'#2A85FF'}
        fontSize={'15px'}
        fontWeight={600}
        variant='ghost'
        leftIcon={
          <Box transform='rotate(-90deg)'>
            <FontAwesomeIcon
              icon={faArrowDownToSquare as IconProp}
              fontSize={'20px'}
              color={'#2A85FF'}
            />
          </Box>
        }
        onClick={() => {
          complianceUpload({
            complianceId: complianceId,
            moveToCompliance: true,
            preDefinedLocationId: params?.locationId,
            onActionComplete: onActionComplete,
          });
        }}
      >
        Move to Compliance
      </Button>
      <Button
        variant='ghost'
        leftIcon={<DeleteAuditIcon />}
        textColor={'#FF6A55'}
        fontSize={'15px'}
        fontWeight={600}
        onClick={() => {
          deleteDocument({
            eid: complianceId,
            onActionComplete: onActionComplete,
          });
        }}
      >
        Delete Selected
      </Button>
    </Flex>
  );
};

export default MoveButtons;
