import { Center, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useControl } from 'sub-components/Launcher/location-owner/layers';
import { DashboardContainer, Loader } from '../../..';
import LaunchLocationHeader from './LaunchLocationHeader';
import LauncherLocationTaskProgress from './LauncherLocationTaskProgress';
import LauncherLocationTasksWrapper from './LauncherLocationTasksWrapper';

interface IProps {}

const LauncherLocationDetails: FC<IProps> = () => {
  // locationID is actually launchId
  //TODO: change locationId name from route
  const { locationId } = useParams<{ locationId: string }>();

  const control = useControl(locationId, true);

  return (
    <DashboardContainer>
      {control?.loading ? (
        <Center h='99vh'>
          <Loader />
        </Center>
      ) : (
        <>
          {control?.locLaunchData && (
            <Flex flexDirection='column' marginTop={1} gap={3}>
              <LaunchLocationHeader
                locationHeaderDetails={{
                  name: control?.locLaunchData?.location?.name,
                  locationId: control?.locLaunchData?.locationId,
                  liveDate: control?.locLaunchData?.liveDate,
                  startDate: control?.locLaunchData?.startDate,
                  isLive: control?.locLaunchData?.isLive,
                  createdAt: control?.locLaunchData?.createdAt,
                  boardName: control?.locLaunchData?.launcher?.title,
                }}
                launchId={locationId}
              />
              <Flex flexDirection='column' gap={6}>
                <LauncherLocationTaskProgress
                  goLiveDate={control?.locLaunchData?.liveDate || new Date()}
                  phaseDetails={control?.locLaunchData?.contents || []}
                  locationId={control?.locLaunchData.locationId}
                  name={control?.locLaunchData?.details?.locationName}
                  refetchData={control?.refetchData}
                />
                <LauncherLocationTasksWrapper
                  locationId={control?.locLaunchData?.locationId}
                  phaseDetails={control?.locLaunchData?.contents || []}
                  liveDate={control?.locLaunchData?.liveDate || new Date()}
                  refetchData={control?.refetchData}
                  launchId={control?.locationLaunchId}
                  locationEntityComplianceData={
                    control.LocationEntityComplianceData
                      ?.EntityCompliancePagination?.items
                  }
                />
              </Flex>
            </Flex>
          )}
        </>
      )}
    </DashboardContainer>
  );
};

LauncherLocationDetails.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/LauncherLocationDetails';

export default LauncherLocationDetails;
