import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import complianceDoc from 'assets/images/compliance/complianceDoc.svg';
import othersDoc from 'assets/images/compliance/othersDoc.svg';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { useUploadOtherDoc } from './useUploadOtherDoc';

interface IProps {
  locationId?: string;
  closeDocumentTypeSelectionModal?: () => void;
}

const DocumentTypeSelectionContent: FC<IProps> = ({
  locationId,
  closeDocumentTypeSelectionModal,
}) => {
  const complianceUpload = useComplianceUpload();
  const uploadOtherDoc = useUploadOtherDoc();

  return (
    <Flex flexDirection='column' gap={4} marginTop='1rem'>
      <Text color='#1A1D1F' fontWeight='600'>
        Which type of document would you like to upload?
      </Text>

      <Flex
        flexDirection='column'
        justifyContent='center'
        gap={2}
        background='#B5E4CA4D'
        padding='20px 24px'
        borderRadius='12px'
        marginTop='1rem'
        height='240px'
        cursor='pointer'
        onClick={() => {
          complianceUpload({
            documentType: 'compliance',
            preDefinedLocationId: locationId,
            uploadedFrom: 'location_profile',
            successCb: () => {
              closeDocumentTypeSelectionModal?.();
            },
          });
        }}
      >
        <Image src={complianceDoc} alt='compliance doc' width='64px' />
        <Text color='#33383F' fontWeight='600' fontSize='18px'>
          Compliance document
        </Text>
        <Text color='#6F767E' fontSize='14px' fontWeight='500'>
          For example: agreements, FDDs, permits, licenses, etc. These will also
          appear on the compliance listing page.
        </Text>
      </Flex>
      <Flex
        flexDirection='column'
        justifyContent='center'
        gap={2}
        background='#CABDFF4D'
        padding='20px 24px'
        borderRadius='12px'
        height='240px'
        cursor='pointer'
        onClick={() => {
          uploadOtherDoc({
            documentType: 'other',
            locationId: locationId,
            closeDocumentTypeSelectionModal: closeDocumentTypeSelectionModal,
          });
        }}
      >
        <Image src={othersDoc} alt='other doc' width='64px' />
        <Text color='#33383F' fontWeight='600' fontSize='18px'>
          Other document
        </Text>
        <Text color='#6F767E' fontSize='14px' fontWeight='500'>
          Any other type of document you'd like to save for the location falls
          under this category.
        </Text>
      </Flex>
    </Flex>
  );
};

DocumentTypeSelectionContent.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/DocumentTypeSelectionContent.tsx';

export default DocumentTypeSelectionContent;
