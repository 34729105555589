import _ from 'lodash';
import moment from 'moment';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import { TaskStatusWithMessage } from 'sub-components/Launcher/shared';
import { TriggerDetails } from 'types/location-launcher/details/location-tasks';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import { capitalizeFirstLetter } from 'utils';
import { GroupedTask } from './GroupBy/GroupByPhase';
import {
  ILocationEntityComplianceFile,
  LocationPhaseDetails,
  PhaseTask,
} from './LauncherLocation.graphql';
import { getUnlockMessage, isTaskUnlocked } from './isTaskUnlocked';

function calculatePercentage(
  numerator: number,
  denominator: number,
  precision: number = 2
): number {
  if (denominator === 0) return 0;
  const percentage = (numerator / denominator) * 100;
  return parseFloat(percentage.toFixed(precision));
}

export function formatDate(dateString: Date) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}

export const getTasksSummary = (
  phaseDetails: LocationPhaseDetails[],
  goLiveDate: Date
) => {
  let totalTasks = 0;
  let completedTasks = 0;

  const groupedTasks = groupTask(phaseDetails, 'Phase', goLiveDate, []);

  phaseDetails?.forEach((phase) => {
    totalTasks += phase.tasks?.length || 0;
    completedTasks +=
      phase.tasks?.filter((task) => task.isCompleted).length || 0;
  });

  const progress = calculatePercentage(completedTasks, totalTasks);

  // flatList of sorted tasks
  const tasks = Object.keys(groupedTasks)
    .map((key) => groupedTasks[key].tasks)
    .flat()
    .sort((a, b) =>
      moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1
    );

  const currentTask = tasks
    .filter((task) => {
      return !task.isCompleted;
    })
    .sort((a, b) =>
      moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1
    )
    .find((task) => {
      // Check if the task has partially completed steps
      const hasPartiallyCompletedSteps = task.steps.some(
        (step: any) => !step.isCompleted
      );
      // Task is current if it has partially completed steps or no steps
      return (
        hasPartiallyCompletedSteps &&
        task.status !== 'locked' &&
        moment(task.startDate).isSameOrBefore(moment().utc())
      );
    });

  const lastCompletedTask = tasks
    .filter((task) => task.isCompleted) // Only include completed tasks
    .sort((a, b) =>
      moment(b.completedAt).isAfter(moment(a.completedAt)) ? 1 : -1
    )
    .shift(); // Get the most recent task

  const upcomingTask = tasks
    .filter((task) => {
      // Task is not completed and its startDate is in the future
      return !task.isCompleted && currentTask?.eid !== task.eid;
    })
    .sort((a, b) =>
      moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1
    )
    .find((task) => {
      // Check if the task has partially completed steps
      const hasPartiallyCompletedSteps = task.steps.some(
        (step: any) => !step.isCompleted
      );
      // Task is current if it has partially completed steps or no steps
      return (
        (hasPartiallyCompletedSteps || task.steps.length === 0) &&
        task.status !== 'locked' &&
        moment(task.startDate).isSameOrBefore(moment().utc())
      );
    });

  return {
    totalTasks: totalTasks,
    completedTasks: completedTasks,
    progress: progress,
    currentTask: currentTask,
    lastTask: lastCompletedTask,
    upcomingTask: upcomingTask,
  };
};

export const getReUploadRequiredForTask = (
  task: PhaseTask,
  locationEntityComplianceData?: ILocationEntityComplianceFile[]
) => {
  if (!locationEntityComplianceData) return false;

  return locationEntityComplianceData.some((data) => {
    return (
      data.metadata?.taskId === task.eid &&
      data.approvalStatus === 'rejected' &&
      data.status === 'active' &&
      task.launchId === data.metadata?.launchId
    );
  });
};

export const groupTask = (
  phaseDetails: LocationPhaseDetails[],
  groupBy: 'Phase' | 'Task Type' | 'Assignee' | 'Status',
  liveDate: Date,
  selectedCategories: string[],
  locationEntityComplianceData?: ILocationEntityComplianceFile[]
) => {
  const groups: GroupedTask = {};

  const allTasks = phaseDetails
    .map((phase) => {
      return phase.tasks;
    })
    .flat();

  phaseDetails?.forEach((phase) => {
    phase?.tasks?.forEach((task) => {
      const newTask = _.cloneDeep(task);

      newTask.reUploadRequired = getReUploadRequiredForTask(
        newTask,
        locationEntityComplianceData
      );

      const status = newTask.reUploadRequired
        ? {
            status: 'reSubmissionRequired',
            unlockMessage: '',
          }
        : getTaskStatus(task, allTasks, liveDate, phaseDetails);
      newTask.status = status.status as ITaskStatus;
      newTask.unlockMessage = status.unlockMessage;

      // Decide the key(s) based on the groupBy filter
      let keys: string[] = [];
      switch (groupBy) {
        case 'Phase':
          keys = [phase.category];
          break;
        case 'Task Type':
          keys = [newTask.category];
          break;
        case 'Status':
          if (['yetToStart', 'locked'].includes(newTask.status)) {
            keys = ['yetToStart/locked'];
          } else {
            keys = [newTask.status];
          }
          break;
        case 'Assignee':
          {
            if (task.assignedType === 'location') {
              keys = ['Assigned to location owner'];
            } else if (task.assignedUser && task.assignedUser.length > 0) {
              keys = task.assignedUser;
            } else if (phase.assignedUser && phase.assignedUser.length > 0) {
              keys = phase.assignedUser;
            }
          }
          break;
        default:
          keys = ['Other'];
      }

      if (
        selectedCategories.length === 0 ||
        selectedCategories.includes(newTask.category) ||
        selectedCategories.includes('All categories')
      ) {
        // Add the task to all relevant groups
        keys.forEach((key) => {
          // If the key doesn't exist, initialize it
          if (!groups[key]) {
            groups[key] = {
              tasks: [],
              phaseId: phase.eid,
              phaseStartDate: phase.startDate,
              goLiveDate: liveDate,
              phaseTrigger: phase.triggerDetails,
              phase: phase,
            };
          }

          // Add the task to the group
          groups[key].tasks.push(newTask);
        });
      }
    });
  });

  return groups;
};

export const getPhaseById = (
  phaseDetails: LocationPhaseDetails[],
  phaseId: string
) => {
  const phase = phaseDetails.find((p) => p.eid === phaseId);

  return phase;
};

export const getPhaseAssignee = (phase: LocationPhaseDetails) => {
  const hasPhaseAssignee = phase?.assignedUser?.length ? true : false;

  return {
    hasPhaseAssignee: hasPhaseAssignee,
    phaseAssignee: phase?.assignedUser || [],
    phaseAssigneeType: phase?.assignedType || 'user',
  };
};

export const getTaskDueDate = (
  task: PhaseTask,
  liveDate: Date,
  isDependentItemCompleted?: boolean,
  dependantItemCompletedAt?: Date | undefined
) => {
  const { triggerDetails, dueDate, startDate } = task;

  // no triggerDetails or default trigger then dueDate -> goLive date
  if (!triggerDetails || triggerDetails.triggerChoice === 'default') {
    return moment(liveDate);
  }

  const { triggerChoice, deadlineOffset, dependantOn, deadlineType } =
    triggerDetails;

  if (triggerChoice === 'deadline' && dependantOn === 'startDate') {
    return moment(startDate).add(deadlineOffset, 'milliseconds');
  }

  if (triggerChoice === 'deadline' && dependantOn === 'goLive') {
    return moment(liveDate).subtract(deadlineOffset, 'milliseconds');
  }

  if (triggerChoice === 'activation' && deadlineType) {
    if (deadlineType === 'goLive') {
      return moment(liveDate);
    }

    if (dueDate) return moment(dueDate);

    return moment(dependantItemCompletedAt).add(
      triggerDetails.deadlineOffset,
      'milliseconds'
    );
  }

  if (isDependentItemCompleted && dependantItemCompletedAt) {
    return moment(dependantItemCompletedAt).add(
      triggerDetails.deadlineOffset,
      'milliseconds'
    );
  }

  if (!dueDate) return moment(liveDate);

  return moment(dueDate);
};

export const getTaskStatus = (
  task: PhaseTask,
  allTasks: PhaseTask[],
  goLiveDate: Date,
  phaseDetails: LocationPhaseDetails[],
  skipLockCheck?: boolean
): TaskStatusWithMessage => {
  const { triggerDetails } = task;
  let dependentItem;

  if (triggerDetails?.dependantOn === 'phase') {
    dependentItem = getDependentPhase(
      phaseDetails,
      triggerDetails.triggerItemId
    );
  }

  if (triggerDetails?.dependantOn === 'task') {
    dependentItem = getDependentTask(
      phaseDetails,
      triggerDetails.triggerItemId
    );
  }

  if (
    (triggerDetails?.dependantOn === 'phase' ||
      triggerDetails?.dependantOn === 'task') &&
    !dependentItem
  ) {
    if (moment(task.startDate).isSameOrAfter(moment().utc())) {
      return {
        status: 'locked',
        unlockMessage: getUnlockMessage(task, allTasks, goLiveDate),
      };
    }

    return {
      status: 'inProgress',
      unlockMessage: ``,
    };
  }

  if (task.reUploadRequired)
    return { status: 'reSubmissionRequired', unlockMessage: '' };

  const isDependantItemComplete = dependentItem?.isCompleted || false;
  const dependantItemCompletedAt = dependentItem?.completedAt || goLiveDate;

  const taskDueMoment = getTaskDueDate(
    task,
    goLiveDate,
    isDependantItemComplete,
    dependantItemCompletedAt
  );

  const currentDate = moment.utc();

  if (
    (task.isCompleted || task.completedAt) &&
    (task.steps.length === 0 || task.steps.every((step) => step?.completedAt))
  ) {
    return { status: 'completed', unlockMessage: '' };
  }

  if (taskDueMoment && currentDate.isSameOrAfter(taskDueMoment, 'day')) {
    return { status: 'overdue', unlockMessage: '' };
  }

  if (
    !skipLockCheck &&
    !isTaskUnlocked(task, allTasks, currentDate, goLiveDate, phaseDetails)
  ) {
    return {
      status: 'locked',
      unlockMessage: getUnlockMessage(task, allTasks, goLiveDate),
    };
  }

  if (
    taskDueMoment &&
    currentDate.isSameOrBefore(taskDueMoment, 'day') &&
    taskDueMoment.diff(currentDate, 'hours') <= 48
  ) {
    return { status: 'duesoon', unlockMessage: '' };
  }

  if (task?.steps?.some((step) => step?.completedBy)) {
    return { status: 'inProgress', unlockMessage: '' };
  }

  if (currentDate.isSameOrBefore(task.startDate)) {
    return {
      status: 'locked',
      unlockMessage: getUnlockMessage(task, allTasks, goLiveDate),
    };
  }

  return { status: 'inProgress', unlockMessage: '' };
};

export const getTaskLabelByStatus = (status: ITaskStatus) => {
  switch (status) {
    case 'completed':
      return 'Completed';
    case 'inProgress':
      return 'In progress';
    case 'duesoon':
      return 'Due soon';
    case 'locked':
      return 'Locked';
    case 'yetToStart':
    case 'yetToStart/locked':
      return 'Yet to start';
    case 'overdue':
      return 'Overdue';
    case 'reSubmissionRequired':
      return 'Re-submission required';
    default:
      return 'yetToStart';
  }
};

export const getTaskCategoryLabel = (
  categories: LTaskCategoryEntity[],
  eid: string
) => {
  const category = categories?.find((category) => category?.eid === eid);
  return category ? category.name : 'No type';
};

export const getDependentPhase = (
  allPhases: LocationPhaseDetails[],
  triggerItemId: string
) => {
  const phase = allPhases?.find((phase) => phase?.eid === triggerItemId);

  return {
    name: phase?.category,
    isCompleted: phase?.isCompleted,
    completedAt: phase?.completedAt,
    tasks: phase?.tasks,
    startDate: phase?.startDate,
  };
};

export const getDependentTask = (
  allPhases: LocationPhaseDetails[],
  triggerItemId: string
) => {
  for (const phase of allPhases) {
    const task = phase?.tasks?.find((task) => task?.eid === triggerItemId);

    if (task) {
      return {
        name: task?.title,
        isCompleted: task?.isCompleted,
        completedAt: task?.completedAt,
        startDate: task?.startDate,
      };
    }
  }

  return {
    title: undefined,
    isCompleted: false,
    completedAt: undefined,
    startDate: undefined,
  };
};

export const getPhaseTargetDate = (
  currentPhase: LocationPhaseDetails,
  allPhases: LocationPhaseDetails[],
  goLiveDate: Date
) => {
  const { startDate, triggerDetails } = currentPhase;
  if (currentPhase?.dueDate) {
    return moment(currentPhase?.dueDate).format('Do MMM YYYY');
  }
  // if phase is locked due to some other task/phase and it is not competed then do not show target date
  if (
    triggerDetails &&
    (triggerDetails.dependantOn === 'phase' ||
      triggerDetails.dependantOn === 'task')
  ) {
    const {
      triggerItemId: currentPhaseTriggerItemId,
      deadlineOffset: currentPhaseDeadlineOffset,
    } = triggerDetails;

    // handling phase
    if (!isDependentItemCompleted(allPhases, currentPhaseTriggerItemId)) {
      return '';
    } else {
      // dependant item is completed then targetDate is
      // dependant item completed date + deadline offset
      const dependantItemCompletedAt = getDependantItemCompletedAt(
        allPhases,
        currentPhaseTriggerItemId
      );

      if (
        currentPhaseDeadlineOffset !== null ||
        currentPhaseDeadlineOffset !== undefined
      ) {
        return moment(dependantItemCompletedAt)
          .add(currentPhaseDeadlineOffset, 'milliseconds')
          .format('Do MMM YYYY');
      } else {
        return moment(goLiveDate).format('Do MMM YYYY');
      }
    }
  }

  // handling task
  if (
    currentPhase.tasks.some(
      (task) => task.triggerDetails && task.triggerDetails.triggerItemId
    )
  ) {
    currentPhase.tasks.forEach((task) => {
      if (
        task.triggerDetails?.triggerItemId &&
        !isDependentItemCompleted(allPhases, task.triggerDetails.triggerItemId)
      ) {
        return ``;
      }
    });

    return ``;
  }

  // phase has a deadline offset
  if (triggerDetails && triggerDetails.triggerChoice === 'deadline') {
    return moment(startDate)
      .add(triggerDetails.deadlineOffset, 'milliseconds')
      .format('Do MMM YYYY');
  }

  //
  // if all task has deadline/ has trigger item then add and show
  if (
    currentPhase.tasks.every(
      (task) =>
        task.triggerDetails &&
        task.triggerDetails.deadlineOffset !== undefined &&
        task.triggerDetails.deadlineOffset !== null
    )
  ) {
    const maxDeadlineOffset = Math.max(
      ...currentPhase.tasks.map(
        (task) => task.triggerDetails?.deadlineOffset || 0
      ) // Extract offsets, default to 0
    );

    return moment(startDate)
      .add(maxDeadlineOffset, 'milliseconds')
      .format('Do MMM YYYY');
  } else {
    // if some tasks don't have deadline then target date is goLiveDate
    return moment(goLiveDate).format('Do MMM YYYY');
  }
};

const isDependentItemCompleted = (
  allPhases: LocationPhaseDetails[],
  triggerItemId: string
) => {
  for (const phase of allPhases) {
    if (phase.eid === triggerItemId && phase.isCompleted) return true;
    if (
      phase.tasks?.some(
        (task) => task.eid === triggerItemId && task.isCompleted
      )
    ) {
      return true;
    }
  }
  return false;
};

const getDependantItemCompletedAt = (
  allPhases: LocationPhaseDetails[],
  triggerItemId: string
) => {
  for (const phase of allPhases) {
    if (phase.eid === triggerItemId && phase.isCompleted)
      return phase.completedAt;
    for (const task of phase.tasks) {
      if (task.eid === triggerItemId && task.isCompleted)
        return task.completedAt;
    }
  }
};

export const getPhaseDependency = (
  triggerDetails: TriggerDetails,
  currentPhase: LocationPhaseDetails,
  allPhases: LocationPhaseDetails[],
  liveDate: Date
) => {
  const { isCompleted, startDate, completedAt, dueDate } = currentPhase;
  const currentDate = moment();
  const targetDate = getPhaseTargetDate(currentPhase, allPhases, liveDate);

  if (!triggerDetails) {
    return {
      dependencyText: '',
      dependencyValue: moment().format('Do MMM YYYY'),
      isLocked: false,
      targetDate,
    };
  }

  const { dependantOn, triggerItemId, activationOrder, triggerChoice } =
    triggerDetails;

  if (currentPhase.tasks.every((task) => task.isCompleted)) {
    const mostRecentCompletedAt = currentPhase.tasks
      .filter((task) => task.isCompleted && task.completedAt)
      .reduce(
        (latest, task) => {
          return new Date(task.completedAt) > new Date(latest.completedAt)
            ? task
            : latest;
        },
        { completedAt: '1970-01-01T00:00:00Z' }
      ).completedAt;

    return {
      dependencyText: 'Phase completed on',
      dependencyValue: moment(mostRecentCompletedAt).format('D MMM'),
      isLocked: false,
      targetDate,
    };
  }

  if (dependantOn === 'startDate' && triggerChoice === 'activation') {
    const isPhaseStarted = currentDate.isSameOrAfter(startDate);
    const phaseActivationOrder = isPhaseStarted
      ? 'on'
      : capitalizeFirstLetter(activationOrder);

    return {
      dependencyText: isPhaseStarted ? 'Phase started' : 'Phase to be started:',
      dependencyValue:
        phaseActivationOrder + ' ' + moment(startDate).format('D MMM'),
      targetDate,
      isLocked: isPhaseStarted ? false : true,
    };
  }

  if (dependantOn === 'phase') {
    const {
      name,
      isCompleted: isDependencyPhaseCompleted,
      completedAt: dependencyPhaseCompletedAt,
    } = getDependentPhase(allPhases, triggerItemId);

    // dependant phase not found
    if (!name) {
      if (moment(currentPhase.startDate).isSameOrAfter(moment().utc())) {
        return {
          dependencyText: 'Phase to be started on:',
          dependencyValue: moment(currentPhase.startDate).format('D MMM'),
          isLocked: true,
          targetDate,
        };
      }

      return {
        dependencyText: 'Phase started on',
        dependencyValue: moment(currentPhase.startDate).format('D MMM'),
        isLocked: false,
        targetDate,
      };
    }

    const dependencyValue = isDependencyPhaseCompleted
      ? moment(dependencyPhaseCompletedAt).format('D MMM')
      : capitalizeFirstLetter(activationOrder) + ' ' + name;

    return {
      dependencyText: isDependencyPhaseCompleted
        ? 'Phase started on'
        : 'Phase to be started:',
      dependencyValue: dependencyValue,
      isLocked: !isDependencyPhaseCompleted,
      targetDate,
    };
  }

  if (dependantOn === 'task') {
    const {
      name,
      isCompleted: isDependencyTaskCompleted,
      completedAt: dependencyTaskCompletedAt,
    } = getDependentTask(allPhases, triggerItemId);

    // dependant phase not found
    if (!name) {
      if (moment(currentPhase.startDate).isSameOrAfter(moment().utc())) {
        return {
          dependencyText: 'Phase to be started on:',
          dependencyValue: moment(currentPhase.startDate).format('D MMM'),
          isLocked: true,
          targetDate,
        };
      }

      return {
        dependencyText: 'Phase started on',
        dependencyValue: moment(currentPhase.startDate).format('D MMM'),
        isLocked: false,
        targetDate,
      };
    }

    const dependencyValue = isDependencyTaskCompleted
      ? moment(dependencyTaskCompletedAt).format('D MMM')
      : capitalizeFirstLetter(activationOrder) + ' ' + name;

    return {
      dependencyText: isDependencyTaskCompleted
        ? 'Phase started on'
        : 'Phase to be started:',
      dependencyValue: dependencyValue,
      isLocked: !isDependencyTaskCompleted,
      targetDate,
    };
  }

  if (moment().utc().isSameOrBefore(moment(startDate))) {
    return {
      dependencyText: 'Phase to be started on:',
      dependencyValue: moment(startDate).format('D MMM'),
      isLocked: true,
      targetDate,
    };
  }

  return {
    dependencyText: 'Phase started on',
    dependencyValue: moment(startDate).format('D MMM'),
    isLocked: false,
    targetDate,
  };
};

export const onPhaseTaskCompleted = (
  task: PhaseTask,
  allPhases?: LocationPhaseDetails[]
) => {
  const taskId = task.eid;

  const dependantPhase = allPhases?.find((phase) => {
    phase?.tasks?.find(
      (task) => task?.triggerDetails?.triggerItemId === taskId
    );
  });

  return dependantPhase ? dependantPhase?.tasks : [];
};
