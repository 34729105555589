import { FC, useState, useMemo, useRef, useEffect } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { TabItem } from './TabItem';
import { Card } from './Cards';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { HeaderColors } from 'configs';
import { LocationAttentionContainerProps } from '../LocationAttentionContainer';
import { processLocationTasks } from 'sub-components/Launcher/tasks/utils';
import { useComplianceApprovalStats } from 'sub-components/nexus/Compliance/Create/components';
import { usePendingApprovals } from 'sub-components/nexus/Compliance/Listing/components/common';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { EnumEntityComplianceApprovalStatus } from 'sub-components/nexus/Compliance/types/compliance.types';

export type TLocationStatus =
  | 'Ready to Go Live'
  | 'Lagging'
  | 'Last Phase'
  | 'Pending Compliance approvals';
interface Tab {
  label: string;
  status: TLocationStatus;
}

interface CardData {
  id: number;
  title: string;
  description?: string;
  status: TLocationStatus;
  date: string;
  badge: string;
  phaseName: string;
  days: string;
  liveDate: string;
  pendingTasksCount?: string;
  launchId: string;
  board: string;
  locationId: string;
}

interface LocationFilterProps extends LocationAttentionContainerProps {
  locationData: any[];
}

//Status Tabs
export const baseTabs: Tab[] = [
  { label: 'Ready to Go Live', status: 'Ready to Go Live' },
  { label: 'Lagging', status: 'Lagging' },
  { label: 'Last Phase', status: 'Last Phase' },
];

const LocationFilter: FC<LocationFilterProps> = ({
  locMakeLiveHandler,
  locationData,
}) => {
  //states
  // const [selectedTab, setSelectedTab] = useState<Tab['status']>(tabs[0].status);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [selectedTab, setSelectedTab] =
    useState<TLocationStatus>('Ready to Go Live');

  const { loggedInUserAuthRole } = useUserDataSelector((state) => ({
    loggedInUserAuthRole: state?.authRole,
  }));

  //updated the tabs based on user roles
  const tabs =
    loggedInUserAuthRole === AuthRole.SUPER_ADMIN
      ? [
          ...baseTabs,
          {
            label: 'Pending Compliance approvals',
            status: 'Pending Compliance approvals',
          },
        ]
      : baseTabs;

  //Get pending compliance approvals count
  const { complianceApprovalStats, locations } = useComplianceApprovalStats(
    EnumEntityComplianceApprovalStatus['Pending_Compliance_approvals']
  );
  const pendingComplianceApprovalsCount = complianceApprovalStats?.pending;

  // location for pendingComp cards
  const pendingComplianceCards = locations;

  // Map the location data to cards format
  const cards: CardData[] = locationData?.map((location) => {
    // Get the last completed task
    const completedTasks = location?.tasks?.filter(
      (task: any) => task.isCompleted
    );
    const lastCompletedTask = completedTasks?.sort(
      (a: any, b: any) =>
        new Date(b.completedAt || 0).getTime() -
        new Date(a.completedAt || 0).getTime()
    )[0];

    const lastCompletedDate = lastCompletedTask?.completedAt;

    //pending Tasks
    const pendingTasksCount = location?.tasks?.filter(
      (task: any) => !task.isCompleted
    ).length;

    let badge = '';
    if (location.status === 'Lagging') {
      badge = 'Passed task deadline!';
    }

    return {
      id: location.locationId,
      title: location.locationName,
      description: `Located in ${location.locationCity}, ${location.locationState}`,
      status: location.status,
      badge: badge,
      date: lastCompletedDate,
      phaseName: location.phaseName,
      days: location.overdueDays,
      liveDate: location.liveDate,
      pendingTasksCount: pendingTasksCount,
      locationId: location.locationId,
      launchId: location.launchId,
      board: location.board,
    };
  });

  // console.log("cards", cards);

  //card counts
  const cardCounts = useMemo(() => {
    return tabs.reduce((acc, tab) => {
      acc[tab.status] = cards.filter(
        (card) => card.status === tab.status
      ).length;
      if (tab.status === 'Pending Compliance approvals') {
        acc[tab.status] = pendingComplianceApprovalsCount ?? 0;
      }
      return acc;
    }, {} as Record<Tab['status'], number>);
  }, [cards]);

  // console.log("card", cards);

  //filtered data for cards
  const filteredCards = useMemo(() => {
    if (selectedTab === 'Pending Compliance approvals') {
      return pendingComplianceCards;
    }
    return cards.filter((card) => card.status === selectedTab);
  }, [cards, selectedTab, locationData]);

  //Tab status
  const firstAvailableTab = useMemo(() => {
    return (
      tabs.find((tab) => cardCounts[tab.status] > 0)?.status || tabs[0].status
    );
  }, [cardCounts]);

  useEffect(() => {
    setSelectedTab(firstAvailableTab);
  }, [firstAvailableTab]);

  //Scroll Logic
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const scrollLeft = () => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    }
  };

  const checkScrollable = () => {
    if (cardContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = cardContainerRef.current;
      setIsScrollable(scrollWidth > clientWidth);
      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener('resize', checkScrollable);

    if (cardContainerRef.current) {
      cardContainerRef.current.addEventListener('scroll', checkScrollable);
    }

    return () => {
      window.removeEventListener('resize', checkScrollable);

      if (cardContainerRef.current) {
        cardContainerRef.current.removeEventListener('scroll', checkScrollable);
      }
    };
  }, [filteredCards]);

  //countcolor Functionality
  const getCountColor = (status: TLocationStatus) => {
    switch (status) {
      case 'Ready to Go Live':
        return HeaderColors.DarkBlue;
      case 'Lagging':
        return HeaderColors.Red.Mid;
      case 'Last Phase':
        return HeaderColors.GreenDark;
      case 'Pending Compliance approvals':
        return HeaderColors.Yellow;
      default:
        return 'gray';
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <Flex justifyContent={'space-between'} alignItems='center'>
        <Flex gap='20px' borderBottom='1px solid #E2E8F0'>
          {tabs.map((tab, index) => (
            <TabItem
              key={index}
              label={tab.label}
              count={cardCounts[tab.status]}
              isSelected={selectedTab === tab.status}
              onClick={() => {
                setSelectedTab(tab.status);
              }}
              status={tab.status}
              countColor={getCountColor(tab.status)}
            />
          ))}
        </Flex>
        {isScrollable && (
          <Flex gap={'4px'}>
            <IconButton
              aria-label='Scroll Left'
              icon={<ChevronLeftIcon boxSize={8} />}
              onClick={scrollLeft}
              variant='ghost'
              isDisabled={isAtStart}
            />
            <IconButton
              aria-label='Scroll Right'
              icon={<ChevronRightIcon boxSize={8} />}
              onClick={scrollRight}
              variant='ghost'
              isDisabled={isAtEnd}
            />
          </Flex>
        )}
      </Flex>

      <Flex mt='20px' align='center' justify='space-between'>
        <Flex
          ref={cardContainerRef}
          direction='row'
          gap='16px'
          overflow='auto'
          maxWidth='100%'
        >
          {filteredCards?.map((card, index) => (
            <Card
              key={index}
              card={card}
              locMakeLiveHandler={() => locMakeLiveHandler?.(card?.locationId)}
            />
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default LocationFilter;
