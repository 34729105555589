import React, { CSSProperties, FC, useMemo } from 'react';
import { IEmojiStructure } from 'delightree-editor';
import { Center, Text } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';

import Image from './Image';
import { getImage } from '../../utils';
import { FileEntity } from '../../types';
import { ChapterFileType, getFileType } from '../../utils';

import DocIcon from '../../assets/images/chapter-editor/doc-file.svg';
import PdfIcon from '../../assets/images/chapter-editor/pdf-file.svg';
import ExcelIcon from '../../assets/images/chapter-editor/xls-file.svg';
import IllustratorIcon from '../../assets/images/illustrator.svg';
import DwgIcon from '../../assets/images/dwg.svg';

// @ts-ignore
const FileTypeImage: Record<ChapterFileType, string> = {
  docs: DocIcon,
  pdf: PdfIcon,
  xlsx: ExcelIcon,
};

// TODO
// imgixParams={{
//   fit: 'crop',
// }}
// ========
// objectFit: 'cover',
//

interface IProps {
  icon?: IEmojiStructure;
  name: string;
  thumbnail?: string;
  boxSize?: number;
  borderRadius?: string;
  iconFontSize?: string;
  files?: FileEntity[];
  objectFit?: CSSProperties['objectFit'];
}

const IconImage: FC<IProps> = ({
  icon,
  thumbnail,
  name,
  iconFontSize = '32px',
  boxSize = 60,
  borderRadius = '10px',
  files,
  objectFit,
}) => {
  const imageHref = useMemo(() => {
    if (icon?.id === 'CHAPTER_ICON' && icon.native) {
      return icon.native;
    }

    if (files?.length) {
      if (files?.[0]?.url?.split('.')?.pop() === 'ai') {
        return IllustratorIcon;
      }
      if (files?.[0]?.url?.split('.')?.pop() === 'dwg') {
        return DwgIcon;
      }
      return (
        FileTypeImage[getFileType(files?.[0]?.mimetype)] ||
        getImage(thumbnail, name)
      );
    }

    return getImage(thumbnail, name);
  }, [icon, thumbnail, name, files]);

  if (icon?.native && icon?.id !== 'CHAPTER_ICON') {
    return (
      <Center
        minW={`${boxSize}px`}
        boxSize={`${boxSize}px`}
        borderRadius={borderRadius}
        border='0.5px solid #DDDDDD'
      >
        <Text fontSize={iconFontSize}>{icon.native}</Text>
      </Center>
    );
  }

  if (files?.length && getFileType(files?.[0]?.mimetype) === 'video') {
    return (
      <Center
        minW={`${boxSize}px`}
        boxSize={`${boxSize}px`}
        borderRadius={borderRadius}
        border='0.5px solid #DDDDDD'
        backgroundImage={thumbnail ?? undefined}
      >
        <FontAwesomeIcon
          icon={faPlay as IconProp}
          fontSize={`calc(${iconFontSize} / 1.6)`}
        />
      </Center>
    );
  }

  return (
    <Image
      src={imageHref}
      height={boxSize}
      width={boxSize}
      style={{
        minHeight: `${boxSize}px`,
        maxHeight: `${boxSize}px`,
        minWidth: `${boxSize}px`,
        maxWidth: `${boxSize}px`,
        borderRadius: borderRadius,
        objectFit: objectFit,
      }}
    />
  );
};

export default IconImage;
