import {
  Flex,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { IComplianceCategory } from 'types/nexus.types';
import { ReactComponent as DeleteIcon } from 'assets/images/nexus/compliance/delete.svg';
import { ReactComponent as RenameIcon } from 'assets/images/nexus/compliance/edit.svg';
import { useDeleteCategory } from './components/deleteCategory';
import { useMutation } from '@apollo/client';
import { RENAME_COMPLIANCE_CATEGORY } from '../query/compliance.graphql';

interface IProps {
  category: IComplianceCategory;
  selectedCategoryId: string;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<string>>;
  onActionComplete: () => void;
  refetchComplianceCategory: () => void;
}

const CategoryCard: FC<IProps> = ({
  category,
  selectedCategoryId,
  setSelectedCategoryId,
  onActionComplete,
  refetchComplianceCategory,
}) => {
  const deleteCategory = useDeleteCategory();
  const options = [
    { title: 'Rename', id: 1, icon: <RenameIcon /> },
    { title: 'Delete', id: 2, icon: <DeleteIcon /> },
  ];
  const [showInputFieldForEid, setShowInputFieldForEid] = useState<string>('');

  const [
    renameComplianceCategory,
    { loading: renameComplianceCategoryLoader },
  ] = useMutation(RENAME_COMPLIANCE_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetchComplianceCategory();
      onActionComplete();
    },
  });
  const handleOperation = (
    operation: string,
    eid: string,
    renameFieldVisibility?: boolean,
    newNameForCategory?: string
  ) => {
    if (operation === 'Rename') {
      setShowInputFieldForEid(newNameForCategory ? '' : eid);

      newNameForCategory &&
        renameComplianceCategory({
          variables: {
            eid: eid,
            category: newNameForCategory,
          },
        });
    }
  };

  return (
    <>
      {category?.eid === showInputFieldForEid ? (
        <>
          <Input
            width={'125px'}
            borderRadius={'8px'}
            backgroundColor={'#EFEFEF'}
            focusBorderColor='transparent'
            outline={'none'}
            border={'1px solid #6F767E'}
            defaultValue={category?.category}
            onKeyUp={(e) => {
              if (e?.keyCode === 13) {
                let newCatName = e?.target?.value
                  ? e?.target?.value
                  : category?.category;
                handleOperation('Rename', category?.eid, false, newCatName);
              }
            }}
            autoFocus
            onBlur={(e) => {
              let newCatName = e?.target?.value
                ? e?.target?.value
                : category?.category;
              handleOperation('Rename', category?.eid, false, newCatName);
            }}
            type={'text'}
            placeholder={category?.category}
          />
        </>
      ) : (
        <Flex
          border={'1px solid #F4F4F4'}
          color={'#6F767E'}
          gap={2}
          justifyContent={'space-between'}
          minW={'fit-content'}
          cursor={'pointer'}
          height={'40px'}
          alignItems={'center'}
          px={'16px'}
          py={'8px'}
          borderRadius={'8px'}
          aria-selected={category.eid === selectedCategoryId}
          _selected={{ bg: '#EFEFEF' }}
          _hover={{
            bg: '#EFEFEF',
            '.action': {
              display: 'flex',
            },
          }}
          onClick={() => {
            if (category.eid === selectedCategoryId) {
              setSelectedCategoryId('');
            } else setSelectedCategoryId(category.eid);
          }}
        >
          <Text
            noOfLines={1}
            fontSize={'14px'}
            fontWeight={600}
            color={category.eid === selectedCategoryId ? '#1A1D1F' : '#6F767E'}
          >
            {category.category} ({category.count})
          </Text>
          <Popover>
            <PopoverTrigger>
              <Flex
                justifyContent={'center'}
                p={'2px'}
                w={'10px'}
                display={'none'}
                className='action'
                _expanded={{ display: 'flex' }}
                onClick={(e) => e.stopPropagation()}
              >
                <FontAwesomeIcon
                  fontSize={'18px'}
                  icon={faEllipsisVertical as IconProp}
                />
              </Flex>
            </PopoverTrigger>

            <PopoverContent borderRadius={'16px'} width={'185px'}>
              <PopoverArrow />
              <PopoverBody>
                <Flex>
                  <ul style={{ listStyle: 'none', width: '100%' }}>
                    {options?.map((item) => {
                      return (
                        <Flex
                          gap={2}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (item?.title === 'Delete') {
                              deleteCategory({
                                eid: category.eid,
                                onActionComplete: onActionComplete,
                              });
                            } else if (item?.title === 'Rename') {
                              handleOperation('Rename', category.eid, true);
                            }
                          }}
                          _hover={{ backgroundColor: '#F4F4F4' }}
                          key={item?.id}
                          style={{
                            borderRadius: '12px',
                            padding: '12px',
                          }}
                        >
                          {item?.icon}
                          <Text>{item?.title}</Text>
                        </Flex>
                      );
                    })}
                  </ul>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      )}
    </>
  );
};

CategoryCard.displayName =
  'sub-components/nexus/Compliance/Listing/CategoryCard';

export default CategoryCard;
