import React, { FC, useEffect, useMemo } from 'react';
import { Box, Center, Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { withAuthorization } from '../../../authorization';
import { AppRoute } from '../../../routes';
import DashboardContainer from 'sub-components/DashboardContainer';
import AddLocationHeader from './AddLocationHeader';
import { IFormInput } from './add-location.types';
import { addLocationFormat } from './formatSubmitData';
import {
  CREATE_LOCATION,
  ATTACH_LAUNCHER_TO_PRE_LAUNCH,
  CreateLocationResponse,
  AttachLauncherToPreLaunchResponse,
} from './add-location.graphql';
import { getDefaultFormData } from './default-form-data';
import LocationSidebar from './LocationSidebar';
import BasicInformation from './BasicInformation';
import LocationDetails from './LocationDetails';
import ProjectDetails from './ProjectDetails';
import AddInviteOwner from './AddInviteOwner';
import {
  NOTIFY_LOCATION_OWNERS_FOR_LOCATION,
  UPDATE_LOCATION_OWNERS_FOR_LOCATION,
} from 'pages/LocationDetails/components/LocationAssetsCard/components/OwnersMembers/components/LocationOwnerListMenu/location-owner-list-menu.graphql';
import { LAUNCHER_DASHBOARD } from 'appRoutes';
import { getTimezones } from 'shared/graphql/SharedGraphql';
import {
  InviteResponse,
  InviteVariable,
  INVITE_USER_QUERY,
  SendInviteResponse,
  SendInviteVariable,
  SEND_INVITE_QUERY,
} from 'ui-components/InviteUserNew/invite.graphql';
import { AuthRole } from 'sop-commons/src/client';
import { toArray } from 'authorization/authorization.utils';
import { LOCATION_DATA_QUERY } from 'pages/Locations/EditLocation/edit-location.graphql';
import Loader from 'ui-components/Loader';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';
import { deployFinishDetails } from './add-location.events';
import {
  BUSINESS_LAUNCHER,
  Response,
} from 'pages/launcher/boards/LauncherBoardContainer';
import { ProjectDetailPhaseProvider } from '../Components/ProjectDetailPhase/store/context';
import {
  AddComplianceInput,
  AddComplianceResponse,
  ADD_COMPLIANCE,
} from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';

interface IProps {}

const AddLocation: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'location']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const history = useHistory();

  const methods = useForm<IFormInput>({
    defaultValues: getDefaultFormData(),
  });

  const currentTab = useWatch<IFormInput, 'currentTab'>({
    control: methods.control,
    name: 'currentTab',
  });

  const isPreLaunchToLaunching = useLocation()?.pathname?.includes(
    '/pre-launch-to-launching/'
  );
  const params = useParams<{ locationId: string }>();

  const [locationByIdHandler, { loading }] = useLazyQuery(LOCATION_DATA_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _data = cloneDeep(data?.userById);
      methods.setValue('locationName', _data?.name);
      methods.setValue('locationType', _data?.locationType);
      methods.setValue('taxPayerId', _data?.taxPayerId);
      methods.setValue('address', _data?.address?.address);
      methods.setValue('zipCode', _data?.address?.zipCode);
      methods.setValue('city', _data?.address?.city);
      methods.setValue('state', _data?.address?.state);
      methods.setValue(
        'locationEmail',
        _data && _data?.locationEmail && _data?.locationEmail?.length > 0
          ? _data?.locationEmail?.map((email: string) => ({ email }))
          : [{ email: '' }]
      );
      methods.setValue(
        'locationPhone',
        _data && _data?.locationPhone && _data?.locationPhone?.length > 0
          ? _data?.locationPhone?.map((phone: string) => ({ phone }))
          : [{ phone: '' }]
      );
    },
  });

  const { execute: getTimezonesData, loading: gettingTimezones } = getTimezones(
    (timezones) => {
      methods.setValue('timezonesData', timezones);
    }
  );

  useEffect(() => {
    getTimezonesData();
  }, []);

  useEffect(() => {
    if (isPreLaunchToLaunching) {
      locationByIdHandler({
        variables: {
          eid: params?.locationId,
        },
      });
    }
  }, [isPreLaunchToLaunching]);

  const [createLocation, { loading: creatingLocation }] = useMutation<
    CreateLocationResponse,
    unknown
  >(CREATE_LOCATION, {
    onCompleted: (data) => {
      methods.setValue(
        'createdLocationId',
        data.CreateLocationWithLauncher.eid
      );
      methods.setValue(
        'createdLocationLaunchId',
        data?.CreateLocationWithLauncher?.launchId
      );
      /** Location is created, now move to STEP 2 */
      methods.setValue('loading', true);
      locationOwnerAdditionHandler();
    },
    onError: (error) => {
      methods.setValue('loading', false);
      history.push(LAUNCHER_DASHBOARD);
      toast({
        status: 'error',
        title: t('common:error'),
        description: error?.message || t('location:location_error'),
      });
    },
  });

  const [preLaunchToLaunching, { loading: preLaunchToLaunchingLoading }] =
    useMutation<AttachLauncherToPreLaunchResponse, unknown>(
      ATTACH_LAUNCHER_TO_PRE_LAUNCH,
      {
        onCompleted: (data) => {
          methods.setValue('createdLocationId', params.locationId);
          methods.setValue(
            'createdLocationLaunchId',
            data?.AttachLauncherToPreLaunch?.launchId
          );
          methods.setValue('loading', true);
          locationOwnerAdditionHandler();
        },
        onError: (error) => {
          methods.setValue('loading', false);
          toast({
            status: 'error',
            title: t('common:error'),
            description: error?.message || t('location:location_error'),
          });
          history.push(LAUNCHER_DASHBOARD);
        },
      }
    );

  const [notifyLOForLaunchedLocation] = useLazyQuery<
    { succeed: boolean },
    { eid: String }
  >(NOTIFY_LOCATION_OWNERS_FOR_LOCATION);

  const onSuccess = () => {
    toast({
      status: 'success',
      title: 'Finished setting up location',
    });
    methods.setValue('loading', false);
    history.push(LAUNCHER_DASHBOARD);
  };

  const { execute } = getLoggedInUserDataHandler(onSuccess);

  const [addLocationOwners] = useMutation(UPDATE_LOCATION_OWNERS_FOR_LOCATION, {
    onCompleted: () => {
      if (methods.getValues('documentData.file.url')) {
        addCompliance({
          variables: {
            input: {
              categoryId: methods.getValues('documentData.categoryId'),
              file: methods.getValues('documentData.file'),
              reminder: methods.getValues('documentData.reminder'),
              title: methods.getValues('documentData.title'),
              expiryDate: methods.getValues('documentData.expiryDate'),
              signingDate: methods.getValues('documentData.signingDate'),
              locationId: methods.getValues('createdLocationId'),
              type: 'compliance',
            },
          },
        });
      } else {
        execute();
      }
      //notify assigned Locations
      notifyLOForLaunchedLocation({
        variables: {
          eid: methods.getValues('createdLocationLaunchId'),
        },
      });
    },
    onError: () => {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: 'Location Owner(s) could not be attached to location',
      });
      history.push(LAUNCHER_DASHBOARD);
    },
  });

  const [businessLaunchers, { loading: loadingBusinessLaunchers }] =
    useLazyQuery<Response>(BUSINESS_LAUNCHER, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        methods.setValue('loadingBoards', false);
        methods.setValue('boards', data?.BusinessLaunchers?.items || []);
      },
      onError: () => {
        methods.setValue('loadingBoards', false);
      },
    });

  useEffect(() => {
    // Only fetch boards if we don't already have them
    if (!methods.getValues('boards')?.length && currentTab === 0) {
      methods.setValue('loadingBoards', true);
      businessLaunchers();
    }
  }, [currentTab]);

  const [inviteUser] = useMutation<InviteResponse, InviteVariable>(
    INVITE_USER_QUERY
  );

  useEffect(() => {
    let flag = creatingLocation || preLaunchToLaunchingLoading;
    methods.setValue('loading', flag);
  }, [creatingLocation, preLaunchToLaunchingLoading]);

  /** STEP 1 */

  const [addCompliance] = useMutation<
    AddComplianceResponse,
    AddComplianceInput
  >(ADD_COMPLIANCE, {
    onCompleted: () => {
      execute();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Compliance document could not be added!',
      });
    },
  });

  const onSubmit = async (data: IFormInput) => {
    if (methods.getValues('currentTab') === 3) {
      const inputData = addLocationFormat(
        cloneDeep(data),
        methods?.getValues('selectedBoard')
      );
      methods.setValue('loading', true);
      if (isPreLaunchToLaunching) {
        const res = await preLaunchToLaunching({
          variables: {
            input: { ...inputData, locationId: params?.locationId },
          },
        }).then((resp) => resp.data?.AttachLauncherToPreLaunch);

        if (res) {
          deployFinishDetails(data);
        }
      } else {
        const res = await createLocation({
          variables: {
            input: inputData,
          },
        }).then((resp) => resp.data?.CreateLocationWithLauncher);

        if (res) {
          deployFinishDetails(data);
        }
      }
    }
  };

  const addLocationOwnersHandler = () => {
    let locationOwnerOneData = methods.getValues('locationOwnerOne');
    let locationOwnerTwoData = methods.getValues('locationOwnerTwo');
    let loEids = [] as string[];
    if (locationOwnerOneData?.eid) {
      loEids?.push(locationOwnerOneData?.eid);
    }
    if (locationOwnerTwoData?.eid) {
      loEids?.push(locationOwnerTwoData?.eid);
    }
    if (loEids?.length > 0) {
      let locationOwnerInput = {
        eid: methods.getValues('createdLocationId'),
        locationOwners: loEids,
      };
      methods.setValue('loading', true);
      addLocationOwners({
        variables: {
          input: locationOwnerInput,
        },
      });
    }
  };

  const [sendSmsInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY
  );

  const [sendEmailInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY
  );

  const onSendEmailInvite = async (invitedUserId: string, email: string) => {
    await sendEmailInvite({
      variables: {
        input: {
          eid: invitedUserId!,
          contact: email,
          type: 'email',
        },
      },
    });
  };

  const onSendPhoneInvite = async (invitedUserId: string, phone: string) => {
    await sendSmsInvite({
      variables: {
        input: {
          eid: invitedUserId,
          contact: phone?.includes('+') ? phone : `+1${phone}`,
          type: 'sms',
        },
      },
    });
  };

  const inviteUserHandler = async (type: 'lo1' | 'lo2') => {
    methods.setValue('loading', true);
    let res = await inviteUser({
      variables: {
        input: {
          name:
            type === 'lo1'
              ? methods.getValues('locationOwnerOne.name')?.trim()
              : methods.getValues('locationOwnerTwo.name')?.trim(),
          authRole: AuthRole.LOCATION_OWNER,
          role:
            type === 'lo1'
              ? methods.getValues('locationOwnerOne.job.value')
              : methods.getValues('locationOwnerTwo.job.value'),
          branchIds: toArray(methods.getValues('createdLocationId'))
            .map((it) => it)
            .filter(Boolean),
        },
      },
    });
    if (res?.errors) {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: `${
          type === 'lo1'
            ? methods.getValues('locationOwnerOne.name')?.trim()
            : methods.getValues('locationOwnerTwo.name')?.trim()
        } could not be added`,
      });
    } else {
      // LO is added, update the form data
      if (type === 'lo1') {
        methods.setValue('locationOwnerOne.eid', res?.data?.inviteUser?.eid!);
      } else {
        methods.setValue('locationOwnerTwo.eid', res?.data?.inviteUser?.eid!);
      }
      // Now, send invite(s) based on the selection of email/phone number
      const invitePromises = [];
      let shouldSendEmailInvite = false;
      let shouldSendSmsInvite = false;
      if (type === 'lo1') {
        if (methods.getValues('locationOwnerOne.email')) {
          shouldSendEmailInvite = true;
        }
        if (methods.getValues('locationOwnerOne.phone')) {
          shouldSendSmsInvite = true;
        }
      } else {
        if (methods.getValues('locationOwnerTwo.email')) {
          shouldSendEmailInvite = true;
        }
        if (methods.getValues('locationOwnerTwo.phone')) {
          shouldSendSmsInvite = true;
        }
      }
      // Prepare promises based on the user selection
      if (shouldSendEmailInvite) {
        let inviteUserId = '';
        let email = '';
        if (type === 'lo1') {
          inviteUserId = methods.getValues('locationOwnerOne.eid');
          email = methods.getValues('locationOwnerOne.email');
        } else {
          inviteUserId = methods.getValues('locationOwnerTwo.eid');
          email = methods.getValues('locationOwnerTwo.email');
        }
        invitePromises.push(onSendEmailInvite(inviteUserId, email));
      }
      if (shouldSendSmsInvite) {
        let inviteUserId = '';
        let phone = '';
        if (type === 'lo1') {
          inviteUserId = methods.getValues('locationOwnerOne.eid');
          phone = methods.getValues('locationOwnerOne.phone');
        } else {
          inviteUserId = methods.getValues('locationOwnerTwo.eid');
          phone = methods.getValues('locationOwnerTwo.phone');
        }
        invitePromises.push(onSendPhoneInvite(inviteUserId, phone));
      }

      // Use Promise.allSettled to wait for all invites to complete
      try {
        methods.setValue('loading', true);
        let result = await Promise.allSettled(invitePromises);
        if (result) {
          setTimeout(() => {
            addLocationOwnersHandler();
          }, 4000);
        }
      } catch (e) {
        toast({
          status: 'error',
          title: 'Invites could not be sent',
        });
        methods.setValue('loading', false);
      }
    }
  };

  const addLO1 = async () => {
    methods.setValue('loading', true);
    let res = await inviteUser({
      variables: {
        input: {
          name: methods.getValues('locationOwnerOne.name')?.trim(),
          authRole: AuthRole.LOCATION_OWNER,
          role: methods.getValues('locationOwnerOne.job.value'),
          branchIds: toArray(methods.getValues('createdLocationId'))
            .map((it) => it)
            .filter(Boolean),
        },
      },
    });
    if (res?.errors) {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: `${methods
          .getValues('locationOwnerOne.name')
          ?.trim()} could not be added`,
      });
    } else {
      methods.setValue('locationOwnerOne.eid', res?.data?.inviteUser?.eid!);
      addLO2();
    }
  };

  const addLO2 = async () => {
    methods.setValue('loading', true);
    let res = await inviteUser({
      variables: {
        input: {
          name: methods.getValues('locationOwnerTwo.name')?.trim(),
          authRole: AuthRole.LOCATION_OWNER,
          role: methods.getValues('locationOwnerTwo.job.value'),
          branchIds: toArray(methods.getValues('createdLocationId'))
            .map((it) => it)
            .filter(Boolean),
        },
      },
    });
    if (res?.errors) {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: `${methods
          .getValues('locationOwnerTwo.name')
          ?.trim()} could not be added`,
      });
    } else {
      methods.setValue('locationOwnerTwo.eid', res?.data?.inviteUser?.eid!);
      setTimeout(() => {
        inviteLO1();
      }, 1000);
    }
  };

  const inviteLO1 = async () => {
    // Now, send invite(s) based on the selection of email/phone number
    const invitePromises = [];
    let shouldSendEmailInvite = false;
    let shouldSendSmsInvite = false;
    if (methods.getValues('locationOwnerOne.email')) {
      shouldSendEmailInvite = true;
    }
    if (methods.getValues('locationOwnerOne.phone')) {
      shouldSendSmsInvite = true;
    }
    // Prepare promises based on the user selection
    if (shouldSendEmailInvite) {
      let inviteUserId = '';
      let email = '';
      inviteUserId = methods.getValues('locationOwnerOne.eid');
      email = methods.getValues('locationOwnerOne.email');
      invitePromises.push(onSendEmailInvite(inviteUserId, email));
    }
    if (shouldSendSmsInvite) {
      let inviteUserId = '';
      let phone = '';
      inviteUserId = methods.getValues('locationOwnerOne.eid');
      phone = methods.getValues('locationOwnerOne.phone');
      invitePromises.push(onSendPhoneInvite(inviteUserId, phone));
    }

    // Use Promise.allSettled to wait for all invites to complete
    try {
      methods.setValue('loading', true);
      let result = await Promise.allSettled(invitePromises);
      if (result) {
        setTimeout(() => {
          inviteLO2();
        }, 4000);
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'Invites could not be sent',
      });
      methods.setValue('loading', false);
    }
  };

  const inviteLO2 = async () => {
    // Now, send invite(s) based on the selection of email/phone number
    const invitePromises = [];
    let shouldSendEmailInvite = false;
    let shouldSendSmsInvite = false;
    if (methods.getValues('locationOwnerTwo.email')) {
      shouldSendEmailInvite = true;
    }
    if (methods.getValues('locationOwnerTwo.phone')) {
      shouldSendSmsInvite = true;
    }
    // Prepare promises based on the user selection
    if (shouldSendEmailInvite) {
      let inviteUserId = '';
      let email = '';
      inviteUserId = methods.getValues('locationOwnerTwo.eid');
      email = methods.getValues('locationOwnerTwo.email');
      invitePromises.push(onSendEmailInvite(inviteUserId, email));
    }
    if (shouldSendSmsInvite) {
      let inviteUserId = '';
      let phone = '';
      inviteUserId = methods.getValues('locationOwnerTwo.eid');
      phone = methods.getValues('locationOwnerTwo.phone');
      invitePromises.push(onSendPhoneInvite(inviteUserId, phone));
    }

    // Use Promise.allSettled to wait for all invites to complete
    try {
      methods.setValue('loading', true);
      let result = await Promise.allSettled(invitePromises);
      if (result) {
        addLocationOwnersHandler();
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'Invites could not be sent',
      });
      methods.setValue('loading', false);
    }
  };

  /** STEP 2 */
  const locationOwnerAdditionHandler = async () => {
    let locOneData = methods.getValues('locationOwnerOne');
    let locTwoData = methods.getValues('locationOwnerTwo');
    /** Check how many location owners data is present. Atleast 1 location owner is required, and maximum can be 2 */
    const checkBothSelected = () => {
      // If both selected are from dropdown
      if (locOneData?.isExistingMember && locTwoData?.isExistingMember) {
        addLocationOwnersHandler();
      }
      // If LO1 is selected from dropdown and LO2 is added manually
      if (locOneData?.isExistingMember && !locTwoData?.isExistingMember) {
        // First add the LO2, then invite through email/phone and then HIT THE API
        inviteUserHandler('lo2');
        return;
      }
      // If LO1 is added manually and LO2 is selected from dropdown
      if (!locOneData?.isExistingMember && locTwoData?.isExistingMember) {
        // First add the LO1, then invite through email/phone and then HIT THE API
        inviteUserHandler('lo1');
        return;
      }
      // If LO1 is added manually and LO2 is added manually
      if (!locOneData?.isExistingMember && !locTwoData?.isExistingMember) {
        addLO1();
        return;
      }
    };

    const checkLO1 = () => {
      if (locOneData?.isExistingMember) {
        // HIT THE API
        addLocationOwnersHandler();
        return;
      } else {
        // First invite the LO1, then HIT THE API
        inviteUserHandler('lo1');
        return;
      }
    };

    /** If BOTH are selected */
    if (locOneData?.name && locTwoData?.name) {
      checkBothSelected();
    } else if (locOneData?.name) {
      /** If only LO1 is selected*/
      checkLO1();
    }
  };

  const Content = useMemo(() => {
    return [BasicInformation, LocationDetails, ProjectDetails, AddInviteOwner][
      currentTab
    ];
  }, [currentTab]);

  return (
    <FormProvider {...methods}>
      <ProjectDetailPhaseProvider>
        <Box display='none'>
          <DashboardContainer />
        </Box>
        <Flex h='100vh' bg='#F4F4F4' overflow='hidden'>
          <LocationSidebar />

          <Flex
            flex={1}
            flexDir='column'
            pb={8}
            px='40px'
            overflow='auto'
            position='relative'
          >
            {loading || gettingTimezones ? (
              <Center h='99vh'>
                <Loader />
              </Center>
            ) : (
              <>
                <AddLocationHeader />

                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Content />
                </form>
              </>
            )}
          </Flex>
        </Flex>
      </ProjectDetailPhaseProvider>
    </FormProvider>
  );
};

AddLocation.displayName =
  'displayName:pages/LocationsNew/AddLocation/AddLocation';

export default withAuthorization({
  permittedFor: 'user',
  permittedRoles: [AuthRole.SUPER_ADMIN],
  redirectTo: AppRoute.LAUNCHER_URL,
})(AddLocation);
