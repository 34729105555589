import React, { FC, useState } from 'react';
import { ActionButton } from 'ui-components';
import { Button, Flex, Input, Text, Textarea } from '@chakra-ui/react';

interface IProps {
  closeModal: () => void;
  handleReUpload?: (eid: string, comment: string) => void;
  record?: any;
  setSelectedReupload?: React.Dispatch<React.SetStateAction<[]>>;
}

const ReUploadContent: FC<IProps> = ({
  closeModal,
  handleReUpload,
  record,
  setSelectedReupload,
}) => {
  const [reUploadReason, setReUploadReason] = useState<string>('');

  return (
    <Flex gap={4} flexDir={'column'}>
      <Text fontWeight={500} color={'#6F767E'} fontSize={'15px'}>
        The respective location owners will be notified about the re-upload
        request. Any reason provided will be included in the notification.
      </Text>
      <Textarea
        onChange={(e) => {
          setReUploadReason(e.target.value?.trim());
        }}
        border={'2px solid #EFEFEF'}
        placeholder='Reason for requesting re-upload'
      />
      <ActionButton
        disabled={reUploadReason?.length > 0 ? false : true}
        borderRadius={'12px'}
        border={'2px solid #EFEFEF'}
        color={'white'}
        colorScheme={'blue'}
        px={'1rem'}
        actionFn={() => {
          handleReUpload?.({ eid: record?.eid, comment: reUploadReason });
          closeModal();
        }}
      >
        Okay
      </ActionButton>
    </Flex>
  );
};

export default ReUploadContent;
