import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DocumentFilterType,
  DocumentTypeState,
  IDocumentFilterState,
} from './FiltersAndListing';
import { ReactComponent as GroupiconIcon } from 'assets/images/groupIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/images/sidebar/location.svg';
import { ReactComponent as FilepreviewIcon } from 'assets/images/filePreview.svg';
import { ReactComponent as CloseicondarkIcon } from 'assets/images/closeIconDark.svg';
import { ReactComponent as ChevronbackwardIcon } from 'assets/images/chevronBackward.svg';
import { ReactComponent as ChevronforwardIcon } from 'assets/images/chevronForward.svg';

import { ReactComponent as ComplianceGray } from 'assets/images/compliance/complianceGray.svg';
import { ReactComponent as OtherDocGray } from 'assets/images/compliance/otherDocGray.svg';
import { ReactComponent as WaitingAproval } from 'assets/images/compliance/waitingAproval.svg';

import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
interface IProps {
  setDocumentFilterState: Dispatch<SetStateAction<IDocumentFilterState>>;
  documentFilterState: IDocumentFilterState;
}

const FilterChip = ({
  type,
  id,
  documentType,
  setDocumentFilterState,
  documentFilterState,
}: {
  type: DocumentFilterType;
  id?: string;
  documentType?: DocumentTypeState;
  setDocumentFilterState: Dispatch<SetStateAction<IDocumentFilterState>>;
  documentFilterState: IDocumentFilterState;
}) => {
  const users = useReactiveVar(usersEntityObj);

  const renderIcons = () => {
    if (type === 'Expired') {
      return <FilepreviewIcon />;
    } else if (type === 'uploadedBy') {
      return <GroupiconIcon />;
    } else if (type === 'location') {
      return <LocationIcon />;
    } else {
      if (documentType === 'compliance') {
        return <ComplianceGray />;
      } else if (documentType === 'other') {
        return <OtherDocGray />;
      } else if (documentType === 'awaitingApproval') {
        return <WaitingAproval />;
      }
    }
  };

  const getUserLocationName = useMemo(() => {
    return users.find((user) => user?.eid === id)?.name;
  }, [users]);

  const getFilterChipText = () => {
    if (type === 'Expired') {
      return 'Expired documents only';
    } else if (type === 'uploadedBy' || type === 'location') {
      return getUserLocationName;
    } else if (type === 'documentType') {
      if (documentType === 'compliance') {
        return 'Compliance';
      } else if (documentType === 'other') {
        return 'Others';
      } else if (documentType === 'awaitingApproval') {
        return 'Awaiting approval';
      }
    }
  };

  const clearFilterFromChip = ({
    type,
    id,
    documentType,
  }: {
    type: DocumentFilterType;
    id?: string;
    documentType?: DocumentTypeState;
  }) => {
    if (type === 'location') {
      const prevLocations = documentFilterState.locations;
      const newLocations = prevLocations.filter((location) => location !== id);
      setDocumentFilterState({
        ...documentFilterState,
        locations: newLocations,
      });
    }

    if (type === 'uploadedBy') {
      const prevUploadedBy = documentFilterState.uploadedBy;
      const newUploadedBy = prevUploadedBy.filter((user) => user !== id);
      setDocumentFilterState({
        ...documentFilterState,
        uploadedBy: newUploadedBy,
      });
    }

    if (type === 'documentType') {
      setDocumentFilterState({
        ...documentFilterState,
        documentType: undefined,
      });
    }

    if (type === 'Expired') {
      setDocumentFilterState({
        ...documentFilterState,
        expiredDocumentOnly: false,
      });
    }
  };

  return (
    <Flex
      borderRadius='8px'
      p='6px 8px'
      gap={2}
      alignItems='center'
      backgroundColor='#E8DEF8'
    >
      {renderIcons()}
      <Text fontWeight={500}>{getFilterChipText()}</Text>
      <CloseicondarkIcon
        cursor='pointer'
        onClick={() =>
          clearFilterFromChip({
            type: type,
            id: id,
            documentType: documentType,
          })
        }
        width='18px'
      />
    </Flex>
  );
};

const FiltersChipContainer: FC<IProps> = ({
  documentFilterState,
  setDocumentFilterState,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false); // Track overflow state
  const [isAtStart, setIsAtStart] = useState(true); // Check if scrolled to start
  const [isAtEnd, setIsAtEnd] = useState(false); // Check if scrolled to the end

  const updateScrollState = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;

      // Check if the content overflows (scrollWidth > clientWidth)
      setIsScrollable(scrollWidth > clientWidth);
      setIsAtStart(scrollLeft === 0); // At the start
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth); // At the end
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      updateScrollState(); // Check overflow immediately on mount
      scrollContainer.addEventListener('scroll', updateScrollState);
      window.addEventListener('resize', updateScrollState); // Recheck on window resize

      return () => {
        scrollContainer.removeEventListener('scroll', updateScrollState);
        window.removeEventListener('resize', updateScrollState);
      };
    }
  }, [documentFilterState]);

  return (
    <Flex alignItems='center'>
      <Text
        color='#6F767E'
        fontSize='12px'
        fontWeight='500'
        whiteSpace='nowrap'
      >
        Filter selcted:
      </Text>
      {isScrollable && (
        <Button
          disabled={isAtStart}
          _hover={{ backgroundColor: 'transparent' }}
          backgroundColor={'transparent'}
          cursor='pointer'
          onClick={scrollLeft}
          size='xs'
        >
          <ChevronbackwardIcon />
        </Button>
      )}

      <Flex
        position='relative'
        ref={scrollContainerRef}
        whiteSpace='nowrap'
        width='100%'
        overflowX='auto'
        gap={2}
        ml={2}
      >
        {documentFilterState.expiredDocumentOnly && (
          <FilterChip
            type='Expired'
            documentFilterState={documentFilterState}
            setDocumentFilterState={setDocumentFilterState}
          />
        )}
        {documentFilterState.uploadedBy?.length > 0 && (
          <>
            {documentFilterState.uploadedBy?.map((item) => {
              return (
                <FilterChip
                  type='uploadedBy'
                  id={item}
                  key={item}
                  documentFilterState={documentFilterState}
                  setDocumentFilterState={setDocumentFilterState}
                />
              );
            })}
          </>
        )}

        {documentFilterState.locations?.length > 0 && (
          <>
            {documentFilterState.locations?.map((item) => {
              return (
                <FilterChip
                  type='location'
                  id={item}
                  key={item}
                  documentFilterState={documentFilterState}
                  setDocumentFilterState={setDocumentFilterState}
                />
              );
            })}
          </>
        )}
        {documentFilterState.documentType && (
          <FilterChip
            type='documentType'
            id={documentFilterState.documentType}
            documentType={documentFilterState.documentType}
            documentFilterState={documentFilterState}
            setDocumentFilterState={setDocumentFilterState}
          />
        )}
      </Flex>

      {isScrollable && (
        <Button
          disabled={isAtEnd}
          _hover={{ backgroundColor: 'transparent' }}
          backgroundColor={'transparent'}
          cursor='pointer'
          onClick={scrollRight}
          size='xs'
        >
          <ChevronforwardIcon />
        </Button>
      )}
    </Flex>
  );
};

FiltersChipContainer.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/FiltersChipContainer.tsx';

export default FiltersChipContainer;
