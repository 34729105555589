import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ReactComponent as Light_blueIcon } from 'assets/images/nexus/compliance/light_blue.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  status: 'compliance' | 'reupload';
}

const ShowStatus: FC<IProps> = ({ status }) => {
  const renderIcons = () => {
    if (status === 'compliance') {
      return <Light_blueIcon />;
    } else if (status === 'reupload') {
      return (
        <FontAwesomeIcon
          color='#FF6A55'
          fontSize={'0.8rem'}
          icon={faArrowRotateRight as IconProp}
        />
      );
    }
  };

  return (
    <Flex
      padding={'4px 8px'}
      borderRadius={'8px'}
      backgroundColor={status === 'compliance' ? '#B1E5FC' : '#FFBC99'}
      alignItems={'center'}
      gap={2}
    >
      {renderIcons()}
      <Text>
        {status === 'compliance'
          ? 'Marked as compliance'
          : 'Re-upload requested'}
      </Text>
    </Flex>
  );
};

export default ShowStatus;
