export type EntityComplianceCategoriesType = {
  category: string;
  createdAt: string;
  createdBy: string;
  eid: string;
  entityId: string;
  updatedAt: string;
  count?: number;
};

export type CreatedByUserType = {
  authRole: string;
  eid: string;
  name: string;
  profilePic: string | null | undefined;
  role: string;
};

export type FileType = {
  createdBy: CreatedByUserType;
  name: string;
  type: string;
  updatedAt: string;
  url: string;
  createdAt?: string;
};

export type ReminderType = {
  remindBefore?: number;
  remindChannels?: ['email', 'sms'];
  remindDate?: string;
  remindType?: 'date' | 'days' | 'weeks' | 'months' | 'years';
  remindUserType?: ('self' | 'users')[];
  remindUsers?: string[];
};
export interface ComplianceExpiringSoonReminderType extends ReminderType {
  remindType?: 'days' | 'weeks' | 'months' | 'years';
}

export type LocationType = {
  eid: string;
  name: string;
  isRoot: boolean;
};

export type EntityComplianceType = {
  signingDate: string;
  category: EntityComplianceCategoriesType;
  categoryId: string;
  createdAt: string;
  createdBy: string;
  createdByUser: CreatedByUserType;
  eid: string;
  entityId: string;
  expiryDate: string;
  files: FileType[];
  lastUpdateBy: string | null;
  locationId: string;
  reminder: ReminderType;
  title?: string;
  updatedAt: string;
  location: LocationType;
};

export interface TableDataType {
  eid: string;
  isExpiring: any;
  key: string;
  location?: LocationType;
  uploadedBy: string[];
  docType: string;
  expiryDate: string;
  fileVersions?: FileType[];
  title: string;
  signingDate?: string;
}

export type VersionHistoryType = {
  eid: string;
  category: EntityComplianceCategoriesType;
  files: FileType[];
  location: LocationType;
};

export type EntityComplianceCategoriesResponse = {
  EntityComplianceCategories: EntityComplianceCategoriesType[];
};

export type AddEntityComplianceInput = {
  categoryId: string;
  title: string;
  locationId: string;
  file: FileType;
  expiryDate: Date;
  reminder: ReminderType;
};
export type isComplianceDocumentExpiringType = (
  expiryDate: Date,
  reminder: ComplianceExpiringSoonReminderType,
  expireType?: 'expired' | 'expiringSoon'
) => boolean | undefined;

export type ComplianceFilters = (
  pageIndex?: number,
  categoryId?: string,
  sort?: string
) => {
  filter: {
    query?: string | undefined;
    locationId?: string | undefined;
    categoryId?: string | undefined;
    expiryDate?: string | undefined;
    createdBy?: string[] | undefined;
    locationIds?: string[] | undefined;
  };
  sort: string;
  page: number;
  perPage: number;
};
export enum EnumEntityComplianceApprovalStatus {
  Pending_Compliance_approvals = 'pending',
  Approved_Compliance_approvals = 'approved',
  Rejected_Compliance_approvals = 'rejected',
}
export type EntityComplianceApprovalStatusType = {
  locationId: string;
  approvalStatus: EnumEntityComplianceApprovalStatus;
  location: {
    name: string;
    eid: string;
    launchId: string;
  };
};
export type TEntitityComplianceApprovalStatuseResponse = {
  EntityCompliancePagination: {
    items: EntityComplianceApprovalStatusType[];
  };
};
