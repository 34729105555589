import React, { FC, useEffect, useState } from 'react';
import { ConfigProvider, Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { Flex, Text } from '@chakra-ui/react';
import { ActionButton } from 'ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import ShowStatus from './ShowStatus';
import { ReactComponent as EditDarkIcon } from 'assets/images/edit-dark.svg';
// import { useReUpload } from './useReUpload';
import {
  DocumentName,
  Location,
  Source,
  LauncherTaskName,
  DataType,
  ReviewDate,
  Reason,
} from './TableColumns';
import { useLazyQuery } from '@apollo/client';
import { GET_RESUBMIT_REQUIRED_DOC_LIST } from '../reSubmissionRequest/resubmit.query';
import Loader from 'sub-components/Loader';
import styled from 'styled-components';
import { useComplianceUpload } from 'sub-components/nexus/Compliance/Create/components';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { eventBus } from 'shared/eventEmit';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  locationId?: string;
}

const StyledTable = styled(Table)`
  && {
    background-color: #ffbc9933;

    /* Header Styles */
    .ant-table-thead > tr > th {
      background-color: #ffbc9933 !important;
      color: white !important;
      font-weight: bold;
    }

    /* Scrollable Header Fix */
    .ant-table-fixed-thead .ant-table-thead > tr > th {
      background-color: #ffbc9933 !important;
    }

    /* Body Styles */
    .ant-table-tbody > tr > td {
      background-color: #ffbc9933;
    }
  }
`;

const ReSubmissionTable: FC<IProps> = () => {
  const [tableData, setTableData] = useState([]);
  const { userAuthRole, loggedInUserLocations, loggedInUserId } =
    useUserDataSelector((state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
      loggedInUserId: state?.eid,
    }));

  const complianceUpload = useComplianceUpload();

  const getFilters = () => {
    const filters = {
      approvalStatus: 'rejected',
      status: ['active'],
      createdBy: [loggedInUserId],
    };

    return filters;
  };

  const [getReSubmissionDocList, { loading: reSubmissionLoading }] =
    useLazyQuery(GET_RESUBMIT_REQUIRED_DOC_LIST, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTableData(data?.EntityCompliancePagination?.items);
      },
    });

  useEffect(() => {
    getReSubmissionDocList({
      variables: {
        filter: getFilters(),
      },
    });
  }, []);

  useEffect(() => {
    const unsubscribe = eventBus.on('refetchCompliance', () => {
      getReSubmissionDocList({
        variables: {
          filter: getFilters(),
        },
      });
    });

    return () => unsubscribe?.();
  }, []);

  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <Text color={'#6F767E'} fontWeight={500}>
          Document Name
        </Text>
      ),
      width: '14%',
      dataIndex: 'documentName',
      render: (value, record) => <DocumentName record={record} />,
    },
    {
      title: (
        <Text color={'#6F767E'} fontWeight={500}>
          Date of review
        </Text>
      ),
      width: '12%',
      dataIndex: 'reviewedAt',
      render: (value, record) => <ReviewDate record={record} />,
    },
    ...(userAuthRole === AuthRole.LOCATION_OWNER &&
    loggedInUserLocations?.length > 1
      ? [
          {
            title: (
              <Text color={'#6F767E'} fontWeight={500}>
                Location
              </Text>
            ),
            width: '13%',
            dataIndex: 'location',
            render: (value, record) => <Location record={record} />,
          },
        ]
      : []),

    {
      title: (
        <Text color={'#6F767E'} fontWeight={500}>
          Source
        </Text>
      ),
      width: '13%',
      dataIndex: 'source',
      render: (value, record) => <Source record={record} />,
    },
    {
      title: (
        <Text color={'#6F767E'} fontWeight={500}>
          Task Name
        </Text>
      ),
      width: '15%',
      dataIndex: 'launcherTaskName',
      render: (value, record) => <LauncherTaskName record={record} />,
    },
    {
      title: (
        <Text color={'#6F767E'} fontWeight={500}>
          Reason
        </Text>
      ),
      width: '15%',
      dataIndex: 'comment',
      render: (value, record) => <Reason record={record} />,
    },
    {
      title: (
        <Text color={'#6F767E'} fontWeight={500}>
          Action
        </Text>
      ),
      dataIndex: '',
      render: (value, record) => {
        return (
          <ActionButton
            borderRadius={'12px'}
            border={'2px solid #EFEFEF'}
            color={'white'}
            colorScheme={'red'}
            px={'0.5rem'}
            leftIcon={
              <FontAwesomeIcon
                fontSize={'1.2rem'}
                icon={faArrowRotateRight as IconProp}
              />
            }
            actionFn={() => {
              complianceUpload({
                complianceId: [record?.eid],
                preDefinedLocationId: record?.locationId,
                replaceDocument: true,
                disableCategorySelect: true,
                disableDocumentName: true,
              });
            }}
          >
            Replace document
          </ActionButton>
        );
      },
    },
  ];

  return (
    <Flex width={'100%'}>
      {reSubmissionLoading ? (
        <Flex
          alignItems={'center'}
          height={'300px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Loader />
        </Flex>
      ) : (
        <ConfigProvider
          renderEmpty={() => (
            <Flex
              height={'240px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <EmptyState
                image='Report'
                title={'No Data'}
                description={'Nothing available to be re-submitted.'}
              />
            </Flex>
          )}
        >
          <StyledTable
            style={{ width: '100%' }}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: 55 * 5 }}
          />
        </ConfigProvider>
      )}
    </Flex>
  );
};

ReSubmissionTable.displayName =
  'packages/sop-web/src/sub-components/nexus/Compliance/Listing/components/common/reSubmissionRequest/ReSubmissionTable';

export default ReSubmissionTable;
