import { Flex, Text } from '@chakra-ui/react';
import { ReactComponent as NotFound } from 'assets/images/nexus/compliance/not_found.svg';
import { ReactComponent as EmptyTable } from 'assets/images/nexus/compliance/table_empty_state.svg';
import { FC } from 'react';

interface IProps {
  searchFieldText?: string;
  filters?: boolean;
  complianceCategoryId: string;
}
const DefaultTableEmptyState: FC<IProps> = ({
  searchFieldText,
  filters,
  complianceCategoryId,
}) => {
  let title, description;

  if (searchFieldText) {
    title = `No results found for '${searchFieldText}'`;
    description = 'Please check the spelling and try again.';
  } else if (filters) {
    title = `No results found`;
    description =
      'Your selected filters didn’t match any items. Try adjusting your filters.';
  } else if (complianceCategoryId) {
    title = 'No documents uploaded yet';
    description = 'No documents available in this category.';
  } else {
    title = 'No documents uploaded yet';
    description = (
      <>
        Click{' '}
        <Text as='span' fontWeight='600' color='#1A1D1F'>
          ‘Add document’
        </Text>{' '}
        to upload and manage your files
      </>
    );
  }

  const renderEmptyStateIcon = () => {
    if (searchFieldText || filters) {
      return <NotFound />;
    }
    return <EmptyTable />;
  };

  return (
    <Flex alignItems={'center'} justifyContent={'center'} h={'50vh'}>
      <Flex alignItems={'center'} flexDir={'column'}>
        {renderEmptyStateIcon()}
        <Text mt={'20px'} fontSize={'20px'} fontWeight={600} color={'#33383F'}>
          {title}
        </Text>
        <Text
          mt={'8px'}
          fontSize={'14px'}
          fontWeight={500}
          color={'#6F767E'}
          maxWidth='260px'
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default DefaultTableEmptyState;
