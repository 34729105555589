import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { DocumentFilterType, IDocumentFilterState } from './FiltersAndListing';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Image,
  Tabs,
  Text,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import location from 'assets/images/compliance/location.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { SearchInput } from 'atoms';
import { EmptyState } from 'sub-components';
import openLocation from 'assets/images/training/openLocationTypeIcon.svg';
import launchLocation from 'assets/images/training/launcherLocationIcon.svg';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';

interface IProps {
  onFilterSelect: (filterType: DocumentFilterType) => void;
  setDocumentFilterState: Dispatch<SetStateAction<IDocumentFilterState>>;
  documentFilterState: IDocumentFilterState;
}

interface ByLocationButtonProps {
  onFilterSelect: (filterType: DocumentFilterType) => void;
  documentFilterState: IDocumentFilterState;
}

export const ByLocationButton = ({
  onFilterSelect,
  documentFilterState,
}: ByLocationButtonProps) => {
  return (
    <Button
      gap={1}
      width='100%'
      fontWeight={400}
      fontSize='14px'
      color='#1A1D1F'
      justifyContent='space-between'
      padding='12px'
      borderRadius='8px'
      variant='ghost'
      onClick={() => onFilterSelect('location')}
    >
      <Flex alignItems='center' gap={1}>
        <Image src={location} width='20px' />
        Location
        {documentFilterState.locations.length > 0 && (
          <Box as='span' color='#6F767E' fontWeight={400} fontSize='12px'>
            {`( ${documentFilterState.locations.length} Selected)`}
          </Box>
        )}
      </Flex>
      <FontAwesomeIcon
        icon={faChevronRight as IconProp}
        fontSize='12px'
        color='#6F767E'
      />
    </Button>
  );
};

const ByLocation: FC<IProps> = ({
  onFilterSelect,
  documentFilterState,
  setDocumentFilterState,
}) => {
  const [search, setSearch] = useState('');
  const allUsers = useReactiveVar(usersEntityObj);
  const [tabIndex, setTabIndex] = useState(0);

  const { userAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );
  const loggedInUserLocationIds = loggedInUserLocations?.map(
    (item) => item?.eid
  );

  const filteredLocations = useMemo(() => {
    return allUsers.filter(
      (user) =>
        user?.name?.toLowerCase().includes(search.toLowerCase()) &&
        user?.type === 'branch' &&
        (userAuthRole === AuthRole.LOCATION_OWNER
          ? loggedInUserLocationIds?.includes(user?.eid)
          : true)
    );
  }, [allUsers, search]);

  const locationsByStatus = useMemo(() => {
    return {
      all: filteredLocations,
      open: filteredLocations.filter((loc) => loc.locationStatus === 'open'),
      launching: filteredLocations.filter(
        (loc) => loc.locationStatus === 'development'
      ),
    };
  }, [filteredLocations]);

  const locationSelectHandler = (locationId: string) => {
    const prevIds = documentFilterState.locations;

    const newIds = prevIds.includes(locationId)
      ? prevIds.filter((id) => id !== locationId)
      : [...prevIds, locationId];

    setDocumentFilterState({
      ...documentFilterState,
      locations: newIds,
    });
  };

  return (
    <Flex flexDirection='column' flex={1} gap={2}>
      <Flex
        cursor='pointer'
        alignItems='center'
        gap={3}
        onClick={() => {
          onFilterSelect(null);
        }}
        width='fit-content'
      >
        <FontAwesomeIcon
          icon={faArrowLeftLong as IconProp}
          fontSize='16px'
          color='#6F767E'
        />

        <Text color='#1A1D1F' fontSize='14px' fontWeight={600}>
          Location
        </Text>
      </Flex>
      <SearchInput
        value={search}
        placeholder='Search by location'
        hideShortcuts
        width='100%'
        onChange={(e) => setSearch(e.target.value)}
      />

      <Tabs index={tabIndex} onChange={setTabIndex} paddingX='0px'>
        <TabList>
          <Tab display='flex' gap={1} alignItems='center' fontSize='12px'>
            All ({locationsByStatus.all.length})
          </Tab>
          <Tab display='flex' gap={1} alignItems='center' fontSize='12px'>
            Open ({locationsByStatus.open.length})
          </Tab>
          <Tab display='flex' gap={1} alignItems='center' fontSize='12px'>
            Launching ({locationsByStatus.launching.length})
          </Tab>
        </TabList>

        <TabPanels paddingX='0px'>
          {['all', 'open', 'launching'].map((status) => (
            <TabPanel key={status} paddingX='0px'>
              <Flex maxHeight='28vh' flexDirection='column' overflowY='scroll'>
                {locationsByStatus[status].length ? (
                  locationsByStatus[status].map((location) => (
                    <Flex key={location?.eid} gap={4}>
                      <Checkbox
                        value={location?.eid}
                        isChecked={documentFilterState.locations.includes(
                          location?.eid
                        )}
                        onChange={() => locationSelectHandler(location?.eid)}
                        padding='8px 12px'
                        borderRadius='8px'
                        width='100%'
                        _hover={{ background: '#EFEFEF' }}
                      >
                        <Flex alignItems='center' gap={2}>
                          <Center
                            width='20px'
                            height='20px'
                            background={
                              location.locationStatus === 'open'
                                ? '#83BF6E'
                                : '#8E59FF'
                            }
                            borderRadius='5px'
                          >
                            <Image
                              src={
                                location.locationStatus === 'open'
                                  ? openLocation
                                  : launchLocation
                              }
                              width={16}
                              height={16}
                            />
                          </Center>
                          <Text fontWeight={400} fontSize='14px'>
                            {location?.name}
                          </Text>
                        </Flex>
                      </Checkbox>
                    </Flex>
                  ))
                ) : (
                  <EmptyState
                    image='Search'
                    title='No locations found'
                    description='Oops! Try a different search to get results.'
                    imageSize='160px'
                  />
                )}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

ByLocation.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/ByLocation.tsx';

export default ByLocation;
