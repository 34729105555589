export interface IDocumentFilterState {
  expiredDocumentOnly: boolean;
  uploadedBy: string[];
  documentType: DocumentTypeState | undefined;
  locations: string[];
}

export type DocumentTypeState = 'compliance' | 'other' | 'awaitingApproval';

export type NexusDocumentType = 'compliance' | 'other';

export type DocumentFilterType =
  | 'uploadedBy'
  | 'Expired'
  | 'location'
  | 'documentType'
  | null;

export const DocumentFilterEmptyState = {
  expiredDocumentOnly: false,
  uploadedBy: [],
  locations: [],
  documentType: undefined,
};

export interface IDocument {
  eid: string;
  title: string;
  approvalStatus: 'approved' | 'rejected' | 'pending';
  type: NexusDocumentType;
  createdBy: string;
  expiryDate: Date;
  signingDate: Date;
  createdAt: Date;
  location: {
    isRoot: boolean;
    name: string;
    eid: string;
  };
}

export interface IDocumentPagination {
  EntityCompliancePagination: {
    items: IDocument[];
    count: number;
  };
}
