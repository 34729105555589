import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import DocTitleInput from './DocTitleInput';

interface IProps {}

const UpdateForm: FC<IProps> = () => {
  return (
    <Flex>
      <DocTitleInput />
    </Flex>
  );
};

UpdateForm.displayName =
  'packages/sop-web/src/pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/uploadOtherDoc/UpdateForm';

export default UpdateForm;
