import omit from 'lodash/omit';
import { IDocumentInput } from './common/compliance.input';
import {
  AddComplianceInput,
  MetaComplianceData,
  UpdateComplianceInput,
} from './add-document.graphql';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';

export type Input = AddComplianceInput['input'];

const getReminderData = (
  value: IDocumentInput['reminder']
): Input['reminder'] => {
  if (!value?.remindUserType?.length) {
    return {
      remindAdmins: [],
      remindOwners: [],
    };
  }

  const data: Input['reminder'] = {
    remindAdmins: [],
    remindOwners: [],
    remindUserType: value.remindUserType,
    remindChannels: value.remindChannels,
  };

  if (value?.remindUserType?.includes('users')) {
    data.remindAdmins = value.remindAdmins;
    data.remindOwners = value.remindOwners;
  }

  switch (value.reminderType) {
    case 'before':
      data.remindType = value.remindType;
      data.remindBefore = +value.remindBefore;
      break;
    case 'on':
      data.remindType = 'date';
      data.remindDate = value.remindDate;
      break;
    default:
      break;
  }

  return data;
};

export const toComplianceInput = (
  values: IDocumentInput,
  metaComplianceData?: MetaComplianceData,
  moveToCompliance?: boolean
): Input => {
  return [
    {
      title: values.title,
      categoryId: values.category?.value,
      signingDate: values?.signingDate,
      expiryDate: values.expiryDate,
      file: omit(values.file, 'rawFile'),
      locationId: values.location?.value ?? values?.locationId,
      reminder: getReminderData(values.reminder),
      metadata: metaComplianceData,
      type: values?.type,
    },
  ];
};

type UpdateInput = UpdateComplianceInput['input'];

export const toUpdateComplianceInput = (
  values: IDocumentInput,
  moveToCompliance?: boolean,
  sendForApproval?: boolean
): UpdateInput => {
  const input: UpdateInput = {
    eid: values.eid!,
    categoryId: values.category?.value,
    expiryDate: values.expiryDate,
    locationId: values.location?.value,
    reminder: getReminderData(values.reminder),
    signingDate: values?.signingDate,
    title: values?.title,
    type: moveToCompliance ? 'compliance' : values?.type,
  };

  // Set approvalStatus while editing the document to pending if the user role is Admin and LO
  if (sendForApproval) {
    input.approvalStatus = 'pending';
  }

  return input;
};
