import { useMutation } from '@apollo/client';
import {
  Button,
  Flex,
  Text,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { FC } from 'react';
import Loader from 'sub-components/Loader';
import { DELETE_COMPLIANCE_DOCUMENT } from '../../query/compliance.graphql';
import { ActionButton } from 'ui-components';

interface IProps {
  eid: string;
  onActionComplete: () => void;
}

const DeleteComplianceContent: FC<IProps> = ({ eid, onActionComplete }) => {
  const { onClose } = useModalContext();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const [deleteDocument, { loading: deleteDocumentLoading }] = useMutation(
    DELETE_COMPLIANCE_DOCUMENT
  );

  const handleDeleteDocument = async () => {
    try {
      await deleteDocument({
        variables: {
          eids: eid,
        },
      });

      toast({
        title: `${eid.length} document${
          eid.length > 1 ? 's have' : ' has'
        } been deleted`,
        status: 'success',
      });

      onClose();
      onActionComplete();
    } catch (error) {}
  };

  return (
    <>
      <Flex>
        <Text color={'#6F767E'}>
          This will permanently delete the uploaded document
        </Text>
      </Flex>
      <Flex mt={10} width={'100%'} gap={2} justifyContent={'space-between'}>
        <Button onClick={onClose} width={'50%'}>
          Cancel
        </Button>
        <ActionButton
          actionFn={handleDeleteDocument}
          width={'50%'}
          colorScheme='blue'
          mr={3}
          isLoading={deleteDocumentLoading}
          disabled={deleteDocumentLoading}
        >
          {deleteDocumentLoading ? <Loader /> : 'Yes, delete'}
        </ActionButton>
      </Flex>
    </>
  );
};

export default DeleteComplianceContent;
