import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Key, useCallback, useRef } from 'react';
import { useConfirm, UseConfirm } from 'ui-components';
import { MetaComplianceData } from './add-document.graphql';
import BaseContent from './BaseContent';
import BaseTitle from './BaseTitle';
import { Input } from './compliance.helper';

interface IProps {
  complianceId?: string[] | Key[];
  type?: 'update' | 'reminder';
  documentType?: 'compliance' | 'other';
  moveToCompliance?: boolean;
  onActionComplete?: () => void;
  /**`localSave` is used to prevent api hit*/
  localSave?: boolean;

  /** Used to show read-only input field for license and will show instead of License dropdown */
  preDefinedLicense?: string;

  /** Used to show read-only input field for location name and will show instead of location dropdown */
  preDefinedLocationName?: string;

  /** Used to show pre selected location in the dropdown which will be read-only */
  preDefinedLocationId?: string;

  /** Used to disable category selection dropdown which will be read-only */
  disableCategorySelect?: boolean;

  /** Used to disable document name field which will be read-only */
  disableDocumentName?: boolean;

  disableSigningDate?: boolean;
  disableExpiryDate?: boolean;
  disableReminder?: boolean;

  /** Used to return the data as callback that had to be used otherwise for api hit */
  localSaveCb?: (data: Input) => void;

  /** If file is selected from elsewhere and only the form has to be shown  */
  initialFile?: File;

  /** Successfully returned data callback */
  successCb?: (data: Input | undefined) => void;

  /** Meta Compliance Data: `MetaComplianceData` */
  metaComplianceData?: MetaComplianceData;

  /** pass true to want to replace the document */
  replaceDocument?: boolean;

  /** pass the location from where this hook is being called, to decide what notification msg to show based on different role */
  uploadedFrom?: 'compliance' | 'location_profile' | 'task_attachment';

  /** this param decides if the document is updated it needs to sent for approval or not */
  sendForApproval?: boolean;
}

type ComplianceUpload = (props?: IProps) => void;

export const useComplianceUpload = (): ComplianceUpload => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      complianceId,
      type = 'update',
      moveToCompliance = false,
      localSave = false,
      preDefinedLicense = '',
      preDefinedLocationName = '',
      preDefinedLocationId = '',
      initialFile,
      metaComplianceData,
      localSaveCb,
      successCb,
      documentType,
      replaceDocument,
      disableCategorySelect,
      disableDocumentName,
      disableSigningDate,
      disableExpiryDate,
      disableReminder,
      onActionComplete,
      uploadedFrom = 'compliance',
      sendForApproval = false,
    } = {}) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <BaseTitle
            type={type}
            complianceId={complianceId}
            moveToCompliance={moveToCompliance}
            replaceDocument={replaceDocument}
          />
        ),
        content: (
          <BaseContent
            documentType={documentType}
            moveToCompliance={moveToCompliance}
            type={type}
            complianceId={complianceId}
            localSave={localSave}
            preDefinedLicense={preDefinedLicense}
            preDefinedLocationName={preDefinedLocationName}
            preDefinedLocationId={preDefinedLocationId}
            localSaveCb={localSaveCb}
            successCb={successCb}
            initialFile={initialFile}
            metaComplianceData={metaComplianceData}
            replaceDocument={replaceDocument}
            disableCategorySelect={disableCategorySelect}
            disableDocumentName={disableDocumentName}
            disableSigningDate={disableSigningDate}
            disableExpiryDate={disableExpiryDate}
            disableReminder={disableReminder}
            onActionComplete={onActionComplete}
            uploadedFrom={uploadedFrom}
            sendForApproval={sendForApproval}
          />
        ),
        isCentered: false,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '12px',
          minW: '660px',
        },
        footer: null,
      });
    },
    []
  );
};
