import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Button, Checkbox, Flex, Image, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  DocumentFilterType,
  DocumentTypeState,
  IDocumentFilterState,
} from './FiltersAndListing';
import complianceGray from 'assets/images/compliance/complianceGray.svg';
import otherDocGray from 'assets/images/compliance/otherDocGray.svg';
import waitingAproval from 'assets/images/compliance/waitingAproval.svg';
import documentType from 'assets/images/compliance/documentType.svg';

interface IProps {
  onFilterSelect: (filterType: DocumentFilterType) => void;
  setDocumentFilterState: Dispatch<SetStateAction<IDocumentFilterState>>;
  documentFilterState: IDocumentFilterState;
}

interface DocumentTypeButtonProps {
  onFilterSelect: (filterType: DocumentFilterType) => void;
  documentFilterState: IDocumentFilterState;
}

export const DocumentTypeButton = ({
  onFilterSelect,
  documentFilterState,
}: DocumentTypeButtonProps) => {
  return (
    <Button
      gap={1}
      width='100%'
      fontWeight={400}
      fontSize='14px'
      color='#1A1D1F'
      justifyContent='space-between'
      padding='12px'
      borderRadius='8px'
      variant='ghost'
      onClick={() => onFilterSelect('documentType')}
    >
      <Flex alignItems='center' gap={1}>
        <Image src={documentType} width='20px' />
        Document type
        {documentFilterState.documentType && (
          <Box as='span' color='#6F767E' fontWeight={400} fontSize='12px'>
            {`( Selected)`}
          </Box>
        )}
      </Flex>
      <FontAwesomeIcon
        icon={faChevronRight as IconProp}
        fontSize='12px'
        color='#6F767E'
      />
    </Button>
  );
};

const DocumentType: FC<IProps> = ({
  onFilterSelect,
  documentFilterState,
  setDocumentFilterState,
}) => {
  const menuItems = [
    {
      name: 'Compliance',
      value: 'compliance',
      icon: <Image src={complianceGray} width='22px' />,
    },
    {
      name: 'Others',
      value: 'other',
      icon: <Image src={otherDocGray} width='22px' />,
    },
    {
      name: 'Awaiting approval',
      value: 'awaitingApproval',
      icon: <Image src={waitingAproval} width='22px' />,
    },
  ];

  const handleChange = (value: DocumentTypeState) => {
    if (documentFilterState.documentType === value) {
      setDocumentFilterState({
        ...documentFilterState,
        documentType: undefined,
      });
      return;
    } else {
      setDocumentFilterState({
        ...documentFilterState,
        documentType: value,
      });
    }
  };

  return (
    <Flex flexDirection='column' flex={1} gap={2}>
      <Flex
        cursor='pointer'
        alignItems='center'
        gap={3}
        onClick={() => {
          onFilterSelect(null);
        }}
        width='fit-content'
      >
        <FontAwesomeIcon
          icon={faArrowLeftLong as IconProp}
          fontSize='16px'
          color='#6F767E'
        />

        <Text color='#1A1D1F' fontSize='14px' fontWeight={600}>
          Document type
        </Text>
      </Flex>
      <Flex maxHeight='27vh' flexDirection='column' overflowY='scroll'>
        {menuItems.map((item) => {
          return (
            <Flex key={item.value} gap={2} alignItems={'center'}>
              <Checkbox
                value={item.value}
                onChange={() => handleChange(item.value as DocumentTypeState)}
                isChecked={
                  documentFilterState.documentType ===
                  (item.value as DocumentTypeState)
                }
                _hover={{
                  background: '#EFEFEF',
                }}
                padding='8px 12px'
                borderRadius='8px'
                width='100%'
              >
                <Flex alignItems={'center'} gap={2}>
                  {item.icon}
                  <Text fontWeight={400} fontSize={'14px'}>
                    {item.name}
                  </Text>
                </Flex>
              </Checkbox>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default DocumentType;
