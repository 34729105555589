import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { FC, useState } from 'react';

const reqUrl = `${process.env.REACT_APP_OPS_ENDPOINT}/dump/compliance`;

const useExportDocument = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const exportDocuments = ({
    locationId,
    categoryIds,
    successCb,
    entityId,
    type,
  }: {
    locationId?: string;
    categoryIds?: string[];
    type?: 'compliance' | 'other';
    successCb?: () => void;
    entityId?: string;
  }) => {
    let data: {
      locationId?: string;
      categoryIds?: string[];
      type?: 'compliance' | 'other';
      entityId?: string;
    } = {};

    if (locationId) {
      data.locationId = locationId;
    }

    if (categoryIds) {
      data.categoryIds = categoryIds;
    }

    if (type) {
      data.type = type;
    }

    if (entityId) {
      data.entityId = entityId;
    }

    setLoading(true);

    try {
      axios.post(reqUrl, data, { responseType: 'blob' }).then((response) => {
        const _url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = _url;
        link.setAttribute('download', 'documentExport.xlsx');
        document.body.appendChild(link);
        link.click();
        // Cleanup

        toast({
          title: `Summary exported successfully.`,
          status: 'success',
        });

        window.URL.revokeObjectURL(_url);
        document.body.removeChild(link);
        successCb?.();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { loading, exportDocuments };
};

export default useExportDocument;
