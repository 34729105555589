import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../atoms';
import { ActionButton } from 'ui-components';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';
import { IoWarningOutline } from 'react-icons/io5';
import { Authorize, AuthRole } from 'authorization';
import { usePendingApprovals } from 'sub-components/nexus/Compliance/Listing/components/common';
import { usePendingApprovalCount } from 'sub-components/nexus/Compliance/Listing/usePendingApprovalCount';

const ComplianceHeading: FC = () => {
  const { t } = useTranslation(['home']);
  const history = useHistory();

  const complianceUpload = useComplianceUpload();
  const pendingApprovals = usePendingApprovals();
  const { pendingApprovalCount } = usePendingApprovalCount();

  const pendingApprovalsCount = pendingApprovalCount;

  return (
    <Flex w='full' justify='space-between' align='center'>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='28px' minH={8}>
          {t('home:complianceListing')}
        </Text>

        <Button
          fontSize='14px'
          fontWeight={500}
          width='max-content'
          variant='link'
          leftIcon={<BackIcon width='16' height='16' />}
          _hover={{
            textDecoration: 'none',
          }}
          onClick={history.goBack}
        >
          Back to Dashboard
        </Button>
      </Flex>

      <Flex gap={2}>
        <Authorize
          permittedFor={'user'}
          permittedRoles={[AuthRole.SUPER_ADMIN]}
        >
          <ActionButton
            disabled={pendingApprovalsCount > 0 ? false : true}
            border={'2px solid #EFEFEF'}
            colorScheme={pendingApprovalsCount ? 'red' : 'whiteAlpha'}
            color={pendingApprovalsCount ? 'white' : '#6F767E'}
            leftIcon={
              <IoWarningOutline
                color={pendingApprovalsCount ? 'white' : '#6F767E'}
                fontSize={'18px'}
              />
            }
            actionFn={() => {
              pendingApprovals();
            }}
          >
            View pending approvals
          </ActionButton>
        </Authorize>

        <Authorize
          permittedFor={'user'}
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
        >
          <ActionButton
            colorScheme='blue'
            leftIcon={<AddIcon />}
            actionFn={() =>
              complianceUpload({
                documentType: 'compliance',
                uploadedFrom: 'compliance',
              })
            }
          >
            Add document
          </ActionButton>
        </Authorize>
      </Flex>
    </Flex>
  );
};

export default ComplianceHeading;
