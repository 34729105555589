import { gql } from '@apollo/client';
import { LAUNCHER_TASK_STEP_TYPE, TASK_DEPENDENCY } from '../../../../types';
import { BaseBoardItemEntity } from './boards-list';

export interface LauncherTaskStep {
  formId: string | null;
  sopId: string | null;
  trainingId: string | null;
  stepId: string;
  title: string;
  type: LAUNCHER_TASK_STEP_TYPE;
  completedBy?: string;
  completedAt?: string;
}

export interface LauncherAssignee {
  assignedType: 'location' | 'user';
  assignedUser: string[];
}

export type TriggerUnit = 'days' | 'weeks' | 'months';

export interface BaseTriggerEntity {
  value: number;
  unit: TriggerUnit;
  order: 'before' | 'after';
  itemId?: string | null;
  dependentOn: 'phase' | 'task' | 'goLive' | 'startDate';
}

export interface ActivationTrigger extends BaseTriggerEntity {
  deadlineType: 'goLive' | 'duration';
  deadlineUnit?: TriggerUnit;
  deadlineValue?: number;
}

export interface TriggerEntity {
  activationTrigger?: ActivationTrigger | null;
  deadlineTrigger?: BaseTriggerEntity | null;
  triggerChoice: 'default' | 'activation' | 'deadline';
}

export interface ConfigMetaEntity {
  triggerItemId?: string;
}

export interface LauncherTrigger {
  trigger: TriggerEntity | null;
  meta?: ConfigMetaEntity | null;
}

export interface LauncherTask extends LauncherAssignee, LauncherTrigger {
  eid: string;
  title: string;
  description?: string;
  duration: number;
  completed: boolean;
  dependency: TASK_DEPENDENCY;
  isRequired: boolean;
  docRequired: boolean;
  steps: LauncherTaskStep[];
  selected: boolean;
}

export interface LauncherContent extends LauncherAssignee, LauncherTrigger {
  eid: string;
  category: string;
  selected?: boolean;
  tasks: LauncherTask[];
  triggerAssignment?: 'task' | 'phase';
}

export interface LauncherConfig extends BaseBoardItemEntity {
  eid: string;
  title: string;
  published: boolean;
  locations: number;
  contents: LauncherContent[];
}

export const LauncherConfigFragment = gql`
  fragment LauncherConfig on Launcher {
    eid
    title
    published
    locations
    contents {
      eid
      category
      selected
      assignedType
      assignedUser
      meta
      triggerAssignment
      trigger {
        triggerChoice
        activationTrigger {
          value
          unit
          order
          itemId
          dependentOn
          deadlineType
          deadlineValue
          deadlineUnit
        }
        deadlineTrigger {
          value
          unit
          order
          itemId
          dependentOn
        }
      }
      tasks {
        eid
        title
        description
        category
        duration
        completed
        dependency
        isRequired
        docRequired
        complianceDocRequired

        selected
        assignedType
        assignedUser
        meta
        steps {
          formId
          stepId
          title
          type
          sopId
          trainingId
        }
        trigger {
          triggerChoice
          activationTrigger {
            value
            unit
            order
            itemId
            dependentOn
            deadlineType
            deadlineValue
            deadlineUnit
          }
          deadlineTrigger {
            value
            unit
            order
            itemId
            dependentOn
          }
        }
        triggerTask
      }
    }
  }
`;
