import { useMutation } from '@apollo/client';
import { Box, Button, Flex, Text, useModalContext } from '@chakra-ui/react';
import React, { FC } from 'react';
import { DELETE_COMPLIANCE_CATEGORY } from 'sub-components/nexus/Compliance/query/compliance.graphql';
import { ActionButton } from 'ui-components';

interface IProps {
  eid: string;
  onActionComplete: () => void;
}

const DeleteCategoryContent: FC<IProps> = ({ eid, onActionComplete }) => {
  const { onClose } = useModalContext();

  const [deleteComplianceCategory, { loading: deleteLoader }] = useMutation(
    DELETE_COMPLIANCE_CATEGORY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        onActionComplete();
      },
    }
  );

  const handleDeleteDocument = async () => {
    try {
      await deleteComplianceCategory({
        variables: {
          eid: eid,
        },
      });
      onClose();
      onActionComplete();
    } catch (error) {}
  };

  return (
    <Flex flexDirection='column' gap={6} width='100%' marginTop={4}>
      <Text color={'#6F767E'}>
        This will permanently delete the uploaded documents in the category
      </Text>

      <Flex width={'100%'} gap={2} justifyContent={'center'}>
        <Button onClick={onClose} flex={1}>
          Cancel
        </Button>
        <ActionButton
          colorScheme='red'
          mr={3}
          actionFn={handleDeleteDocument}
          disabled={deleteLoader}
          isLoading={deleteLoader}
          flex={1}
        >
          Yes
        </ActionButton>
      </Flex>
    </Flex>
  );
};

DeleteCategoryContent.displayName =
  'displayName:sub-components/nexus/Compliance/Listing/components/deleteCategory/DeleteCategoryContent.tsx';

export default DeleteCategoryContent;
