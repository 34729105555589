import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { FC } from 'react';
import { FileType } from 'sub-components/nexus/Compliance/types/compliance.types';
import { getImage } from 'utils';
import { formatDateTime } from '../../helper';

interface IProps {
  data: FileType;
  index: number;
}

const VersionCard: FC<IProps> = ({ data, index }) => {
  let createdByUser = data?.createdBy?.name || 'Name not provided';
  let createdByProfilePic = data?.createdBy?.profilePic || null;

  return (
    <Flex
      borderBottom={'1px solid #EEEEEE'}
      borderRadius={index === 0 ? '10px' : ''}
      p={'12px'}
      bgColor={index === 0 ? '#EFEFEF' : ''}
      flexDir={'column'}
    >
      <Flex gap={1} alignItems={'center'}>
        {index === 0 && (
          <Text color={'#2A85FF'} fontWeight={600} fontSize={'11px'}>
            Latest Upload:
          </Text>
        )}
        <Text fontSize={'11px'} color={'#6F767E'}>
          {moment(data?.createdAt).format('DD MMM')},&nbsp;
          {moment(data?.createdAt).format('hh:mm A')}
          {data?.signingDate &&
            ` | Signed on ${moment(data?.signingDate).format('DD MMM')}`}
        </Text>
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Flex gap={1} alignItems={'center'}>
          <Image
            borderRadius={'50%'}
            fit={'cover'}
            w={'20px'}
            h={'20px'}
            src={getImage(createdByProfilePic, createdByUser)}
          />
          <Tooltip label={createdByUser}>
            <Text isTruncated maxW='250px'>
              {createdByUser}
            </Text>
          </Tooltip>
        </Flex>
        <Flex>
          <Text
            onClick={() => window.open(data?.url, '_blank')}
            fontSize={'12px'}
            fontWeight={600}
            cursor={'pointer'}
            color={'#2A85FF'}
            textDecoration={'underline'}
          >
            View Doc
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VersionCard;
