import { Flex, VStack } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderColors } from 'configs';
import { useUploadOtherDoc } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions';
import { FC } from 'react';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { LocationLaunchTaskByIdResponse } from 'sub-components/Launcher/tasks/components/task-details/types';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { BoxHeader } from 'ui-components';
import useCombinedStore from 'zustandStore/store';
import ComplianceDocContainer from './ComplianceDocContainer';
import OtherDocsContainer from './OtherDocsContainer';

interface IProps {
  task: PhaseTask;
  refetchData: () => void;
  complianceFiles: LocationLaunchTaskByIdResponse['LocationLaunchTaskById']['complianceFiles'];
  otherFiles: LocationLaunchTaskByIdResponse['LocationLaunchTaskById']['complianceFiles'];
  taskStatus: string;
  locationId: string;
  launchId?: string;
}

const TaskAttachment: FC<IProps> = ({
  task,
  refetchData,
  complianceFiles,
  otherFiles,
  taskStatus,
  locationId,
  launchId,
}) => {
  const complianceUpload = useComplianceUpload();
  const uploadOtherDoc = useUploadOtherDoc();

  const { selectedInProgressLocation } = useCombinedStore();

  const handleFileUpload = (
    files: File[],
    documentType: 'compliance' | 'other'
  ) => {
    if (files?.length === 0) return;

    if (documentType === 'compliance') {
      complianceUpload({
        initialFile: files?.[0],
        metaComplianceData: {
          launchId: launchId || selectedInProgressLocation?.launchId!,
          taskId: task?.eid,
          phaseId: task?.phaseEid,
          phase: task?.phaseName,
          task: task?.title,
        },
        preDefinedLocationId: locationId,
        documentType: documentType,
        successCb: refetchData,
        uploadedFrom: 'task_attachment',
      });
    } else {
      uploadOtherDoc({
        locationId: locationId,
        documentType: documentType,
        initialFile: files?.[0],
        metaComplianceData: {
          launchId: launchId || selectedInProgressLocation?.launchId!,
          taskId: task?.eid,
          phaseId: task?.phaseEid,
          phase: task?.phaseName,
          task: task?.title,
        },
        successCb: refetchData,
      });
    }
  };

  const handleReupload = (eid: string) => {
    complianceUpload({
      metaComplianceData: {
        launchId: launchId || selectedInProgressLocation?.launchId!,
        taskId: task?.eid,
        phaseId: task?.phaseEid,
        phase: task?.phaseName,
        task: task?.title,
      },
      complianceId: [eid],
      preDefinedLocationId: locationId,
      documentType: 'compliance',
      replaceDocument: true,
      successCb: refetchData,
      disableDocumentName: true,
      disableCategorySelect: true,
    });
  };

  return (
    <VStack spacing={4} alignItems='flex-start'>
      <Flex alignItems='center' justifyContent='space-between' width='100%'>
        <BoxHeader
          color={HeaderColors.Purple}
          title='Attachment'
          fontSize='14px'
          fontWeight={500}
          isRequired={task.docRequired}
        />
        {task.reUploadRequired && (
          <FontAwesomeIcon
            icon={faExclamationTriangle as IconProp}
            color='#FF6A55'
            fontSize='22px'
          />
        )}
      </Flex>

      <ComplianceDocContainer
        documents={complianceFiles}
        complianceDocRequired={task.complianceDocRequired as boolean}
        refetchData={refetchData}
        taskStatus={taskStatus}
        handleFileUpload={handleFileUpload}
        handleReupload={handleReupload}
      />

      <OtherDocsContainer
        otherDocs={otherFiles}
        docRequired={task.docRequired as boolean}
        refetchData={refetchData}
        taskStatus={taskStatus}
        handleFileUpload={handleFileUpload}
      />
    </VStack>
  );
};

TaskAttachment.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/Attachments/TaskAttachment';

export default TaskAttachment;
