import {
  Box,
  Button,
  Center,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as FilterIcon } from 'assets/images/filterFunnelIcon.svg';
import { HeaderColors } from 'configs';
import React, { useMemo, useState } from 'react';
import { BoxHeader } from 'ui-components';
import ExpiredDocumentOnly from './ExpiredDocumentOnly';
import DocumentUploadedBy, { DocumentUploadedByButton } from './UploadedBy';
import {
  DocumentFilterEmptyState,
  DocumentFilterType,
  IDocumentFilterState,
} from './FiltersAndListing';
import ByLocation, { ByLocationButton } from './ByLocation';
import DocumentType, { DocumentTypeButton } from './DocumentType';

interface useDocumentFilterProps {
  filters: Exclude<DocumentFilterType, null>[];
}

const useDocumentFilter = ({ filters }: useDocumentFilterProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [documentFilterState, setDocumentFilterState] =
    useState<IDocumentFilterState>(DocumentFilterEmptyState);

  const [selectedFilter, setSelectedFilter] = useState<DocumentFilterType>();

  const onFilterSelect = (filterType: DocumentFilterType) => {
    setSelectedFilter(filterType);
  };

  const hasSelectedFilters = useMemo(() => {
    return (
      documentFilterState.expiredDocumentOnly ||
      documentFilterState.uploadedBy.length > 0 ||
      documentFilterState.locations.length > 0 ||
      !!documentFilterState.documentType
    );
  }, [documentFilterState]);

  const handleClearFilters = () => {
    if (!selectedFilter || selectedFilter === 'Expired') {
      setDocumentFilterState(DocumentFilterEmptyState);
    } else if (selectedFilter === 'uploadedBy') {
      setDocumentFilterState({
        ...documentFilterState,
        uploadedBy: [],
      });
    } else if (selectedFilter === 'location') {
      setDocumentFilterState({
        ...documentFilterState,
        locations: [],
      });
    } else if (selectedFilter === 'documentType') {
      setDocumentFilterState({
        ...documentFilterState,
        documentType: undefined,
      });
    }
  };

  const filterCount = useMemo(() => {
    return Object.values(documentFilterState).reduce((acc, value) => {
      if (Array.isArray(value)) return acc + value.length;
      if (typeof value === 'boolean' && value) return acc + 1;
      if (typeof value === 'string' && value) return acc + 1;
      return acc;
    }, 0);
  }, [documentFilterState]);

  const isClearDisabled = useMemo(() => {
    if (filterCount === 0) return true;
    if (selectedFilter === 'location') {
      return documentFilterState.locations.length === 0;
    }
    if (selectedFilter === 'uploadedBy') {
      return documentFilterState.uploadedBy.length === 0;
    }
    if (selectedFilter === 'documentType') {
      return documentFilterState.documentType === undefined;
    }

    return false;
  }, [filterCount, selectedFilter]);

  const filterConfig = {
    uploadedBy: DocumentUploadedBy,
    location: ByLocation,
    documentType: DocumentType,
    Expired: <></>, //  we are not rendering a diff component for expired documents, its just a single button
  };

  const filterButtonConfig = {
    Expired: ExpiredDocumentOnly,
    uploadedBy: DocumentUploadedByButton,
    location: ByLocationButton,
    documentType: DocumentTypeButton,
  };

  const FilterComponent = (
    <Popover
      placement='bottom-start'
      strategy='fixed'
      isOpen={isOpen}
      onClose={() => {
        setSelectedFilter(null);
        onClose();
      }}
    >
      <PopoverTrigger>
        <Center
          width='46px'
          height='46px'
          border='2px solid #EFEFEF'
          borderRadius='12px'
          cursor='pointer'
          onClick={onOpen}
          position='relative'
        >
          <FilterIcon />

          {filterCount > 0 && (
            <Center
              position='absolute'
              top='-5px'
              right='-5px'
              width='16px'
              height='16px'
              background='#2A85FF'
              borderRadius='50%'
              fontSize='8px'
              color='white'
              fontWeight={600}
            >
              {filterCount}
            </Center>
          )}
        </Center>
      </PopoverTrigger>
      <PopoverContent
        borderRadius='12px'
        padding='14px 7px'
        boxShadow='lg'
        width='330px'
      >
        <PopoverBody>
          <Flex justifyContent='space-between' alignItems='center'>
            <BoxHeader color={HeaderColors.Purple} title='Filters' />
            <Button
              variant='unstyled'
              color={isClearDisabled ? '#8dc3ff' : '#2A85FF'}
              disabled={isClearDisabled}
              fontWeight={600}
              fontSize='14px'
              onClick={handleClearFilters}
            >
              {selectedFilter && selectedFilter !== 'Expired'
                ? 'Clear Selection'
                : 'Clear All'}
            </Button>
          </Flex>
          {selectedFilter ? (
            <Flex marginTop='1rem'>
              {selectedFilter &&
                filterConfig[selectedFilter] &&
                React.createElement(
                  filterConfig[selectedFilter] as React.ElementType,
                  {
                    onFilterSelect,
                    setDocumentFilterState,
                    documentFilterState,
                  }
                )}
            </Flex>
          ) : (
            <Flex flexDirection='column' gap={1} marginTop='1rem'>
              {filters.map((filter) => {
                const FilterComponent = filterButtonConfig[
                  filter
                ] as React.ElementType;

                return FilterComponent ? (
                  <FilterComponent
                    key={filter}
                    onFilterSelect={onFilterSelect}
                    setDocumentFilterState={setDocumentFilterState}
                    documentFilterState={documentFilterState}
                  />
                ) : null;
              })}
            </Flex>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return {
    FilterComponent,
    documentFilterState,
    hasSelectedFilters,
    setDocumentFilterState,
  };
};
useDocumentFilter.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/useDocumentFilter.tsx';

export default useDocumentFilter;
