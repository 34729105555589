import { Flex, Text } from '@chakra-ui/react';
import React, { FC, Key } from 'react';
import { ActionButton } from 'ui-components';

interface IProps {
  complianceIds: string[] | Key[];
  handlePrev: () => void;
  handleNext: () => void;
  handleBatchUpdate: () => void;
  currentIndex: any;
  formDataMap: any[];
  isLoading: Boolean | undefined;
  methods: any;
  categorySelect: any;
}

const UpdatecomplianceFooter: FC<IProps> = ({
  complianceIds,
  handlePrev,
  handleNext,
  handleBatchUpdate,
  currentIndex,
  formDataMap,
  isLoading,
  categorySelect,
}) => {
  return (
    <Flex
      justifyContent='space-between'
      alignItems='center'
      mt={4}
      bg={'#FCFCFC'}
    >
      <Text fontSize='13px' fontWeight={500} color={'#33383F'}>
        <Text as={'span'} fontSize='20px' fontWeight={600}>
          {currentIndex + 1}
        </Text>
        /{complianceIds.length}
      </Text>
      <Flex gap={'8px'}>
        {currentIndex > 0 && (
          <ActionButton
            actionFn={handlePrev}
            colorScheme='gray'
            variant='outline'
            isDisabled={isLoading}
            borderRadius={'12px'}
          >
            Prev
          </ActionButton>
        )}
        {currentIndex < complianceIds.length - 1 ? (
          <ActionButton
            actionFn={handleNext}
            colorScheme='blue'
            isDisabled={!Object.keys(formDataMap).length || !categorySelect}
            borderRadius={'12px'}
          >
            Save and next
          </ActionButton>
        ) : (
          <ActionButton
            actionFn={handleBatchUpdate}
            colorScheme='blue'
            isLoading={isLoading}
            isDisabled={
              !Object.keys(formDataMap).length || !categorySelect || isLoading
            }
            borderRadius={'12px'}
          >
            Move to Compliance
          </ActionButton>
        )}
      </Flex>
    </Flex>
  );
};

export default UpdatecomplianceFooter;
