import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';

import { Input, SelectOption } from '../../../../../../atoms';
import { useUserDataSelector, useUserEntity } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils';
import { useShallow } from '../../../../../../zustandStore';
import TitleHeader from '../../../../../CardEditor/TitleHeader';
import { IDocumentInput } from './compliance.input';
import { LocationSelect } from './location-select';
import { useTranslation } from 'react-i18next';
import { AuthRole } from 'sop-commons/src/client';

interface IProps {
  locationList?: SelectOption[];
  preDefinedLocationId?: string;
}

const LocationSelectField: FC<IProps> = () => {
  const { t } = useTranslation(['nexus']);
  const { getValues, setValue } = useFormContext<IDocumentInput>();
  let locationList = useUserEntity<SelectOption[]>(
    useShallow((entity) => {
      return toArray(entity?.locations)
        .map((location) => {
          return { label: location.name, value: location.eid };
        })
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    })
  );

  //Filter locations if the user role is Location Owner
  const { userAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );

  if (userAuthRole === AuthRole.LOCATION_OWNER) {
    locationList = loggedInUserLocations
      ?.map((location) => {
        return { label: location.name, value: location.eid };
      })
      .sort((a, b) => a?.label?.localeCompare(b?.label));
  }

  const onLocationChange = (_: SelectOption) => {
    // TODO reset remind to location owner when document location is being changed, so that other location owner not get notified
  };

  const preDefinedLocationName = getValues('preDefinedLocationName');
  const preDefinedLocationId = getValues('preDefinedLocationId');

  useEffect(() => {
    if (preDefinedLocationId && !preDefinedLocationName) {
      const foundLocation = locationList?.find(
        (loc) => loc?.value === preDefinedLocationId
      );
      if (foundLocation) {
        setValue('location', foundLocation);
      }
    }
  }, [preDefinedLocationId, locationList, preDefinedLocationName]);

  const preDefinedName = useCallback(() => {
    if (preDefinedLocationName) {
      return preDefinedLocationName;
    } else if (preDefinedLocationId) {
      const foundLocation = locationList?.find(
        (loc) => loc?.value === preDefinedLocationId
      );
      if (foundLocation) {
        return foundLocation?.label;
      }
    }
    return '';
  }, [preDefinedLocationId, locationList, preDefinedLocationName]);

  if (preDefinedLocationName || preDefinedLocationId) {
    return (
      <FormControl>
        <TitleHeader title={'Add location'} />
        <Input size='lg' readOnly value={preDefinedName()} />
      </FormControl>
    );
  }

  return (
    <Controller<IDocumentInput, 'location'>
      name='location'
      rules={{
        required: t('nexus:documents.validations.locationRequired'),
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <Flex align='center' gap='4px' mb={2}>
            <TitleHeader
              isRequired={true}
              title='Add location'
              // desc='(Optional)'
            />
          </Flex>

          <LocationSelect
            options={locationList}
            placeholder='Location select'
            {...field}
            onChange={callAll(field.onChange, onLocationChange)}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

LocationSelectField.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/LocationSelectField';

export default LocationSelectField;
