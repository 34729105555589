import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import {
  FilterKeyType,
  GroupedLocation,
  GroupedTasksData,
  TaskFilterType,
} from '../types';
import { useService } from 'sub-components/Launcher/location-owner/layers/useService';
import {
  ILocationLaunchTask,
  LocationLaunchTasksResponseV2,
} from 'sub-components/Launcher/location-owner/types';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { processLocationTasks } from '../utils';
import { useService as useLocalService } from './useService';
import moment from 'moment';
import { cloneDeep } from '@apollo/client/utilities';
import { HeaderColors } from 'configs';
import { TaskType } from 'sub-components/Launcher/dashboard/dashboard-grapghql';
import useCombinedStore from 'zustandStore/store';
import {
  ENTITY_COMPLIANCE_COUNT_FOR_LAUNCHER,
  LocationEntityComplianceResponse,
  LocationPhaseDetails,
  PhaseTask,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { getReUploadRequiredForTask } from 'sub-components/Launcher/dashboard/Location/helper';
import { useLazyQuery } from '@apollo/client';

export const useControl = () => {
  const {
    stateDrawer: { updateLoading },
  } = useCombinedStore();
  const [tasks, setTasks] = useState<GroupedTasksData[]>([]);
  const [initialTasks, setInitialTasks] = useState<ILocationLaunchTask[]>([]);
  const businessLocations = useUserEntity((entity) => entity?.locations);
  const loggedInUser = useUserDataSelector((state) => state);
  const [categories, setCategories] = useState<TaskType[]>([]);
  const [appliedFiltersForContainer, setAppliedFiltersForContainer] =
    useState<FilterKeyType>('status');
  const [taskFilters, setTaskFilters] = useState<TaskFilterType[]>([
    {
      label: 'Due today',
      value: 'dueToday',
      selected: false,
      showSelection: false,
      taskCount: 0,
      bannerColor: HeaderColors.Purple,
    },
    {
      label: 'Overdue',
      value: 'overdue',
      selected: false,
      showSelection: false,
      taskCount: 0,
      bannerColor: HeaderColors.Red.Light,
    },
    {
      label: 'This week',
      value: 'thisWeek',
      selected: false,
      showSelection: false,
      taskCount: 0,
      bannerColor: HeaderColors.Blue,
    },
    {
      label: 'Total tasks',
      value: 'totalTasks',
      selected: false,
      showSelection: false,
      taskCount: 0,
      bannerColor: HeaderColors.Yellow,
    },
  ]);

  const {
    locationLaunchTasksV2: {
      getLocationLaunchTasksV2,
      locationLaunchTasksV2Data,
    },
    locationEntityCompliance: {
      getLocationEntityCompliance,
      LocationEntityComplianceData,
    },
  } = useService(onLaunchTaskError);

  const {
    launcherTaskCategories: {
      getLauncherTaskCategories,
      launcherTaskCategoriesData,
    },
  } = useLocalService();

  const [
    getLocationEntityComplianceCount,
    { data: locationEntityComplianceCountData },
  ] = useLazyQuery(ENTITY_COMPLIANCE_COUNT_FOR_LAUNCHER, {
    fetchPolicy: 'network-only',
  });

  // const [getLocationLaunchTasks] = useLazyQuery<
  //   { LocationLaunchTasks: ILocationLaunchTask[] },
  //   { locationIds: string[] }
  // >(LOCATION_LAUNCH_TASKS_BY_ID, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (data) => {
  //     // Create a map for O(1) lookup using composite key
  //     const launchTasksMap = new Map(
  //       data?.LocationLaunchTasks?.map((launch) => [
  //         `${launch.locationId}-${launch.eid}-${launch.launchId}`,
  //         launch,
  //       ])
  //     );

  //     // Update initial tasks more efficiently
  //     const _initialTasks = initialTasks.map((task) => {
  //       const key = `${task.locationId}-${task.eid}-${task.launchId}`;
  //       return launchTasksMap.get(key) || task;
  //     });

  //     setInitialTasks(_initialTasks);
  //     onLaunchTaskSuccess({ LocationLaunchTasks: data?.LocationLaunchTasks });
  //   },
  //   onError: (err) => {},
  // });

  const _refetch = async () => {
    if (locationLaunchTasksV2Data?.refetch) {
      const { data } = await locationLaunchTasksV2Data?.refetch();
      setInitialTasks(data?.LocationLaunchTasks);
      onLaunchTaskSuccess(
        { LocationLaunchTasks: data?.LocationLaunchTasks },
        LocationEntityComplianceData
      );
    }
  };

  const refetchHandler = async (
    resData?: ILocationLaunchTask,
    phaseDetails?: LocationPhaseDetails[],
    task?: PhaseTask
  ) => {
    if (!resData?.eid || !tasks || !task) return;

    // Check if any task has this resData.eid as their triggerItemId
    const isTaskTrigger = initialTasks.some(
      (task) => task.triggerDetails?.triggerItemId === resData.eid
    );

    const allStepsCompleted = resData?.steps?.every(
      (step) => step?.completedAt
    );
    const taskCompleted = resData?.completedAt;

    if (isTaskTrigger && allStepsCompleted && taskCompleted) {
      _refetch?.();
      return;
    }

    let _initialTasks = cloneDeep(initialTasks);
    _initialTasks?.forEach((inTask) => {
      if (inTask?.eid === task?.eid && inTask?.launchId === task?.launcherId) {
        inTask.completedAt = resData?.completedAt;
        inTask.isCompleted = resData?.isCompleted;
        inTask.completedBy = resData?.completedBy;
        if (inTask?.steps?.length && resData?.steps?.length) {
          inTask?.steps?.forEach((inStep) => {
            const matchingStep = resData?.steps?.find(
              (reStep) => reStep?.stepId === inStep?.stepId
            );
            if (matchingStep) {
              inStep.completedAt = matchingStep?.completedAt;
              inStep.isCompleted = matchingStep?.isCompleted;
              inStep.completedBy = matchingStep?.completedBy;
            }
          });
        }
      }
    });

    setInitialTasks(_initialTasks);
    onLaunchTaskSuccess(
      { LocationLaunchTasks: _initialTasks },
      LocationEntityComplianceData
    );
  };

  useEffect(() => {
    if (locationLaunchTasksV2Data?.data && launcherTaskCategoriesData?.data) {
      let categories =
        launcherTaskCategoriesData?.data?.EntityLauncherTaskCategories || [];
      setCategories(categories);
      setInitialTasks(
        locationLaunchTasksV2Data?.data?.LocationLaunchTasks || []
      );
      let _data = cloneDeep(
        locationLaunchTasksV2Data?.data?.LocationLaunchTasks || []
      );
      _data?.map((data) => {
        let foundCategory = categories
          ?.filter((cat) => cat?.eid === data?.taskCategoryEid)
          ?.map((cat) => cat?.name)?.[0];
        data.taskCategory = foundCategory;
        data.category = data?.taskCategoryEid;
      });
      onLaunchTaskSuccess(
        { LocationLaunchTasks: _data },
        LocationEntityComplianceData
      );

      getLocationEntityComplianceCount({
        variables: {
          filter: {
            locationIds:
              businessLocations
                ?.filter((loc) => loc?.locationStatus === 'development')
                ?.map((loc) => loc?.eid) || [],
            type: 'compliance',
            status: ['active'],
          },
        },
      });
    }
  }, [locationLaunchTasksV2Data, launcherTaskCategoriesData]);

  function onLaunchTaskSuccess(
    apiRes: LocationLaunchTasksResponseV2,
    locationEntityComplianceData?: LocationEntityComplianceResponse
  ) {
    const apiResForSupervisors = apiRes?.LocationLaunchTasks?.filter(
      (task) => task?.isPhaseSelected || task?.isCompleted
      // task?.assignedType === 'user' &&
      // task?.assignedUsers?.includes(loggedInUser?.eid) &&
      // task?.isPhaseSelected
    );
    const groupedMap = apiResForSupervisors?.reduce((acc, item) => {
      const key = item.locationId;
      let locationName = businessLocations
        ?.filter((bus) => bus?.eid === item?.locationId)
        ?.map((loc) => loc?.name)?.[0];
      if (!acc.has(key)) {
        acc.set(key, {
          locationId: item.locationId,
          locationName: locationName || '',
          data: [],
        });
      }

      acc.get(key)!.data.push(item);
      return acc;
    }, new Map<string, GroupedLocation>());

    // Convert Map to array
    let groupedData = Array.from(groupedMap.values());
    let completeGroupedData: GroupedTasksData[] = groupedData?.map((group) => {
      let groupedTasks = processLocationTasks(
        group?.data,
        loggedInUser,
        moment.utc()
      );

      groupedTasks?.forEach((group) => {
        group.tasks.forEach((task) => {
          const reUploadRequired = getReUploadRequiredForTask(
            task,
            locationEntityComplianceData?.EntityCompliancePagination?.items ||
              []
          );
          if (reUploadRequired) {
            task.status = 'reSubmissionRequired';
          }
        });
      });

      return {
        locationName: group?.locationName,
        locationId: group?.locationId,
        groupedPhases: groupedTasks,
      };
    });
    let filteredData = completeGroupedData
      .map((locationData) => ({
        ...locationData,
        groupedPhases: locationData.groupedPhases
          .map((phase) => ({
            ...phase,
            tasks: phase.tasks.filter((task) =>
              task.assignedUsers?.some((user) => user === loggedInUser?.eid)
            ),
          }))
          .filter((phase) => phase.tasks.length > 0),
      }))
      .filter((location) => location.groupedPhases.length > 0);
    setTasks(filteredData);
    updateLoading(false);
  }

  function onLaunchTaskError() {
    return;
  }

  const filteredTasks = useMemo(() => {
    const selectedTaskFilter = taskFilters?.find((filter) => filter?.selected);

    if (!selectedTaskFilter) {
      return tasks ?? [];
    }

    switch (selectedTaskFilter.value) {
      case 'dueToday':
        return tasks
          .map((task) => ({
            ...task,
            groupedPhases: task.groupedPhases
              .map((phase) => ({
                ...phase,
                tasks: phase.tasks.filter((task) => task.status === 'dueToday'),
              }))
              .filter((phase) => phase.tasks.length > 0),
          }))
          .filter((task) => task.groupedPhases.length > 0);

      case 'overdue':
        return tasks
          .map((task) => ({
            ...task,
            groupedPhases: task.groupedPhases
              .map((phase) => ({
                ...phase,
                tasks: phase.tasks.filter((task) => task.status === 'overdue'),
              }))
              .filter((phase) => phase.tasks.length > 0),
          }))
          .filter((task) => task.groupedPhases.length > 0);

      case 'thisWeek':
        return tasks
          .map((task) => ({
            ...task,
            groupedPhases: task.groupedPhases
              .map((phase) => ({
                ...phase,
                tasks: phase.tasks.filter(
                  (task) =>
                    task.status === 'dueToday' ||
                    task.status === 'duesoon' ||
                    task.status === 'inProgress'
                ),
              }))
              .filter((phase) => phase.tasks.length > 0),
          }))
          .filter((task) => task.groupedPhases.length > 0);

      case 'totalTasks':
      default:
        return tasks ?? [];
    }
  }, [taskFilters, tasks]);

  useEffect(() => {
    getLocationLaunchTasksV2({
      variables: {
        locationIds:
          businessLocations
            ?.filter((loc) => loc?.locationStatus === 'development')
            ?.map((loc) => loc?.eid) || [],
      },
    });
    getLauncherTaskCategories();
  }, []);

  useEffect(() => {
    if (locationLaunchTasksV2Data.data) {
      onLaunchTaskSuccess(
        locationLaunchTasksV2Data.data,
        LocationEntityComplianceData
      );
    }
  }, [LocationEntityComplianceData?.EntityCompliancePagination?.items]);

  useEffect(() => {
    if (locationEntityComplianceCountData?.EntityCompliancePagination) {
      const payload = {
        filter: {
          locationIds:
            businessLocations
              ?.filter((loc) => loc?.locationStatus === 'development')
              ?.map((loc) => loc?.eid) || [],
          type: 'compliance',
          status: ['active'],
        },
        perPage:
          locationEntityComplianceCountData?.EntityCompliancePagination?.count,
      };

      getLocationEntityCompliance({
        variables: payload,
      });
    }
  }, [locationEntityComplianceCountData]);

  return {
    appliedFiltersForContainer,
    categories,
    loading: locationLaunchTasksV2Data?.loading,
    taskFilters,
    tasks,
    filteredTasks,
    refetchHandler,
    setAppliedFiltersForContainer,
    setTaskFilters,
    LocationEntityComplianceData,
  };
};
