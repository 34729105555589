import { Flex, Image, Text } from '@chakra-ui/react';
import lockedTaskIcon from 'assets/images/tasks/lock.svg';
import { FC } from 'react';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import LauncherLocationTask from '../LauncherLocationTask';
import { getTaskLabelByStatus } from '../helper';
import { GroupedTask, PhaseDependencyDetails } from './GroupByPhase';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import { LocationPhaseDetails } from '../LauncherLocation.graphql';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';

interface IProps {
  keyName: string;
  groupedTasks: GroupedTask;
  locationId: string;
  launchId?: string;
  categories: LTaskCategoryEntity[];
  refetchData?: () => void;
  phaseDetails: LocationPhaseDetails[];
}

export const statusOrder = [
  'reSubmissionRequired',
  'overdue',
  'duesoon',
  'inProgress',
  'yetToStart',
  'yetToStart/locked',
  'locked',
  'completed',
];

const GroupByStatus: FC<IProps> = ({
  keyName,
  groupedTasks,
  launchId,
  locationId,
  categories,
  refetchData,
  phaseDetails,
}) => {
  const statusLabel = getTaskLabelByStatus(keyName as ITaskStatus);
  const { currentUserId, currentUserAuthRole } = useUserDataSelector(
    (state) => ({
      currentUserId: state.eid,
      currentUserAuthRole: state.authRole,
    })
  );

  const shouldShowGroup = () => {
    if (
      currentUserAuthRole === AuthRole.SUPER_ADMIN ||
      currentUserAuthRole === AuthRole.ADMIN
    ) {
      return true;
    }

    const currentUserTasks = groupedTasks[keyName].tasks.filter(
      (task) =>
        task.assignedUser?.includes(currentUserId) ||
        (task.assignedType === 'location' &&
          currentUserAuthRole === AuthRole.LOCATION_OWNER)
    );

    if (currentUserTasks.length === 0) return false;

    return true;
  };

  const orderedTasksArray = statusOrder.map((status) => ({
    status,
    ...groupedTasks[status],
  }));

  const reorderedGroup = orderedTasksArray.reduce((acc, { status, tasks }) => {
    acc[status] = { tasks };
    return acc;
  }, {});

  return shouldShowGroup() ? (
    <Flex flexDirection='column' gap={4}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex gap={3}>
          <Flex flexDirection='column' gap={1}>
            <Flex alignItems='center' gap={3}>
              <Text
                color='#111315'
                fontSize={16}
                fontWeight={600}
                lineHeight='24px'
              >
                {statusLabel}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection='column' gap={2}>
        {reorderedGroup[keyName].tasks.map((task, index) => (
          <LauncherLocationTask
            key={index}
            task={task}
            launchId={launchId}
            locationId={locationId}
            categories={categories}
            refetchData={refetchData}
            phaseDetails={phaseDetails}
            goLiveDate={groupedTasks[keyName].goLiveDate}
          />
        ))}
      </Flex>
    </Flex>
  ) : null;
};

GroupByStatus.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/GroupBy/GroupByStatus';

export default GroupByStatus;
