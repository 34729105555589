import { gql } from '@apollo/client';

export const GET_PENDING_APPROVAL_DOC_LIST = gql`
  query EntityCompliancePagination(
    $filter: FilterFindManyEntityComplianceInput
    $perPage: Int
    $page: Int
  ) {
    EntityCompliancePagination(
      filter: $filter
      perPage: $perPage
      page: $page
    ) {
      items {
        createdBy
        eid
        approvalStatus
        location {
          name
          locationStatus
        }
        title
        metadata {
          launchId
          launcherName
          phase
          phaseId
          task
          taskId
        }
        files {
          name
          url
        }
      }
    }
  }
`;

// export const REQUEST_REUPLOAD = gql`
//   mutation RejectionComplianceDoc($eid: String!, $comment: String!) {
//     RejectionComplianceDoc(eid: $eid, comment: $comment) {
//       eid
//     }
//   }
// `;
export const REQUEST_REUPLOAD = gql`
  mutation RejectionComplianceDoc($input: [RejectionInput!]!) {
    RejectionComplianceDoc(input: $input) {
      succeed
    }
  }
`;

export const MARK_COMPLIANCE = gql`
  mutation ApprovalComplianceDocs($eids: [String]!) {
    ApprovalComplianceDocs(eids: $eids) {
      succeed
    }
  }
`;
