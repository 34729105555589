import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, sxInputStyle } from '../../../../../atoms/Input';
import { eventBus } from '../../../../../shared/eventEmit';
import { toArray } from '../../../../../utils';
import TitleHeader from '../../../../CardEditor/TitleHeader';
import { TaskSwitchInput } from './components';
import TaskCategorySelect from './components/TaskCategorySelect';
import TaskPhaseSelect from './components/TaskPhaseSelect';
import { ITaskInput } from './task-form.types';

interface IProps {
  phaseSelectDisabled?: boolean;
}

const TaskDetails: FC<IProps> = ({ phaseSelectDisabled }) => {
  const { setValue, watch } = useFormContext<ITaskInput>();

  const onPhaseChange = async (nextPhase: string) => {
    const config = await new Promise<Record<string, any>>((resolve) => {
      eventBus.emit('GET_CONFIG', (state: any) => {
        const contents = toArray(state.config?.contents).slice();
        const nextIndex = contents.findIndex((con) => con.eid === nextPhase);

        if (nextIndex === -1) {
          return resolve(undefined as never);
        }

        const phase = contents[nextIndex];

        return resolve({
          sectionIndex: nextIndex,
          phase: {
            eid: phase.eid,
            category: phase.category,
            trigger: phase.trigger,
          },
        });
      });
    });

    if (config) {
      // @ts-ignore
      setValue('sectionIndex', config.sectionIndex);
      setValue('phase', config.phase);
    }
  };

  const docRequired = watch('docRequired');

  return (
    <Flex flexDir='column' gap={3}>
      <Box>
        <TitleHeader
          title='Title'
          desc='Explain what needs to be done'
          isRequired
        />
      </Box>

      <Controller<ITaskInput, 'title'>
        name='title'
        defaultValue=''
        rules={{
          required: 'Task name is required',
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <Input
              size='lg'
              variant='auditOutline'
              placeholder='Add name of task'
              {...field}
            />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <Controller<ITaskInput, 'description'>
        name='description'
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <Textarea
              size='lg'
              variant='auditOutline'
              placeholder='Add description (optional)'
              {...field}
              fontSize='15px'
              borderRadius={12}
              sx={sxInputStyle}
              rows={4}
              paddingY={3}
              resize='none'
            />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <Controller<ITaskInput, 'category'>
        name='category'
        rules={{
          required: 'Task category is required',
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <Flex align='center'>
              <TitleHeader
                title='Category'
                isRequired
                titleStyle={{ flex: 1 }}
              />
              <TaskCategorySelect {...field} />
            </Flex>
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <Controller<ITaskInput, 'phaseEid'>
        name='phaseEid'
        rules={{
          required: 'Task phase is required',
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <Flex align='center'>
              <TitleHeader title='Phase' isRequired titleStyle={{ flex: 1 }} />
              <TaskPhaseSelect
                {...field}
                onChange={callAll(field.onChange, onPhaseChange)}
                isDisabled={field.disabled || phaseSelectDisabled}
              />
            </Flex>
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      {/*<Controller<ITaskInput, 'isRequired'>*/}
      {/*  name='isRequired'*/}
      {/*  render={({ field, fieldState }) => (*/}
      {/*    <FormControl isInvalid={fieldState.invalid}>*/}
      {/*      <TaskSwitchInput*/}
      {/*        title='Mandatory'*/}
      {/*        caption='All locations allotted this task will have to complete this task'*/}
      {/*        {...field}*/}
      {/*      />*/}
      {/*    </FormControl>*/}
      {/*  )}*/}
      {/*/>*/}

      <Controller<ITaskInput, 'docRequired'>
        name='docRequired'
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <TaskSwitchInput
              title='Document mandatory'
              caption='Task can’t be completed without it'
              {...field}
            />
          </FormControl>
        )}
      />

      {docRequired && (
        <Controller<ITaskInput, 'complianceDocRequired'>
          name='complianceDocRequired'
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <TaskSwitchInput
                title='One or more attachments necessary for compliance'
                caption=''
                {...field}
              />
            </FormControl>
          )}
        />
      )}
    </Flex>
  );
};

TaskDetails.displayName =
  'sub-components/Launcher/launcher-config/common/config-task-form/TaskDetails';

export default TaskDetails;
