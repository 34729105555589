import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import DragDropComponent from './DragDropComponent';
import { IDocumentInput } from './NewDocUpload';
import AddForm from './AddForm';

interface IProps {}

const UploadDocument: FC<IProps> = () => {
  const file = useWatch<IDocumentInput, 'file'>({
    name: 'file',
  });

  return (
    <Flex flexDir='column' gap={3}>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='15px' color='#1A1D1F'>
          Upload document <span style={{ color: 'red' }}> *</span>
        </Text>

        <DragDropComponent
          subTitle='PDF, XLSX, DOCX, JPG, PNG, PPT, ZIP &bull; Max size 50 MB'
          accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              [],
            'application/vnd.ms-powerpoint': [],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation':
              [],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'application/zip': ['.zip'],
          }}
        />
      </Flex>

      {file && <AddForm />}
    </Flex>
  );
};

UploadDocument.displayName =
  'packages/sop-web/src/pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/uploadOtherDoc/UploadDocument';

export default React.memo(UploadDocument);
