import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import othersDoc from 'assets/images/compliance/otherDocGray.svg';
import { FC, useMemo, useState } from 'react';
import { IComplianceFile } from 'sub-components/Launcher/tasks/components/task-details/types';
import FileDropZone from '../FileDropzone';
import DocumentChip from './DocumentChip';

const purpleFilter = `invert(39%) sepia(86%) saturate(3583%) hue-rotate(240deg) brightness(103%) contrast(101%)`;

interface IProps {
  otherDocs: IComplianceFile[];
  taskStatus: string;
  docRequired: boolean;
  handleFileUpload: (
    files: File[],
    documentType: 'compliance' | 'other'
  ) => void;
  refetchData: () => void;
}

const OtherDocsContainer: FC<IProps> = ({
  otherDocs,
  taskStatus,
  docRequired,
  handleFileUpload,
  refetchData,
}) => {
  const [showOtherDropZone, setShowOtherDropZone] = useState(false);

  const isOtherDropZoneVisible = useMemo(() => {
    if (otherDocs.length) return true;
    if (showOtherDropZone) return true;

    return false;
  }, [otherDocs, showOtherDropZone]);

  const activeDocuments = useMemo(() => {
    return otherDocs.filter((file) => file.status !== 'inactive');
  }, [otherDocs]);

  const deletedDocuments = useMemo(() => {
    return otherDocs.filter((file) => file.status === 'inactive');
  }, [otherDocs]);

  return (
    <Flex width='100%'>
      {!otherDocs.length && !showOtherDropZone && (
        <Button
          variant='outline'
          gap={2}
          fontSize='13px'
          color='#1A1D1F'
          onClick={() => setShowOtherDropZone(true)}
        >
          <FontAwesomeIcon icon={faPlus as IconProp} />
          Add other documents
        </Button>
      )}

      {isOtherDropZoneVisible && (
        <Flex
          flexDirection='column'
          gap={3}
          background={'#8E59FF1A'}
          padding='16px'
          borderRadius='12px'
          position='relative'
          width='100%'
        >
          <Flex
            as='span'
            color='#33383F'
            fontWeight={500}
            fontSize='14px'
            alignItems='center'
            gap={1}
          >
            <Image
              src={othersDoc}
              alt='other doc'
              width='24px'
              filter={purpleFilter}
            />
            Other document(s)
          </Flex>

          {!showOtherDropZone && taskStatus !== 'completed' && (
            <Button
              variant='unstyled'
              color='#2A85FF'
              position='absolute'
              top={2}
              right={5}
              fontWeight={500}
              fontSize='12px'
              display='flex'
              alignItems='center'
              gap={1}
              onClick={() => {
                setShowOtherDropZone(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus as IconProp} />
              Add another document
            </Button>
          )}

          {showOtherDropZone && (
            <FileDropZone
              required={docRequired}
              onFileUpload={handleFileUpload}
            />
          )}

          {activeDocuments.length ? (
            <Flex gap={3} flexWrap='wrap'>
              {activeDocuments.map((file, index) => (
                <DocumentChip
                  key={index}
                  document={file}
                  refetchData={refetchData}
                  taskStatus={taskStatus}
                />
              ))}
            </Flex>
          ) : null}

          {deletedDocuments.length ? (
            <Flex flexDirection='column' gap={2}>
              <Flex gap={2} alignItems='center'>
                <FontAwesomeIcon
                  icon={faInfoCircle as IconProp}
                  color='#FF6A55'
                  fontSize='20px'
                />
                <Text color='#FF6A55' fontSize='12px' fontWeight={500}>
                  The following document(s) have been deleted from the system.
                </Text>
              </Flex>
              <Flex gap={3} flexWrap='wrap'>
                {deletedDocuments.map((file, index) => (
                  <DocumentChip
                    key={index}
                    document={file}
                    refetchData={refetchData}
                    taskStatus={taskStatus}
                  />
                ))}
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      )}
    </Flex>
  );
};

OtherDocsContainer.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/Attachments/OtherDocsContainer.tsx';

export default OtherDocsContainer;
