import * as XLSX from 'xlsx';

interface IProps {
  isCategorizedExport?: boolean;
  fileName?: string;
}

// Make the prop default to false if it's not provided
const useExportDocs = ({
  isCategorizedExport = false,
  fileName = 'documents.xlsx',
}: IProps = {}): void => {
  const dataByCategories = {
    'Category 1': {
      columns: ['Name', 'Age', 'Country'],
      data: [
        { Name: 'John Doe', Age: 30, Country: 'USA' },
        { Name: 'Jane Smith', Age: 25, Country: 'Canada' },
      ],
    },
    'Category 2': {
      columns: ['Product', 'Price', 'Quantity'],
      data: [
        { Product: 'Laptop', Price: 1000, Quantity: 5 },
        { Product: 'Phone', Price: 700, Quantity: 10 },
      ],
    },
  };

  // if (isCategorizedExport) {
  //   console.log('Show hook to select categories');
  // } else {
  //   console.log('Export directly');
  //   exportToExcel(dataByCategories, fileName);
  // }

  exportToExcel(dataByCategories, fileName);
};

export function generateDataByCategories() {}

export function exportToExcel(dataByCategories, fileName) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Iterate through categories and add each category as a sheet in the workbook
  Object.keys(dataByCategories).forEach((categoryName) => {
    const categoryData = dataByCategories[categoryName];
    const columns = categoryData.columns;
    const data = categoryData.data;

    // Combine the columns and data into a format suitable for XLSX
    const wsData = [
      columns,
      ...data.map((item) => columns.map((col) => item[col])),
    ];

    // Create a new worksheet from the data
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, categoryName);
  });

  // Write the workbook to a file
  XLSX.writeFile(wb, fileName);
}

export default useExportDocs;
