import { useLazyQuery } from '@apollo/client';
import { GET_DOCUMENTS_FOR_LISTING } from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';
import { IDocumentPagination } from '../DocumentFilters/FiltersAndListing';

const useDocuments = () => {
  const [getDocuments, { loading: isDocumentLoading, data: documentsList }] =
    useLazyQuery<IDocumentPagination>(GET_DOCUMENTS_FOR_LISTING, {
      fetchPolicy: 'network-only',
    });

  return { getDocuments, isDocumentLoading, documentsList };
};

export default useDocuments;
