import { Divider, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as ChevronforwardIcon } from 'assets/images/chevronForward.svg';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';

import { DetailsFiltersType } from './AnalyticsContainer';
import { DetailsPageNameType } from './DetailsMainContainer';

export type TAnalyticsFilterClearFilter =
  | 'all'
  | 'detailsStatuses'
  | 'detailsScores';

interface IProps {
  setPageName: React.Dispatch<React.SetStateAction<DetailsPageNameType>>;
  clearFilters: (type?: TAnalyticsFilterClearFilter) => void;
  detailsFilters: DetailsFiltersType;
  setDetailsFilters?: React.Dispatch<React.SetStateAction<DetailsFiltersType>>;
}

const AnalyticsBaseFilter: FC<IProps> = ({
  detailsFilters,
  clearFilters,
  setPageName,
}) => {
  return (
    <Flex gap={4} flexDir='column'>
      <Flex alignItems={'center'} mt={'10px'} justifyContent={'space-between'}>
        <BoxHeader title='Filters' color={HeaderColors.Purple} />
        <Text
          cursor={'pointer'}
          onClick={() => clearFilters()}
          fontSize={'14px'}
          fontWeight={400}
          color={detailsFilters?.totalFiltersTypes > 0 ? '#2A85FF' : '#6F767E'}
        >
          Clear All
        </Text>
      </Flex>

      <Flex
        onClick={() => setPageName('detailsStatus')}
        cursor={'pointer'}
        mt={'20px'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex gap={4}>
          <Text
            color={'#333B4F'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            Status
          </Text>
          <Text
            color={'#6F767E'}
            fontWeight={400}
            lineHeight='16px'
            fontSize='14px'
          >
            {`${
              detailsFilters?.selectedStatuses?.length > 0
                ? '(' + detailsFilters?.selectedStatuses?.length + ' selected)'
                : ''
            }`}
          </Text>
        </Flex>
        <ChevronforwardIcon />
      </Flex>
      <Divider />
      {detailsFilters?.scores?.length > 0 && (
        <Flex
          mb={'30px'}
          onClick={() => setPageName('detailsScores')}
          cursor={'pointer'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Flex gap={4}>
            <Text
              color={'#333B4F'}
              fontWeight={400}
              lineHeight='16px'
              fontSize='14px'
            >
              Score
            </Text>
            <Text
              color={'#6F767E'}
              fontWeight={400}
              lineHeight='16px'
              fontSize='14px'
            >
              {`${
                detailsFilters?.selectedScores?.length > 0
                  ? '(' + detailsFilters?.selectedScores?.length + ' selected)'
                  : ''
              }`}
            </Text>
          </Flex>
          <ChevronforwardIcon />
        </Flex>
      )}
    </Flex>
  );
};

export default AnalyticsBaseFilter;
