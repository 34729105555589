import { Flex, Text, Button } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  closeModal?: () => void;
  description?: string;
}

const NotificationContent: FC<IProps> = ({ closeModal, description }) => {
  return (
    <Flex gap={4} flexDir={'column'}>
      <Text fontWeight={500} color={'#6F767E'} fontSize={'15px'}>
        {description}
      </Text>
      <Button onClick={closeModal} w={'full'} colorScheme={'blue'}>
        Okay
      </Button>
    </Flex>
  );
};

export default NotificationContent;
