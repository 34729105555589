import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useService } from './useService';
import { getTaskStatus } from 'sub-components/Launcher/dashboard/Location/helper';
import { cloneDeep } from '@apollo/client/utilities';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { useUserDataSelector, useUserEntity } from 'hooks';
import {
  convertTaskData,
  processLocationTasks,
} from 'sub-components/Launcher/tasks/utils';
import moment from 'moment';
import { ILocationLaunchTask } from 'sub-components/Launcher/location-owner/types';
import { useMarkAsComplete } from 'sub-components/Launcher/location-owner/StatesDrawer/Subtasks/useMarkAsComplete';
import { useMarkAsUndone } from 'sub-components/Launcher/location-owner/StatesDrawer/Subtasks/useMarkAsUndone';
import {
  useChapterView,
  useTrainingStartWarning,
  useTrainingView,
} from 'ui-components/resource-view';
import { useSubmitForm } from 'sub-components/forms';
import { useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { UPDATE_COMPLIANCE } from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';
import { useNotificationUpdate } from 'sub-components/nexus/Compliance/Listing/components/common/notificationModal';
import { AuthRole } from 'authorization';

export const useControl = () => {
  const { locationId, launchId, taskId } = useParams<{
    locationId: string;
    launchId: string;
    taskId: string;
  }>();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const businessLocations = useUserEntity((entity) => entity?.locations);
  const loggedInUser = useUserDataSelector((state) => state);

  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [loadingStepIds, setLoadingStepIds] = useState<string[]>([]);

  const { markAsComplete } = useMarkAsComplete();
  const { markAsUndone } = useMarkAsUndone();
  const chapterView = useChapterView();
  const submitForm = useSubmitForm();
  const trainingWarning = useTrainingStartWarning();
  const trainingView = useTrainingView();
  const [updateComplianceStatus] = useMutation(UPDATE_COMPLIANCE);
  const notificationModal = useNotificationUpdate();

  const {
    locLaunchById: { getLocationLaunchById, locationLaunchByIdData },
    locationLaunchTasksById: {
      getLocationLaunchTasksById,
      locationLaunchTasksByIdData,
    },
  } = useService();

  useEffect(() => {
    if (launchId) {
      getLocationLaunchById({
        variables: {
          eid: launchId,
        },
      });
    }
    if (launchId && taskId) {
      getLocationLaunchTasksById({
        variables: {
          launchId: launchId,
          taskId: taskId,
        },
      });
    }
  }, [launchId, taskId]);

  const refetchHandler = () => {
    locationLaunchByIdData?.refetch?.();
    locationLaunchTasksByIdData?.refetch?.();
  };

  const convertToLocationLaunchTaskEntity = (
    tasks: PhaseTask[],
    startDate: Date
  ): ILocationLaunchTask[] => {
    let _con: ILocationLaunchTask[] = [];
    tasks?.map((task) => {
      _con?.push({
        assignedType: task?.assignedType || 'location',
        assignedUsers: task?.assignedUser || [],
        buffer: 0,
        completedAt:
          task?.completedAt && moment(task?.completedAt)?.isValid()
            ? moment(task?.completedAt)?.toISOString()
            : null,
        completedBy: '',
        description: task?.description,
        docRequired: !!task?.docRequired,
        dueDate:
          task?.dueDate && moment(task?.dueDate)?.isValid()
            ? moment(task?.dueDate)?.toISOString()
            : null,
        duration: 0,
        locationStartDate:
          startDate && moment(startDate)?.isValid()
            ? moment(startDate)?.utc()?.startOf('day')?.toISOString()
            : moment?.utc()?.startOf('day')?.toISOString(),
        eid: task?.eid,
        files: task?.files,
        isCompleted: task?.isCompleted,
        isPhaseSelected: task?.isPhaseSelected,
        isRequired: task?.isRequired,
        launchId: task?.launcherId,
        liveDate: task?.liveDate,
        locationId: task?.locationId,
        phase: task?.phase,
        phaseEid: task?.phaseEid,
        startDate: task?.startDate,
        status: '',
        steps: task?.steps,
        taskCategory: task?.category,
        taskCategoryEid: task?.category,
        title: task?.title,
        triggerDetails: task?.triggerDetails,
        locationName: '',
        unlockMessage: '',
      });
    });
    return _con;
  };

  const handleStatusChange = async (
    step: PhaseTask['steps'][number],
    completed: boolean
  ) => {
    try {
      if (completed) {
        switch (step?.type) {
          case 'text': {
            setLoadingStepIds((prev) => [...prev, step.stepId]);
            return await markAsComplete({
              stepId: step?.stepId,
              launchId: launchId,
              taskId: taskId,
              formResponseId: '',
            })
              ?.then(() => {
                toast({
                  status: 'success',
                  title: 'Successfully marked step as complete',
                });
                setLoadingStepIds((prev) =>
                  prev.filter((id) => id !== step.stepId)
                );
                refetchHandler?.();
              })
              ?.catch(() => {
                toast({
                  status: 'error',
                  title: 'Failed to mark step as complete',
                });
                setLoadingStepIds((prev) =>
                  prev.filter((id) => id !== step.stepId)
                );
                refetchHandler?.();
              });
          }
          case 'sop':
            return chapterView({
              sopId: step?.sopId!,
              title: '',
              canOverride: true,
              onReadClick: async () => {
                setLoadingStepIds((prev) => [...prev, step.stepId]);
                return await markAsComplete({
                  stepId: step?.stepId,
                  launchId: launchId,
                  taskId: taskId,
                  formResponseId: '',
                })
                  ?.then(() => {
                    toast({
                      status: 'success',
                      title: 'Successfully marked step as complete',
                    });
                    setLoadingStepIds((prev) =>
                      prev.filter((id) => id !== step.stepId)
                    );
                    refetchHandler?.();
                  })
                  ?.catch(() => {
                    toast({
                      status: 'error',
                      title: 'Failed to mark step as complete',
                    });
                    setLoadingStepIds((prev) =>
                      prev.filter((id) => id !== step.stepId)
                    );
                    refetchHandler?.();
                  });
              },
            });
          case 'form':
            return submitForm({
              formId: step?.formId!,
              title: step?.title,
              canOverride: true,
              onSubmitted: async (returnedFormData) => {
                setLoadingStepIds((prev) => [...prev, step.stepId]);
                return markAsComplete({
                  stepId: step?.stepId,
                  launchId: launchId,
                  taskId: taskId,
                  formResponseId: returnedFormData?.eid,
                })
                  ?.then(() => {
                    toast({
                      status: 'success',
                      title: 'Successfully marked step as complete',
                    });
                    setLoadingStepIds((prev) =>
                      prev.filter((id) => id !== step.stepId)
                    );
                    refetchHandler?.();
                  })
                  ?.catch(() => {
                    toast({
                      status: 'error',
                      title: 'Failed to mark step as complete',
                    });
                    setLoadingStepIds((prev) =>
                      prev.filter((id) => id !== step.stepId)
                    );
                    refetchHandler?.();
                  });
              },
            });
          case 'training':
            trainingWarning(() => {
              trainingView({
                trainingId: step?.stepId,
                title: step?.title,
                formAccessOverride: true,
                inputVariable: {
                  locationLaunchId: launchId,
                  taskId: taskId,
                  stepId: step?.stepId,
                },
                onFinish: async () => {
                  setLoadingStepIds((prev) => [...prev, step.stepId]);
                  return markAsComplete({
                    stepId: step?.stepId,
                    launchId: launchId,
                    taskId: taskId,
                    formResponseId: '',
                  })
                    ?.then(() => {
                      toast({
                        status: 'success',
                        title: 'Successfully marked step as complete',
                      });
                      setLoadingStepIds((prev) =>
                        prev.filter((id) => id !== step.stepId)
                      );
                      refetchHandler?.();
                    })
                    ?.catch(() => {
                      toast({
                        status: 'error',
                        title: 'Failed to mark step as complete',
                      });
                      setLoadingStepIds((prev) =>
                        prev.filter((id) => id !== step.stepId)
                      );
                      refetchHandler?.();
                    });
                },
              });
            }, step);
            break;
          default:
            return;
        }
      } else {
        setLoadingStepIds((prev) => [...prev, step.stepId]);
        await markAsUndone({
          launchId: launchId,
          stepId: step?.stepId,
          taskId: taskId,
          formResponseId: '',
        })
          ?.then(() => {
            toast({
              status: 'success',
              title: 'Successfully marked step as incomplete',
            });
            setLoadingStepIds((prev) =>
              prev.filter((id) => id !== step.stepId)
            );
            refetchHandler?.();
          })
          ?.catch(() => {
            toast({
              status: 'error',
              title: 'Failed to mark step as incomplete',
            });
            setLoadingStepIds((prev) =>
              prev.filter((id) => id !== step.stepId)
            );
            refetchHandler?.();
          });
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'Failed to update step status',
      });
      setLoadingStepIds((prev) => prev.filter((id) => id !== step.stepId));
    } finally {
      setLoadingStepIds((prev) => prev.filter((id) => id !== step.stepId));
    }
  };

  const foundTaskData = useMemo(() => {
    if (locationLaunchByIdData?.data && locationLaunchTasksByIdData?.data) {
      let _data = cloneDeep(locationLaunchByIdData?.data?.LocationLaunchById);
      let convertedData = convertToLocationLaunchTaskEntity(
        _data?.tasks || [],
        _data?.startDate
      );
      let groupedTasks = processLocationTasks(
        convertedData,
        loggedInUser,
        moment?.utc()
      );
      const allTasks = groupedTasks?.map((task) => task?.tasks)?.flat();
      const liveDate = _data?.liveDate || new Date();

      let foundTask = allTasks
        ?.filter((task) => task?.eid === taskId)
        ?.map((task) => convertTaskData(task))?.[0];

      return foundTask;
    }
  }, [locationLaunchByIdData?.data, locationLaunchTasksByIdData?.data]);

  const fileAttachments = useMemo(() => {
    let files =
      locationLaunchTasksByIdData?.data?.LocationLaunchTaskById
        ?.complianceFiles || [];

    let locFiles = files?.filter((f) => f?.locationId === locationId) || [];
    return locFiles;
  }, [locationLaunchTasksByIdData?.data]);

  const otherFiles = useMemo(() => {
    let foundTask =
      locationLaunchByIdData?.data?.LocationLaunchById?.tasks?.find(
        (task) => task?.eid === taskId
      );
    if (foundTask) {
      return foundTask?.files;
    }
    // let files = locationLaunchByIdData?.data?.LocationLaunchById?.contents
    //   ?.map((phase) => phase.tasks.map((task) => task.files))
    //   .flat(3);
    return [];
  }, [locationLaunchByIdData?.data]);

  const attachmentUrls = useMemo(() => {
    return (
      locationLaunchTasksByIdData?.data?.LocationLaunchTaskById?.complianceFiles
        ?.filter((f) => f?.status !== 'active' && f?.locationId === locationId)
        ?.flatMap((f) => f?.files?.map((file) => file?.url)) || []
    );
  }, [locationLaunchTasksByIdData?.data?.LocationLaunchTaskById, locationId]);

  const filteredOtherFiles = useMemo(() => {
    return otherFiles?.filter((file) => !attachmentUrls?.includes(file?.url));
  }, [otherFiles, attachmentUrls]);

  const updateDocumentStatus = (eids: string[]) => {
    try {
      updateComplianceStatus({
        variables: {
          input: eids.map((eid) => ({
            eid,
            status: 'active',
          })),
        },
      });
    } catch (error) {}
  };

  const getNotificationMessage = () => {
    let title = '';
    let description = '';

    if (loggedInUser?.authRole === AuthRole.SUPER_ADMIN) {
      title = 'Notification update';
      description =
        'The respective location owners/admins will be notified about the newly added document(s). No further action is needed.';
    } else if (
      loggedInUser?.authRole === AuthRole.LOCATION_OWNER ||
      loggedInUser?.authRole === AuthRole.ADMIN
    ) {
      title = 'Document added';
      description =
        "The document(s) will be active in the Compliance tab once approved by the Superadmin. You'll be notified of the decision. This may take some time.";
    }

    return {
      title,
      description,
    };
  };

  const handleTaskStatusChange = async () => {
    if (!foundTaskData || !launchId || !taskId) return;
    try {
      if (
        foundTaskData?.steps?.every((step) => step?.completedAt) &&
        foundTaskData?.completedAt
      ) {
        setIsTaskLoading(true);
        await markAsUndone({
          stepId: '',
          launchId: launchId,
          taskId: taskId,
          formResponseId: '',
          task: foundTaskData,
        })
          ?.then(() => {
            toast({
              status: 'success',
              title: 'Success',
              description: 'Successfully marked task as undone',
            });
            setIsTaskLoading(false);
            refetchHandler?.();
          })
          ?.catch(() => {
            toast({
              status: 'error',
              title: 'Error',
              description: 'Failed to mark task as undone',
            });
            setIsTaskLoading(false);
            refetchHandler?.();
          });
      } else {
        setIsTaskLoading(true);
        await markAsComplete({
          stepId: '',
          launchId: launchId,
          taskId: taskId,
          formResponseId: '',
          task: foundTaskData,
        })
          ?.then(() => {
            toast({
              status: 'success',
              title: 'Success',
              description: 'Successfully marked task as complete',
            });

            const files = fileAttachments?.filter(
              (file) => file.status === 'draft'
            );

            if (files && files?.length > 0) {
              updateDocumentStatus(files.map((file) => file.eid));
              const { title, description } = getNotificationMessage();

              notificationModal({
                title: title,
                description: description,
              });
            }

            setIsTaskLoading(false);
            refetchHandler?.();
          })
          ?.catch(() => {
            toast({
              status: 'error',
              title: 'Error',
              description: 'Failed to mark task as complete',
            });
            setIsTaskLoading(false);
            refetchHandler?.();
          });
      }
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to update task status',
      });
      setIsTaskLoading(false);
    } finally {
      setIsTaskLoading(false);
    }
  };

  return {
    createdAt: locationLaunchByIdData?.data?.LocationLaunchById?.createdAt,
    createdBy: locationLaunchByIdData?.data?.LocationLaunchById?.createdBy,
    isTaskLoading,
    loadingStepIds,
    updatedAt: locationLaunchByIdData?.data?.LocationLaunchById?.updatedAt,
    foundTaskData,
    fileAttachments,
    loading:
      locationLaunchByIdData?.loading || locationLaunchTasksByIdData?.loading,
    handleStatusChange,
    handleTaskStatusChange,
    refetchHandler,
    otherFiles: filteredOtherFiles,
    attachmentUrls,
    locationId,
    launchId,
  };
};
