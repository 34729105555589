import { useLazyQuery, useMutation } from '@apollo/client';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import {
  faClock,
  faPaperclip,
  faStopwatch,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import check from 'assets/images/dashboard/check.svg';
import reload from 'assets/images/dashboard/reload.svg';
import { ReactComponent as FormiconIcon } from 'assets/images/formIcon.svg';
import { Button } from 'atoms';
import { HeaderColors } from 'configs';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react';
import {
  getTaskCategoryLabel,
  getTaskLabelByStatus,
} from 'sub-components/Launcher/dashboard/Location/helper';
import {
  LocationPhaseDetails,
  PhaseTask,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { LTaskCategoryEntity } from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
import { LOCATION_LAUNCH_TASK_BY_ID } from 'sub-components/Launcher/tasks/components/task-details/api';
import {
  LocationLaunchTaskByIdResponse,
  LocationLaunchTaskByIdVariable,
} from 'sub-components/Launcher/tasks/components/task-details/types';
import Loader from 'sub-components/Loader';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import useCombinedStore from 'zustandStore/store';
import { ILocationLaunchTask } from '../../types';
import { Subtasks } from '../Subtasks';
import { useMarkAsComplete } from '../Subtasks/useMarkAsComplete';
import { useMarkAsUndone } from '../Subtasks/useMarkAsUndone';
import { TaskAttachment } from './Attachments';
import { UPDATE_COMPLIANCE } from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';
import { useNotificationUpdate } from 'sub-components/nexus/Compliance/Listing/components/common/notificationModal';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { TextWithLink } from '../../../launcher-config/common';

interface IProps {
  status: 'overdue' | 'locked' | 'yetToStart' | 'inProgress' | string;
  launchId?: string;
  locationId: string;
  task: PhaseTask;
  isOpen: boolean;
  toggleDrawer: () => void;
  refetchData?: (
    resData?: ILocationLaunchTask,
    phaseDetails?: LocationPhaseDetails[],
    task?: PhaseTask
  ) => void;
  phaseDetails?: LocationPhaseDetails[];
  categories: LTaskCategoryEntity[];
}

const StateDrawer: FC<IProps> = ({
  status,
  task,
  launchId,
  locationId,
  isOpen,
  refetchData,
  toggleDrawer,
  phaseDetails,
  categories,
}) => {
  const {
    stateDrawer: { loading },
  } = useCombinedStore();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const { selectedInProgressLocation } = useCombinedStore();
  const notificationModal = useNotificationUpdate();

  const { markAsComplete, markAsCompleteLoading } = useMarkAsComplete();
  const { markAsUndone, markAsUndoneLoading } = useMarkAsUndone();

  const isTaskCompleted = !!(
    task?.completedAt && task?.steps?.every((step) => step?.completedAt)
  );

  const [updateComplianceStatus] = useMutation(UPDATE_COMPLIANCE);

  const { authRole } = useUserDataSelector((state) => ({
    authRole: state?.authRole,
  }));

  const getNotificationMessage = () => {
    let title = '';
    let description = '';

    if (authRole === AuthRole.SUPER_ADMIN) {
      title = 'Notification update';
      description =
        'The respective location owners/admins will be notified about the newly added document(s). No further action is needed.';
    } else if (
      authRole === AuthRole.LOCATION_OWNER ||
      authRole === AuthRole.ADMIN
    ) {
      title = 'Document added';
      description =
        "The document(s) will be active in the Compliance tab once approved by the Superadmin. You'll be notified of the decision. This may take some time.";
    }

    return {
      title,
      description,
    };
  };

  const [
    getLocationLaunchTaskById,
    {
      loading: gettingLocationLaunchTaskById,
      data: locationLaunchTaskByIdData,
      refetch: locationLaunchTaskByIdRefetch,
    },
  ] = useLazyQuery<
    LocationLaunchTaskByIdResponse,
    LocationLaunchTaskByIdVariable
  >(LOCATION_LAUNCH_TASK_BY_ID, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isOpen && launchId && task?.eid) {
      getLocationLaunchTaskByIdHandler();
    }
  }, [launchId, task?.eid, isOpen]);

  const getLocationLaunchTaskByIdHandler = () => {
    if (launchId && task?.eid) {
      getLocationLaunchTaskById({
        variables: {
          launchId,
          taskId: task?.eid,
        },
      });
    }
  };

  const _refetch = async () => {
    if (locationLaunchTaskByIdRefetch) {
      try {
        const { data } = await locationLaunchTaskByIdRefetch();
        refetchData?.(data?.LocationLaunchTaskById?.task, phaseDetails, task);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const attachmentUrls =
    locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles
      ?.filter((f) => f?.status === 'active' && f?.locationId === locationId)
      ?.flatMap((f) => f?.files?.map((file) => file?.url)) || [];

  const fields = useMemo(() => {
    const fields = [
      {
        label: 'Status',
        icon: <FontAwesomeIcon icon={faClock as IconProp} />,
        value: (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {getTaskLabelByStatus(status as ITaskStatus)}
          </Box>
        ),
      },
      {
        label: 'Tags',
        icon: <FontAwesomeIcon icon={faTag as IconProp} />,
        value: task.category ? (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            background='#F4F4F4'
            padding='4px 8px'
            borderRadius='6px'
          >
            {getTaskCategoryLabel(categories, task?.category as string)}
          </Box>
        ) : null,
      },
      {
        label: 'Description',
        icon: <FontAwesomeIcon icon={faClock as IconProp} />,
        value: (
          <TextWithLink
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
            linkText={task?.description || '-'}
          />
        ),
      },
    ];

    const taskDuration = task.triggerDetails?.deadlineOffset
      ? moment.duration(task.triggerDetails.deadlineOffset).asDays()
      : 0;

    if (task.status !== 'locked') {
      fields.splice(1, 0, {
        label: 'Due date',
        icon: <FontAwesomeIcon icon={faCalendar as IconProp} />,
        value: (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
          >
            {moment(task?.dueDate)?.isValid() && task?.dueDate
              ? moment(task?.dueDate)?.format('DD MMMM YYYY')
              : '-'}
          </Box>
        ),
      });

      fields.splice(2, 0, {
        label: 'Task duration',
        icon: <FontAwesomeIcon icon={faStopwatch as IconProp} />,
        value: !task.triggerDetails?.deadlineOffset ? (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {'-'}
          </Box>
        ) : (
          <Box
            as='span'
            fontSize='12px'
            wordBreak='break-word'
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {`${taskDuration} Day${taskDuration > 1 ? 's' : ''} `}
          </Box>
        ),
      });
      fields.push({
        label: 'Others',
        icon: <FontAwesomeIcon icon={faPaperclip as IconProp} />,
        value: (
          <Flex gap={2} flexWrap='wrap'>
            {task?.files?.length
              ? task?.files
                  ?.filter((file) => !attachmentUrls?.includes(file?.url))
                  .map((file, fileIndex) => {
                    return (
                      <Flex
                        key={fileIndex}
                        gap={1}
                        alignItems='center'
                        onClick={() => {
                          window.open && window.open(file.url);
                        }}
                      >
                        {file.type === 'image' ? (
                          <Image
                            src={file.url}
                            height={25}
                            width={25}
                            style={{ borderRadius: '50%' }}
                          />
                        ) : (
                          <Center
                            boxSize='25px'
                            border='1px solid rgba(111, 118, 126, 1)'
                            borderRadius='50%'
                            p='3px'
                          >
                            <FormiconIcon />
                          </Center>
                        )}
                        <Tooltip hasArrow label={file?.name}>
                          <Text
                            as='span'
                            isTruncated
                            maxW='140px'
                            cursor='pointer'
                          >
                            {file?.name}
                          </Text>
                        </Tooltip>
                      </Flex>
                    );
                  })
              : '-'}
          </Flex>
        ),
      });
    }

    return fields;
  }, [task, locationLaunchTaskByIdData?.LocationLaunchTaskById]);

  const updateDocumentStatus = (eids: string[]) => {
    try {
      updateComplianceStatus({
        variables: {
          input: eids.map((eid) => ({
            eid,
            status: 'active',
          })),
        },
      });
    } catch (error) {}
  };

  const handleMarkAsComplete = () => {
    if (task?.steps?.every((step) => step?.completedAt) && task?.completedAt) {
      markAsUndone({
        stepId: '',
        launchId: launchId || selectedInProgressLocation?.launchId!,
        taskId: task?.eid,
        formResponseId: '',
        task: task,
      })
        ?.then((res) => {
          toast({
            status: 'success',
            title: 'Success',
            description: 'Successfully marked task as undone',
          });

          toggleDrawer?.();
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        })
        ?.catch(() => {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Failed to mark task as undone',
          });
          toggleDrawer?.();
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        });
    } else {
      markAsComplete({
        stepId: '',
        launchId: launchId || selectedInProgressLocation?.launchId!,
        taskId: task?.eid,
        formResponseId: '',
        task: task,
      })
        ?.then((res) => {
          toast({
            status: 'success',
            title: 'Success',
            description: 'Successfully marked task as complete',
          });

          const files =
            locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles?.filter(
              (file) => file.status === 'draft'
            );
          if (files && files?.length > 0) {
            updateDocumentStatus(files.map((file) => file.eid));
            const { title, description } = getNotificationMessage();

            notificationModal({
              title: title,
              description: description,
            });
          }
          toggleDrawer?.();
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        })
        ?.catch(() => {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Failed to mark task as complete',
          });
          locationLaunchTaskByIdRefetch?.();
          _refetch?.();
        });
    }
  };

  const isDisabledFn = () => {
    if (task?.completedAt) {
      return markAsCompleteLoading || markAsUndoneLoading;
    }

    const locFiles =
      locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles?.filter(
        (f) => {
          return f?.status !== 'inactive' && f?.locationId === locationId;
        }
      ) || [];

    const requiredReupload =
      locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles?.filter(
        (f) =>
          f?.status !== 'inactive' &&
          f.type === 'compliance' &&
          f.approvalStatus === 'rejected'
      )?.length;

    return (
      markAsCompleteLoading ||
      markAsUndoneLoading ||
      task?.steps?.some((step) => !step?.completedAt) ||
      (task?.docRequired && locFiles.length === 0) ||
      requiredReupload
    );
  };

  const isLoading =
    markAsCompleteLoading ||
    markAsUndoneLoading ||
    loading ||
    gettingLocationLaunchTaskById;

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={toggleDrawer}
        size='lg'
      >
        <DrawerContent p='47px'>
          {isLoading && (
            <Box
              position='absolute'
              top='0'
              left='0'
              right='0'
              bottom='0'
              bg='rgba(255, 255, 255, 0.8)'
              zIndex={9999}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Loader
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Box>
          )}
          <DrawerHeader p={0} mb='16px'>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize='18px' fontWeight={600}>
                {task?.title}
              </Text>
              <IconButton
                aria-label='close'
                variant='ghost'
                onClick={toggleDrawer}
                icon={<CloseIcon h='15px' w='15px' />}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody p={0} m={0}>
            <Flex flexDir='column' gap={4}>
              {fields.map((field, index) => (
                <Flex
                  key={index}
                  justifyContent='space-between'
                  alignItems='flex-start'
                  mb={2}
                  gap='12px'
                >
                  <Flex align='center' gap={2} color='#6F767E'>
                    {field?.icon}
                    <Text
                      fontSize='12px'
                      fontWeight={400}
                      wordBreak='break-word'
                    >
                      {field.label}
                    </Text>
                  </Flex>
                  <Flex flex='1' maxWidth='70%'>
                    {field.value}
                  </Flex>
                </Flex>
              ))}

              {status !== 'locked' && task?.steps?.length > 0 && (
                <Subtasks
                  isTaskCompleted={isTaskCompleted}
                  launchId={launchId}
                  taskDetails={task}
                  refetchData={_refetch}
                />
              )}

              {status !== 'locked' && (
                <TaskAttachment
                  task={task}
                  refetchData={() => {
                    _refetch?.();
                    locationLaunchTaskByIdRefetch?.();
                  }}
                  complianceFiles={
                    locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles?.filter(
                      (f) => f.type === 'compliance'
                    ) || []
                  }
                  otherFiles={
                    locationLaunchTaskByIdData?.LocationLaunchTaskById?.complianceFiles?.filter(
                      (f) => f.type === 'other'
                    ) || []
                  }
                  taskStatus={status}
                  locationId={locationId}
                  launchId={launchId}
                />
              )}
              {status !== 'locked' && (
                <Tooltip
                  hasArrow
                  label='Button will get enabled once all tasks are completed and mandatory files are added if any'
                  fontSize={15}
                  fontWeight={500}
                  padding='8px 16px'
                  background='#000000C7'
                  placement='bottom-end'
                  isDisabled={
                    !(
                      markAsCompleteLoading ||
                      markAsUndoneLoading ||
                      task?.steps?.some((step) => !step?.completedAt) ||
                      status !== 'completed'
                    )
                  }
                >
                  <Box display='inline-block' w='full'>
                    <Button
                      w={'full'}
                      bg={
                        status !== 'completed'
                          ? HeaderColors.DarkBlue
                          : HeaderColors.Red.Dark
                      }
                      colorScheme={status !== 'completed' ? 'blue' : 'red'}
                      variant='solid'
                      rightIcon={
                        <Image
                          width='20px'
                          src={status === 'completed' ? reload : check}
                        />
                      }
                      disabled={isDisabledFn()}
                      my={'12px'}
                      fontSize={'13px'}
                      fontWeight={700}
                      py={'8px'}
                      px={'16px'}
                      _hover={{
                        bg:
                          status !== 'completed'
                            ? HeaderColors.DarkBlue
                            : HeaderColors.Red.Dark,
                      }}
                      onClick={handleMarkAsComplete}
                    >
                      {status !== 'completed'
                        ? 'Mark task as completed'
                        : 'Mark as unfinished'}
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

StateDrawer.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/StateDrawer';

export default StateDrawer;
