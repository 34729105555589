import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DateInput, {
  DateInputButton,
} from '../../../../../../pages/Locations/Components/DateInput';
import { IDocumentInput } from './compliance.input';
import TitleHeader from '../../../../../CardEditor/TitleHeader';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import DatePickerCustom from 'ui-components/DatePicker/DatePicker';

interface IProps {
  isEditing?: boolean;
  disableSigningDate?: boolean;
}

const SignedDateSelectField: FC<IProps> = ({
  isEditing,
  disableSigningDate,
}) => {
  const { t } = useTranslation(['nexus']);

  const { getValues } = useFormContext();

  return (
    <Flex>
      <Controller<IDocumentInput, 'signingDate'>
        name='signingDate'
        rules={{
          deps: ['expiryDate'],
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <TitleHeader
              title={
                isEditing
                  ? 'Add date of signing'
                  : t('nexus:documents.addSignedDate')
              }
              titleStyle={{ marginBottom: '8px' }}
            />
            <Flex>
              <DatePickerCustom
                placeholder=''
                value={field.value}
                onChange={field.onChange}
                disableLeftIcon
                rightIcon={<CalendarIcon />}
                colorScheme='gray'
                format='DD-MM-yyyy'
                width='190px'
                disabled={disableSigningDate}
              />
            </Flex>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Flex>
  );
};

SignedDateSelectField.displayName =
  'packages/sop-web/src/sub-components/nexus/Compliance/Create/components/common/SignedDateSelectField';

export default SignedDateSelectField;
