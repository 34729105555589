import { useToast } from '@chakra-ui/react';
import { FileInputEntity } from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer/task-detail-drawer.types';
import { FileTypeEntity } from 'types';
import useCombinedStore from 'zustandStore/store';
import { useAddLauncherTaskFiles } from '../../useAddLauncherTaskFiles';
import { useService } from './useService';

export const useControl = (refetchData?: () => void) => {
  const {
    stateDrawer: { updateLoading },
  } = useCombinedStore();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const {
    deleteCompliance: { deleteComplianceDocument, deleteComplianceData },
  } = useService();
  const addLauncherTaskFiles = useAddLauncherTaskFiles();

  const removeFile = (
    file: FileTypeEntity,
    files: FileTypeEntity[],
    launchId: string,
    taskId: string,
    id: string
  ) => {
    let filteredFiles = files?.filter((_file) => _file?.url !== file?.url);

    updateLoading(true);
    addLauncherTaskFiles({
      file: filteredFiles,
      launchId,
      taskId,
      onSuccess: () => {
        deleteComplianceDocument({
          variables: {
            eids: [id],
          },
        })
          ?.then((res) => {
            toast({
              status: 'success',
              title: 'Success',
              description: 'File removed successfully',
            });
            updateLoading(false);
            refetchData?.();
          })
          ?.catch((err) => {
            toast({
              status: 'error',
              title: 'Error',
              description: 'File could not be removed',
            });
            updateLoading(false);
            refetchData?.();
          });
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'File could not be removed',
        });
        updateLoading(false);
        refetchData?.();
      },
    });
  };

  return {
    removeFile,
  };
};
