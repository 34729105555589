import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Box, ModalCloseButton } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { AppRouter } from 'routes';
import DeleteCategoryContent from './DeleteCategoryContent';
import { HeaderColors } from 'configs';
interface IProps {}

const DeleteCategoryTitle = () => (
  <>
    <BoxHeader color={HeaderColors.Green} title='Delete doc category?' />
    <ModalCloseButton top='1.5rem' right='1.5rem' fontSize='18px' />
  </>
);

const useDeleteCategory = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      eid,
      onActionComplete,
    }: {
      eid: string;
      onActionComplete: () => void;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <AppRouter>{children}</AppRouter>
            </ApolloProvider>
          );
        },
        size: '3xl',
        title: <DeleteCategoryTitle />,
        content: (
          <DeleteCategoryContent
            eid={eid}
            onActionComplete={onActionComplete}
          />
        ),
        contentProps: {
          borderRadius: '12px',
          p: '2rem 2.5rem',
        },
        headerProps: {
          p: '0px',
        },
        bodyProps: {
          p: '0px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [confirm]
  );
};

useDeleteCategory.displayName =
  'displayName:sub-components/nexus/Compliance/Listing/components/deleteCategory/useDeleteCategory.tsx';

export default useDeleteCategory;
