import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import DeleteComplianceContent from './DeleteComplianceContent';

type DeleteCompliance = (props?: any) => void;

export const useDeleteCompliance = (): DeleteCompliance => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      eid,
      onActionComplete,
    }: {
      eid: string;
      onActionComplete: () => void;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex justify='space-between' gap={3}>
            <BoxHeader title={'Delete Document ?'} color={'#CABDFF'} />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <DeleteComplianceContent
            eid={eid}
            onActionComplete={onActionComplete}
          />
        ),
        isCentered: true,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '12px',
          minW: '660px',
        },
        footer: null,
      });
    },
    []
  );
};
