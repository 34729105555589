import { ArrowLeftOutlined } from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import {
  AlertDialogCloseButton,
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import mailIcon from 'assets/images/UI icon_mail_light.svg';
import locationIcon from 'assets/images/location.svg';
import shieldIcon from 'assets/images/shieldIcon.svg';
import { MessageButton, SearchInput } from 'atoms';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import Loader from 'sub-components/Loader';
import { generateQuizResponsesTable } from 'sub-components/training-v2/dashboard/utils/util';
import { useService } from 'sub-components/training-v2/play/layers';
import { ResponsesTable } from 'sub-components/training-v2/shared/quiz/components/responses/components';
import { TPPathAnalyticsItem } from 'sub-components/training-v2/shared/types';
import { BoxHeader, SingleUserChatModal } from 'ui-components';
import { getImage } from 'utils';
import { LocationBadge } from '../../../Tracking/components';

interface IProps {
  trainingId: string;
  sessionId: string;
  quizId: string;
  record: TPPathAnalyticsItem;
}

const ModalContent: FC<IProps> = ({
  trainingId,
  sessionId,
  quizId,
  record,
}) => {
  const businessEntities = useReactiveVar(usersEntityObj);
  const { loggedInUserEid } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
  }));
  const {
    tpSession: { getTPBySession, tpBySessionData },
  } = useService();

  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(null);
  const [showChat, setShowChat] = useState(false);
  const [filterSelections, setFilterSelections] = useState<{
    [key: string]: string[];
  }>({});

  const [searchString, setSearchString] = useState('');
  const [response, setResponse] = useState();

  const allUsers = useReactiveVar(usersEntityObj);

  const getUserDetails = (userId: string) => {
    const user = allUsers?.find((user) => user?.eid === userId);

    return {
      name: user?.name,
      role: user?.role,
      profilePic: user?.profilePic,
      locations: user?.locations,
    };
  };

  const handleSelectChange = (value: string, eid: string) => {
    setFilterSelections((prev) => {
      const currentValues = prev[eid] || [];
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      if (newValues.length === 0) {
        const { [eid]: _, ...rest } = prev;
        return rest;
      }

      return { ...prev, [eid]: newValues };
    });
  };
  useEffect(() => {
    getTpSessionByIdData();
  }, []);

  useEffect(() => {
    if (!selectedAssignee) return;

    const selectedUserProgress =
      tpBySessionData?.data?.TpSessionById?.userProgress?.find(
        (progress) => progress.userId === selectedAssignee
      );

    setResponse(
      generateQuizResponsesTable(
        record.eid,
        tpBySessionData?.data?.TpSessionById?.contents,
        selectedUserProgress
      )
    );
  }, [selectedAssignee]);

  const getTpSessionByIdData = () => {
    getTPBySession({
      variables: {
        eid: sessionId,
      },
    });
  };

  console.log(tpBySessionData, 'tp');

  const businessUsers = useMemo(() => {
    return businessEntities?.filter((e) => e?.type === 'user');
  }, [businessEntities]);

  const selectedAssigneeData = useMemo(() => {
    return businessUsers?.find((user) => user?.eid === selectedAssignee);
  }, [selectedAssignee, businessUsers]);

  const getResponseDisplay = (response: any, question: any) => {
    switch (question.type) {
      case 'multiChoice':
        return (
          <Stack spacing={4}>
            {response.options.map((opt: string, index: number) => {
              const option = question.options.find((o: any) => o.uid === opt);

              return (
                <Box
                  key={index}
                  textAlign='center'
                  display='flex'
                  gap='8px'
                  alignItems='center'
                >
                  {option?.thumbnail && (
                    <Image
                      src={option.thumbnail}
                      alt={`Thumbnail for ${option.text}`}
                      boxSize='35px'
                      objectFit='cover'
                      borderRadius='md'
                    />
                  )}
                  {option?.text}
                </Box>
              );
            })}
          </Stack>
        );

      case 'trueFalse':
        const userSelectedTrue = response.isCorrect === question.isTrue;
        return <Box>{userSelectedTrue ? 'True' : 'False'}</Box>;

      case 'fillInBlanks':
        return <Box>{response.blanks?.map((blank: any) => blank)}</Box>;

      case 'matchSet':
        if (!response.pairs?.length) return '-';
        return (
          <Stack spacing={4}>
            {response.pairs.map((pair: any, index: number) => (
              <Box
                key={index}
                textAlign='center'
                display='flex'
                gap='8px'
                alignItems='center'
              >
                {pair.leftThumbnail && (
                  <Image
                    src={pair.leftThumbnail}
                    alt={`Thumbnail for ${pair.left}`}
                    boxSize='35px'
                    objectFit='cover'
                    borderRadius='md'
                  />
                )}
                {pair.left} - {pair.right}
                {pair.rightThumbnail && (
                  <Image
                    src={pair.rightThumbnail}
                    alt={`Thumbnail for ${pair.right}`}
                    boxSize='35px'
                    objectFit='cover'
                    borderRadius='md'
                  />
                )}
              </Box>
            ))}
          </Stack>
        );

      case 'sequence':
        if (!response.sequence?.length) return '-';
        return (
          <Flex gap={1} flexWrap='wrap'>
            {response.sequence.map((seq: any, index: number) => (
              <Flex key={index} textAlign='center' alignItems='center' gap={1}>
                {seq.thumbnail && (
                  <Image
                    src={seq.thumbnail}
                    alt={`Thumbnail ${seq.text}`}
                    boxSize='35px'
                    objectFit='cover'
                    borderRadius='md'
                  />
                )}
                {seq.text}

                {index !== question.sequence.length - 1 ? '>' : ''}
              </Flex>
            ))}
          </Flex>
        );

      default:
        return '-';
    }
  };

  const allAssigneesColumns = useMemo(() => {
    if (!tpBySessionData?.data?.TpSessionById) return [];

    const quiz = tpBySessionData.data.TpSessionById.contents.find(
      (content) => content.eid === quizId && content.type === 'quiz'
    );

    if (!quiz?.questions) return [];

    return [
      {
        title: 'Date',
        dataIndex: 'completedAt',
        key: 'completedAt',
        fixed: 'left',
        render: (date: Date) => (
          <Box width='6rem'>
            <Box fontSize='14px'>
              {moment(date).isValid()
                ? moment(date).format('DD MMM YYYY')
                : '--'}
            </Box>
            <Box color='#8D949C' fontSize='12px'>
              {moment(date).isValid() ? moment(date).format('hh:mm A') : '--'}
            </Box>
          </Box>
        ),
      },
      {
        title: 'Assignee',
        dataIndex: 'userName',
        key: 'userName',
        fixed: 'left',
        render: (text: string, record: any) => {
          const user = getUserDetails(record.userId);
          return (
            <Flex
              gap={2}
              onClick={() => {
                setSelectedAssignee(record.userId);
              }}
              cursor='pointer'
              padding='5px 10px'
              width='10rem'
            >
              <Image
                src={getImage(user?.profilePic, user?.name)}
                width='24px'
                height='24px'
                borderRadius='5px'
              />
              <Flex fontSize='14px' gap={1}>
                <Box
                  as='span'
                  color='#407eff'
                  fontWeight={600}
                  textTransform='capitalize'
                >
                  {text},
                </Box>
                <Box textTransform='capitalize'>{user.role}</Box>
              </Flex>
            </Flex>
          );
        },
      },
      {
        title: 'Location',
        dataIndex: 'locations',
        key: 'locations',
        render: (text: string, record: any) => {
          const user = getUserDetails(record.userId);
          return (
            <Box minW='200px'>
              <Box
                background='#B5E4CA66'
                padding='5px 10px'
                borderRadius='7px'
                width='fit-content'
              >
                <LocationBadge size='24px' locations={user.locations || []} />
              </Box>
            </Box>
          );
        },
      },
      ...quiz.questions
        .filter((q) => q.type !== 'initial')
        .map((question) => {
          // Generate options for dropdowns
          const options =
            question.type === 'trueFalse'
              ? [
                  { value: 'true', label: 'True' },
                  { value: 'false', label: 'False' },
                ]
              : question.type === 'multiChoice' && question.options?.length
              ? question.options.map((opt: any) => ({
                  value: opt.uid,
                  label: opt.text,
                }))
              : null;

          return {
            title: (
              <Flex w='full' justifyContent='space-between'>
                <Flex alignItems='center' gap={1}>
                  {question.thumbnail && (
                    <Image
                      src={question.thumbnail}
                      alt={`Thumbnail for ${question.label}`}
                      boxSize='35px'
                      objectFit='cover'
                      borderRadius='md'
                    />
                  )}
                  {question.label}
                </Flex>

                {options && (
                  <Menu>
                    <MenuButton ml={1}>
                      <FontAwesomeIcon
                        icon={faSortDown as IconProp}
                        style={{ marginBottom: '2px', marginLeft: '5px' }}
                        color='#6f767e'
                      />
                    </MenuButton>
                    <MenuList
                      boxShadow='lg'
                      borderRadius='16px'
                      p={2}
                      border='none'
                      width='fit-content'
                    >
                      {options.map((option: any) => (
                        <MenuItem
                          borderRadius='12px'
                          fontSize='15px'
                          color='#1A1D1F'
                          key={option.value}
                          onClick={() =>
                            handleSelectChange(option.value, question.eid)
                          }
                        >
                          <Checkbox
                            isChecked={filterSelections[question.eid]?.includes(
                              option.value
                            )}
                            pointerEvents='none'
                            mr={2}
                          />
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
              </Flex>
            ),
            dataIndex: ['responses', question.eid],
            key: question.eid,
            render: (text: string, record: any) => {
              const response = record.responses.find(
                (r: any) => r.qid === question.eid
              );
              const display = response
                ? getResponseDisplay(response, question)
                : '-';

              return (
                <Box minW='200px' maxW='max-content'>
                  {display}
                </Box>
              );
            },
          };
        }),
    ];
  }, [tpBySessionData, quizId, filterSelections]);

  const singleAssigneeColumns = useMemo(() => {
    return [
      {
        title: 'Question',
        dataIndex: 'question',
        key: 'question',
        width: '30%',
      },
      {
        title: 'Response',
        dataIndex: 'response',
        key: 'response',
        width: '40%',
        render: (text: string) => <Box whiteSpace='pre-line'>{text}</Box>,
      },
    ];
  }, []);

  const singleAssigneeData = useMemo(() => {
    if (!selectedAssignee || !tpBySessionData?.data?.TpSessionById) return [];

    const quiz = tpBySessionData.data.TpSessionById.contents.find(
      (content) => content.eid === quizId && content.type === 'quiz'
    );

    if (!quiz) return [];

    const assignee = tpBySessionData.data.TpSessionById.userProgress.find(
      (progress) => progress.userId === selectedAssignee
    );

    if (!assignee) return [];

    const quizProgress = assignee.progress.find((p) => p.id === quizId);
    if (!quizProgress) return [];

    return quiz.questions
      .filter((q) => q.type !== 'initial')
      .map((question) => {
        const response = quizProgress.responses.find(
          (r) => r.qid === question.eid
        );
        return {
          key: question.eid,
          question: question.label,
          response: response ? getResponseDisplay(response, question) : '-',
          score: response?.score || 0,
          status: response?.isCorrect ? 'Correct' : 'Incorrect',
        };
      });
  }, [selectedAssignee, tpBySessionData, quizId]);

  if (tpBySessionData?.loading) {
    return (
      <Center h='70vh'>
        <Loader />
      </Center>
    );
  }

  const assigneeResponses =
    tpBySessionData?.data?.TpSessionById?.userProgress
      .filter((progress) => {
        const quizProgress = progress.progress.find((p) => p.id === quizId);
        if (!quizProgress) return false;

        if (!Object.keys(filterSelections).length) return true;

        return Object.entries(filterSelections).some(
          ([qid, selectedValues]) => {
            const response = quizProgress.responses.find((r) => r.qid === qid);
            if (!response) return false;

            const matchOptions = response.options.some((option: string) =>
              selectedValues.includes(option)
            );

            const matchBoolean =
              (selectedValues.includes('true') &&
                response.isCorrect === true) ||
              (selectedValues.includes('false') &&
                response.isCorrect === false);

            return matchOptions || matchBoolean;
          }
        );
      })
      .map((progress) => {
        const user = getUserDetails(progress.userId);

        return {
          userId: progress.userId,
          userName:
            businessUsers?.find((bus) => bus?.eid === progress.userId)?.name ||
            '-',
          completedAt: progress.completedAt,
          responses:
            progress.progress.find((p) => p.id === quizId)?.responses || [],
          location: user.locations as any[],
          userRole: user.role as string,
        };
      }) || [];

  const responseData = assigneeResponses.filter(
    (item) =>
      item.userName.toLowerCase().includes(searchString.toLowerCase()) ||
      item.userRole.toLowerCase().includes(searchString.toLowerCase()) ||
      item.location.some((location) =>
        location.name.toLowerCase().includes(searchString.toLowerCase())
      )
  );

  const handleSearch = (query: string) => {
    setSearchString(query);
  };

  const maxScore = tpBySessionData?.data?.TpSessionById?.maxScore;

  return (
    <Box p={4}>
      <Flex gap='12px' mb={4}>
        <Box flex={1}>
          {selectedAssignee ? (
            <Flex
              w='fit-content'
              align='center'
              fontWeight={600}
              fontSize='18px'
              gap={2}
              onClick={() => setSelectedAssignee(null)}
              cursor='pointer'
            >
              <ArrowLeftOutlined />
              <Box>{selectedAssigneeData?.name}'s responses</Box>
            </Flex>
          ) : (
            <BoxHeader
              title={'Quiz responses from within this training path'}
              color='#CABDFF'
            />
          )}
        </Box>
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
      {selectedAssignee && response ? (
        <Grid gap={10} gridTemplateColumns='65% 30%'>
          <ResponsesTable responseData={response} maxScore={maxScore} />
          <Flex flexDir='column' gap='10px'>
            <div style={{ paddingBottom: '30px' }}>
              <HStack align='flex-start' spacing='15px'>
                <Box>
                  <Image
                    height={80}
                    width={80}
                    style={{
                      minWidth: '80px',
                      maxWidth: '80px',
                      minHeight: '80px',
                      maxHeight: '80px',
                    }}
                    className='img-card-user'
                    src={getImage(
                      selectedAssigneeData?.profilePic,
                      selectedAssigneeData?.name
                    )}
                  />
                </Box>

                <VStack align='stretch'>
                  <Text
                    fontWeight={600}
                    fontSize='18px'
                    m={0}
                    isTruncated
                    maxW='200px'
                  >
                    {selectedAssigneeData?.name}
                  </Text>
                  <Text
                    fontWeight={600}
                    my={-6}
                    fontSize='13px'
                    color='#9A9FA5'
                  >
                    {selectedAssigneeData?.role}
                  </Text>
                </VStack>
              </HStack>
            </div>

            <div>
              <HStack spacing='15px'>
                <img src={mailIcon} />
                <VStack spacing={-6} align='stretch'>
                  <Box w='max-content' color='#1A1D1F'>
                    <div>
                      {selectedAssigneeData?.email
                        ? selectedAssigneeData?.email
                        : 'N/A'}
                    </div>
                  </Box>
                </VStack>
              </HStack>
            </div>
            <Divider className='Line-2' />
            <div>
              <HStack spacing='15px'>
                <Box>
                  <img src={shieldIcon} />
                </Box>

                <VStack spacing={-6} align='stretch'>
                  <Box w='max-content' color='#1A1D1F'>
                    <div style={{ width: '100%', textTransform: 'capitalize' }}>
                      {selectedAssigneeData?.authRole || 'N/A'}
                    </div>
                  </Box>
                </VStack>
              </HStack>
            </div>
            <Divider className='Line-2' />
            <div>
              <HStack spacing='15px'>
                <Box>
                  <img src={locationIcon} />
                </Box>
                <VStack spacing={-6} align='stretch'>
                  <Box w='max-content' color='#1A1D1F'>
                    <div style={{ width: '100%' }}>
                      {selectedAssigneeData?.locations?.[0]?.name || 'N/A'}
                    </div>
                  </Box>
                </VStack>
              </HStack>
            </div>
            {loggedInUserEid !== selectedAssigneeData?.eid && (
              <div style={{ marginTop: '30px' }}>
                <MessageButton
                  width='full'
                  size='lg'
                  colorScheme='blue'
                  onClick={() => {
                    setShowChat(true);
                  }}
                />
              </div>
            )}
          </Flex>
        </Grid>
      ) : (
        <Flex gap={4} flexDirection='column' marginTop='3rem'>
          <Flex justify='space-between' align='center'>
            <Flex align='center' gap={2}>
              <Image
                src={getImage(record?.thumbnail, record?.title)}
                width='48px'
                height='48px'
                style={{ borderRadius: '6px' }}
              />
              <Flex flexDir='column' color='rgba(111, 118, 126, 1)'>
                <Box>{record?.title}</Box>
                <Flex gap={2} alignItems='center'>
                  <Box fontSize='12px' fontWeight={400}>
                    Added on {moment(record?.createdOn).format('DD MMM')}
                  </Box>
                  <Box
                    background='rgba(111, 118, 126, 1)'
                    width='4px'
                    height='4px'
                    borderRadius='100%'
                  ></Box>
                  <Box fontSize='12px' fontWeight={400}>
                    Created by{' '}
                    {getUserDetails(record?.createdBy as string)?.name}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            <Flex gap={4}>
              <Box w='300px'>
                <SearchInput
                  placeholder='Search by user, job or location'
                  hideShortcuts
                  onChange={(e) => handleSearch(e.target.value)}
                  value={searchString}
                />
              </Box>
            </Flex>
          </Flex>
          <Table
            columns={allAssigneesColumns}
            dataSource={responseData}
            pagination={false}
            scroll={{ x: true }}
            bordered
          />
        </Flex>
      )}
      {showChat && (
        <SingleUserChatModal
          selectedUser={selectedAssigneeData?.eid}
          onClose={() => {
            setShowChat(false);
          }}
          usePortal
        />
      )}
    </Box>
  );
};

export default ModalContent;
