import { useReactiveVar } from '@apollo/client';
import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import launchLocation from 'assets/images/training/launcherLocationIcon.svg';
import openLocation from 'assets/images/training/openLocationTypeIcon.svg';
import moment from 'moment';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';

export const LocationName = ({ values }) => {
  const allUsers = useReactiveVar(usersEntityObj);

  const getUser = (eid: string) => {
    return allUsers.find((user) => user?.eid === eid);
  };

  const locationStatus = getUser(values?.location?.eid)?.locationStatus;

  const getBgColor = () => {
    if (locationStatus === 'open') {
      return '#B5E4CA99';
    }

    if (locationStatus === 'development') {
      return '#CABDFF';
    }

    return '#EFEFEF';
  };

  return (
    <Flex
      gap={2}
      borderRadius={'10px'}
      p={'5px 10px'}
      minW={'fit-content'}
      bgColor={getBgColor()}
      alignItems='center'
    >
      {values?.location?.name && locationStatus && (
        <Image
          src={locationStatus === 'open' ? openLocation : launchLocation}
          width='24px'
          height='24px'
        />
      )}
      <Tooltip
        label={
          values?.location?.isRoot
            ? 'HQ'
            : values?.location?.name
            ? values?.location?.name
            : '--'
        }
      >
        <Text isTruncated width={'fit-content'} maxWidth={'150px'}>
          {values?.location?.isRoot
            ? 'HQ'
            : values?.location?.name
            ? values?.location?.name
            : '--'}
        </Text>
      </Tooltip>
      {!values?.location && <Box height={'24px'} width={'24px'}></Box>}
    </Flex>
  );
};

export const DocumentName = ({ values, getStyles }) => {
  return (
    <Flex>
      <Flex style={getStyles(values)} alignItems={'center'} gap={2}>
        {/* <Flex
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={'50%'}
          p={'5px'}
          bgColor={values?.location ? '#B5E4CA66' : '#EFEFEF'}
        >
          <DocumentIcon />
        </Flex> */}
        <Tooltip label={values?.title}>
          <Text minW={'fit-content'} isTruncated>
            {values?.title}
          </Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export const UploadedBy = ({ values }) => {
  return (
    <Flex gap={2}>
      <Image
        borderRadius={'5px'}
        fit={'cover'}
        w={'24px'}
        h={'24px'}
        src={getImage(values?.uploadedBy[2], values?.uploadedBy[0])}
      />
      <Flex>
        <Tooltip label={values?.uploadedBy[0]}>
          <Text maxWidth={'100px'} isTruncated fontWeight={600}>
            {values?.uploadedBy[0]}, &nbsp;
          </Text>
        </Tooltip>
        <Tooltip label={values?.uploadedBy[1]}>
          <Text color={'#6F767E'}> {values?.uploadedBy[1]}</Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export const ExpiryDate = ({ values }) => {
  return (
    <Flex flexDir={'column'}>
      <Text
        fontWeight={values?.isExpiring && 600}
        color={values?.isExpiring ? '#FF2047' : '#33383F'}
      >
        {values?.expiryDate && moment(values?.expiryDate)?.isValid()
          ? moment(values?.expiryDate).format('DD MMM YYYY')
          : '--'}
      </Text>
    </Flex>
  );
};

export const UploadedOn = ({ values }) => {
  return (
    <Flex flexDir={'column'}>
      <Text fontWeight={values?.isExpiring && 600} color={'#33383F'}>
        {values?.createdAt && moment(values?.createdAt)?.isValid()
          ? moment(values?.createdAt).format('DD MMM YYYY')
          : '--'}
      </Text>
      <Text color={'#6F767E'} fontSize={'12px'}>
        {values?.createdAt && moment(values?.createdAt)?.isValid()
          ? moment(values?.createdAt).format('hh:mm A')
          : '--'}
      </Text>
    </Flex>
  );
};

export const SignedDate = ({ values }) => {
  return (
    <Flex flexDir={'column'}>
      <Text fontWeight={values?.isExpiring && 600} color={'#33383F'}>
        {values?.signingDate && moment(values?.signingDate)?.isValid()
          ? moment(values?.signingDate).format('DD MMM YYYY')
          : '--'}
      </Text>
    </Flex>
  );
};
