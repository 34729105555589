import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, Key } from 'react';
import { BoxHeader } from 'ui-components';

interface IProps {
  complianceId: string[] | Key[];
  type?: 'update' | 'reminder';
  moveToCompliance?: boolean;
  replaceDocument?: boolean;
}

const BaseTitle: FC<IProps> = ({
  complianceId,
  type,
  moveToCompliance,
  replaceDocument,
}) => {
  if (type === 'reminder') {
    return (
      <Flex justify='space-between' gap={3}>
        <BoxHeader
          title={complianceId ? 'Edit reminder' : 'Edit reminder'}
          color={HeaderColors.Green}
        />
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    );
  }

  if (moveToCompliance) {
    return (
      <Flex justify='space-between' gap={3}>
        <BoxHeader
          title={'Add additional information'}
          color={HeaderColors.Green}
        />
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    );
  }

  if (replaceDocument) {
    return (
      <Flex justify='space-between' gap={3}>
        <BoxHeader title={'Replace document'} color={HeaderColors.Green} />
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    );
  }

  return (
    <Flex justify='space-between' gap={3}>
      <BoxHeader
        title={complianceId ? 'Update document' : 'Add Document'}
        color={HeaderColors.Green}
      />
      <AlertDialogCloseButton
        pos='relative'
        borderRadius='full'
        top='0'
        right='0'
      />
    </Flex>
  );
};

BaseTitle.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/BaseTitle';

export default BaseTitle;
