import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import uploadedBy from 'assets/images/compliance/uploadedBy.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DocumentFilterType, IDocumentFilterState } from './FiltersAndListing';
import { SearchInput } from 'atoms';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import { EmptyState } from 'sub-components';
import { LocationBadge } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/crossTrain';
import locationlaunchicon from 'assets/images/training/LocationLaunchIcon.svg';
import locationopenicon from 'assets/images/training/LocationOpenIcon.svg';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';

interface IProps {
  onFilterSelect: (filterType: DocumentFilterType) => void;
  setDocumentFilterState: Dispatch<SetStateAction<IDocumentFilterState>>;
  documentFilterState: IDocumentFilterState;
}

interface DocumentUploadedByButtonProps {
  onFilterSelect: (filterType: DocumentFilterType) => void;
  documentFilterState: IDocumentFilterState;
}

export const DocumentUploadedByButton = ({
  onFilterSelect,
  documentFilterState,
}: DocumentUploadedByButtonProps) => {
  return (
    <Button
      gap={1}
      width='100%'
      fontWeight={400}
      fontSize='14px'
      color='#1A1D1F'
      justifyContent='space-between'
      padding='12px'
      borderRadius='8px'
      variant='ghost'
      onClick={() => onFilterSelect('uploadedBy')}
    >
      <Flex alignItems='center' gap={1}>
        <Image src={uploadedBy} width='20px' />
        Uploaded by{' '}
        {documentFilterState.uploadedBy.length > 0 && (
          <Box as='span' color='#6F767E' fontWeight={400} fontSize='12px'>
            {`( ${documentFilterState.uploadedBy.length} Selected)`}
          </Box>
        )}
      </Flex>
      <FontAwesomeIcon
        icon={faChevronRight as IconProp}
        fontSize='12px'
        color='#6F767E'
      />
    </Button>
  );
};

const DocumentUploadedBy: FC<IProps> = ({
  onFilterSelect,
  documentFilterState,
  setDocumentFilterState,
}) => {
  const [search, setSearch] = useState('');
  const allUsers = useReactiveVar(usersEntityObj);
  const { userAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );
  const loggedInUserLocationIds = loggedInUserLocations?.map(
    (item) => item?.eid
  );

  const users = useMemo(() => {
    return allUsers.filter(
      (user) =>
        user?.name?.toLowerCase().includes(search.toLowerCase()) &&
        user?.type === 'user' &&
        (userAuthRole === AuthRole.LOCATION_OWNER
          ? user?.locations?.some(
              (location) =>
                loggedInUserLocationIds?.includes(location?.eid) ||
                user.authRole === AuthRole.SUPER_ADMIN ||
                user.authRole === AuthRole.ADMIN
            )
          : true)
    );
  }, [allUsers, search]);

  const userSelectHandler = (userId: string) => {
    const prevIds = documentFilterState.uploadedBy;

    const newIds = prevIds.includes(userId)
      ? prevIds.filter((id) => id !== userId)
      : [...prevIds, userId];

    setDocumentFilterState({
      ...documentFilterState,
      uploadedBy: newIds,
    });
  };

  return (
    <Flex flexDirection='column' flex={1} gap={2}>
      <Flex
        cursor='pointer'
        alignItems='center'
        gap={3}
        onClick={() => {
          onFilterSelect(null);
        }}
        width='fit-content'
      >
        <FontAwesomeIcon
          icon={faArrowLeftLong as IconProp}
          fontSize='16px'
          color='#6F767E'
        />

        <Text color='#1A1D1F' fontSize='14px' fontWeight={600}>
          Uploaded by
        </Text>
      </Flex>
      <SearchInput
        value={search}
        placeholder='Search by name'
        hideShortcuts
        width='100%'
        onChange={(e) => setSearch(e.target.value)}
      />

      <Flex maxHeight='27vh' flexDirection='column' overflowY='scroll'>
        {users.length ? (
          users.map((user) => {
            return (
              <Flex key={user?.eid}>
                <Checkbox
                  value={user?.eid}
                  onChange={() => userSelectHandler(user?.eid)}
                  isChecked={documentFilterState.uploadedBy.includes(user?.eid)}
                  gap={2}
                  _hover={{
                    background: '#EFEFEF',
                  }}
                  padding='8px 12px'
                  borderRadius='8px'
                  width='100%'
                >
                  <Flex alignItems={'center'} gap={4}>
                    <Image
                      width={'40px'}
                      height={'40px'}
                      borderRadius={'50%'}
                      src={getImage(user?.profilePic, user?.name)}
                    />
                    <Flex justifyContent={'center'} flexDir={'column'}>
                      <Box as='span' fontWeight={600} fontSize={'14px'}>
                        {user?.name}
                      </Box>
                      <Flex gap={1}>
                        <Flex
                          alignItems='center'
                          color={'#92929D'}
                          fontSize={'12px'}
                          fontWeight={500}
                        >
                          {user?.locations[0]?.locationStatus === 'open' ? (
                            <Image src={locationopenicon} alt='Open Location' />
                          ) : (
                            <Image
                              src={locationlaunchicon}
                              alt='Launch Location'
                            />
                          )}
                          <Tooltip label={user?.locations[0]?.name}>
                            <Text isTruncated maxWidth={'70px'}>
                              {user?.locations[0]?.name}
                            </Text>
                          </Tooltip>
                        </Flex>
                        <span style={{ color: '#92929D' }}>&bull;</span>
                        <Text
                          fontWeight={500}
                          color={'#92929D'}
                          fontSize={'12px'}
                        >
                          {user?.authRole}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Checkbox>
              </Flex>
            );
          })
        ) : (
          <EmptyState
            image='NoLocationMember'
            title='No members found'
            description='Oops! Try a different search to get results.'
          />
        )}
      </Flex>
    </Flex>
  );
};

DocumentUploadedBy.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/UploadedBy.tsx';

export default DocumentUploadedBy;
