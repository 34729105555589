import { useQuery } from '@apollo/client';
import { Box, Center } from '@chakra-ui/react';
import { LauncherLocationDetail } from 'pages/launcher/details/types/location-launch-details.types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Loader from 'sub-components/Loader';
import LiveModal from 'sub-components/LocationLaunchDashboard/LiveModal';
import { useLocationLoginDetailModal } from 'sub-components/LocationLaunchDashboard/LocationLoginDetails/useLocationLoginDetail';
import { HeaderColors } from '../../../configs';
import { AllLocationTable } from './AllLoacationtable';
import {
  LOCATION_LAUNCH_LIST,
  LocationLaunchListResponse,
  TASK_TYPES,
  TaskTypeArray,
} from './dashboard-grapghql';
import { DashHeader } from './DashHeader';
import {
  calculateLocationStatus,
  transformLocationLaunchList,
  transformTabledata,
} from './helper';
import { LaunchRateGraph } from './LaunchRateGraph';
import { LocationAttentionContainer } from './LocationAttention';
import { OverallStatus } from './PieCharts';
import { EmptyLauncherTasks } from './States';
import {
  IAnalyticData,
  useLauncherDashboardEventTrack,
} from './useLauncherDashboardEventTrack';
import { useComplianceApprovalStats } from 'sub-components/nexus/Compliance/Create/components';
import { EnumEntityComplianceApprovalStatus } from 'sub-components/nexus/Compliance/types/compliance.types';

interface IProps {}

const DashboardContainerConfig: FC<IProps> = () => {
  //location login detail for username and password to make a location live
  const locationLoginDetails = useLocationLoginDetailModal();

  //get the compliance approval stats
  const { complianceApprovalStats } = useComplianceApprovalStats(
    EnumEntityComplianceApprovalStatus['Pending_Compliance_approvals']
  );

  //data fetching
  const {
    data: locationData,
    loading: locationLoading,
    refetch,
  } = useQuery<LocationLaunchListResponse>(LOCATION_LAUNCH_LIST, {
    fetchPolicy: 'network-only',
  });
  const { data: taskList } = useQuery<TaskTypeArray>(TASK_TYPES);

  // states
  const [launcherOneLocationAdded, setLauncherOneLocationAdded] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<LauncherLocationDetail>();
  const [showLiveModal, setShowLiveModal] = useState<boolean>(false);

  //API data
  const locationsData = locationData?.LocationLaunchList ?? [];
  const taskListData = taskList?.EntityLauncherTaskCategories ?? [];
  const haveConfig = !!locationData?.BusinessLaunchers?.count;

  //non-live locations
  const liveLocations = locationsData.filter((location) => !location.isLive);

  //Live Modal refetch
  const refetchHandler = () => {
    refetch?.();
  };

  //live modal onclick functionality to check and make location live
  const onClick = useCallback(
    (locationId: string) => {
      let foundLocation: any = locationsData?.find(
        (value: any) => value.locationId === locationId
      );

      setSelected(foundLocation);
      if (!foundLocation?.location?.username) {
        locationLoginDetails({
          locationData: foundLocation,
          locationName: foundLocation?.location?.name,
          type: 'developmentToOpen',
          onClose: () => {},
          // afterSuccess: () => history.push(LOCATIONS_PAGE),
          afterSuccess: () => {},
          moveToLocationLiveModal: () => setShowLiveModal(true),
        });
      } else {
        setShowLiveModal(true);
      }
    },
    [locationsData]
  );

  //single location test case
  useEffect(() => {
    setLauncherOneLocationAdded(liveLocations.length === 1);
  }, [locationsData]);

  // Memoized calculation for location stats
  const locations = useMemo((): IAnalyticData[] => {
    const { lagging, onTime } = calculateLocationStatus(locationsData);
    return [
      {
        key: 'overallStatus',
        name: 'Overall Status',
        color: HeaderColors.Blue,
        greenValue: onTime?.toString(),
        orangeValue: lagging?.toString(),
        majorValue: locationsData
          .filter((location) => !location.isLive)
          .length.toString(),
      },
      // {
      //   key: 'launchRate',
      //   name: 'Launch Rate',
      //   color: HeaderColors.Green,
      //   greenValue:
      //     locationsData
      //       ?.filter((location) => location.isLive)
      //       ?.length?.toString() || '0',
      //   orangeValue: lagging?.toString() || '0',
      //   majorValue: (() => {
      //     const liveLocations =
      //       locationsData?.filter((location) => location.isLive)?.length || 0;
      //     const totalLocations = locationsData?.length || 0;
      //     if (totalLocations === 0) {
      //       return '0%';
      //     }

      //     const percentage = (liveLocations / totalLocations) * 100;
      //     return `${percentage.toFixed(1)}%`;
      //   })(),
      // },
    ];
  }, [locationsData]);

  useLauncherDashboardEventTrack(locations, locationLoading);

  // console.log(locationsData);

  // Transform data for cards
  const cardsData = useMemo(
    () => transformLocationLaunchList(locationsData),
    [locationsData, liveLocations]
  );

  //Transform data for table
  const tableData = useMemo(
    () => transformTabledata(locationsData),
    [locationsData]
  );

  // console.log(tableData);

  // Early return for loading state
  if (locationLoading) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  // Render appropriate content based on data
  const renderContent = () => {
    if (liveLocations.length === 0) {
      return <EmptyLauncherTasks haveConfig={haveConfig} />;
    }

    return (
      <>
        {/* <Piechart piechartData={locations} /> */}
        <OverallStatus piechartData={locations} />
        <LaunchRateGraph locationData={locationData} />
        <LocationAttentionContainer
          locMakeLiveHandler={onClick}
          cardsData={cardsData}
        />
        <AllLocationTable
          tableData={tableData}
          taskTypeOptions={taskListData}
        />
      </>
    );
  };

  return (
    <>
      <DashHeader haveConfig={haveConfig} />
      {/* {!launcherOneLocationAdded && (
        <DashNavbar
          locations={locations}
          launcherEmptyState={locationsData.length === 0}
        />
      )} */}

      {renderContent()}
      {selected?.locationId && showLiveModal && (
        <LiveModal
          isOpen={!!selected?.locationId}
          data={selected}
          refetchHandler={refetchHandler}
          onClose={() => {
            setSelected(undefined);
            setShowLiveModal(false);
          }}
        />
      )}
    </>
  );
};

export default DashboardContainerConfig;
