import { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { getImage } from 'utils';
import { ReactComponent as Compliance } from 'assets/images/nexus/compliance/light_blue.svg';
import { ReactComponent as Document } from 'assets/images/nexus/compliance/document_purple.svg';
import { ReactComponent as Pending } from 'assets/images/tasks/scheduled.svg';
import moment from 'moment';

export const getDocumentStatus = (row) => {
  if (row?.approvalStatus === 'pending') {
    return 'pending';
  } else {
    if (row?.type === 'compliance') {
      return 'compliance';
    } else {
      return 'other';
    }
  }
};

const getDocTypeStyle = (row) => {
  if (getDocumentStatus(row) === 'pending') {
    return {
      backgroundColor: '#F4F4F4',
      padding: '2px 5px',
      borderRadius: '8px',
    };
  } else {
    if (getDocumentStatus(row) === 'compliance') {
      return {
        backgroundColor: '#B1E5FC',
        padding: '2px 5px',
        borderRadius: '8px',
      };
    } else {
      return {
        backgroundColor: '#CABDFF',
        padding: '2px 5px',
        borderRadius: '8px',
      };
    }
  }
};

const getDocTypeIcon = (row) => {
  if (getDocumentStatus(row) === 'pending') {
    return <Pending height={'20px'} width={'20px'} />;
  } else {
    if (getDocumentStatus(row) === 'compliance') {
      return <Compliance />;
    } else {
      return <Document />;
    }
  }
};

const getDocTypeTitle = (row) => {
  if (getDocumentStatus(row) === 'pending') {
    return 'Awaiting approval';
  } else {
    if (getDocumentStatus(row) === 'compliance') {
      return 'Compliance';
    } else {
      return 'Other';
    }
  }
};

export const TaskName = ({ value }) => {
  return (
    <Flex maxWidth={'300px'} justifyContent={'center'} flexDir={'column'}>
      <Tooltip label={value?.metadata?.task}>
        <Text isTruncated>{value?.metadata?.task || '--'}</Text>
      </Tooltip>
      <Tooltip label={value?.taskDetails?.phase}>
        <Text
          width={'300px'}
          isTruncated
          lineHeight={'12px !important'}
          color={'#6F767E'}
          fontSize={'12px'}
        >
          {value?.metadta?.phase || '--'}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export const DocType = ({ value, row }) => {
  return (
    <Tooltip label={value}>
      <Flex alignItems={'center'} gap={2} style={getDocTypeStyle(row)}>
        {getDocTypeIcon(row)}
        <Text width={'fit-content'} isTruncated maxWidth={'150px'}>
          {getDocTypeTitle(row)}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export const UploadedBy = ({ value }) => {
  return (
    <Flex gap={2}>
      <Image
        borderRadius={'5px'}
        fit={'cover'}
        w={'24px'}
        h={'24px'}
        src={getImage(
          value?.createdByUser?.profilePic,
          value?.createdByUser?.name
        )}
      />
      <Flex>
        <Tooltip label={value?.name}>
          <Text maxWidth={'120px'} isTruncated fontWeight={600}>
            {value?.createdByUser?.name}, &nbsp;
          </Text>
        </Tooltip>
        <Text color={'#6F767E'}> {value?.createdByUser?.role}</Text>
      </Flex>
    </Flex>
  );
};

export const ExpiryDate = ({ value }) => {
  return <></>;
};

export const DocumentName = ({ value }) => {
  return (
    <Tooltip label={value}>
      <Flex>
        <Text isTruncated maxWidth={'200px'}>
          {value}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export const UploadedOn = ({ value }) => {
  return (
    <Flex flexDir={'column'}>
      <Text>{value ? moment(value).format('DD MMM YYYY') : '--'}</Text>
      <Text color={'#6F767E'} fontSize={'12px'}>
        {value ? moment(value).format('hh:mm A') : '--'}
      </Text>
    </Flex>
  );
};
