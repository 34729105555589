import { match, P } from 'ts-pattern';

const Excel = [
  'vnd.ms-excel',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const Word = [
  'msword',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
];
const CAD = ['acad', 'x-dwg', 'vnd.dwg', 'image/vnd.dwg', 'image/x-dwg'];

export type ChapterFileType =
  | 'docs'
  | 'xlsx'
  | 'video'
  | 'pdf'
  | 'cad'
  | 'file';

export const getFileType = (fileType = ''): ChapterFileType => {
  return match<string[], ChapterFileType>(fileType?.split('/'))
    .with(
      ['application', P.when((value) => Word.includes(value))],
      () => 'docs'
    )
    .with(
      ['application', P.when((value) => Excel.includes(value))],
      () => 'xlsx'
    )
    .with(['video', P._], () => 'video')
    .with(['application', 'pdf'], () => 'pdf')
    .with(['application', P.when((value) => CAD.includes(value))], () => 'cad')
    .with(['image', P.when((value) => value.includes('dwg'))], () => 'cad')
    .otherwise(() => 'file');
};
