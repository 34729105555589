import React, { FC } from 'react';
import { Box, Flex, Tooltip, Text } from '@chakra-ui/react';
import { ReactComponent as OpenlocationtypeiconIcon } from 'assets/images/training/openLocationTypeIcon.svg';
import { ReactComponent as LauncherlocationiconIcon } from 'assets/images/training/launcherLocationIcon.svg';
import moment from 'moment';
import { RecordType } from '../type';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { useUserDataSelector } from 'hooks';

interface IRecord {
  record: RecordType;
}

const getIconAndStyle = (type = 'open') => {
  const data = {
    icon: <OpenlocationtypeiconIcon />,
    style: {
      backgroundColor: '#B5E4CA99',
    },
  };

  if (type === 'open') {
    data.icon = <OpenlocationtypeiconIcon style={{ fontSize: '50px' }} />;
    data.style = {
      backgroundColor: '#B5E4CA99',
    };
  } else if (type === 'launching') {
    data.icon = <LauncherlocationiconIcon />;
    data.style = {
      backgroundColor: '#CABDFF',
    };
  }

  return data;
};

export const DocumentName: FC<IRecord> = ({ record }) => {
  const getFileUrl = record?.files?.[record?.files?.length - 1]?.url;
  return (
    <Tooltip label={record?.title}>
      <Box
        onClick={() => {
          getFileUrl && window.open(getFileUrl, '_blank');
        }}
        textDecoration={getFileUrl ? 'underline' : ''}
        _hover={
          getFileUrl
            ? { color: '#2A85FF', cursor: 'pointer' }
            : { cursor: 'not-allowed' }
        }
        fontSize={'15px'}
        fontWeight={600}
        isTruncated
        width={'100%'}
      >
        {record?.title}
      </Box>
    </Tooltip>
  );
};

export const ReviewDate: FC<IRecord> = ({ record }) => {
  return (
    <Box fontSize={'15px'} fontWeight={600} isTruncated width={'100%'}>
      {record?.reviewedAt
        ? moment(record?.reviewedAt).format('DD MMM YYYY')
        : '--'}
    </Box>
  );
};

export const Reason: FC<IRecord> = ({ record }) => {
  return (
    <Tooltip label={record?.comment}>
      <Box fontSize={'15px'} fontWeight={600} isTruncated width={'100%'}>
        {record?.comment}
      </Box>
    </Tooltip>
  );
};

export const Source: FC<IRecord> = ({ record }) => {
  const { launchId } = record?.metadata;
  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Tooltip label={launchId ? 'Task attachments' : 'Compliance docs'}>
        <Text isTruncated width={'100%'} fontWeight={600}>
          {launchId ? 'Task attachments' : 'Compliance docs'}
        </Text>
      </Tooltip>
      <Tooltip label={launchId ? 'Location launcher' : getAddedByName(record)}>
        <Text fontWeight={400} color={'#6F767E'} isTruncated width={'100%'}>
          {launchId ? 'Location launcher' : getAddedByName(record)}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export const LauncherTaskName: FC<IRecord> = ({ record }) => {
  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Tooltip label={record?.metadata?.task}>
        <Text isTruncated width={'100%'} fontWeight={600}>
          {record?.metadata?.task ?? '--'}
        </Text>
      </Tooltip>
      <Tooltip label={record?.metadata?.phase}>
        <Text fontWeight={400} color={'#6F767E'} isTruncated width={'100%'}>
          {record?.metadata?.phase ?? '--'}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export const Location: FC<IRecord> = ({ record }) => {
  const { icon, style } = getIconAndStyle(record?.location?.locationStatus);
  return (
    <>
      <Tooltip
        label={record?.location?.isRoot ? 'HQ' : record?.location?.name ?? '--'}
      >
        <Flex
          borderRadius={'6px'}
          gap={2}
          p={'5px'}
          style={style}
          alignItems={'center'}
        >
          <Box>{icon}</Box>
          <Text isTruncated width={'100%'}>
            {record?.location?.isRoot ? 'HQ' : record?.location?.name ?? '--'}
          </Text>
        </Flex>
      </Tooltip>
    </>
  );
};

// function to get added by name
function getAddedByName(record: RecordType) {
  let createdByName = '--';
  const { loggedInUserId } = useUserDataSelector((state) => ({
    loggedInUserId: state?.eid,
  }));
  const entityObjData = useReactiveVar(usersEntityObj);
  const foundUser = entityObjData?.find(
    (item) => item?.eid === record?.createdBy
  );
  if (foundUser) {
    createdByName = foundUser?.name;
  }

  return `Added by ${
    loggedInUserId === record?.createdBy ? 'you' : createdByName
  }`;
}
