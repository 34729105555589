import {
  Box,
  Button,
  Center,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDownToSquare,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as DeleteAuditIcon } from 'assets/images/delete-audit.svg';
import { ReactComponent as EditDarkIcon } from 'assets/images/edit-dark.svg';
import { FC, useCallback } from 'react';
import { useVersionHistory } from 'sub-components/nexus/Compliance/Listing/components/VersionHistory/useVersionHistory';
import { IDocument } from '../DocumentFilters/FiltersAndListing';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { useUploadOtherDoc } from '../DocumentActions';
import { useMoveToOther } from '../DocumentActions/moveToOther';
import { useDeleteCompliance } from 'sub-components/nexus/Compliance/Listing/components/useDeleteCompliance';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { useParams } from 'react-router-dom';
import useFileData from './useFileData';

interface IProps {
  document: IDocument;
  onActionComplete: () => void;
  pageType: 'NexusCompliance' | 'LocationProfile' | 'LocationLauncher';
  selectedTab?: string;
}

const DocumentAction: FC<IProps> = ({
  document,
  onActionComplete,
  pageType,
  selectedTab,
}) => {
  const params = useParams<{ locationId: string }>();

  const { userAuthRole, loggedInUserId } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
    loggedInUserId: state?.eid,
  }));

  const versionHistory = useVersionHistory();
  const complianceUpload = useComplianceUpload();
  const uploadOtherDoc = useUploadOtherDoc();
  const moveToOther = useMoveToOther();
  const deleteDocument = useDeleteCompliance();
  const { getFileData, data, loading, error } = useFileData();

  const handleFetchData = (eid: string) => {
    if (eid) {
      getFileData({
        variables: { eid: eid },
      });
    }
  };

  const getActionList = () => {
    if (pageType === 'NexusCompliance') {
      if (userAuthRole === AuthRole.SUPER_ADMIN) {
        return ['view', 'update', 'markAsOther', 'delete'];
      } else if (
        loggedInUserId === document?.createdBy ||
        userAuthRole === AuthRole.LOCATION_OWNER ||
        userAuthRole === AuthRole.ADMIN
      ) {
        return ['view', 'update'];
      } else {
        return ['view'];
      }
    }

    if (pageType === 'LocationProfile') {
      if (
        userAuthRole === AuthRole.SUPER_ADMIN &&
        document.type === 'compliance'
      ) {
        return ['view', 'update', 'moveToOther', 'delete'];
      }

      if (
        (userAuthRole === AuthRole.LOCATION_OWNER ||
          userAuthRole === AuthRole.ADMIN) &&
        document.type === 'compliance'
      ) {
        return ['view', 'update'];
      }

      if (document.type === 'other') {
        return ['view', 'rename', 'moveToCompliance', 'delete'];
      }
    }

    if (pageType === 'LocationLauncher') {
      if (
        userAuthRole === AuthRole.SUPER_ADMIN &&
        document.approvalStatus === 'pending'
      ) {
        return ['view', 'update', 'delete'];
      }

      if (
        userAuthRole === AuthRole.SUPER_ADMIN &&
        document.type === 'compliance'
      ) {
        return ['view', 'update', 'moveToOther', 'delete'];
      }

      if (userAuthRole === AuthRole.SUPER_ADMIN && document.type === 'other') {
        return ['view', 'rename', 'moveToCompliance', 'delete'];
      }

      if (
        (userAuthRole === AuthRole.LOCATION_OWNER ||
          userAuthRole === AuthRole.ADMIN) &&
        document.approvalStatus === 'pending'
      ) {
        return ['view'];
      }

      if (
        (userAuthRole === AuthRole.LOCATION_OWNER ||
          userAuthRole === AuthRole.ADMIN) &&
        document.type === 'compliance'
      ) {
        return ['view', 'update'];
      }

      if (document.type === 'other') {
        return ['view', 'rename', 'moveToCompliance', 'delete'];
      }
    }
  };

  const getMenuData = useCallback(() => {
    const menuData = [];
    const actionList = getActionList();

    if (actionList?.includes('view')) {
      menuData.push({
        name: 'View',
        icon: (
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare as IconProp}
            fontSize='20px'
            color='#272B30'
            width='22px'
          />
        ),
        onClick: () => {
          if (selectedTab === 'compliance') {
            versionHistory({ complianceId: document.eid });
          } else {
            handleFetchData?.(document?.eid);
          }
        },
        isLoading: loading,
      });
    }

    if (actionList?.includes('rename')) {
      menuData.push({
        name: 'Rename',
        icon: <EditDarkIcon fontSize='20px' />,
        onClick: () => {
          uploadOtherDoc({
            documentId: document.eid,
          });
        },
      });
    }

    if (actionList?.includes('update')) {
      menuData.push({
        name: 'Update',
        icon: <EditDarkIcon fontSize='20px' />,
        onClick: () => {
          if (
            (userAuthRole === AuthRole.LOCATION_OWNER ||
              userAuthRole === AuthRole.ADMIN) &&
            loggedInUserId !== document?.createdBy
          ) {
            complianceUpload({
              complianceId: [document.eid],
              preDefinedLocationId: params?.locationId || document.location.eid,
              onActionComplete: onActionComplete,
              disableCategorySelect: true,
              disableDocumentName: true,
              disableSigningDate: true,
              disableExpiryDate: true,
              disableReminder: true,
              sendForApproval: true,
            });
          } else {
            complianceUpload({
              complianceId: [document.eid],
              preDefinedLocationId: params?.locationId,
              onActionComplete: onActionComplete,
            });
          }
        },
      });
    }

    if (actionList?.includes('markAsOther')) {
      menuData.push({
        name: `Mark as 'other' location doc`,
        icon: (
          <Button
            variant='unstyled'
            transform='rotate(-90deg)'
            disabled={!document.location}
          >
            <FontAwesomeIcon
              icon={faArrowDownToSquare as IconProp}
              fontSize={'20px'}
              color={'#33383F'}
            />
          </Button>
        ),
        onClick: () => {
          if (!document.location) return;

          moveToOther({
            documentId: [document.eid],
            documentName: [document.title],
            onActionComplete: onActionComplete,
            markAsOther: true,
          });
        },
      });
    }

    if (actionList?.includes('moveToOther')) {
      menuData.push({
        name: 'Move to others',
        icon: (
          <Box transform='rotate(-90deg)'>
            <FontAwesomeIcon
              icon={faArrowDownToSquare as IconProp}
              fontSize={'20px'}
              color={'#33383F'}
            />
          </Box>
        ),
        onClick: () => {
          moveToOther({
            documentId: [document.eid],
            documentName: [document.title],
            onActionComplete: onActionComplete,
          });
        },
      });
    }

    if (actionList?.includes('moveToCompliance')) {
      menuData.push({
        name: 'Move to compliance',
        icon: (
          <Box transform='rotate(-90deg)'>
            <FontAwesomeIcon
              icon={faArrowDownToSquare as IconProp}
              fontSize={'20px'}
              color={'#33383F'}
            />
          </Box>
        ),
        onClick: () => {
          complianceUpload({
            complianceId: [document.eid],
            moveToCompliance: true,
            preDefinedLocationId: params?.locationId,
          });
        },
      });
    }

    if (actionList?.includes('delete')) {
      menuData.push({
        name: 'Delete',
        icon: <DeleteAuditIcon />,
        onClick: () => {
          deleteDocument({
            eid: [document.eid],
            onActionComplete: onActionComplete,
          });
        },
      });
    }

    return menuData;
  }, [document]);

  return (
    <Popover placement='bottom-start'>
      <PopoverTrigger>
        <Center cursor='pointer'>
          <FontAwesomeIcon
            fontSize='16px'
            icon={faEllipsis as IconProp}
            color='#6F767E'
          />
        </Center>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          borderRadius='12px'
          padding='7px'
          boxShadow='lg'
          width='275px'
          zIndex={1500}
        >
          <PopoverBody>
            <Flex flexDir='column' gap={1}>
              {getMenuData().map((item) => {
                return (
                  <Flex
                    key={item.name}
                    gap={3}
                    alignItems='center'
                    _hover={{
                      background: '#EFEFEF',
                    }}
                    padding='7px'
                    borderRadius='8px'
                    cursor={item?.isLoading ? 'not-allowed' : 'pointer'}
                    onClick={item.onClick}
                  >
                    <Center width='20px'>{item.icon}</Center>
                    <Text
                      fontSize='14px'
                      fontWeight={500}
                      color={item.name === 'Delete' ? '#FF6A55' : '#1A1D1F'}
                    >
                      {item?.isLoading ? 'please wait...' : item.name}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

DocumentAction.displayName =
  'displayName:LocationDetails/components/LocationAssetsCard/components/Documents/components/DocumentAction.tsx';

export default DocumentAction;
