import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { FlexCard } from '../../../../../../../atoms';
import { StringOrNumber } from '../../../../../../../types';
import { IDocumentInput } from '../compliance.input';
import ReminderDetails from './ReminderDetails';
import RemindUserType from './RemindUserType';
import { ReminderWillSentTo } from './remind-to-user';

interface IProps {
  disableReminder?: boolean;
}

const DocumentReminder: FC<IProps> = ({ disableReminder }) => {
  const { t } = useTranslation(['nexus']);

  const onChange = (value: StringOrNumber[]) => {
    // TODO reset values here
  };

  return (
    <Flex flexDir='column' gap={2}>
      <Box fontWeight='600'>
        <Trans
          t={t}
          i18nKey='nexus:documents.sendReminder'
          components={{
            span: (
              <Box as='span' fontWeight='400' fontSize='12px' color='#6F767E' />
            ),
          }}
        />
      </Box>

      <Controller<IDocumentInput, 'reminder.remindUserType'>
        name='reminder.remindUserType'
        render={({ field }) => (
          <FlexCard
            flexDir='column'
            p='14px 20px'
            bg='#FCFCFC'
            border='1px solid #EFEFEF'
            gap={4}
          >
            <RemindUserType
              {...field}
              onChange={callAll(field.onChange, onChange)}
              isDisabled={disableReminder}
            />

            <ReminderWillSentTo visible={field.value?.includes('users')} />

            <ReminderDetails />
          </FlexCard>
        )}
      />
    </Flex>
  );
};

DocumentReminder.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/document-reminder/DocumentReminder';
export default DocumentReminder;
