import { Flex, Text, Button, useModalContext } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { EntityComplianceCategoriesType } from 'sub-components/nexus/Compliance/types/compliance.types';
import SelectInput from './SelectInput';
import { useExportDocument } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components';
import { ActionButton } from 'ui-components';
import { useUserDataSelector } from 'hooks';

interface IProps {
  cb?: () => void;
  closeModal: () => void;
  categories?: EntityComplianceCategoriesType[];
}

const SelectCategoryModal: FC<IProps> = ({ closeModal, categories }) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const { exportDocuments, loading: exportLoading } = useExportDocument();
  const { entityId } = useUserDataSelector((state) => ({
    entityId: state?.entity?.eid,
  }));

  return (
    <>
      <Flex gap={2} flexDir={'column'}>
        <Text fontWeight={600}>Select categories:</Text>
        <SelectInput
          categories={categories}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
        {selectedOptions?.length > 0 && (
          <Text
            color={'#2A85FF'}
            onClick={() => setSelectedOptions([])}
            width={'fit-content'}
            cursor={'pointer'}
          >
            Clear all
          </Text>
        )}
        <Flex mt={8} w='full' align='center' gap={4}>
          <Button
            w='50%'
            size='lg'
            variant='outline'
            borderRadius='12px'
            bg='white'
            border='2px solid rgba(239, 239, 239, 1)'
            onClick={closeModal}
          >
            Cancel
          </Button>
          <ActionButton
            w='50%'
            variant='solid'
            size='lg'
            borderRadius='12px'
            colorScheme='blue'
            disabled={exportLoading || selectedOptions.length === 0}
            isLoading={exportLoading}
            actionFn={() => {
              exportDocuments({
                categoryIds: selectedOptions.map((option) => option.value),
                type: 'compliance',
                entityId: entityId,
                successCb: () => {
                  closeModal();
                },
              });
            }}
          >
            Export
          </ActionButton>
        </Flex>
      </Flex>
    </>
  );
};

export default SelectCategoryModal;
