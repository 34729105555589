import React, { FC, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import DragDropComponent from './DragDropComponent';
import { IDocumentInput } from './compliance.input';
import UpdateDocumentForm from './UpdateDocumentForm';

interface IProps {
  complianceId?: string;
  type?: 'update' | 'reminder';
  preDefinedLocationId?: string;
  replaceDocument?: boolean;
  disableCategorySelect?: boolean;
  disableDocumentName?: boolean;
  disableSigningDate?: boolean;
  disableExpiryDate?: boolean;
  disableReminder?: boolean;
  moveToCompliance?: boolean;
}

const UpdateDocument: FC<IProps> = ({
  complianceId,
  type,
  preDefinedLocationId,
  replaceDocument,
  disableCategorySelect,
  disableDocumentName,
  moveToCompliance,
  disableExpiryDate,
  disableReminder,
  disableSigningDate,
}) => {
  const file = useWatch<IDocumentInput, 'file'>({
    name: 'file',
  });

  const isUploaded = !file?.rawFile;

  const { setValue } = useFormContext<IDocumentInput>();
  useEffect(() => {
    if (replaceDocument) setValue('file', null as never);
  }, [replaceDocument]);

  return (
    <Flex flexDir='column' gap={3}>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='15px' color='#1A1D1F'>
          {isUploaded ? 'Uploaded document' : 'Upload document'}
        </Text>

        <DragDropComponent
          moveToCompliance={moveToCompliance}
          replaceDocument={replaceDocument}
          type={type}
          subTitle='PDF, XLSX, DOCX, JPG, PNG, PPT, ZIP &bull; Max size 50 MB'
          accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              [],
            'application/vnd.ms-powerpoint': [],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation':
              [],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'application/zip': ['.zip'],
          }}
        />
      </Flex>

      {file && (
        <UpdateDocumentForm
          preDefinedLocationId={preDefinedLocationId}
          type={type}
          complianceId={complianceId}
          disableCategorySelect={disableCategorySelect}
          disableDocumentName={disableDocumentName}
          disableSigningDate={disableSigningDate}
          disableExpiryDate={disableExpiryDate}
          disableReminder={disableReminder}
        />
      )}
    </Flex>
  );
};

UpdateDocument.displayName =
  'displayName:sub-components/nexus/Compliance/Create/components/common/UpdateDocument';

export default UpdateDocument;
