import { useLazyQuery } from '@apollo/client';
import { GET_FILE_DATA } from 'sub-components/nexus/Compliance/query/compliance.graphql';

const useFileData = () => {
  const [getFileData, { data, loading, error }] = useLazyQuery(GET_FILE_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { files } = data?.EntityComplianceById;
      const url = files?.[files?.length - 1]?.url;
      url && window.open(url, '_blank');
    },
  });

  return {
    getFileData, // Function to trigger the API call
    data,
    loading,
    error,
  };
};

export default useFileData;
