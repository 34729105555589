import { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';

export const GET_REQUIRED_RE_SUBMISSION_COUNT = gql`
  query GetReSubmissionCount($filter: FilterFindManyEntityComplianceInput) {
    EntityCompliancePagination(filter: $filter) {
      count
      items {
        eid
      }
    }
  }
`;

export const useReSubmissionCount = () => {
  const { data } = useQuery(GET_REQUIRED_RE_SUBMISSION_COUNT, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        approvalStatus: 'rejected',
      },
    },
  });

  return { reSubmissionCount: data?.EntityCompliancePagination?.count };
};
