import React, { FC } from 'react';
import { Flex, useModalContext, useToast, Skeleton } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useApolloClient, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import omit from 'lodash/omit';
import { useUploadImage } from 'hooks';
import { ActionButton } from 'ui-components';
import UpdateForm from './UpdateForm';
import { IDocumentInput } from './NewDocUpload';
import {
  FETCH_COMPLIANCE,
  UpdateComplianceInput,
  UpdateComplianceResponse,
  UPDATE_COMPLIANCE,
} from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';
import { getComplianceData } from 'sub-components/nexus/Compliance/Create/components/compliance-update.helper';
import { toUpdateComplianceInput } from 'sub-components/nexus/Compliance/Create/components/compliance.helper';
import { updateObject } from 'utils/objectHelper';
import {
  GET_NEXUS_COMPLIANCES,
  GET_NEXUS_COMPLIANCES_CATEGORY,
} from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';

interface IProps {
  documentId: string | undefined;
}

const UpdateDoc: FC<IProps> = ({ documentId }) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const client = useApolloClient();

  const { onClose } = useModalContext();

  const methods = useForm<IDocumentInput>({
    defaultValues: async () => {
      const response = await client.query({
        query: FETCH_COMPLIANCE,
        fetchPolicy: 'network-only',
        variables: {
          eid: documentId,
        },
      });
      let complianceData = getComplianceData(
        response.data?.EntityComplianceById
      );

      return complianceData;
    },
  });

  const [updateCompliance] = useMutation<
    UpdateComplianceResponse,
    UpdateComplianceInput
  >(UPDATE_COMPLIANCE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: '1 document has been renamed successfully!',
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to rename the document',
      });
    },
    refetchQueries: [
      GET_NEXUS_COMPLIANCES,
      'EntityCompliancePagination',
      GET_NEXUS_COMPLIANCES_CATEGORY,
      'EntityComplianceCategories',
    ],
  });

  const uploadImage = useUploadImage();

  const onSubmit = async (values: IDocumentInput) => {
    const input = toUpdateComplianceInput(cloneDeep(values));
    if (values.file?.rawFile) {
      const fileUrl = await uploadImage(values.file?.rawFile!);
      const file = omit(values.file, 'rawFile');
      file.url = fileUrl;
      updateObject(input, ['file'], file);
    }

    const response = await updateCompliance({ variables: { input: input } });

    if (response.errors) {
      return Promise.reject(response.errors);
    }

    setTimeout(onClose);
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        {methods?.formState?.isLoading ? (
          <Flex gap={2} flexDir={'column'}>
            <Skeleton width='40%' height='16px' borderRadius='4px' />
            <Skeleton width='full' height='46px' borderRadius='12px' />
          </Flex>
        ) : (
          <UpdateForm />
        )}
        <ActionButton
          isFullWidth
          colorScheme='blue'
          mt={2}
          actionFn={methods.handleSubmit(onSubmit)}
          isDisabled={!methods.formState.isDirty}
        >
          Save changes
        </ActionButton>
      </Flex>
    </FormProvider>
  );
};

UpdateDoc.displayName =
  'packages/sop-web/src/pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/uploadOtherDoc/UpdateDoc';

export default UpdateDoc;
