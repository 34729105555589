import { useLazyQuery } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownToSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigProvider, Table } from 'antd';
import { TableProps } from 'antd/lib';
import { Button, SearchInput } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { useDebounce, useUserDataSelector } from 'hooks';
import { NexusComplianceCategoryResponseType } from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DefaultTableEmptyState } from 'sub-components/nexus/Compliance/Listing/components';
import { GET_COMPLIANCE_CATEGORIES } from 'sub-components/nexus/Compliance/query/compliance.graphql';
import { TabConfig } from 'sub-components/training-v2/dashboard/components/TrackPathDashboard/topSection/Leaderboard';
import { ActionButton } from 'ui-components';
import { useMoveToOther } from '../DocumentActions/moveToOther';
import useDocumentTypeSelect from '../DocumentActions/useDocumentTypeSelect';
import { FiltersChipContainer, useDocumentFilter } from '../DocumentFilters';
import { NexusDocumentType } from '../DocumentFilters/FiltersAndListing';
import { useDocumentColumns, useDocuments } from '../hooks';
import ExportDocuments from './ExportDocuments';
import MoveButtons from './MoveButtons';
import { SortType } from './SortPopover';

interface IProps {
  setIsMultiSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const tabsConfig: TabConfig[] = [
  { label: 'Compliance', minWidth: '176px', value: 'compliance' },
  { label: 'Others', minWidth: '176px', value: 'other' },
];

const DocumentsTable: FC<IProps> = ({ setIsMultiSelected }) => {
  const [getComplianceCategories, { data: complianceCategoryData }] =
    useLazyQuery<NexusComplianceCategoryResponseType>(
      GET_COMPLIANCE_CATEGORIES,
      {
        fetchPolicy: 'network-only',
      }
    );

  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(1);

  const [selectedTab, setSelectedTab] =
    useState<NexusDocumentType>('compliance');
  const { documentsList, getDocuments, isDocumentLoading } = useDocuments();
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [sorting, setSorting] = useState<SortType | undefined>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedDocumentName, setSelectedDocumentName] = useState<string[]>(
    []
  );

  const { currentUserRole } = useUserDataSelector((data) => {
    return {
      currentUserRole: data.authRole,
    };
  });

  const debouncedSearchHandler = useDebounce((value: string) => {
    setDebouncedSearch(value);
  }, 400);

  const { locationId } = useParams<{ locationId: string }>();

  const documentTypeSelect = useDocumentTypeSelect();
  const moveToOther = useMoveToOther();

  const onActionComplete = () => {
    setSelectedPageIndex(1);
    setSelectedRowKeys([]);
    setIsMultiSelected(false);
  };

  const { columns } = useDocumentColumns({
    documentType: selectedTab,
    onActionComplete: onActionComplete,
    setSorting: setSorting,
    sorting: sorting,
    selectedTab: selectedTab,
  });
  const {
    FilterComponent,
    documentFilterState,
    hasSelectedFilters,
    setDocumentFilterState,
  } = useDocumentFilter({
    filters:
      selectedTab === 'compliance' ? ['Expired', 'uploadedBy'] : ['uploadedBy'],
  });

  const rowSelection: TableProps['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys,

    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
      const isMultiSelected = selectedRows?.length > 0;
      setIsMultiSelected(isMultiSelected);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  useEffect(() => {
    getComplianceCategories();
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
    setIsMultiSelected(false);
  }, [selectedTab]);

  useEffect(() => {
    let filterPayload: {
      query?: string;
      createdBy?: string[];
      expiryDate?: Date;
    } = {};

    if (documentFilterState.expiredDocumentOnly) {
      filterPayload.expiryDate = new Date();
    }

    if (documentFilterState.uploadedBy.length > 0) {
      filterPayload.createdBy = documentFilterState.uploadedBy;
    }

    getDocuments({
      variables: {
        page: selectedPageIndex,
        perPage: 10,
        filter: {
          locationIds: [locationId],
          type: selectedTab,
          query: debouncedSearch || '',
          status: ['active'],
          approvalStatus: 'approved',
          ...filterPayload,
        },
        sort: sorting ? sorting : 'CREATEDAT_DESC',
      },
    });
  }, [
    documentFilterState,
    locationId,
    selectedTab,
    debouncedSearch,
    sorting,
    selectedPageIndex,
  ]);

  useEffect(() => {
    if (!documentsList?.EntityCompliancePagination) return;
    setTotalRowsCount(documentsList?.EntityCompliancePagination?.count);
  }, [documentsList?.EntityCompliancePagination]);

  useEffect(() => {
    if (documentsList?.EntityCompliancePagination) {
      setSelectedDocumentName(
        documentsList?.EntityCompliancePagination?.items
          ?.filter((item) => selectedRowKeys.includes(item?.eid))
          ?.map((item) => item?.title)
      );
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    setSelectedPageIndex(1);
  }, [documentFilterState, locationId, selectedTab, debouncedSearch, sorting]);

  const shouldShowRowSelection =
    currentUserRole === AuthRole.SUPER_ADMIN ||
    ((currentUserRole === AuthRole.LOCATION_OWNER ||
      currentUserRole === AuthRole.ADMIN) &&
      selectedTab !== 'compliance');

  return (
    <Flex flexDirection='column' gap={5} width='100%' marginTop='.7rem'>
      <Flex justifyContent='space-between'>
        <Flex gap={3}>
          {tabsConfig.map((tab, index) => (
            <Button
              variant={selectedTab === tab.value ? 'solid' : 'ghost'}
              onClick={() => {
                if (tab.value === 'other') {
                  setDocumentFilterState({
                    ...documentFilterState,
                    expiredDocumentOnly: false,
                  });
                }
                setSelectedTab(tab.value as NexusDocumentType);
              }}
              key={index}
              borderRadius='8px'
            >
              {tab.label}
            </Button>
          ))}
        </Flex>
        <Authorize
          permittedFor={'user'}
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
        >
          <ActionButton
            colorScheme='blue'
            leftIcon={<AddIcon />}
            borderRadius='12px'
            actionFn={() => documentTypeSelect({ locationId })}
          >
            Add document
          </ActionButton>
        </Authorize>
      </Flex>

      <Flex gap={3}>
        <SearchInput
          placeholder='Search by document name'
          size='lg'
          width={selectedTab === 'compliance' ? '85%' : '90%'}
          hideShortcuts
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            debouncedSearchHandler(e.target.value);
          }}
        />
        {selectedTab === 'compliance' && (
          <>
            {documentsList &&
              documentsList?.EntityCompliancePagination.items?.length > 0 && (
                <ExportDocuments
                  type='compliance'
                  locationId={locationId}
                  categoryIds={complianceCategoryData?.EntityComplianceCategories?.map(
                    (category) => category.eid
                  )}
                />
              )}
          </>
        )}

        {FilterComponent}
      </Flex>

      {hasSelectedFilters && (
        <FiltersChipContainer
          documentFilterState={documentFilterState}
          setDocumentFilterState={setDocumentFilterState}
        />
      )}

      <ConfigProvider
        renderEmpty={() => (
          <DefaultTableEmptyState
            searchFieldText={debouncedSearch}
            filters={hasSelectedFilters}
            complianceCategoryId={''}
          />
        )}
      >
        {selectedRowKeys.length > 0 && selectedTab === 'other' && (
          <MoveButtons
            complianceId={selectedRowKeys}
            onActionComplete={onActionComplete}
          />
        )}
        {selectedRowKeys.length > 0 && selectedTab === 'compliance' && (
          <Flex>
            <Button
              leftIcon={
                <Box transform='rotate(-90deg)'>
                  <FontAwesomeIcon
                    icon={faArrowDownToSquare as IconProp}
                    fontSize={'20px'}
                    color={'#2A85FF'}
                  />
                </Box>
              }
              variant='ghost'
              textColor={'#2A85FF'}
              onClick={() => {
                moveToOther({
                  documentId: selectedRowKeys.map(String),
                  documentName: selectedDocumentName,
                  onActionComplete: onActionComplete,
                  markAsOther: false,
                });
              }}
            >
              Move to others
            </Button>
          </Flex>
        )}

        <Table
          rowSelection={shouldShowRowSelection ? rowSelection : undefined}
          columns={columns}
          dataSource={documentsList?.EntityCompliancePagination.items}
          rowKey={(record) => record.id || record.eid || record.name}
          pagination={{
            total: totalRowsCount,
            current: selectedPageIndex,
            onChange: (page) => {
              setSelectedPageIndex(page);
            },
          }}
          scroll={{ x: 'max-content' }}
          loading={isDocumentLoading}
        />
      </ConfigProvider>
    </Flex>
  );
};

DocumentsTable.displayName =
  'displayName:LocationDetails/components/LocationAssetsCard/components/Documents/components/DocumentsTable.tsx';

export default DocumentsTable;
