import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LOCATIONS_PAGE } from 'appRoutes';
import { Authorize, AuthRole } from 'authorization';
import { ActionButton } from 'ui-components';
import {
  usePendingApprovals,
  useReSubmission,
} from 'sub-components/nexus/Compliance/Listing/components/common';
import { IoWarningOutline } from 'react-icons/io5';
import { usePendingApprovalCount } from 'sub-components/nexus/Compliance/Listing/usePendingApprovalCount';
import { useReSubmissionCount } from 'sub-components/nexus/Compliance/Listing/useReSubmissionCount';

interface LocationState {
  backToTitle?: string;
}

interface IProps {
  title: string;
}

interface CustomState {
  prevPageLocation?: string;
  locationId?: string;
}

const LocationDetailsHeading: FC<IProps> = ({ title }) => {
  const { locationId } = useParams<CustomState>();
  const history = useHistory<CustomState>();
  const locState = useLocation<LocationState>().state;
  const resubmission = useReSubmission();
  const pendingApprovals = usePendingApprovals();

  const { reSubmissionCount } = useReSubmissionCount();
  const { pendingApprovalCount } = usePendingApprovalCount(locationId);

  const reSubmissionsCount = reSubmissionCount;
  const pendingApprovalsCount = pendingApprovalCount;

  const onBackClick = () => {
    if (locState?.backToTitle) {
      return history.goBack();
    }
    return history.push(LOCATIONS_PAGE);
  };

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Flex gap='10px' cursor='pointer' onClick={onBackClick} w='fit-content'>
        <FontAwesomeIcon icon={faArrowLeft as IconProp} size='2x' />
        <Flex flexDir='column'>
          <Text fontWeight={600} fontSize='24px'>
            {title}
          </Text>
          <Text fontWeight={400} fontSize='15px' color='#6F767E'>
            {locState?.backToTitle
              ? locState?.backToTitle
              : 'Back to Locations'}
          </Text>
        </Flex>
      </Flex>

      <Authorize
        permittedFor={'user'}
        permittedRoles={[AuthRole.LOCATION_OWNER, AuthRole.ADMIN]}
      >
        <ActionButton
          disabled={reSubmissionsCount > 0 ? false : true}
          border={'2px solid #EFEFEF'}
          colorScheme={reSubmissionsCount ? 'red' : 'whiteAlpha'}
          color={reSubmissionsCount ? 'white' : '#6F767E'}
          leftIcon={
            <IoWarningOutline
              color={reSubmissionsCount ? 'white' : '#6F767E'}
              fontSize={'18px'}
            />
          }
          actionFn={() => {
            resubmission();
          }}
        >
          Re-submission required
        </ActionButton>
      </Authorize>

      <Authorize permittedFor={'user'} permittedRoles={[AuthRole.SUPER_ADMIN]}>
        <ActionButton
          disabled={pendingApprovalsCount > 0 ? false : true}
          border={'2px solid #EFEFEF'}
          colorScheme={pendingApprovalsCount ? 'red' : 'whiteAlpha'}
          color={pendingApprovalsCount ? 'white' : '#6F767E'}
          leftIcon={
            <IoWarningOutline
              color={pendingApprovalsCount ? 'white' : '#6F767E'}
              fontSize={'18px'}
            />
          }
          actionFn={() => {
            pendingApprovals({ locationId: locationId });
          }}
        >
          View pending approvals
        </ActionButton>
      </Authorize>
    </Flex>
  );
};

export default LocationDetailsHeading;
