import { gql } from '@apollo/client';

export const GET_RESUBMIT_REQUIRED_DOC_LIST = gql`
  query EntityCompliancePagination(
    $filter: FilterFindManyEntityComplianceInput
  ) {
    EntityCompliancePagination(filter: $filter) {
      items {
        eid
        approvalStatus
        location {
          name
          locationStatus
        }
        title
        locationId
        categoryId
        metadata {
          launchId
          launcherName
          phase
          phaseId
          task
          taskId
        }
        reviewedAt
        createdBy
        comment
        files {
          createdAt
          fileSize
          mimetype
          name
          signingDate
          type
          updatedAt
          url
        }
      }
    }
  }
`;
