import { useReactiveVar } from '@apollo/client';
import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { ColumnsType } from 'atoms';
import { AuthRole } from 'authorization';
import moment from 'moment';
import { DocumentAction } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components';
import SortPopover, {
  SortType,
} from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/SortPopover';
import { IDocument } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/FiltersAndListing';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import { DocumentName, LocationName } from './components/component';

interface IProps {
  onActionComplete: () => void;
  setSorting: (value: SortType | undefined) => void;
  sorting: SortType | undefined;
  loggedInUserLocationIds?: string[];
}

const useComplianceDocumentColumns = ({
  onActionComplete,
  setSorting,
  sorting,
  loggedInUserLocationIds,
}: IProps) => {
  const users = useReactiveVar(usersEntityObj);

  const getUser = (eid: string) => {
    return users.find((user) => user?.eid === eid);
  };

  const getStyles = (row: any) => {
    if (row?.uploadedBy?.[3] === AuthRole?.LOCATION_OWNER) {
      return { color: 'red' };
    }
    return {};
  };

  const columns: ColumnsType = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document name
        </Text>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (_, values) => {
        return <DocumentName getStyles={getStyles} values={values} />;
      },
    },
    ...(loggedInUserLocationIds && loggedInUserLocationIds.length > 1
      ? [
          {
            title: (
              <Text fontWeight={500} color={'#6F767E'}>
                Location name
              </Text>
            ),
            dataIndex: 'location',
            key: 'location',
            width: '15%',
            render: (_, values) => <LocationName values={values} />,
          },
        ]
      : []),
    {
      title: (
        <Box as='span' fontWeight={500} color={'#6F767E'} fontSize={'14px'}>
          Uploaded by
        </Box>
      ),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '15%',
      render: (_, record: IDocument) => {
        const user = getUser(record?.createdBy);

        return (
          <Flex gap='5px' fontSize={'14px'}>
            <Image
              src={getImage(user?.profilePic, user?.name)}
              height='24px'
              width='24px'
              borderRadius='5px'
            />
            <Flex>
              <Tooltip label={user?.name}>
                <Box
                  as='span'
                  isTruncated
                  maxWidth={'100px'}
                  fontWeight='600'
                  color='#33383F'
                >
                  {user?.name}
                </Box>
              </Tooltip>

              <Box
                isTruncated
                maxWidth={'100px'}
                as='span'
                color='#6F767E'
                fontWeight={400}
              >
                , {user?.role}
              </Box>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex
          gap={2}
          fontWeight={500}
          color={'#6F767E'}
          fontSize={'14px'}
          alignItems='center'
        >
          Uploaded on
          <SortPopover
            setSorting={setSorting}
            sorting={sorting}
            sortKey='CREATEDAT'
          />
        </Flex>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '13%',
      render: (value) => (
        <Flex
          flex={1}
          cursor='pointer'
          flexDirection='column'
          alignItems='flex-end'
        >
          <Box as='span' color='#33383F' fontWeight={600}>
            {value && moment(value)?.isValid()
              ? moment(value).format('DD MMM YYYY')
              : '--'}
          </Box>
          <Box as='span' color='#6F767E' fontWeight={400} fontSize='12px'>
            {value && moment(value)?.isValid()
              ? moment(value).format('hh:mm A')
              : '--'}
          </Box>
        </Flex>
      ),
    },

    {
      title: (
        <Flex
          gap={2}
          fontWeight={500}
          color={'#6F767E'}
          fontSize={'14px'}
          alignItems='center'
        >
          Signed Date
        </Flex>
      ),
      dataIndex: 'signingDate',
      key: 'signingDate',
      width: '13%',
      render: (value: Date) => (
        <Flex
          flex={1}
          cursor='pointer'
          flexDirection='column'
          alignItems='flex-end'
        >
          <Box as='span' color='#33383F' fontWeight={600}>
            {value && moment(value)?.isValid()
              ? moment(value).format('DD MMM YYYY')
              : '--'}
          </Box>
        </Flex>
      ),
    },
    {
      title: (
        <Flex
          gap={2}
          fontWeight={500}
          color={'#6F767E'}
          fontSize={'14px'}
          alignItems='center'
        >
          Expiry Date
          <SortPopover
            setSorting={setSorting}
            sorting={sorting}
            sortKey='EXPIRYDATE'
          />
        </Flex>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      width: 145,
      render: (value: Date) => {
        const isExpired =
          moment(value)?.isValid() && moment(value)?.isBefore(moment()?.utc());

        return (
          <Flex
            flex={1}
            cursor='pointer'
            flexDirection='column'
            alignItems='flex-end'
          >
            <Box
              as='span'
              color={isExpired ? '#FF6A55' : '#33383F'}
              fontWeight={600}
            >
              {value && moment(value)?.isValid()
                ? moment(value).format('DD MMM YYYY')
                : '--'}
            </Box>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      width: '5%',
      render: (_, record) => {
        return (
          <>
            <DocumentAction
              document={record}
              onActionComplete={onActionComplete}
              pageType='NexusCompliance'
            />
          </>
        );
      },
    },
  ];

  return { columns };
};

export default useComplianceDocumentColumns;
