import React, { FC, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Flex,
  Tooltip,
  Checkbox,
} from '@chakra-ui/react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EntityComplianceCategoriesType } from 'sub-components/nexus/Compliance/types/compliance.types';

interface OptionType {
  value: string;
  label: string;
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: '50px',
    borderColor: '#e2e8f0',
    borderRadius: '12px',
    boxShadow: 'none',
    width: '100%',
    border: '2px solid #EFEFEF',
  }),
  multiValue: (base: any) => ({
    ...base,
    background: '#EFEFEF',
    padding: '5px 10px',
    borderRadius: '7px',
    color: '#111315',
    fontWeight: 500,
    flex: 'wrap',
    gap: '8px',
    alignItems: 'center',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    fontSize: '14px',
    color: '#1a202c',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: '#EFEFEF',
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    color: '#6F767E',
    ':hover': {
      background: 'transparent',
    },
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: '12px',
    marginTop: '8px',
    width: '100%',
  }),
  menuList: (base: any) => ({
    ...base,
    maxHeight: '300px',
    padding: '10px',
  }),
  option: (base: any, state: any) => ({
    ...base,
    padding: '8px 12px',
    borderRadius: '8px',
    background: state.isFocused ? '#EFEFEF' : 'white',
    color: '#1A1D1F',
    fontWeight: 500,
    display: 'flex',
    margin: '4px 0px',
    alignItems: 'center',
    cursor: 'pointer',
    ':active': {
      background: '#EFEFEF',
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#4a5568',
  }),
  valueContainer: (base: any) => ({
    ...base,
    maxWidth: '100%',
    display: 'flex',
    gap: '5px',
    flexWrap: 'wrap',
    ':hover': {
      border: 'none',
    },
    ':active': {
      border: 'none',
    },
  }),
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <FontAwesomeIcon icon={faXmark as IconProp} />
    </components.MultiValueRemove>
  );
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <Tooltip label={props.label}>
          <Text
            maxW='320px'
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
          >
            {props.label}
          </Text>
        </Tooltip>
        <Box
          as='span'
          mr={3}
          w='20px'
          h='20px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          border='2px solid'
          borderColor={props.isSelected ? 'blue.500' : 'gray.300'}
          borderRadius='4px'
          bg={props.isSelected ? 'blue.500' : 'transparent'}
        >
          {props.isSelected && (
            <Box display='flex' alignItems='center'>
              <Checkbox isChecked={props.isSelected} />
            </Box>
          )}
        </Box>
      </Flex>
    </components.Option>
  );
};

const MultiValueLabel = ({ children, ...props }: any) => (
  <Tooltip label={children}>
    <Box maxWidth={'200px'}>
      <components.MultiValueLabel {...props}>
        {children}
      </components.MultiValueLabel>
    </Box>
  </Tooltip>
);

const SelectAll = (props: any) => {
  const allSelected = props.getValue().length === props.options.length;

  const handleSelectAll = () => {
    if (allSelected) {
      props.clearValue();
    } else {
      props.setValue(props.options);
    }
  };

  return (
    <Box
      px={4}
      py={3}
      borderBottom='1px solid'
      borderColor='gray.200'
      cursor='pointer'
      _hover={{ bg: '#EFEFEF' }}
      onClick={handleSelectAll}
      display='flex'
      alignItems='center'
      fontWeight={500}
      fontSize='14px'
      color='#6F767E'
      borderRadius='8px'
    >
      <Flex justifyContent={'space-between'} width={'100%'}>
        <Text>SELECT ALL</Text>
        <Box
          as='span'
          mr={3}
          w='20px'
          h='20px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          border='2px solid'
          borderColor={allSelected ? 'blue.500' : 'gray.300'}
          borderRadius='4px'
          bg={allSelected ? 'blue.500' : 'transparent'}
        >
          {allSelected && (
            <Box display='flex' alignItems='center'>
              <Checkbox isChecked={allSelected} />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

interface IProps {
  categories?: EntityComplianceCategoriesType[];
  selectedOptions: any[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<any[]>>;
}

const SelectInput: FC<IProps> = ({
  categories,
  selectedOptions,
  setSelectedOptions,
}) => {
  const options = categories?.map((category) => {
    return {
      value: category?.eid,
      label: category?.category,
    };
  });

  return (
    <ChakraProvider>
      <Box>
        <Select
          isMulti
          options={options}
          value={selectedOptions}
          onChange={(selected) => setSelectedOptions(selected as any)}
          styles={customStyles}
          components={{
            MultiValueRemove,
            MultiValueLabel,
            Option,
            MenuList: (props: any) => (
              <components.MenuList {...props}>
                <SelectAll {...props} />
                {props.children}
              </components.MenuList>
            ),
          }}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          autoFocus
          placeholder='Select categories'
        />
      </Box>
    </ChakraProvider>
  );
};

export default SelectInput;
