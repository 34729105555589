import { useMutation } from '@apollo/client';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useModalContext,
} from '@chakra-ui/react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useMemo, useState } from 'react';
import { FaX } from 'react-icons/fa6';
import { SearchInput } from '../../../../../../atoms';
import { SortPopover } from '../../Tracking/components';
import { MemberAnaylticsTable } from '../../Tracking/tracking-types';
import {
  ADD_CROSS_TRAINING_USERS,
  GET_TP_ALLOWED_CROSS_TRAINEES,
  GET_TP_SESSION_DATA,
} from '../query/track-dashboard-graphql';
import LocationBadge from './LocationBadge';
import { ActionButton, Image } from 'ui-components';
import { getImage } from 'utils';
import Filters from './Filter';
import { eventBus } from 'shared/eventEmit';
import useCrosstrainConfirm from './useCrosstrainConfirm';

interface IProps {
  crossTrainModalData: any[];
  trainingId: string;
  onClose: () => void;
  sessionId: string;
}

const CrossContent: FC<IProps> = ({
  crossTrainModalData,
  trainingId,
  sessionId,
}) => {
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
  const [selectedJobIds, setSelectedJobIds] = useState<string[]>([]);

  const { onClose } = useModalContext();
  const showConfirm = useCrosstrainConfirm(onClose);

  const [addCrossTrainingUsers] = useMutation(ADD_CROSS_TRAINING_USERS, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const handleCloseRequest = () => {
      if (assignedUsers.length > 0) {
        showConfirm();
      } else {
        onClose();
      }
    };
    eventBus.on('modalCloseRequested', handleCloseRequest);
    return () => {
      eventBus.off('modalCloseRequested', handleCloseRequest);
    };
  }, [assignedUsers, onClose, showConfirm]);

  const filteredData = useMemo(() => {
    let filtered =
      crossTrainModalData?.filter((user) => {
        const matchesSearch =
          user.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.userRole.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesLocation =
          selectedLocationIds.length === 0 ||
          user.locations?.some((loc: any) =>
            selectedLocationIds.includes(loc.eid)
          );
        const matchesJob =
          selectedJobIds.length === 0 || selectedJobIds.includes(user.userRole);

        return matchesSearch && matchesLocation && matchesJob;
      }) || [];

    if (sortOrder) {
      filtered = filtered.sort((a, b) =>
        sortOrder === 'asc'
          ? a.userName.localeCompare(b.userName)
          : b.userName.localeCompare(a.userName)
      );
    }
    return filtered;
  }, [
    searchTerm,
    sortOrder,
    crossTrainModalData,
    selectedLocationIds,
    selectedJobIds,
  ]);

  const handleSort = (order: 'asc' | 'desc' | '') => {
    setSortOrder(order);
  };

  const handleAssign = (userId: string) => {
    setAssignedUsers((prev) => [...prev, userId]);
  };

  const handleRemove = (userId: string) => {
    setAssignedUsers((prev) => prev.filter((id) => id !== userId));
  };

  const handleConfirmSelection = async () => {
    try {
      const { data } = await addCrossTrainingUsers({
        variables: {
          trainingId,
          users: assignedUsers,
        },
        refetchQueries: [
          {
            query: GET_TP_SESSION_DATA,
            variables: {
              eid: sessionId,
              limit: 1000,
            },
          },
          {
            query: GET_TP_ALLOWED_CROSS_TRAINEES,
            variables: {
              trainingId: trainingId,
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      onClose();
      console.log('Users assigned:', data.AddCrossTrainingUsers);
    } catch (error) {
      console.error('Error assigning users:', error);
    }
  };

  const columns: ColumnsType<MemberAnaylticsTable> = [
    {
      title: (
        <Flex gap={1} align='center'>
          <Text fontSize='12px' fontWeight={500} textColor={'#6F767E'}>
            Members
          </Text>
          <SortPopover onSort={handleSort} sortOrder={sortOrder} />
        </Flex>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (_, record: any) => (
        <Flex gap={3} align='center' width='200px'>
          <Image
            src={getImage(record.profilePic, record.userName)}
            height={64}
            width={64}
            style={{ borderRadius: '8px', objectFit: 'contain' }}
          />

          <Text fontWeight={600} fontSize='15px' color='#111315'>
            {record.userName}
          </Text>
        </Flex>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor='#6F767E'>
          Job
        </Text>
      ),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (_, record) => (
        <Text color='#8E59FF' fontSize='12px' fontWeight={500}>
          {record.userRole}
        </Text>
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor='#6F767E'>
          Location
        </Text>
      ),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (_, record: any) => (
        <LocationBadge locations={record.locations} />
      ),
    },
    {
      title: (
        <Text fontSize='12px' fontWeight={500} textColor='#6F767E'>
          Action
        </Text>
      ),
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_, record: any) => {
        const isAssigned = assignedUsers.includes(record.eid);
        const isHovered = hoveredRow === record.eid;

        return (
          <Button
            size='sm'
            fontSize='15px'
            fontWeight={700}
            color={isAssigned ? '#83BF6E' : '#2A85FF'}
            variant='unstyled'
            onClick={() =>
              isAssigned ? handleRemove(record.eid) : handleAssign(record.eid)
            }
            onMouseEnter={() => setHoveredRow(record.eid)}
            onMouseLeave={() => setHoveredRow(null)}
          >
            <Flex alignItems='center'>
              {isAssigned ? (
                isHovered ? (
                  <>
                    <Text as='span' color='#2A85FF'>
                      Remove
                    </Text>
                    <FaX
                      size={14}
                      style={{ marginLeft: '5px', color: '#2A85FF' }}
                    />
                  </>
                ) : (
                  <>
                    <Text as='span' color='#83BF6E'>
                      Assigned
                    </Text>
                    <CheckIcon boxSize={4} color='#83BF6E' ml={1} />
                  </>
                )
              ) : (
                <Text as='span'>Assign</Text>
              )}
            </Flex>
          </Button>
        );
      },
    },
  ];

  return (
    // @ts-ignore
    <Flex direction='column' gap='12px' h='60vh'>
      <Flex
        direction='column'
        height='100%'
        justify='space-between'
        bg='white'
        borderRadius='12px'
        px='40px'
      >
        <Flex justify='space-between' mb={4} alignItems={'center'} gap={2}>
          <SearchInput
            hideShortcuts
            placeholder='Search members, jobs'
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Filters
            onLocationFilterChange={setSelectedLocationIds}
            onJobFilterChange={setSelectedJobIds}
            selectedJobIds={selectedJobIds}
            selectedLocationIds={selectedLocationIds}
          />
        </Flex>
        <Box flex='1' overflowY='auto' maxHeight='600px' maxW='766px' pb='80px'>
          <Table
            columns={columns}
            dataSource={filteredData}
            rowKey='eid'
            pagination={false}
          />
        </Box>
      </Flex>
      <Flex
        justify='flex-end'
        align='center'
        p='12px 16px'
        gap='12px'
        bg='#F9FAFB'
        borderTop='1px solid #E5E7EB'
        borderBottomRadius='12px'
        position='sticky'
        bottom='0'
      >
        <Button variant='outline' onClick={() => setAssignedUsers([])}>
          Clear
        </Button>

        <ActionButton
          variant='solid'
          colorScheme='blue'
          actionFn={handleConfirmSelection}
          isDisabled={assignedUsers.length === 0}
        >
          Confirm Selection ({assignedUsers.length})
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default CrossContent;
