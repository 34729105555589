import React, { FC } from 'react';
import { Box, Flex, Tooltip, Text } from '@chakra-ui/react';
import { ReactComponent as OpenlocationtypeiconIcon } from 'assets/images/training/openLocationTypeIcon.svg';
import { ReactComponent as LauncherlocationiconIcon } from 'assets/images/training/launcherLocationIcon.svg';
import { UserEntityData } from 'shared/graphql/shared-types';
import { RecordType } from '../type';

interface IRecord {
  record: RecordType;
  userObject?: UserEntityData[];
}

const getIconAndStyle = (type = 'open') => {
  const data = {
    icon: <OpenlocationtypeiconIcon />,
    style: {
      backgroundColor: '#B5E4CA99',
    },
  };

  if (type === 'open') {
    data.icon = <OpenlocationtypeiconIcon style={{ fontSize: '50px' }} />;
    data.style = {
      backgroundColor: '#B5E4CA99',
    };
  } else if (type === 'launching') {
    data.icon = <LauncherlocationiconIcon />;
    data.style = {
      backgroundColor: '#CABDFF',
    };
  }

  return data;
};

export interface DataType {
  key: React.Key;
  documentName: string;
  location: string;
  source: {
    from: string;
    additionalInfo: string;
  };
  launcherTaskName: {
    taskName: string;
    phaseName: string;
  };
}

export const DocumentName: FC<IRecord> = ({ record }) => {
  const getFileUrl = record?.files?.[record?.files?.length - 1]?.url;
  return (
    <Tooltip label={record?.title}>
      <Box
        onClick={() => {
          getFileUrl && window.open(getFileUrl, '_blank');
        }}
        textDecoration={getFileUrl ? 'underline' : ''}
        _hover={
          getFileUrl
            ? { color: '#2A85FF', cursor: 'pointer' }
            : { cursor: 'not-allowed' }
        }
        fontSize={'15px'}
        fontWeight={600}
        isTruncated
        width={'100%'}
      >
        {record?.title}
      </Box>
    </Tooltip>
  );
};

export const Location: FC<IRecord> = ({ record }) => {
  const { icon, style } = getIconAndStyle(record?.location?.locationStatus);
  return (
    <>
      <Tooltip
        label={record?.location?.isRoot ? 'HQ' : record?.location?.name ?? '--'}
      >
        <Flex
          borderRadius={'6px'}
          gap={2}
          p={'5px'}
          style={style}
          alignItems={'center'}
        >
          <Box>{icon}</Box>
          <Text isTruncated width={'100%'}>
            {record?.location?.isRoot ? 'HQ' : record?.location?.name ?? '--'}
          </Text>
        </Flex>
      </Tooltip>
    </>
  );
};

export const Source: FC<IRecord> = ({ record, userObject }) => {
  const { launchId } = record?.metadata;

  const foundUser = userObject?.find(
    (item: UserEntityData) => item?.eid === record?.createdBy
  );

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Tooltip label={launchId ? 'Task attachments' : 'Compliance docs'}>
        <Text isTruncated width={'100%'} fontWeight={600}>
          {launchId ? 'Task attachments' : 'Compliance docs'}
        </Text>
      </Tooltip>
      <Tooltip
        label={
          launchId
            ? 'Location launcher'
            : `Added by ${foundUser?.name ?? 'user'}`
        }
      >
        <Text fontWeight={400} color={'#6F767E'} isTruncated width={'100%'}>
          {launchId
            ? 'Location launcher'
            : `Added by ${foundUser?.name ?? 'user'}`}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export const LauncherTaskName: FC<IRecord> = ({ record }) => {
  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Tooltip label={record?.metadata?.task}>
        <Text isTruncated width={'100%'} fontWeight={600}>
          {record?.metadata?.task ?? '--'}
        </Text>
      </Tooltip>
      <Tooltip label={record?.metadata?.phase}>
        <Text fontWeight={400} color={'#6F767E'} isTruncated width={'100%'}>
          {record?.metadata?.phase ?? '--'}
        </Text>
      </Tooltip>
    </Flex>
  );
};
