import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import NotificationModal from './NotificationContent';

type NotificationUpdate = (props?: any) => void;

export const useNotificationUpdate = (): NotificationUpdate => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ title, description }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex justify='space-between' gap={3}>
          <BoxHeader title={title} color={'#CABDFF'} />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <NotificationModal
          closeModal={() => confirmRef.current?.destroy()}
          description={description}
        />
      ),
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '12px',
        minW: '550px',
      },
      footer: null,
    });
  }, []);
};
