import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useNotificationUpdate } from '../notificationModal';
import { MARK_COMPLIANCE } from './pendingApproval.query';

export const useMarkCompliance = () => {
  const notificationModal = useNotificationUpdate();

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [approveComplianceDocs, { data, loading, error }] =
    useMutation(MARK_COMPLIANCE);

  const approveDocs = async (
    eids: string[],
    closeModal?: () => void,
    showToastAndNotifyModal?: boolean
  ) => {
    try {
      const { data } = await approveComplianceDocs({
        variables: { eids },
      });
      if (data?.ApprovalComplianceDocs?.succeed && showToastAndNotifyModal) {
        toast({
          status: 'success',
          title: 'Success',
          description: `${eids?.length} document(s) marked as Compliance successfully.`,
        });
        closeModal?.();
        notificationModal({
          title: 'Notification update',
          description:
            'The respective location owners will be notified about documents that are approved as compliance and those that need to be re-uploaded.',
        });
      }
    } catch (error) {
      console.log('Error during mutation:', error);
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to mark document as Compliance.',
      });
    }
  };

  return {
    approveDocs,
    data,
    loading,
    error,
  };
};
