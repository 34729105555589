import { useMutation } from '@apollo/client';
import { Box, Button, Flex, Input, Skeleton } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useRef, useState } from 'react';
import Loader from 'sub-components/Loader';
import { IComplianceCategory } from 'types/nexus.types';
import { ADD_NEW_COMPLIANCE_CATEGORY } from '../query/compliance.graphql';
import CategoryCard from './CategoryCard';
import { getDefaultCategoryName } from './helper';

interface IProps {
  complianceCategories?: IComplianceCategory[];
  refetchComplianceCategory: () => void;
  onActionComplete: () => void;
  complianceCategoryLoader: boolean;
  setCategoryId: React.Dispatch<React.SetStateAction<string>>;
  categoryId: string;
}

const CategoryListingContainer: FC<IProps> = ({
  complianceCategories,
  refetchComplianceCategory,
  onActionComplete,
  complianceCategoryLoader,
  categoryId,
  setCategoryId,
}) => {
  const [showAddCategoryField, setShowAddCategoryField] =
    useState<boolean>(false);
  const categorySliderRef = useRef(null);
  const categorySliderContainerRef = useRef(null);
  const addCategoryRef = useRef(null);

  const handleCategorySlider = (scrollDirection: string) => {
    if (!categorySliderRef.current) {
      return undefined;
    }
    if (scrollDirection === 'left') {
      // @ts-ignore
      categorySliderRef.current.scrollLeft -= 100;
    } else if (scrollDirection === 'right') {
      // @ts-ignore
      categorySliderRef.current.scrollLeft += 100;
    }
  };

  const [addComplianceCategory, { loading: addComplianceCategoryLoader }] =
    useMutation(ADD_NEW_COMPLIANCE_CATEGORY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        refetchComplianceCategory();
        setShowAddCategoryField(false);
      },
    });

  const handleAddCategory = (categoryName: string) => {
    if (!categoryName?.trim()) {
      categoryName = getDefaultCategoryName(complianceCategories);
    }
    addComplianceCategory({
      variables: {
        category: categoryName,
      },
    });
    setShowAddCategoryField(false);
  };

  return (
    <Flex
      ref={categorySliderContainerRef}
      justifyContent={'space-between'}
      gap={2}
      border={'1px solid #EFEFEF'}
      px={'10px'}
      alignItems={'center'}
      backgroundColor={'#FCFCFC'}
      borderRadius={'12px'}
      height={'56px'}
    >
      {/* @ts-ignore */}
      {categorySliderRef.current?.scrollWidth >
        //@ts-ignore
        categorySliderContainerRef.current?.scrollWidth - 150 && (
        <Button
          onClick={() => handleCategorySlider('left')}
          border={'1px solid #EFEFEF'}
          bgColor={'#FCFCFC'}
        >
          <FontAwesomeIcon icon={faChevronLeft as IconProp} />
        </Button>
      )}

      <Flex
        style={{ scrollBehavior: 'smooth' }}
        ref={categorySliderRef}
        overflowX={'auto'}
        gap={2}
        width='90%'
      >
        {complianceCategoryLoader ? (
          <Flex gap={2} alignItems={'center'}>
            {Array(8)
              ?.fill('')
              ?.map((_, i) => (
                <Flex
                  height={'45px'}
                  flexDir='column'
                  justifyContent={'center'}
                  width={'150px'}
                  gap={3}
                  pt='6px'
                  key={i}
                >
                  <Skeleton width='full' height='46px' borderRadius='12px' />
                </Flex>
              ))}
          </Flex>
        ) : (
          <>
            {complianceCategories?.map((category) => {
              return (
                <Box minW={'fit-content'} key={category?.eid}>
                  <CategoryCard
                    category={category}
                    selectedCategoryId={categoryId}
                    setSelectedCategoryId={setCategoryId}
                    onActionComplete={onActionComplete}
                    refetchComplianceCategory={refetchComplianceCategory}
                  />
                </Box>
              );
            })}
          </>
        )}

        {addComplianceCategoryLoader && (
          <Flex
            border={'1px solid #F4F4F4'}
            justifyContent={'center'}
            style={{
              borderRadius: '12px',
              padding: '6px',
            }}
            width={'150px'}
            backgroundColor={'#EFEFEF'}
          >
            <Loader />
          </Flex>
        )}
        {showAddCategoryField && (
          <Flex alignItems={'center'}>
            <Input
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  handleAddCategory(e?.target?.value);
                }
              }}
              onBlur={(e) => handleAddCategory(e?.target?.value)}
              width={'150px'}
              borderRadius={'8px'}
              backgroundColor={'#EFEFEF'}
              focusBorderColor='transparent'
              outline={'none'}
              border={'1px solid #6F767E'}
              autoFocus
              ref={addCategoryRef}
              placeholder='New category'
            />
          </Flex>
        )}
      </Flex>

      {/* @ts-ignore */}
      {categorySliderRef.current?.scrollWidth >
        //@ts-ignore
        categorySliderContainerRef.current?.scrollWidth - 150 && (
        <Button
          onClick={() => handleCategorySlider('right')}
          border={'1px solid #EFEFEF'}
          bgColor={'#FCFCFC'}
        >
          <FontAwesomeIcon icon={faChevronRight as IconProp} />
        </Button>
      )}
      <Button
        disabled={showAddCategoryField}
        ml={'1.5rem'}
        mr={'10px'}
        //@ts-ignore
        _active={'#FCFCFC'}
        //@ts-ignore
        _hover={'#FCFCFC'}
        color={'#2A85FF'}
        backgroundColor={'#FCFCFC'}
        leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
        onClick={() => setShowAddCategoryField(true)}
      >
        Add Category
      </Button>
    </Flex>
  );
};

CategoryListingContainer.displayName =
  'sub-components/nexus/Compliance/Listing/CategoryListingContainer';

export default CategoryListingContainer;
