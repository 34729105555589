import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import ReUploadContent from './ReUploadContent';

interface IProps {
  handleReUpload?: (eid: string, comment: string) => void;
  record?: any;
  setSelectedReupload?: React.Dispatch<React.SetStateAction<[]>>;
}

type ReUploadProps = (props?: any) => void;

export const useReUpload = (): ReUploadProps => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({ handleReUpload, record, setSelectedReupload }: IProps = {}) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex justify='space-between' gap={3}>
            <BoxHeader
              title={'Reason for re-upload request'}
              color={'#CABDFF'}
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <ReUploadContent
            record={record}
            handleReUpload={handleReUpload}
            closeModal={() => confirmRef.current?.destroy()}
            setSelectedReupload={setSelectedReupload}
          />
        ),
        isCentered: true,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '12px',
          minW: '550px',
        },
        footer: null,
      });
    },
    []
  );
};
