import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import expiredDocument from 'assets/images/compliance/expiredDocument.svg';
import { Dispatch, FC, SetStateAction } from 'react';
import { IDocumentFilterState } from './FiltersAndListing';

interface IProps {
  setDocumentFilterState: Dispatch<SetStateAction<IDocumentFilterState>>;
  documentFilterState: IDocumentFilterState;
}

const ExpiredDocumentOnly: FC<IProps> = ({
  setDocumentFilterState,
  documentFilterState,
}) => {
  return (
    <Button
      gap={1}
      width='100%'
      fontWeight={400}
      fontSize='14px'
      color='#1A1D1F'
      justifyContent='space-between'
      padding='12px'
      borderRadius='8px'
      variant='ghost'
      onClick={() => {
        setDocumentFilterState({
          ...documentFilterState,
          expiredDocumentOnly: !documentFilterState.expiredDocumentOnly,
        });
      }}
    >
      <Flex alignItems='center' gap={1}>
        <Image src={expiredDocument} width='20px' />
        Expired documents only
        <Box as='span' color='#6F767E' fontWeight={400} fontSize='12px'>
          {documentFilterState.expiredDocumentOnly && ' (selected)'}
        </Box>
      </Flex>
    </Button>
  );
};

ExpiredDocumentOnly.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/ExpiredDocumentOnly.tsx';

export default ExpiredDocumentOnly;
