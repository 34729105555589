import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';

import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { usersEntityObj } from 'sub-components/Header';
import Loader from 'sub-components/Loader';
import { useTrainingCategories } from 'sub-components/training-v2/create/components/TrainingPathDetailsSection/training-category/useTrainingCategories';
import {
  EnumTPContentsType,
  IPathAnalytics,
  TMemberAnalyticsItem,
  TPPathAnalyticsItem,
  TTPDetails,
  TTPProgress,
  TTPSummary,
} from 'sub-components/training-v2/shared/types';
import {
  AnalyticsContainer,
  TrackPathHeader,
  TrackPathSummaryContainer,
} from './components';
import {
  calculateAverageCompletionRate,
  calculateAverageScore,
  calculateAverageTimeSpentOnPath,
  calculatePathAnalyticsActionCardMetrics,
  calculateProgressData,
  calculateSummaryCardData,
  getMemberDetails,
} from './helpers';
import {
  GET_REPEATING_TP_CYCLES,
  GET_TP_ALLOWED_CROSS_TRAINEES,
  GET_TP_SESSION_DATA,
  IGetTpSessionResponse,
  TPSession,
} from './query/track-dashboard-graphql';
import { useUserDataSelector } from 'hooks';
import { eventBus } from 'shared/eventEmit';
import { AuthRole } from 'sop-commons/src/client';
import { ReactComponent as LauncherLocationIcon } from 'assets/images/training/launcherLocationIcon.svg';
import { ReactComponent as OpenLocationTypeIcon } from 'assets/images/training/openLocationTypeIcon.svg';
import useCombinedStore from 'zustandStore/store';

const TrackPathDashboard: FC = () => {
  const { trainingId, sessionId } = useParams<{
    trainingId: string;
    sessionId: string;
  }>();
  const location = useLocation();
  const userData = useReactiveVar(usersEntityObj);
  const { userAuthRole, userLocations, bussinessLocations, currentUser } =
    useUserDataSelector((state) => ({
      currentUser: state?.eid,
      userAuthRole: state?.authRole,
      userLocations: state?.locations ?? [],
      bussinessLocations: state?.entity?.locations ?? [],
    }));

  const { loading: categoriesLoading, categories } = useTrainingCategories();
  // const [userProgressForUsers, setUserProgressForUsers] = useState<string[]>(
  //   []
  // );
  const currentUserLocations = useMemo(() => {
    return userAuthRole === AuthRole.SUPER_ADMIN
      ? bussinessLocations
      : userLocations;
  }, [userAuthRole]);

  const {
    data: tpSessionData,
    loading: isTpSessionLoading,
    refetch: refetchTpSessionData,
  } = useQuery<
    IGetTpSessionResponse,
    {
      eid: string;
      filter?: Record<'userId', string[]>;
      limit?: number;
    }
  >(GET_TP_SESSION_DATA, {
    variables: {
      eid: sessionId,
      limit: 1000,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (tpSessionData?.TpSessionById) {
      const session = tpSessionData?.TpSessionById;
      setTpSession(session);

      // const assignedUserIds =
      //   session?.assignedUsers?.map((user) => user?.userId) || [];
      // if (assignedUserIds?.length !== userProgressForUsers?.length) {
      //   setUserProgressForUsers(assignedUserIds);
      // }
    }
  }, [tpSessionData?.TpSessionById, refetchTpSessionData]);

  //Cross train members
  const [getCrossTrainData, { data: crossTrainData }] = useLazyQuery(
    GET_TP_ALLOWED_CROSS_TRAINEES,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (trainingId) {
      getCrossTrainData({
        variables: { trainingId: trainingId },
      });
    }
  }, [trainingId]);

  const [tpSession, setTpSession] = useState<TPSession>({
    assignedUsers: [],
    userProgress: [],
    contents: [],
    endDate: new Date(),
    maxScore: 0,
    createdBy: 'Guest',
    createdAt: new Date(),
    supervisors: [],
    trainingId: '',
    hasSkillVerifier: false,
    skillVerifier: {
      name: '',
      skill: '',
    },
    hasScoring: false,
    isRepeating: false,
    crossTrain: false,
  });

  const {
    data: repeatingTpCyclesData,
    loading: isLoadingRepeatingTpCyclesData,
  } = useQuery<
    { TpById: { sessions: { endDate: Date; startDate: Date; eid: string }[] } },
    { eid: string }
  >(GET_REPEATING_TP_CYCLES, {
    fetchPolicy: 'network-only',
    variables: {
      eid: trainingId,
    },
  });
  const { currentRepeatingTrainingSessionCycle } = useCombinedStore();
  const [memberAnalytics, setMemberAnalytics] = useState<
    TMemberAnalyticsItem[]
  >([]);
  const [pathAnalytics, setPathAnalytics] = useState<
    IPathAnalytics['pathAnalyticsData']
  >([]);
  const [tpDetails, setTpDetails] = useState<TTPDetails>({
    averageCompletionRate: 0,
    averageScore: { averagePercent: 0, totalAverageScore: 0, maxScore: 0 },
    averageTimeSpentOnTP: 0,
  });
  const [crossTrainingData, setCrossTrainingData] = useState<string[]>();
  const [progressData, setProgressData] = useState<TTPProgress>({
    totalMembers: 0,
    membersYetToStart: 0,
    onGoingMembers: 0,
    overDueMembers: 0,
    completedMembers: 0,
  });

  const [summaryData, setSummaryData] = useState<TTPSummary>({
    tpSessionSummaryCategory: '',
    tpSessionCreatedOn: '',
    tpSessionCreatedBy: '',
    tpSessionSupervisedBy: [
      {
        name: '',
        avatar: '',
      },
    ],
    tpSessionRepeatFrequency: '',
    tpSessionStatus: '',
  });
  const [isRefetching, setIsRefetching] = useState<boolean>(false);

  const [cyclesSessionId, setCycleSessionId] = useState<{
    endDate?: Date;
    stateDate?: Date;
    eid: string;
  }>({
    eid: sessionId,
  });
  //doRefetch
  const doRefetchTpSessionData = async (passedSessionId: string) => {
    setIsRefetching(true);
    try {
      await refetchTpSessionData({ eid: passedSessionId });
    } catch (err) {
      console.error('Error during refetch:', err);
    } finally {
      setIsRefetching(false);
    }
  };
  //sync changes when the evaluate flow is completed
  useEffect(() => {
    eventBus.on('evalute_flow_completed_refetch_for_training_actions', () => {
      doRefetchTpSessionData(sessionId);
    });

    return () => {
      eventBus.off(
        'evalute_flow_completed_refetch_for_training_actions',
        () => {
          doRefetchTpSessionData(sessionId);
        }
      );
    };
  }, []);

  useEffect(() => {
    // Trigger the refetch when cyclesSessionId changes
    doRefetchTpSessionData(cyclesSessionId.eid);
  }, [cyclesSessionId]);

  //effect to refetch the assignedUsers to filterout the userProgress
  // useEffect(() => {
  //   if (userProgressForUsers.length > 0) {
  //     refetchTpSessionData({
  //       eid: sessionId,
  //       // filter: {
  //       //   userId: userProgressForUsers,
  //       // },
  //     });
  //   }
  // }, [userProgressForUsers]);

  const TrainingID = tpSessionData?.TpSessionById?.training?.eid || '';
  const trainingName = tpSessionData?.TpSessionById?.training?.title || '';
  const trainingStatus = tpSessionData?.TpSessionById?.training?.status || '';
  const isTpReapeating =
    tpSessionData?.TpSessionById?.training?.isRepeating || false;
  const repeatCycles = [
    ...(repeatingTpCyclesData?.TpById?.sessions || []),
  ].reverse();
  const crossTrainAcceess =
    tpSessionData?.TpSessionById?.training?.accessPermission?.locationOwner?.includes(
      'CAN_CROSSTRAIN'
    );
  // Check if adminPermissions is exactly ["EDIT"] OR contains all three permissions
  const permissions = ['VIEW', 'EDIT', 'ASSIGN'];
  const adminPermissions =
    tpSessionData?.TpSessionById?.training?.accessPermission?.admin || [];
  const adminTpEditAccess =
    (Array.isArray(adminPermissions) &&
      adminPermissions?.length === 1 &&
      adminPermissions?.[0] === 'EDIT') ||
    (Array.isArray(adminPermissions) &&
      permissions?.every((perm) => adminPermissions?.includes(perm)));
  const editPathAccess =
    currentUser &&
    tpSessionData?.TpSessionById?.training?.createdBy === currentUser;

  const processDataSummaryContainer = () => {
    //tpDetails
    const averageScore = calculateAverageScore(tpSession);
    const averageTimeSpentOnTP = calculateAverageTimeSpentOnPath(tpSession);
    const averageCompletionRate = calculateAverageCompletionRate(tpSession);

    //progress
    const progressData = calculateProgressData(
      tpSession?.assignedUsers,
      tpSession,
      currentRepeatingTrainingSessionCycle,
      userData
    );

    //summaryCard
    const summaryData = calculateSummaryCardData(
      tpSession,
      userData,
      categories,
      trainingStatus
    );

    return {
      tpDetails: {
        averageCompletionRate,
        averageScore,
        averageTimeSpentOnTP,
      },
      progressData,
      summaryData,
    };
  };

  const processDataAnayticsContainer = () => {
    if (!tpSessionData?.TpSessionById?.assignedUsers && !userData) {
      console.log('Data is not present');
      return;
    }
    if (
      !tpSessionData?.TpSessionById?.userProgress?.[0]?.progress &&
      !tpSessionData?.TpSessionById?.contents
    ) {
      return;
    }

    //member details
    const memberDetails = getMemberDetails(tpSessionData, userData, tpSession, {
      OpenLocationTypeIcon,
      LauncherLocationIcon,
    });

    //pathAnalytics details
    //calculate the pathAnalytics details
    const contentSummary = calculatePathAnalyticsActionCardMetrics(
      userData,
      tpSession?.assignedUsers,
      tpSession?.userProgress,
      tpSession?.contents
    );

    const pathDetails: TPPathAnalyticsItem[] =
      tpSessionData?.TpSessionById?.contents
        ?.filter((content) => !content?.isRemoved)
        ?.map((content) => {
          const currContent = contentSummary?.find(
            (summary) => summary[content?.eid]
          ); //find the currContentSummary
          return {
            eid: content?.eid,
            thumbnail: content?.thumbnail,
            title: content?.title,
            progress: currContent
              ? currContent[content?.eid]
              : {
                  onTime: 0,
                  overDue: 0,
                },
            type: content?.type,
            dateAdded: content?.dateAdded,
          };
        });

    //member details filtering based on the currUserLocations [Only for LO's]
    const currUserLocationsMembers = memberDetails?.filter((member) =>
      member?.locations?.some((loc) =>
        currentUserLocations?.some(
          (userLocations) => userLocations?.eid === loc?.eid
        )
      )
    );
    return {
      memberData:
        userAuthRole === AuthRole.LOCATION_OWNER
          ? currUserLocationsMembers
          : memberDetails,
      pathData: pathDetails,
    };
  };

  const crossTrainModalData = useMemo(() => {
    return (
      crossTrainData?.TpAllowedCrossTrainees?.map((user: any) => ({
        userName: user?.name,
        userRole: user?.role,
        profilePic: user?.profilePic || '',
        eid: user?.eid,
        locations: user?.locations?.map((loc: any) => ({
          userName: loc?.name,
          locationStatus: loc?.locationStatus,
          eid: loc?.eid,
        })),
      })) || []
    );
  }, [crossTrainData]);

  useEffect(() => {
    if (tpSession && userData) {
      const analyticsData = processDataAnayticsContainer();
      const memberAnalyticsData = analyticsData?.memberData?.filter(
        (user) => !user?.crossTrained
      );
      const crossTainingData = analyticsData?.memberData?.filter(
        (user) => user?.crossTrained
      );
      setMemberAnalytics(memberAnalyticsData);
      setCrossTrainingData(crossTainingData);
      setPathAnalytics(analyticsData?.pathData);
      const summaryContainerData = processDataSummaryContainer();
      setTpDetails(summaryContainerData?.tpDetails);
      setProgressData(summaryContainerData?.progressData);
      setSummaryData(summaryContainerData?.summaryData);
    }
  }, [tpSession, userData]);
  return (
    <Box>
      <TrackPathHeader
        crossTrainModalData={crossTrainModalData}
        sessionId={sessionId}
        crossTrainAcceess={crossTrainAcceess!}
        editPathAccess={!!editPathAccess}
        isTpRepeating={isTpReapeating}
        repeatingCycles={repeatCycles}
        adminTpEditAccess={adminTpEditAccess}
        setCycleSessionId={setCycleSessionId}
        cyclesSessionId={cyclesSessionId}
        tp={tpSession}
        refetchTpSessionData={refetchTpSessionData}
        tpName={trainingName}
        publishedStatus={trainingStatus}
        trainingId={TrainingID}
      />
      <Box p={'32px'} bg={'#FFFFFF'} mt={6} borderRadius={'8px'}>
        {isTpSessionLoading || categoriesLoading || isRefetching ? (
          // @ts-ignore
          <Flex justify={'center'} align='center' h={'100vh'}>
            <Loader />
          </Flex>
        ) : (
          <Flex flexDir={'column'} gap={6}>
            <TrackPathSummaryContainer
              tpDetails={tpDetails}
              summaryData={summaryData}
              progressData={progressData}
            />
            <AnalyticsContainer
              tp={tpSessionData}
              sessionId={sessionId}
              tpName={trainingName}
              crossTrainData={crossTrainingData ?? ['']}
              memberAnalytics={memberAnalytics}
              crossTrainModalData={crossTrainModalData}
              trainingId={TrainingID}
              pathAnalytics={pathAnalytics ?? []}
              refetchTpSession={refetchTpSessionData}
              editPathAcccess={!!editPathAccess}
              crossTrainAcceess={!!crossTrainAcceess}
            />
          </Flex>
        )}
      </Box>
    </Box>
  );
};
TrackPathDashboard.displayName =
  'displayName:sub-components/training-v2/dashboard/components/TrackPathDashboard/TrackPathDashboard.tsx';
export default TrackPathDashboard;
