import { Box, Button, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { IComplianceFile } from 'sub-components/Launcher/tasks/components/task-details/types';
import complianceDoc from 'assets/images/compliance/complianceGray.svg';
import DocumentChip from './DocumentChip';
import FileDropZone from '../FileDropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faPlus,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const blueFilter =
  'invert(38%) sepia(89%) saturate(1356%) hue-rotate(199deg) brightness(99%) contrast(104%)';
const redFilter = `invert(72%) sepia(68%) saturate(5273%) hue-rotate(327deg) brightness(103%) contrast(101%)`;

interface IProps {
  documents: IComplianceFile[];
  complianceDocRequired: boolean;
  refetchData: () => void;
  taskStatus: string;
  handleFileUpload: (
    files: File[],
    documentType: 'compliance' | 'other'
  ) => void;
  handleReupload: (eid: string) => void;
}

const ComplianceDocContainer: FC<IProps> = ({
  documents,
  complianceDocRequired,
  refetchData,
  taskStatus,
  handleFileUpload,
  handleReupload,
}) => {
  const [showComplianceDropZone, setShowComplianceDropZone] = useState(false);

  const requireReupload = useMemo(() => {
    return documents.some((file) => {
      return file.approvalStatus === 'rejected' && file.status !== 'inactive';
    });
  }, [documents]);

  const isComplianceDropZoneVisible = useMemo(() => {
    if (documents.length === 0 && complianceDocRequired) return true;
    if (showComplianceDropZone) return true;

    return false;
  }, [documents, showComplianceDropZone]);

  const activeDocuments = useMemo(() => {
    return documents.filter(
      (file) => file.approvalStatus !== 'rejected' && file.status !== 'inactive'
    );
  }, [documents]);

  const deletedDocuments = useMemo(() => {
    return documents.filter((file) => file.status === 'inactive');
  }, [documents]);

  const rejectedDocuments = useMemo(() => {
    return documents.filter(
      (file) => file.approvalStatus === 'rejected' && file.status !== 'inactive'
    );
  }, [documents]);

  return (
    <Flex
      flexDirection='column'
      gap={3}
      background={requireReupload ? '#FFBC9933' : '#2A85FF1A'}
      padding='16px'
      borderRadius='12px'
      position='relative'
      flex={1}
      width='100%'
      border={`1px solid ${requireReupload ? '#FF6A55' : '#2A85FF1A'}`}
    >
      <Flex
        as='span'
        color='#33383F'
        fontWeight={500}
        fontSize='14px'
        alignItems='center'
        gap={1}
      >
        <Image
          src={complianceDoc}
          alt='compliance doc'
          width='24px'
          filter={requireReupload ? redFilter : blueFilter}
        />
        Compliance document(s)
        {complianceDocRequired && (
          <Text color='#FF6A55' fontWeight={600} fontSize='16px'>
            *
          </Text>
        )}
      </Flex>

      {!isComplianceDropZoneVisible && taskStatus !== 'completed' && (
        <Button
          variant='unstyled'
          color='#2A85FF'
          position='absolute'
          top={2}
          right={5}
          fontWeight={500}
          fontSize='12px'
          display='flex'
          alignItems='center'
          gap={1}
          onClick={() => {
            setShowComplianceDropZone(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus as IconProp} />
          Add another document
        </Button>
      )}

      {isComplianceDropZoneVisible && (
        <FileDropZone
          required={complianceDocRequired}
          onFileUpload={handleFileUpload}
          isComplianceUpload
        />
      )}

      {/* active document listing */}
      {activeDocuments.length ? (
        <Flex gap={3} flexWrap='wrap'>
          {activeDocuments.map((file, index) => (
            <DocumentChip
              key={index}
              document={file}
              refetchData={refetchData}
              taskStatus={taskStatus}
            />
          ))}
        </Flex>
      ) : null}

      {/* re-upload document listing */}

      {requireReupload && (
        <Flex flexDirection='column' gap={2}>
          <Text color='#33383F' fontSize='12px' fontWeight={500}>
            The following attachment(s) were not accepted as compliance
            documents. Please re-upload.
          </Text>

          <Flex flexDirection='column' gap={1}>
            {rejectedDocuments.map((file, index) => {
              const lastFile = file?.files?.[file?.files?.length - 1];
              if (!lastFile) return null;

              return (
                <Flex fontSize='14px' gap={4} key={index}>
                  <Flex
                    background='white'
                    padding='5px 10px'
                    borderRadius='7px'
                    alignItems='center'
                    gap='2px'
                    flex={1}
                    justifyContent='space-between'
                  >
                    <Text
                      color='#FF6A55'
                      textDecoration='underline'
                      textUnderlineOffset='2px'
                      isTruncated
                      width='280px'
                    >
                      {lastFile.name}
                    </Text>

                    <Tooltip label={file.comment} placement='top'>
                      <Flex
                        color='#1A1D1F'
                        fontSize='12px'
                        alignItems='center'
                        gap={1}
                        cursor='pointer'
                      >
                        <FontAwesomeIcon icon={faInfoCircle as IconProp} />
                        Reason
                      </Flex>
                    </Tooltip>
                  </Flex>
                  <Button
                    colorScheme='red'
                    size='sm'
                    fontSize='12px'
                    fontWeight={500}
                    gap={2}
                    onClick={() => {
                      handleReupload(file.eid);
                    }}
                  >
                    <FontAwesomeIcon icon={faRotateRight as IconProp} />
                    Replace document
                  </Button>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}

      {/* deleted document listing */}
      {deletedDocuments.length ? (
        <Flex flexDirection='column' gap={2}>
          <Flex gap={2} alignItems='center'>
            <FontAwesomeIcon
              icon={faInfoCircle as IconProp}
              color='#FF6A55'
              fontSize='20px'
            />
            <Text color='#FF6A55' fontSize='12px' fontWeight={500}>
              The following document(s) have been deleted from the system.
            </Text>
          </Flex>
          <Flex gap={3} flexWrap='wrap'>
            {deletedDocuments.map((file, index) => (
              <DocumentChip
                key={index}
                document={file}
                refetchData={refetchData}
                taskStatus={taskStatus}
              />
            ))}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};

ComplianceDocContainer.displayName =
  'displayName:sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/Attachments/ComplianceDocContainer.tsx';

export default ComplianceDocContainer;
