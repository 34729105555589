import { useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import {
  Flex,
  Text,
  Button,
  Input,
  useToast,
  useModalContext,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toComplianceInput } from 'sub-components/nexus/Compliance/Create/components/compliance.helper';
import { FileEntity } from 'types';
import { ActionButton } from 'ui-components';
import DocTitleInput from './DocTitleInput';
import UploadDocument from './UploadDocument';
import { useUploadImage, useUserDataSelector } from 'hooks';
import { updateObject } from 'utils/objectHelper';
import {
  AddComplianceInput,
  AddComplianceResponse,
  ADD_COMPLIANCE,
  MetaComplianceData,
} from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';
import {
  GET_NEXUS_COMPLIANCES,
  GET_NEXUS_COMPLIANCES_CATEGORY,
} from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';
import { eventBus } from 'shared/eventEmit';

interface IProps {
  closeModal?: () => void;
  documentType?: string;
  locationId?: string;
  closeDocumentTypeSelectionModal?: () => void;

  localSave?: boolean;
  preDefinedLocationName?: string;
  preDefinedLocationId?: string;
  localSaveCb?: (data: any) => void;
  successCb?: (data: any | undefined) => void;
  initialFile?: File;
  metaComplianceData?: MetaComplianceData;
}

export interface FileType extends FileEntity {
  createdBy: string;
  rawFile?: File;
}

export interface IDocumentInput {
  eid?: string;
  file?: FileType;
  title?: string;
  type?: string;
  locationId?: string;
}

const NewDocUpload: FC<IProps> = ({
  closeModal,
  documentType,
  locationId,
  closeDocumentTypeSelectionModal,

  localSave,
  preDefinedLocationName,
  preDefinedLocationId,
  initialFile,
  metaComplianceData,
  localSaveCb,
  successCb,
}) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const userId = useUserDataSelector((state) => state.eid);
  const { onClose } = useModalContext();

  const methods = useForm<IDocumentInput>({
    defaultValues: {
      title: '',
      type: 'other',
      locationId: locationId,
      preDefinedLocationName: preDefinedLocationName,
      preDefinedLocationId: preDefinedLocationId,
      file: initialFile
        ? {
            type: initialFile.type,
            name: initialFile.name,
            fileSize: initialFile.size,
            mimetype: initialFile.type,
            rawFile: initialFile,
            createdBy: userId,
          }
        : undefined,
    },
  });

  const uploadImage = useUploadImage();

  const [addCompliance] = useMutation<
    AddComplianceResponse,
    AddComplianceInput
  >(ADD_COMPLIANCE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Document successfully added.',
      });
      closeModal?.();
      closeDocumentTypeSelectionModal?.();
      successCb?.({
        title: methods?.getValues('title'),
        file: methods?.getValues('file'),
        type: methods?.getValues('type'),
      });
      eventBus.emit('refetchCompliance');
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Document creation failed!',
      });
    },
    refetchQueries: [
      GET_NEXUS_COMPLIANCES,
      'EntityCompliancePagination',
      GET_NEXUS_COMPLIANCES_CATEGORY,
      'EntityComplianceCategories',
    ],
  });

  const onSubmit = async (values: IDocumentInput) => {
    const { title, locationId } = values;
    methods.setValue('title', title);
    methods.setValue('locationId', locationId);

    const input = toComplianceInput(cloneDeep(values), metaComplianceData);
    const clonedInput = cloneDeep(input);

    if (values.file?.rawFile) {
      const fileUrl = await uploadImage(values.file?.rawFile!);

      methods.setValue('file.url', fileUrl);
      clonedInput[0].file.url = fileUrl;
      updateObject(input, ['file', 'url'], fileUrl);
    }
    if (localSave) {
      setTimeout(onClose);
      return localSaveCb?.(input);
    }

    const response = await addCompliance({ variables: { input: clonedInput } });

    if (response.errors) {
      return Promise.reject(response.errors);
    }

    setTimeout(onClose);
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        <UploadDocument />
        <ActionButton
          isFullWidth
          colorScheme='blue'
          mt={2}
          actionFn={methods.handleSubmit(onSubmit)}
          isDisabled={!methods.formState.isDirty}
        >
          Add document
        </ActionButton>
      </Flex>
    </FormProvider>
  );
};

NewDocUpload.displayName =
  'packages/sop-web/src/pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/uploadOtherDoc/NewDocUpload';

export default NewDocUpload;
