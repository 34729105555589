import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Box,
} from '@chakra-ui/react';
import AddLocationTask from './AddLocationTask';
import TaskGroup, { TaskGroupTypes } from './TaskGroup';
import { AuthRole, Authorization } from 'authorization';
import CategoryDropdown from '../../../../ui-components/Dropdown/CategoryDropdown';
import {
  ILocationEntityComplianceFile,
  LocationEntityComplianceResponse,
  LocationPhaseDetails,
} from './LauncherLocation.graphql';
import { useUserDataSelector } from 'hooks';
import { eventBus } from 'shared/eventEmit';
import { FC, useCallback, useEffect, useState } from 'react';
import { client } from 'sop-commons/src/client';
import { usersEntityObj } from 'sub-components/Header';
import {
  LAUNCHER_TASK_CATEGORY,
  LTaskCategoryEntity,
  TaskCategoryResponse,
} from 'sub-components/Launcher/launcher-config/common/launcher-config.graphql';
interface IProps {
  launchId?: string;
  locationId: string;
  phaseDetails: LocationPhaseDetails[];
  liveDate: Date;
  refetchData?: () => void;
  locationEntityComplianceData: ILocationEntityComplianceFile[];
}

const LauncherLocationTasksWrapper: FC<IProps> = ({
  launchId,
  locationId,
  phaseDetails,
  liveDate,
  refetchData,
  locationEntityComplianceData,
}) => {
  const [categories, setCategories] = useState<LTaskCategoryEntity[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const loggedInUserAuthRole = useUserDataSelector((state) => state?.authRole);

  const tabData = [
    { title: 'By Phase', groupBy: 'Phase' as TaskGroupTypes },
    { title: 'By Status', groupBy: 'Status' as TaskGroupTypes },
    { title: 'By Task Type', groupBy: 'Task Type' as TaskGroupTypes },
  ];

  if (!(loggedInUserAuthRole === AuthRole.LOCATION_OWNER)) {
    tabData.push({
      title: 'By Assignee',
      groupBy: 'Assignee' as TaskGroupTypes,
    });
  }

  useEffect(() => {
    const remove = eventBus.on('GET_CONFIG', (callback) => {
      callback({
        config: {
          contents: phaseDetails,
        },
      });
    });
    return () => remove();
  }, []);

  const fetchCategories = useCallback(() => {
    client
      .query<TaskCategoryResponse>({
        query: LAUNCHER_TASK_CATEGORY,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        if (response.data) {
          setCategories(response.data.EntityLauncherTaskCategories);
        }
        if (response.error) {
          console.log(response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [phaseDetails]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Flex
      borderRadius={12}
      background='#ffffff'
      padding='20px 24px'
      flexDirection='column'
      gap={8}
    >
      <Tabs variant='unstyled' w='full'>
        <Flex justifyContent={'space-between'} mb={6} mt={2}>
          <Flex gap={2}>
            <Text
              fontSize={'15px'}
              fontWeight={600}
              color={'#6F767E'}
              ml={8}
              alignContent={'center'}
            >
              Group By:
            </Text>
            <TabList>
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  borderRadius='8px'
                  py='8px'
                  px='16px'
                  fontSize='15px'
                  fontWeight='600'
                  whiteSpace='nowrap'
                  _selected={{
                    bg: '#EFEFEF',
                    color: '#1A1D1F',
                  }}
                  color={'#6F767E'}
                >
                  {tab.title}
                </Tab>
              ))}
            </TabList>
          </Flex>
          <Authorization
            permittedRoles={[AuthRole.SUPER_ADMIN]}
            permittedFor='user'
          >
            <AddLocationTask
              refetch={refetchData}
              phaseDetails={phaseDetails}
            />
          </Authorization>
          <Authorization
            permittedRoles={[AuthRole.LOCATION_OWNER]}
            permittedFor='user'
          >
            <Box>
              <CategoryDropdown
                categories={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </Box>
          </Authorization>
        </Flex>
        <TabPanels>
          {tabData.map((tab, index) => (
            <TabPanel key={index}>
              <TaskGroup
                groupBy={tab.groupBy}
                phaseDetails={phaseDetails}
                launchId={launchId}
                locationId={locationId}
                liveDate={liveDate}
                refetchData={refetchData}
                categories={categories}
                selectedCategories={selectedCategories}
                locationEntityComplianceData={locationEntityComplianceData}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

LauncherLocationTasksWrapper.displayName =
  'displayName:sub-components/Launcher/dashboard/Location/LauncherLocationTasksWrapper';

export default LauncherLocationTasksWrapper;
