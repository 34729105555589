import React, { FC } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { LAUNCHER_ADD_LOCATION } from '../../../../appRoutes';
import { AmplitudeEvent, deployEvent } from '../../../../shared';
import { HeaderColors } from '../../../../shared/headerColors/header-colors';
import { useOpenLocationConfirm } from '../../../../pages/Locations/OpenLocationConfirm/useOpenLocationConfirm';
import { Button } from '../../../../atoms';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AppRoute, useHistory } from 'routes';
import useCombinedStore from 'zustandStore/store';

interface IProps {}

const LocationHeader: FC<IProps> = () => {
  const history = useHistory();
  const openLocationConfirm = useOpenLocationConfirm();
  const { selectedInProgressLocation } = useCombinedStore();

  return (
    <Flex align='center' justify='space-between' mt={2}>
      <Flex direction={'row'} alignItems={'center'} gap={4}>
        {/* <IconButton aria-label='Back Arrow' variant={'ghost'}> */}
        <FontAwesomeIcon
          icon={faArrowLeft as IconProp}
          fontSize='25px'
          onClick={() => history.goBack()}
          cursor={'pointer'}
        />
        {/* </IconButton> */}
        <Text fontSize={'28px'} fontWeight={600}>
          Location Launcher
        </Text>
      </Flex>
      <Button
        variant='outline'
        style={{
          color: HeaderColors.DarkBlue,
          backgroundColor: 'white',
        }}
        onClick={() =>
          history.push({
            pathname: AppRoute.LAUNCHER_DOCUMENT_LISTING,
            params: {
              locationId: selectedInProgressLocation?.eid,
              launchId: selectedInProgressLocation?.launchId,
            },
          })
        }
      >
        View task attachments
      </Button>
    </Flex>
  );
};

LocationHeader.displayName = 'sc/L/lo/LH/LocationHeader';

export default LocationHeader;
