import { useQuery, useReactiveVar } from '@apollo/client';
import { useUserDataSelector } from 'hooks';
import React from 'react';
import { usersEntityObj } from 'sub-components/Header';
import { GET_COMPLIANCE_DOCUMENT_APPROVAL_STATUS } from '../../query/compliance.graphql';
import {
  TEntitityComplianceApprovalStatuseResponse,
  EnumEntityComplianceApprovalStatus,
} from '../../types/compliance.types';

type TComplianceApprovalStats = {
  pending: number;
  approved: number;
  rejected: number;
};

export const useComplianceApprovalStats = (
  statusFilter: EnumEntityComplianceApprovalStatus
) => {
  const allUsers = useReactiveVar(usersEntityObj);

  const { data, loading } = useQuery<
    TEntitityComplianceApprovalStatuseResponse,
    {
      filter: {
        approvalStatus: EnumEntityComplianceApprovalStatus;
        status: ['active'];
      };
    }
  >(GET_COMPLIANCE_DOCUMENT_APPROVAL_STATUS, {
    variables: {
      filter: {
        approvalStatus: statusFilter,
        status: ['active'],
      },
    },
    skip: !statusFilter,
  });

  const getLocationStatus = (locationId: string) => {
    const location = allUsers.find((user) => user.eid === locationId);
    return location?.locationStatus;
  };

  //filter out the null locations
  let locations = data?.EntityCompliancePagination?.items?.filter(
    (item) =>
      item?.locationId &&
      item?.location &&
      getLocationStatus(item?.locationId || item.location.eid) === 'development'
  );

  //unique locations data to render
  const _uniqueLocations = new Map(
    locations?.map((item) => [item.locationId, item])
  );
  const _uniqueLocationsData = Array.from(new Map(_uniqueLocations).values());

  //map the location to the statusFilter Passed
  const getEnumKeyByvalue = (value: string) => {
    return Object.keys(EnumEntityComplianceApprovalStatus)
      .find(
        (key) =>
          EnumEntityComplianceApprovalStatus[
            key as keyof typeof EnumEntityComplianceApprovalStatus
          ] === value
      )
      ?.split('_')
      ?.join(' ');
  };

  locations = _uniqueLocationsData?.map((loc) => {
    return {
      ...loc,
      status: getEnumKeyByvalue(statusFilter),
    };
  });

  //populate the approval stats
  const complianceApprovalStats = _uniqueLocationsData?.reduce(
    (acc, curr) => {
      if (curr.approvalStatus === 'pending') {
        acc['pending']++;
      } else if (curr.approvalStatus === 'approved') {
        acc['approved']++;
      } else {
        acc['rejected']++;
      }
      return acc;
    },
    {
      pending: 0,
      approved: 0,
      rejected: 0,
    } as TComplianceApprovalStats
  );

  return {
    complianceApprovalStats,
    locations,
    complianceApprovalsLoading: loading,
  };
};
