import { cloneDeep } from '@apollo/client/utilities';
import { useUserDataSelector } from 'hooks';
import { useEffect, useState } from 'react';
import {
  ENTITY_COMPLIANCE_COUNT_FOR_LAUNCHER,
  LAUNCHER_LOCATION_BY_ID,
  LocationLaunchByIdResponse,
} from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { filterVisibleContent } from 'sub-components/Launcher/dashboard/Location/utils';
import useCombinedStore from 'zustandStore/store';
import { useService } from './useService';
import { LocationPhaseDetails } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { PhaseTask } from 'sub-components/Launcher/dashboard/Location/LauncherLocation.graphql';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

export const useControl = (
  launchId?: string | undefined,
  disableFiltering?: boolean
) => {
  const { locationId } = useParams<{ locationId: string }>();

  const {
    stateDrawer: { updateLoading },
  } = useCombinedStore();
  const loggedInUser = useUserDataSelector((state) => state);
  const userLocations = loggedInUser?.locations || [];
  const { selectedInProgressLocation, updateSelectedInProgressLocation } =
    useCombinedStore();
  const [locLaunchData, setLocLaunchData] = useState<
    LocationLaunchByIdResponse['LocationLaunchById'] | undefined
  >(undefined);

  const [
    getLauncherLocationById,
    { data, loading: gettingLauncherLocationById, refetch },
  ] = useLazyQuery<LocationLaunchByIdResponse, { eid: string }>(
    LAUNCHER_LOCATION_BY_ID,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [
    getLocationEntityComplianceCount,
    { data: locationEntityComplianceCountData },
  ] = useLazyQuery(ENTITY_COMPLIANCE_COUNT_FOR_LAUNCHER, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (locationId) {
      const matchedLocation = userLocations?.find(
        (location) => location?.eid === locationId
      );

      if (matchedLocation) {
        updateSelectedInProgressLocation(matchedLocation);
      }
    }
  }, [locationId, userLocations, updateSelectedInProgressLocation]);

  const locationLaunchId = selectedInProgressLocation?.launchId
    ? selectedInProgressLocation?.launchId
    : launchId;

  const updateLocLaunchData = (
    data: LocationLaunchByIdResponse['LocationLaunchById'] | undefined
  ) => {
    let _data = cloneDeep(data!);
    (_data?.contents || [])?.map((phase) => {
      phase?.tasks.map((task) => {
        task.phaseEid = phase?.eid;
        task.phaseName = phase?.category; // phase name
        task.launcherId = _data?.launcherId;
        task.launchId = _data?.eid;
      });
    });
    let processedData;

    const __data = {
      ..._data,
      contents: _data?.contents?.filter(
        (phase) => !(phase?.selected === false && phase?.isCompleted === false)
      ),
    };

    processedData = __data;
    setLocLaunchData(processedData);
  };

  const {
    locationEntityCompliance: {
      getLocationEntityCompliance,
      LocationEntityComplianceData,
    },
  } = useService();

  const refetchData = async (
    task: PhaseTask,
    phaseDetails: LocationPhaseDetails[],
    originalTask: PhaseTask
  ) => {
    if (refetch) {
      updateLoading(true);

      if (!task || !phaseDetails) {
        const { data: newData } = await refetch?.();
        updateLocLaunchData(newData?.LocationLaunchById);
      } else {
        let isDependant = false;

        if (
          phaseDetails?.some(
            (phase) => phase?.triggerDetails?.triggerItemId === task?.eid
          )
        ) {
          isDependant = true;
        }

        if (
          phaseDetails
            ?.map((phase) => phase?.tasks)
            .flat()
            .some((t) => t?.triggerDetails?.triggerItemId === task?.eid)
        ) {
          isDependant = true;
        }

        if (isDependant) {
          const { data: newData } = await refetch?.();
          updateLocLaunchData(newData?.LocationLaunchById);
        } else {
          const updatedContents = locLaunchData?.contents?.map(
            (contentItem: LocationPhaseDetails) => {
              if (contentItem?.tasks) {
                return {
                  ...contentItem,
                  tasks: contentItem?.tasks?.map((t) =>
                    t?.eid === task?.eid
                      ? {
                          ...originalTask,
                          isCompleted: task?.isCompleted,
                          completedAt: task?.completedAt,
                          completedBy: task?.completedBy,
                          steps: originalTask?.steps?.map((inStep) => {
                            const matchingStep = task?.steps?.find(
                              (reStep) => reStep?.stepId === inStep?.stepId
                            );
                            if (!matchingStep) return inStep;
                            return {
                              ...inStep,
                              isCompleted: matchingStep?.isCompleted,
                              completedAt: matchingStep?.completedAt,
                              completedBy: matchingStep?.completedBy,
                            };
                          }),
                        }
                      : t
                  ),
                };
              }
              return contentItem;
            }
          );

          const updatedLocLaunchData = {
            ...locLaunchData,
            contents: updatedContents,
          };

          updateLocLaunchData(
            updatedLocLaunchData as LocationLaunchByIdResponse['LocationLaunchById']
          );
        }
      }

      updateLoading(false);
    }
  };

  useEffect(() => {
    if (data?.LocationLaunchById) {
      updateLocLaunchData(data?.LocationLaunchById);

      getLocationEntityComplianceCount({
        variables: {
          filter: {
            locationIds: [data?.LocationLaunchById?.locationId],
            status: ['active'],
          },
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (locationEntityComplianceCountData?.EntityCompliancePagination) {
      const payload = {
        filter: {
          locationIds: [data?.LocationLaunchById?.locationId],
          status: ['active'],
        },
        perPage:
          locationEntityComplianceCountData?.EntityCompliancePagination?.count,
      };

      getLocationEntityCompliance({
        variables: payload,
      });
    }
  }, [locationEntityComplianceCountData]);

  useEffect(() => {
    if (locationLaunchId) {
      getLauncherLocationById({
        variables: {
          eid: locationLaunchId,
        },
      });
    }
  }, [locationLaunchId]);

  return {
    loading: gettingLauncherLocationById,
    locationLaunchId,
    locLaunchData,
    refetchData,
    updateLocLaunchData,
    LocationEntityComplianceData,
  };
};
