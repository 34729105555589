import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, useCallback, useRef } from 'react';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import MoveToOtherContent from './MoveToOtherContent';

interface IProps {}

const useMoveToOther = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({
      documentId,
      documentName,
      onActionComplete,
      markAsOther,
    }: {
      documentId: string[];
      documentName: string[];
      onActionComplete: () => void;
      markAsOther?: boolean;
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex justify='space-between' gap={3}>
            <BoxHeader
              title={
                markAsOther
                  ? `Mark as 'other' location document`
                  : 'Move to Others'
              }
              color={HeaderColors.Purple}
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <MoveToOtherContent
            documentId={documentId}
            documentName={documentName}
            onActionComplete={onActionComplete}
            markAsOther={markAsOther}
          />
        ),
        size: 'xs',
        isCentered: true,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '12px',
          minW: '660px',
        },
        footer: null,
      });
    },
    []
  );
};

useMoveToOther.displayName =
  'displayName:pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/moveToOther/useMoveToOther.tsx';

export default useMoveToOther;
