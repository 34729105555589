import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Text,
  VStack,
  Icon,
  useColorModeValue,
  Flex,
  useToast,
  Center,
} from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import { HeaderColors } from 'configs';

interface FileDropZoneProps {
  disabled?: boolean;
  required?: boolean;
  onFileUpload: (files: File[], documentType: 'compliance' | 'other') => void;
  isComplianceUpload?: boolean;
}

const FileDropZone: React.FC<FileDropZoneProps> = ({
  disabled,
  required,
  onFileUpload,
  isComplianceUpload,
}) => {
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });

  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

  const handleFileSizeCheck = (file: File): boolean => {
    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: 'File too large',
        description: `${file.name} is larger than 50MB`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: any[]) => {
      // Handle rejected files
      rejectedFiles.forEach(({ file, errors }) => {
        if (errors[0]?.code === 'file-too-large') {
          handleFileSizeCheck(file);
        }
      });

      // Only process accepted files
      onFileUpload(acceptedFiles, isComplianceUpload ? 'compliance' : 'other');
    },
    [onFileUpload, toast]
  );

  const handleManualFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();
    const files = Array.from(event.target.files || []);
    const validFiles = files.filter(handleFileSizeCheck);
    if (validFiles.length > 0) {
      onFileUpload(validFiles, isComplianceUpload ? 'compliance' : 'other');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        [],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/zip': ['.zip'],
    },
    maxSize: MAX_FILE_SIZE,
    disabled: disabled,
  });

  return (
    <Center
      {...getRootProps()}
      border={`1px dashed rgba(111, 118, 126, 1)`}
      borderRadius='16px'
      p={6}
      textAlign='center'
      cursor={disabled ? 'no-drop' : 'pointer'}
      background='#FFFFFF'
      height='164px'
    >
      <input {...getInputProps()} onChange={handleManualFileSelect} />
      <VStack spacing={2}>
        <Flex align='center' gap='5px'>
          <Box fontWeight={500} fontSize='15px'>
            {isComplianceUpload
              ? `Drop your compliance file(s) here or`
              : `Drop your non-compliance file(s) here or`}
          </Box>
          <Box
            as='label'
            htmlFor='file-upload'
            fontWeight={600}
            fontSize='15px'
            cursor='pointer'
            color={HeaderColors.DarkBlue}
          >
            browse
          </Box>
          {required && <Box color={HeaderColors.Red.Mid}>*</Box>}
        </Flex>
        <Text fontSize='sm' color='gray.500'>
          PDF, XLSX, DOCX, JPG, PNG, PPT, ZIP &bull; Max size 50Mb
        </Text>
      </VStack>
    </Center>
  );
};

FileDropZone.displayName =
  'sub-components/Launcher/location-owner/StatesDrawer/StateDrawer/FileDropzone';

export default FileDropZone;
