import { useMutation } from '@apollo/client';
import { REQUEST_REUPLOAD } from './pendingApproval.query';
import { useToast } from '@chakra-ui/react';
import { useNotificationUpdate } from '../notificationModal';

type ReuploadDocs = {
  eid: string;
  comment: string;
};

export const useMarkReUpload = () => {
  const [reUpload, { data, loading, error }] = useMutation(REQUEST_REUPLOAD);

  const notificationModal = useNotificationUpdate();

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const requestReUpload = async (
    reuploadDocs: ReuploadDocs[],
    closeModal?: () => void,
    showToastAndNotifyModal?: boolean
  ) => {
    try {
      const { data } = await reUpload({
        variables: { input: reuploadDocs },
      });
      if (data?.RejectionComplianceDoc?.succeed && showToastAndNotifyModal) {
        toast({
          status: 'success',
          title: 'Success',
          description: `${reuploadDocs?.length} document(s) successfully requested for re-upload.`,
        });
        closeModal?.();
        notificationModal({
          title: 'Notification update',
          description:
            'The respective location owners will be notified about documents that are approved as compliance and those that need to be re-uploaded.',
        });
      }
    } catch (error) {
      console.log('Error during mutation:', error);
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to mark document as Compliance.',
      });
    }
  };

  return {
    requestReUpload,
    data,
    loading,
    error,
  };
};
