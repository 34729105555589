import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import NewDocUpload from './NewDocUpload';
import UpdateDoc from './UpdateDoc';
import { MetaComplianceData } from 'sub-components/nexus/Compliance/Create/components/add-document.graphql';

interface IProps {
  documentId?: string;
  closeModal?: () => void;
  documentType?: string;
  locationId?: string;
  closeDocumentTypeSelectionModal?: () => void;

  localSave?: boolean;
  preDefinedLicense?: string;
  preDefinedLocationName?: string;
  preDefinedLocationId?: string;
  localSaveCb?: (data: any) => void;
  successCb?: (data: any | undefined) => void;
  initialFile?: File;
  metaComplianceData?: MetaComplianceData;
}

const BaseContent: FC<IProps> = ({
  documentId,
  closeModal,
  documentType,
  locationId,
  closeDocumentTypeSelectionModal,

  localSave,
  preDefinedLicense,
  preDefinedLocationName,
  preDefinedLocationId,
  initialFile,
  metaComplianceData,
  localSaveCb,
  successCb,
}) => {
  return (
    <>
      {documentId ? (
        <UpdateDoc
          closeModal={closeModal}
          documentType={documentType}
          locationId={locationId}
          closeDocumentTypeSelectionModal={closeDocumentTypeSelectionModal}
          documentId={documentId}
        />
      ) : (
        <NewDocUpload
          closeModal={closeModal}
          documentType={documentType}
          locationId={locationId}
          closeDocumentTypeSelectionModal={closeDocumentTypeSelectionModal}
          localSave={localSave}
          preDefinedLocationName={preDefinedLocationName}
          preDefinedLocationId={preDefinedLocationId}
          localSaveCb={localSaveCb}
          successCb={successCb}
          initialFile={initialFile}
          metaComplianceData={metaComplianceData}
        />
      )}
    </>
  );
};

BaseContent.displayName =
  'packages/sop-web/src/pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/uploadOtherDoc/BaseContent';

export default BaseContent;
