import { Flex, Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import ReSubmissionTable from './ReSubmissionTable';
interface IProps {
  cb?: () => void;
  closeModal: () => void;
  locationId?: string;
}

const ReSubmissionModal: FC<IProps> = ({ closeModal }) => {
  return (
    <Flex gap={8} flexDir={'column'}>
      <Box color={'#6F767E'}>
        The following attachment(s) were not accepted as compliance documents.
        Please re-upload.
      </Box>

      <Flex gap={2} flexDir={'column'}>
        <Flex
          backgroundColor={'#FFBC9933'}
          padding={'16px'}
          borderRadius={'12px'}
          border={'2px solid #FF6A55'}
        >
          <ReSubmissionTable
          // locationId={locationId}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReSubmissionModal;
