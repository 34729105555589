import { Table } from 'antd';
import { useState, useMemo, FC, memo } from 'react';
import { CertificatesV2Entity } from '../certificate.graphql';
import { Box, Flex } from '@chakra-ui/react';
import { Image } from 'ui-components';
import { Button } from 'atoms';
import type { TableProps } from 'antd';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import styled from '@emotion/styled';
import { ChevronDownIcon, ChevronUpIcon, DownloadIcon } from '@chakra-ui/icons';
import { getImage } from 'utils';
import { useParams } from 'react-router-dom';

const StyledTable = styled(
  Table as React.ComponentType<TableProps<TableDataType>>
)`
  margin-top: 1rem;
  .ant-table-thead > tr > th {
    background: transparent;
    border: none;
    padding: 16px 8px;
  }
  .ant-table-tbody > tr > td {
    border: none;
    padding: 16px 8px;
  }
  .ant-table {
    background: transparent;
  }

  .ant-table-row-expand-icon-cell {
    width: 1% !important;
    padding: 0 !important;
    min-width: 20px;
  }

  .ant-table-expanded-row {
    background: rgba(244, 244, 244, 1);

    & > .ant-table-cell {
      padding: 0 !important;
    }
  }

  .expanded-row-content {
    display: table;
    width: 100%;
    padding: 16px 8px;

    .row-cell {
      display: table-cell;
      vertical-align: middle;
    }
  }
`;

interface IProps {
  data: CertificatesV2Entity[];
}

interface TableDataType {
  key: string;
  latestSession: CertificatesV2Entity;
  olderSessions: CertificatesV2Entity[];
}

interface TrainingNameCellProps {
  session: CertificatesV2Entity;
}

const CertificatesList: FC<IProps> = ({ data }) => {
  const { loggedInUserEid } = useUserDataSelector((state) => ({
    loggedInUserEid: state?.eid,
  }));

  const params = useParams<{ memberId: string }>();

  const filteredData = useMemo(() => {
    return data?.filter((_data): _data is CertificatesV2Entity =>
      Boolean(_data?.completedOn)
    );
  }, [data]);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const groupedSessions = useMemo(() => {
    const groups = new Map<string, CertificatesV2Entity[]>();
    filteredData?.forEach((session) => {
      if (!groups?.has(session?.trainingId)) {
        groups?.set(session?.trainingId, []);
      }
      const sessionGroup = groups?.get(session?.trainingId);
      if (sessionGroup) {
        sessionGroup.push(session);
      }
    });

    groups.forEach((sessions) => {
      sessions?.sort(
        (a, b) =>
          new Date(b?.assignedOn)?.getTime() -
          new Date(a?.assignedOn)?.getTime()
      );
    });

    return groups;
  }, [filteredData]);

  const tableData: TableDataType[] = useMemo(() => {
    return Array.from(groupedSessions?.entries())?.map(
      ([trainingId, sessions]) => ({
        key: trainingId,
        latestSession: sessions?.[0],
        olderSessions: sessions?.slice(1),
      })
    );
  }, [groupedSessions]);

  const TrainingNameCell: FC<TrainingNameCellProps> = memo(({ session }) => (
    <Flex gap='16px' alignItems='center'>
      <Box
        boxSize='62px'
        borderRadius='4px'
        overflow='hidden'
        flexShrink={0}
        bg='#F4F4F4'
      >
        <Image
          src={getImage(session?.thumbnail, session?.title)}
          width={62}
          height={62}
          style={{
            borderRadius: '8px',
            minWidth: '62px',
            maxWidth: '62px',
            minHeight: '62px',
            maxHeight: '62px',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box flex={1} overflow='hidden'>
        <Box fontWeight={700} fontSize='15px'>
          {session?.title}
        </Box>
      </Box>
    </Flex>
  ));

  const columns: TableProps<TableDataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: ['latestSession'],
      width: '30%',
      render: (session: CertificatesV2Entity) => (
        <TrainingNameCell session={session} />
      ),
      sorter: (a: TableDataType, b: TableDataType) =>
        a?.latestSession?.title?.localeCompare(b?.latestSession?.title),
    },
    {
      title: 'Status',
      width: '15%',
      render: (completedOn: string | null) => (
        <Box
          bg={
            completedOn
              ? 'rgba(181, 228, 202, 0.3)'
              : 'rgba(255, 188, 153, 0.2)'
          }
          color={
            completedOn ? 'rgba(131, 191, 110, 1)' : 'rgba(255, 106, 85, 1)'
          }
          w='fit-content'
          borderRadius='4px'
          p='4px 8px'
        >
          {completedOn ? 'Completed' : 'Pending'}
        </Box>
      ),
    },
    {
      title: 'Completed on',
      dataIndex: ['latestSession', 'completedOn'],
      width: '15%',
      render: (completedOn: string) => (
        <Box fontWeight={700} fontSize='15px'>
          {moment(completedOn)?.isValid()
            ? moment(completedOn).format('DD MMM YYYY')
            : '-'}
        </Box>
      ),
      sorter: (a: TableDataType, b: TableDataType) => {
        const aDate = new Date(a?.latestSession?.completedOn || '')?.getTime();
        const bDate = new Date(b?.latestSession?.completedOn || '')?.getTime();
        return aDate - bDate;
      },
    },
    {
      title: 'Action',
      width: '15%',
      render: (_, record: TableDataType) => (
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {record?.latestSession?.completedOn &&
            record?.latestSession?.sessionId &&
            loggedInUserEid && (
              <Button
                variant='outline'
                size='lg'
                bg='white'
                rightIcon={<DownloadIcon />}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_OPS_ENDPOINT}/tp/${
                      record?.latestSession?.sessionId
                    }/${params?.memberId || loggedInUserEid}`
                  );
                }}
              >
                Download Certificate
              </Button>
            )}
        </div>
      ),
    },
    {
      title: 'History',
      width: '15%',
      render: (_, record: TableDataType) => (
        <Box>
          {record?.olderSessions?.length > 0 ? (
            <span
              style={{
                color: '#1890ff',
                cursor: 'pointer',
                fontSize: '12px',
                fontWeight: 500,
              }}
              onClick={() => {
                const isExpanded = expandedRows?.includes(record?.key);
                setExpandedRows(
                  isExpanded
                    ? expandedRows?.filter((key) => key !== record?.key)
                    : [...expandedRows, record?.key]
                );
              }}
            >
              {expandedRows?.includes(record?.key) ? (
                <>
                  Hide older cycles <ChevronUpIcon fontSize='20px' />
                </>
              ) : (
                <>
                  View older cycles <ChevronDownIcon fontSize='20px' />
                </>
              )}
            </span>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
  ];

  return (
    <StyledTable
      columns={columns}
      expandIconColumnIndex={-1}
      dataSource={tableData}
      rowKey='key'
      expandable={{
        expandedRowKeys: expandedRows,
        expandedRowRender: (record) => (
          <Box>
            {record?.olderSessions?.map((session: CertificatesV2Entity) => (
              <div className='expanded-row-content' key={session?.sessionId}>
                <div className='row-cell' style={{ width: '30%' }}>
                  <TrainingNameCell session={session} />
                </div>
                <div className='row-cell' style={{ width: '15%' }}>
                  <Box
                    bg={
                      session?.completedOn
                        ? 'rgba(181, 228, 202, 0.3)'
                        : 'rgba(255, 188, 153, 0.2)'
                    }
                    color={
                      session?.completedOn
                        ? 'rgba(131, 191, 110, 1)'
                        : 'rgba(255, 106, 85, 1)'
                    }
                    w='fit-content'
                    borderRadius='4px'
                    p='4px 8px'
                  >
                    {session?.completedOn ? 'Completed' : 'Pending'}
                  </Box>
                </div>
                <div
                  className='row-cell'
                  style={{ width: '15%', fontWeight: 700, fontSize: '15px' }}
                >
                  {moment(session?.completedOn)?.isValid()
                    ? moment(session?.completedOn).format('DD MMM YYYY')
                    : '-'}
                </div>
                <div className='row-cell' style={{ width: '15%' }}>
                  {session?.completedOn && (
                    <Button
                      variant='outline'
                      size='lg'
                      bg='white'
                      rightIcon={<DownloadIcon />}
                      onClick={() => {
                        if (session?.sessionId && loggedInUserEid) {
                          window.open(
                            `${process.env.REACT_APP_OPS_ENDPOINT}/tp/${session?.sessionId}/${loggedInUserEid}`
                          );
                        }
                      }}
                    >
                      Download Certificate
                    </Button>
                  )}
                </div>
                <div className='row-cell' style={{ width: '15%' }}>
                  -
                </div>
              </div>
            ))}
          </Box>
        ),
        expandIcon: () => null,
      }}
      onRow={(record) => ({
        style: {
          backgroundColor: expandedRows?.includes(record?.key)
            ? 'rgba(244, 244, 244, 1)'
            : 'transparent',
        },
      })}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
    />
  );
};

export default CertificatesList;
