import { useLazyQuery } from '@apollo/client';
import { Box, Button, Flex } from '@chakra-ui/react';
import { ConfigProvider, Table } from 'antd';
import { useDebounce, useUserDataSelector } from 'hooks';
import {
  FiltersChipContainer,
  useDocumentFilter,
} from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters';
import { DocumentTypeState } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentFilters/FiltersAndListing';
import { FC, useEffect, useState } from 'react';
import { GET_COMPLIANCE_LISTING } from 'sub-components/nexus/Compliance/query/compliance.graphql';
import SearchInput from '../../../../atoms/SearchInput';
import { Heading, useLocationComplianceColumns } from './';
import { SortType } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/SortPopover';
import { DefaultTableEmptyState } from 'sub-components/nexus/Compliance/Listing/components';
import { LuDownload } from 'react-icons/lu';
import { ExportDocuments } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components';
import { useParams } from 'react-router-dom';
import { TableProps } from 'antd/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useMoveToOther } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/DocumentActions/moveToOther';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { AuthRole } from 'authorization';

interface IProps {
  locId: string;
  triggerRefresh?: boolean;
}

const ListContainer: FC<IProps> = ({ locId, triggerRefresh }) => {
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [sorting, setSorting] = useState<SortType | undefined>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedDocumentName, setSelectedDocumentName] = useState<string[]>(
    []
  );

  const [firstSelectedRowType, setFirstSelectedRowType] = useState<
    'compliance' | 'other' | undefined
  >();

  const moveToOther = useMoveToOther();
  const complianceUpload = useComplianceUpload();

  const { userAuthRole } = useUserDataSelector((state) => ({
    userAuthRole: state?.authRole,
  }));

  const debouncedSearchHandler = useDebounce((value: string) => {
    setDebouncedSearch(value);
  }, 400);

  const { locationId } = useParams<{
    locationId: string;
  }>();

  const onActionComplete = () => {
    let filterPayload: {
      query?: string;
      createdBy?: string[];
      type?: DocumentTypeState;
      approvalStatus?: string;
      approvalStatuses?: string[];
    } = {};

    if (documentFilterState.uploadedBy.length > 0) {
      filterPayload.createdBy = documentFilterState.uploadedBy;
    }

    if (documentFilterState.documentType === 'awaitingApproval') {
      filterPayload.approvalStatus = 'pending';
    } else if (documentFilterState.documentType) {
      filterPayload.type = documentFilterState.documentType;
      filterPayload.approvalStatus = 'approved';
    } else {
      filterPayload.approvalStatuses = ['approved', 'pending'];
    }

    getComplianceListing({
      variables: {
        page: selectedPageIndex,
        perPage: 10,
        filter: {
          locationIds: [locId],
          query: debouncedSearch || '',
          ...filterPayload,
          status: ['active'],
        },
        sort: sorting ? sorting : 'CREATEDAT_DESC',
      },
    });

    setSelectedPageIndex(1);
    setSelectedRowKeys([]);
    setFirstSelectedRowType(undefined);
  };

  const { columns } = useLocationComplianceColumns({
    onActionComplete: onActionComplete,
    setSorting: setSorting,
    sorting: sorting,
  });
  const {
    FilterComponent,
    documentFilterState,
    hasSelectedFilters,
    setDocumentFilterState,
  } = useDocumentFilter({
    filters: ['uploadedBy', 'documentType'],
  });

  const [
    getComplianceListing,
    { loading: complianceLoader, data: complianceData },
  ] = useLazyQuery(GET_COMPLIANCE_LISTING, {
    fetchPolicy: 'network-only',
  });

  const rowSelection: TableProps['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows) => {
      if (selectedRows.length === 1) {
        setFirstSelectedRowType(selectedRows[0].type);
      }

      if (!selectedRows.length) {
        setFirstSelectedRowType(undefined);
      }
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return {
        disabled:
          record.approvalStatus === 'pending' ||
          (!firstSelectedRowType
            ? false
            : firstSelectedRowType !== record.type) ||
          (userAuthRole !== AuthRole.SUPER_ADMIN &&
            record.type === 'compliance'),
      };
    },
  };

  useEffect(() => {
    let filterPayload: {
      query?: string;
      createdBy?: string[];
      type?: DocumentTypeState;
      approvalStatus?: string;
      approvalStatuses?: string[];
    } = {};

    if (documentFilterState.uploadedBy.length > 0) {
      filterPayload.createdBy = documentFilterState.uploadedBy;
    }

    if (documentFilterState.documentType === 'awaitingApproval') {
      filterPayload.approvalStatus = 'pending';
    } else if (documentFilterState.documentType) {
      filterPayload.type = documentFilterState.documentType;
      filterPayload.approvalStatus = 'approved';
    } else {
      filterPayload.approvalStatuses = ['approved', 'pending'];
    }

    getComplianceListing({
      variables: {
        page: selectedPageIndex,
        perPage: 10,
        filter: {
          isFilteringTaskDocs: true,
          locationIds: [locId],
          query: debouncedSearch || '',
          ...filterPayload,
          status: ['active'],
        },
        sort: sorting ? sorting : 'CREATEDAT_DESC',
      },
    });
    setSelectedRowKeys([]);
    setFirstSelectedRowType(undefined);
  }, [
    debouncedSearch,
    documentFilterState,
    selectedPageIndex,
    sorting,
    triggerRefresh,
  ]);

  useEffect(() => {
    setSelectedPageIndex(1);
  }, [documentFilterState, locationId, debouncedSearch, sorting]);

  useEffect(() => {
    if (!complianceData?.EntityCompliancePagination) return;

    setTotalRowsCount(complianceData?.EntityCompliancePagination?.count);
  }, [complianceData?.EntityCompliancePagination]);

  useEffect(() => {
    if (complianceData?.EntityCompliancePagination) {
      setSelectedDocumentName(
        complianceData?.EntityCompliancePagination?.items
          ?.filter((item) => selectedRowKeys.includes(item?.eid))
          ?.map((item) => item?.title)
      );
    }
  }, [selectedRowKeys]);

  return (
    <Box>
      <Heading />
      <Flex
        justifyContent='space-between'
        gap={4}
        alignItems='center'
        marginY={3}
      >
        <SearchInput
          placeholder='Search by document name'
          size='lg'
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            debouncedSearchHandler(e.target.value);
          }}
          hideShortcuts
        />
        <Flex gap={2}>
          {/* Export compliance documents */}

          {complianceData &&
            complianceData?.EntityCompliancePagination?.items?.length > 0 && (
              <ExportDocuments
                locationId={locationId}
                includeTask
                approvalStatuses={['approved', 'pending']}
              />
            )}

          {/*   Filter Container */}
          {FilterComponent}
        </Flex>
      </Flex>

      {hasSelectedFilters && (
        <FiltersChipContainer
          documentFilterState={documentFilterState}
          setDocumentFilterState={setDocumentFilterState}
        />
      )}

      {selectedRowKeys.length > 0 && firstSelectedRowType === 'other' && (
        <Button
          textColor={'#2A85FF'}
          fontSize={'15px'}
          fontWeight={600}
          variant='ghost'
          leftIcon={
            <Box transform='rotate(-90deg)'>
              <FontAwesomeIcon
                icon={faArrowDownToSquare as IconProp}
                fontSize={'20px'}
                color={'#2A85FF'}
              />
            </Box>
          }
          onClick={() => {
            complianceUpload({
              complianceId: selectedRowKeys,
              moveToCompliance: true,
              preDefinedLocationId: locId,
              onActionComplete: onActionComplete,
            });
          }}
        >
          Move to Compliance
        </Button>
      )}
      {selectedRowKeys.length > 0 && firstSelectedRowType === 'compliance' && (
        <Flex>
          <Button
            leftIcon={
              <Box transform='rotate(-90deg)'>
                <FontAwesomeIcon
                  icon={faArrowDownToSquare as IconProp}
                  fontSize={'20px'}
                  color={'#2A85FF'}
                />
              </Box>
            }
            variant='ghost'
            textColor={'#2A85FF'}
            onClick={() => {
              moveToOther({
                documentId: selectedRowKeys.map(String),
                documentName: selectedDocumentName,
                onActionComplete: onActionComplete,
                markAsOther: false,
              });
            }}
          >
            Move to others
          </Button>
        </Flex>
      )}

      <ConfigProvider
        renderEmpty={() => (
          <>
            <DefaultTableEmptyState
              searchFieldText={debouncedSearch}
              filters={hasSelectedFilters}
              complianceCategoryId={''}
            />
          </>
        )}
      >
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={complianceData?.EntityCompliancePagination.items}
          scroll={{ x: 'max-content' }}
          loading={complianceLoader}
          rowKey={(record) => record.id || record.eid || record.name}
          pagination={{
            total: totalRowsCount,
            current: selectedPageIndex,
            onChange: (page) => {
              setSelectedPageIndex(page);
            },
          }}
        />
      </ConfigProvider>
    </Box>
  );
};

ListContainer.displayName =
  'sub-components/Launcher/document-listing/components/ListContainer';
export default ListContainer;
